import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import MapMarker from './mapMarker';

const purposeList = [
    { id: 1, name: 'Buy (Sale)', purpose: 'wantToSell' },
    { id: 2, name: 'Rent', purpose: 'wantToRent' },
    { id: 3, name: 'Both', purpose: 'bothSellRent' },
]


function Listing() {

    const apiUrl = process.env.REACT_APP_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const Token = localStorage.getItem("Token");

    const [propertyList, setPropertyList] = useState([])
    const [wishlist, setWishlist] = useState([])
    const [user, setUser] = useState({})
    const [photoCount, setPhotoCount] = useState([])
    const [typeOptions, setTypeOptions] = useState([])
    const [stateOptions, setStateOptions] = useState([])
    const [cityOptions, setCityOptions] = useState([])
    const [stateId, setStateId] = useState([])
    const [countryId, setCountryId] = useState([])
    const [countryOptions, setCountryOptions] = useState([])
    const [advancedFilter, setAdvancedFilter] = useState([])
    const [typeId, setTypeId] = useState(1)
    const [categoryOptions, setCategoryOptions] = useState([])
    const [categoryId, setCategoryId] = useState(1)
    const [advancedFilterByObj, setAdvancedFilterByObj] = useState({})
    const [applyAdvance, setApplyAdvance] = useState(false)
    const [open, setOpen] = useState(false)
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [distance, setDistance] = useState(null);
    const [filterByObj, setFilterByObj] = useState({
        type: '',
        category: '',
        purpose: '',
        id: '',
        state: '',
        city: '',
        price: '',
        to: '',
        from: '',
        search: '',
        size: "",
    })


    // A function to handle the change of the input fields
    const handleChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        console.log(name, value)
        setFilterByObj((prev) => ({ ...prev, [name]: value }));

        if (name === 'type') {
            const selectedDataKey = e.target.options[e.target.selectedIndex].getAttribute('dataKey')
            setTypeId(selectedDataKey)
        }

        if (name === 'category') {
            let linkedId = e.target.options[e.target.selectedIndex].getAttribute('linkedMasters')
            setCategoryId(linkedId)
        }

        if (user?.id && window.location.href.split('?')?.[1]) {
            const queryString = window.location.href.split('?')?.[1]
            if (!queryString) {
                console.log("No query parameters found in the URL.");
                return {};
            }
            const paramsArray = queryString.split('&');
            paramsArray.forEach((param) => {
                const [key, value] = param.split('=');
                console.log(key, value, "query parameters found in the URL.");
                filterByObj[key] = value
            })
        }
    };

    // A function to handle the change of the input fields
    const handleAdvanceFilterChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        console.log(name, value)
        setAdvancedFilterByObj((prev) => ({ ...prev, [name]: value }));

        if (name === 'type') {
            const selectedDataKey = e.target.options[e.target.selectedIndex].getAttribute('dataKey')
            setTypeId(selectedDataKey)
        }


        if (name === 'category') {
            let linkedId = e.target.options[e.target.selectedIndex].getAttribute('linkedMasters')
            setCategoryId(linkedId)
        }
    };

    // const [mapUrl, setMapUrl] = useState(null);

    // useEffect(() => {
    //     // Check if geolocation is available in the browser
    //     if ('geolocation' in navigator) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 const { latitude, longitude } = position.coords;
    //                 console.log(latitude , longitude , 'jjjjjjjjj')
    //                 setMapUrl(`https://maps.google.com/maps?q=${latitude}, ${longitude}&z=15&output=embed`)
    //             },
    //             (error) => {
    //                 console.error('Error getting location:', error.message);
    //             }
    //         );
    //     } else {
    //         console.error('Geolocation is not available in this browser.');
    //     }
    // }, []);

    useEffect(() => {
        async function getUser() {
            const url = `${apiUrl}/employee/verify-customer`;

            let response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setUser(response.data)
            }
        }
        getUser();
    }, []);

    useEffect(() => {

        if (window.location.href.split('?')?.[1]) {
            const queryString = window.location.href.split('?')?.[1]
            if (!queryString) {
                console.log("No query parameters found in the URL.");
                return {};
            }

            const paramsArray = queryString.split('&');

            paramsArray.forEach((param) => {
                const [key, value] = param.split('=');
                console.log(key, value, "query parameters found in the URL.");
                filterByObj[key] = value
            })
        }

        fetch(`${apiUrl}/property/property?purpose=${filterByObj.purpose}&type=${filterByObj.type}&state=${filterByObj.state}&city=${filterByObj.city}&price=${filterByObj.price}&category=${filterByObj.category}&size=${filterByObj.size}&search=${filterByObj.search}&advancedFilter=${JSON.stringify(advancedFilterByObj)}`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    setPropertyList(data.data)
                    console.log('fetched data =>', data.data)
                    let count = []
                    for (let j = 0; j < data.data.length; j++) {
                        let temp = 0;
                        for (let i = 0; i < 5; i++) {
                            if (data.data[j][`photo${i + 1}`]) {
                                temp++;
                            }
                        }
                        count = [...count, temp]
                    }
                    console.log(count, 'count=======', advancedFilterByObj)
                    setPhotoCount(count)
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [apiKey, apiUrl, filterByObj, applyAdvance])

    useEffect(() => {
        fetch(`${apiUrl}/category/getAllCategory`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    console.log('dataaaaaaaaaaa', data.data)
                    setTypeOptions(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [])

    useEffect(() => {
        fetch(`${apiUrl}/employee/allCountry`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    console.log('dataaaaaaaaaaa', data.data)
                    setCountryOptions(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [apiKey, apiUrl])

    useEffect(() => {
        if (countryId) {
            fetch(`${apiUrl}/employee/allStates/239`, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data && Array.isArray(data.data)) {
                        console.log('dataaaaaaaaaaa =>', data.data)
                        setStateOptions(data.data);
                    } else {
                        console.error('API response does not contain an array:', data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching masters data:', error);
                });
        }
    }, [apiKey, apiUrl, countryId])


    useEffect(() => {
        if (stateId) {
            fetch(`${apiUrl}/employee/allcities/${stateId}`, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data && Array.isArray(data.data)) {
                        console.log('dataaaaaaaaaaa', data.data)
                        setCityOptions(data.data);
                    } else {
                        console.error('API response does not contain an array:', data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching masters data:', error);
                });
        }
    }, [stateId])


    useEffect(() => {
        fetch(`${apiUrl}/master/getAllMaster`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    setAdvancedFilter(data.data);
                    let newObj = { ...advancedFilterByObj }
                    for (let i = 0; i < data.data.length; i++) {
                        newObj = {
                            ...newObj,
                            [data.data[i].name]: ""
                        }
                    }
                    setAdvancedFilterByObj(newObj)
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [])


    function handleChangeProperty(e) {
        let value = e.target.value

        if (value === 'increase') {

            setPropertyList((prevPropertyList) => {

                let newPropertyList = [...prevPropertyList];

                const sortedProperties = newPropertyList.sort((a, b) => {
                    // Convert maxPrice to numbers for comparison
                    const priceA = parseFloat(a.maxPrice);
                    const priceB = parseFloat(b.maxPrice);

                    // Handle the case where maxPrice is not a number (e.g., "0")
                    if (isNaN(priceA)) return 1;
                    if (isNaN(priceB)) return -1;

                    return priceA - priceB;
                });

                return sortedProperties

            })
        }
        if (value === 'decrease') {

            setPropertyList((prevPropertyList) => {

                let newPropertyList = [...prevPropertyList];

                const sortedProperties = newPropertyList.sort((a, b) => {

                    // Convert maxPrice to numbers for comparison
                    const priceA = parseFloat(a.maxPrice);
                    const priceB = parseFloat(b.maxPrice);

                    // Handle the case where maxPrice is not a number (e.g., "0")
                    if (isNaN(priceA)) return 1;
                    if (isNaN(priceB)) return -1;

                    return priceB - priceA;
                });

                return sortedProperties

            })
        }
    }

    // A function to handle the submit of the property form
    const handleWishlist = async (e, id, userId) => {
        e.preventDefault();
        e.stopPropagation()
        if (!userId) {
            toast.error("Login First")
            return
        }

        try {

            const response = await fetch(`${apiUrl}/property/addToWishlist/${userId}-${id}`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                }
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            setTimeout(() => {
                window.location.reload()
            }, 2500)
        } catch (error) {
            toast.error(error.message);
        }
    };

    // // A function to handle the submit of the property form
    const handleCompare = async (e, id, userId) => {
        e.preventDefault();
        e.stopPropagation()
        if (!userId) {
            toast.error("Login First")
            return
        }
        try {
            const response = await fetch(`${apiUrl}/property/addToCompare/${userId}-${id}`, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                }
            });
            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            setTimeout(() => {
                window.location.reload()
            }, 2500)
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {

        if (user?.id && window.location.href.split('?')?.[1]) {
            const queryString = window.location.href.split('?')?.[1]
            if (!queryString) {
                console.log("No query parameters found in the URL.");
                return {};
            }

            const paramsArray = queryString.split('&');

            paramsArray.forEach((param) => {
                const [key, value] = param.split('=');
                console.log(key, value, "query parameters found in the URL.");
                setFilterByObj((prev) => ({ ...prev, [key]: value }));
            })
        }

    }, [user])



    useEffect(() => {
        async function getWishlist() {
            const url = `${apiUrl}/property/getWishlist/${user.id}`;


            let response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setWishlist(response.wishlistProperty)
                if (Array.isArray(response.wishlistProperty)) {
                    console.log("wishlistProperty is an array");
                } else {
                    console.log("wishlistProperty is not an array");
                }
            }
        }
        if (user?.id) {
            getWishlist()
        }

    }, [user, apiUrl, Token]);



    useEffect(() => {
        fetch(`${apiUrl}/category/getAllCategoryData/${typeId}`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    console.log('dataaaaaaaaaaa', data.data)
                    setCategoryOptions(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [typeId, apiKey, apiUrl])


    return (
        <div>
            {/*=============== basic  ===============*/}\
            {/*=============== css  ===============*/}
            <link type="text/css" rel="stylesheet" href="css/plugins.css" />
            <link type="text/css" rel="stylesheet" href="css/style.css" />
            <link type="text/css" rel="stylesheet" href="css/color.css" />
            {/*=============== favicons ===============*/}
            <link rel="shortcut icon" href="images/favicon.ico" />
            {/*loader*/}
            <div className="loader-wrap">
                <div className="loader-inner">
                    <svg>
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
                                <feColorMatrix
                                    in="blur"
                                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                                    result="gooey"
                                />
                                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div>
            {/*loader end*/}
            {/* main */}
            <div id="main">
                {/* wrapper  */}
                <div id="wrapper">
                    {/* content */}
                    <div className="content">
                        {/* categoties-column */}
                        <div className="categoties-column" style={{ display: 'none' }}>
                            <div className="categoties-column_container cat-list">
                                <ul>
                                    <li className='mt-3'>
                                        <a
                                            href="#1"
                                            name="purpose"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setFilterByObj((prev) => ({ ...prev, 'purpose': '' }));
                                            }}
                                        >
                                            <i className="fa-solid fa-sliders-h" /><br />All
                                            <span>Looking for</span>
                                        </a>
                                    </li>
                                    <li className='mt-3'>
                                        <a
                                            href="#1"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setFilterByObj((prev) => ({ ...prev, 'purpose': 'wantToSell' }));
                                            }}
                                        >
                                            <i className="fa-solid fa-sliders-h" /><br />Buy
                                            <span>Buy</span>
                                        </a>
                                    </li>
                                    <li className='mt-3'>
                                        <a
                                            href="#1"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setFilterByObj((prev) => ({ ...prev, 'purpose': 'wantToRent' }));
                                            }}
                                        >
                                            <i className="fa-solid fa-sliders-h" /><br />Rent
                                            <span>Rent</span>
                                        </a>
                                    </li>
                                    <li className='mt-3'>
                                        <a
                                            href="#1"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setFilterByObj((prev) => ({ ...prev, 'purpose': 'bothSellRent' }));
                                            }}
                                        >
                                            <i className="fa-solid fa-sliders-h" /><br />Both
                                            <span>Both</span>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="#">
                                            <i className="fa-solid  fa-home" />
                                            <span>House</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fa-solid  fa-hotel" />
                                            <span>Hotel</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fa-solid  fa-warehouse" />
                                            <span>Villa</span>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="progress-indicator">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                                    <circle
                                        cx={16}
                                        cy={16}
                                        r="15.9155"
                                        className="progress-bar__background"
                                    />
                                    <circle
                                        cx={16}
                                        cy={16}
                                        r="15.9155"
                                        className="progress-bar__progress 
                              js-progress-bar"
                                    />
                                </svg>
                            </div>
                        </div>
                        {/* categoties-column end */}
                        {/* Map */}
                        {/* <div className="map-container column-map hid-mob-map">
                            <iframe
                                src={mapUrl ? mapUrl : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.7422365569396!2d77.43369877549985!3d28.607508675678467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4faf77fb773%3A0xe0809fc938620cad!2sWEBKYPE!5e0!3m2!1sen!2sin!4v1706864052846!5m2!1sen!2sin"}
                                width="100%"
                                height={650}
                                style={{ border: 0 }}
                                allowFullScreen
                                title='map'
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />
                        </div> */}
                        <div className="map-container column-map hid-mob-map">
                            <MapMarker
                                propertyList={propertyList}
                                selectedProperty={selectedProperty}
                                setSelectedProperty={setSelectedProperty}
                                distance={distance}
                                setDistance={setDistance}
                            />
                        </div>
                        {/* Map end */}
                        {/* top-search-content */}
                        <div className="top-search-content">
                            <div className="top-search-dec color-bg" style={{ display: 'none' }}>
                                <i className="fa-solid fa-sliders-h" />
                            </div>
                            <div className="top-search-content-title">Search:</div>
                            <div className="close_sb-filter">
                                <i className="fa-solid  fa-times" />
                            </div>
                            <div className="custom-form fl-wrap">
                                <div className="row" style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                    {/* listsearch-input-item */}
                                    {/* <div className="col-sm-3">
                                        <div className="listsearch-input-item">
                                            <input
                                                type="text"
                                                onclick="this.select()"
                                                placeholder="Address , Street , State..."
                                                defaultValue
                                            />
                                        </div>
                                    </div> */}
                                    {/* listsearch-input-item */}
                                    {/* listsearch-input-item */}




                                    <div className="col-sm-2 mt-2">
                                        <div className="listsearch-input-item">
                                            <select
                                                data-placeholder="Categories"
                                                className="form-select form-control"
                                                name='type'
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                            >
                                                <option>All Categories</option>
                                                {typeOptions.map((department) => (
                                                    <option key={department.id} value={department.name} dataKey={department.id}>
                                                        {department.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {/* listsearch-input-item */}
                                    {/* listsearch-input-item */}
                                    <div className="col-sm-2 mt-2">
                                        <div className="listsearch-input-item">
                                            <select
                                                data-placeholder="Sub Category"
                                                className="form-select"
                                                name='category'
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                            >
                                                <option value="">All Sub Category</option>
                                                {categoryOptions.map((department) => (
                                                    <option key={department.id} value={department.name} dataKey={department.id}>
                                                        {department.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {/* listsearch-input-item */}
                                    {/* listsearch-input-item */}
                                    {/* <div className="col-sm-2 mt-2">
                                        <div className="listsearch-input-item">
                                            <select
                                                data-placeholder="Status"
                                                className="form-select"
                                                name='purpose'
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                            >
                                                <option value="">All Availability</option>
                                                <option value="wantToRent">For Rent</option>
                                                <option value="wantToSell">For Sale</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    {/* listsearch-input-item */}
                                    {/* listsearch-input-item */}

                                    {/* <div className="col-sm-2 mt-2">
                                        <div className="listsearch-input-item">
                                            <select
                                                data-placeholder="All Countries"
                                                name='country'
                                                className="form-select"
                                                onChange={(e) => {
                                                    setCountryId(e.target.value)
                                                    handleChange(e)
                                                }}
                                            >
                                                <option>All Countries</option>
                                                {countryOptions.map((department) => (
                                                    <option
                                                        key={department.id}
                                                        value={department.id}
                                                    // selected={department.name === 'Spain'}
                                                    >
                                                        {department.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div> */}


                                    <div className="col-sm-2 mt-2">
                                        <div className="listsearch-input-item">
                                            <select
                                                data-placeholder="All States"
                                                name='state'
                                                className="form-select"
                                                onChange={(e) => {
                                                    setStateId(e.target.value)
                                                    handleChange(e)
                                                }}
                                            >
                                                <option>All States</option>
                                                {stateOptions.map((department) => (
                                                    <option
                                                        key={department.id}
                                                        value={department.id}
                                                    >
                                                        {department.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {/* listsearch-input-item */}
                                    {/* listsearch-input-item */}
                                    <div className="col-sm-2 mt-2">
                                        <div className="listsearch-input-item">
                                            <select
                                                data-placeholder="All Cities"
                                                className="form-select"
                                                name='city'
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                            >
                                                <option value={""}>All Cities</option>
                                                {cityOptions.map((department) => (
                                                    <option key={department.id} value={department.id}>
                                                        {department.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {/* listsearch-input-item */}
                                    <div className="col-sm-2 mt-2">
                                        <div className="listsearch-input-item">
                                            <select
                                                data-placeholder="Purpose"
                                                className="form-select"
                                                name='purpose'
                                                onChange={(e) => {
                                                    const value = e.target.value
                                                    setFilterByObj((prev) => ({ ...prev, 'purpose': value }));
                                                }}
                                            >
                                                <option value={""}>All Availability</option>
                                                {purposeList.map((purpose) => (
                                                    <option key={purpose.id} value={purpose.purpose}>
                                                        {purpose.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {/* listsearch-input-item */}
                                    {/* <div className="col-sm-2">
                                        <div className="listsearch-input-item">
                                            <div className="price-rage-item fl-wrap">
                                                <span className="pr_title">Price: <br />$ 0 to {filterByObj.price ? filterByObj.price : 'max'}</span>
                                                <input
                                                    type="range"
                                                    className="w-80"
                                                    min={1000}
                                                    max={1000000}
                                                    name="price"
                                                    step={100}
                                                    data-prefix="$"
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <label style={{ marginTop: 10 }}>Price Range</label>
                                        <div className="price-rage-item fl-wrap">
                                            <input
                                                type="range"
                                                className="w-100"
                                                min={100}
                                                max={100000}
                                                name="price"
                                                step={1}
                                                defaultValue={1}
                                                data-prefix="$"
                                                onChange={(e) => {
                                                    // handleChange(e)
                                                }}
                                            />
                                        </div>
                                    </div> */}
                                    {/* listsearch-input-item */}
                                    {/* <div className="col-sm-2">
                                        <div className="listsearch-input-item">
                                            <div className="price-range-item fl-wrap">
                                                <span className="pr_title">Size: <br />0 to {filterByObj.size ? filterByObj.size : 'max'}</span>
                                                <input
                                                    type="range"
                                                    className="w-50"
                                                    min={10}
                                                    max={10000}
                                                    name="size"
                                                    step={1}
                                                    data-prefix="$"
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <label style={{ marginTop: 10 }}>Price Range</label>
                                        <div className="price-rage-item fl-wrap">
                                            <input
                                                type="range"
                                                className="w-100"
                                                min={100}
                                                max={100000}
                                                name="price"
                                                step={1}
                                                defaultValue={1}
                                                data-prefix="$"
                                                onChange={(e) => {
                                                    // handleChange(e)
                                                }}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="more-opt_btn mor-opt-btn_act">
                                <i className="fa-solid fa-sliders-h" />
                                <span>
                                    <i className="fa-solid fa-caret-down" />
                                </span>
                            </div>
                            {/* more-search-opt-wrap */}
                            <div
                                className={`more-search-opt-wrap ${open === false ? 'more-hidden_wrap' : 'vis-more-search'}`}
                                style={{ height: '70vh', overflow: 'scroll' }}
                            >
                                <div className="msotw_title fl-wrap" style={{
                                    width: "fit-content",
                                    marginTop: "15px"
                                }}>
                                    <i className="fa-solid fa-sliders-h" />
                                    Advanced Search
                                </div>

                                <div style={{
                                    display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '30px'
                                }}>

                                    <div className="col-sm-2">
                                        <div className="listsearch-input-item">
                                            <div className="price-rage-item fl-wrap" >
                                                <div style={{ display: 'flex', marginTop: '20px', marginLeft: '30px' }}>
                                                    <span className="pr_title" style={{ fontWeight: "700" }}>Price: <br />$ 0 to {filterByObj.price ? filterByObj.price : 'max'}</span>
                                                    <input
                                                        type="range"
                                                        className="w-80"
                                                        min={1000}
                                                        max={1000000}
                                                        name="price"
                                                        step={100}
                                                        data-prefix="$"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="listsearch-input-item">
                                            <div className="price-range-item fl-wrap">
                                                <div style={{
                                                    display: 'flex', marginTop: '20px', justifyContent: 'end',
                                                }}>
                                                    <span className="pr_title" style={{ fontWeight: "700" }}>Size: <br />0 to {filterByObj.size ? filterByObj.size : 'max'}</span>
                                                    <input
                                                        type="range"
                                                        className="w-50"
                                                        min={10}
                                                        max={10000}
                                                        name="size"
                                                        step={1}
                                                        data-prefix="$"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="close_msotw">
                                    <i className="fa-solid  fa-times" />
                                </div>
                                <div className="clearfix" />
                                <div className="row">
                                    {/* listsearch-input-item */}
                                    {/* <div className="col-sm-2">
                                        <div className="listsearch-input-item">
                                            <label>Bedrooms</label>
                                            <select
                                                data-placeholder="Bedrooms"
                                                className="chosen-select on-radius no-search-select"
                                            >
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    {/* listsearch-input-item end*/}
                                    {/* listsearch-input-item */}
                                    {/* <div className="col-sm-2">
                                        <div className="listsearch-input-item">
                                            <label>Bathrooms</label>
                                            <select
                                                data-placeholder="Bathrooms"
                                                className="chosen-select on-radius no-search-select"
                                            >
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    {/* listsearch-input-item end*/}
                                    {/* listsearch-input-item */}
                                    {/* <div className="col-sm-2">
                                        <div className="listsearch-input-item">
                                            <label>Floors</label>
                                            <select
                                                data-placeholder="Bathrooms"
                                                className="chosen-select on-radius no-search-select"
                                            >
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    {/* listsearch-input-item end*/}



                                    {advancedFilter.map((listDet) => (

                                        (listDet?.type !== 'Multiple Select') && <div className='col-2' >
                                            <label className='mt-3' style={{
                                                display: "flex",
                                                fontWeight: "650",
                                                marginBottom: "5px",
                                                color: "gray",
                                                fontSize: "13px",
                                            }}>{listDet?.name}: </label>
                                            <select
                                                data-placeholder="Apartments"
                                                className="form-select"
                                                name={listDet?.name}
                                                onChange={(e) => {
                                                    handleAdvanceFilterChange(e)
                                                }}
                                                style={{ fontSize: "small" }}
                                            >
                                                <option >Select</option>
                                                {listDet?.Data.map((department) => (
                                                    <option key={department.id} value={department.name}>
                                                        {department.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                    ))}
                                    {/* listsearch-input-item */}
                                    {/* listsearch-input-item */}
                                    {/* <div className="col-sm-2 mt-2">
                                        <div className="listsearch-input-item">
                                            <label>Property Id</label>
                                            <input
                                                type="text"
                                                onclick="this.select()"
                                                placeholder="Id"
                                                defaultValue
                                            />
                                        </div>
                                    </div> */}
                                    {/* listsearch-input-item end*/}
                                    {/* listsearch-input-item */}
                                </div>
                                <div className="clearfix" />
                                {/* listsearch-input-item*/}
                                {/* <div className="listsearch-input-item clact">
                                    <label>Amenities</label>
                                    <div className=" fl-wrap filter-tags">
                                        <ul className="no-list-style">
                                            <li>
                                                <input id="check-aa" type="checkbox" name="check" />
                                                <label htmlFor="check-aa">Elevator in building</label>
                                            </li>
                                            <li>
                                                <input id="check-b" type="checkbox" name="check" />
                                                <label htmlFor="check-b"> Laundry Room</label>
                                            </li>
                                            <li>
                                                <input
                                                    id="check-c"
                                                    type="checkbox"
                                                    name="check"
                                                    defaultChecked
                                                />
                                                <label htmlFor="check-c">Equipped Kitchen</label>
                                            </li>
                                            <li>
                                                <input id="check-d" type="checkbox" name="check" />
                                                <label htmlFor="check-d">Air Conditioned</label>
                                            </li>
                                            <li>
                                                <input
                                                    id="check-d2"
                                                    type="checkbox"
                                                    name="check"
                                                    defaultChecked
                                                />
                                                <label htmlFor="check-d2">Parking</label>
                                            </li>
                                            <li>
                                                <input
                                                    id="check-d3"
                                                    type="checkbox"
                                                    name="check"
                                                    defaultChecked
                                                />
                                                <label htmlFor="check-d3">Swimming Pool</label>
                                            </li>
                                            <li>
                                                <input id="check-d4" type="checkbox" name="check" />
                                                <label htmlFor="check-d4">Fitness Gym</label>
                                            </li>
                                            <li>
                                                <input id="check-d5" type="checkbox" name="check" />
                                                <label htmlFor="check-d5">Security</label>
                                            </li>
                                            <li>
                                                <input id="check-d6" type="checkbox" name="check" />
                                                <label htmlFor="check-d6">Garage Attached</label>
                                            </li>
                                            <li>
                                                <input id="check-d7" type="checkbox" name="check" />
                                                <label htmlFor="check-d7">Back yard</label>
                                            </li>
                                            <li>
                                                <input id="check-d8" type="checkbox" name="check" />
                                                <label htmlFor="check-d8">Fireplace</label>
                                            </li>
                                            <li>
                                                <input id="check-d9" type="checkbox" name="check" />
                                                <label htmlFor="check-d9">Window Covering</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}


                                {advancedFilter?.map((listDet) => (
                                    listDet.type === 'Multiple Select' && (
                                        <div className=" add-list-tags fl-wrap" style={{ marginTop: '10px', paddingBottom: '20px', borderBottom: '2px solid #F5F7FB' }}>
                                            <label className='mt-3' style={{
                                                display: 'block',
                                                paddingBottom: '10px',
                                                textAlign: 'left',
                                                fontWeight: '600',
                                                fontSize: '15px',
                                                color: '#70778b'
                                            }} >{listDet.name} </label>
                                            <ul className="fl-wrap filter-tags no-list-style ds-tg"
                                                style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                                                    gap: "5px"
                                                }}
                                            >
                                                {listDet.Data.map((department) => (
                                                    <li className='m-1'>
                                                        <input
                                                            id="check-aaa5"
                                                            type="checkbox"
                                                            name={department.name}
                                                            value={department.name}
                                                            onChange={(e) => {
                                                                let data = advancedFilterByObj
                                                                // console.log(listDet.name)
                                                                if (e.target.checked) {
                                                                    data[listDet.name] = data[listDet.name] ? `${data[listDet.name]},${e.target.value}` : `${e.target.value}`
                                                                    setAdvancedFilterByObj(data);
                                                                } else {
                                                                    let str = data[listDet.name]
                                                                    str = str.split(',')
                                                                    str = str.filter(t => t !== e.target.value)
                                                                    str = str.join(',')
                                                                    data[listDet.name] = str
                                                                    setAdvancedFilterByObj(data)
                                                                }
                                                            }}
                                                        />
                                                        <label htmlFor="check-aaa5" style={{ fontSize: "smaller" }}>{department.name}</label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )
                                ))}
                                {/* listsearch-input-item end*/}
                                <div>
                                    <a
                                        href="#1"
                                        className="btn small-btn float-btn color-bg"
                                        onClick={(e) => {
                                            setOpen(true)
                                            e.preventDefault()
                                            setApplyAdvance(!applyAdvance)
                                            console.log(advancedFilterByObj, 'advancedFilterByObj')
                                        }}
                                    >
                                        Search
                                    </a>

                                    <div
                                        className='float-btn'
                                        style={{ marginTop: '25px', marginLeft: "10px" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            const emptyObject = {};
                                            for (const key in advancedFilterByObj) {
                                                emptyObject[key] = '';
                                            }
                                            setAdvancedFilterByObj(emptyObject);
                                        }}
                                    >
                                        {" "}
                                        <i className="fa-solid fa-sync-alt" /> Reset Filters
                                    </div>
                                </div>
                            </div>
                            {/* more-search-opt-wrap end */}
                        </div>
                        {/* top-search-content end */}
                        {/* col-list-wrap */}
                        <div className="col-list-wrap gray-bg ">
                            <div className="col-list-wrap_opt fl-wrap">
                                <div className="show-hidden-filter col-list-wrap_opt_btn color-bg">
                                    Show Filters
                                </div>
                                <div className="show-hidden-map not-vis_lap col-list-wrap_opt_btn color-bg">
                                    Show Map
                                </div>
                            </div>
                            {/* list-main-wrap-header*/}
                            <div className="list-main-wrap-header fl-wrap fixed-listing-header">
                                <div className="container">
                                    {/* list-main-wrap-title*/}
                                    <div className="list-main-wrap-title">
                                        <h2>
                                            Total Results :
                                            <strong>{propertyList?.length}</strong>
                                        </h2>
                                    </div>
                                    {/* list-main-wrap-title end*/}
                                    {/* list-main-wrap-opt*/}
                                    <div className="list-main-wrap-opt">
                                        {/* price-opt*/}
                                        <div className="price-opt">
                                            <span className="price-opt-title">Sort by:</span>
                                            <div
                                                className="listsearch-input-item"
                                            >
                                                <select
                                                    data-placeholder="Popularity"
                                                    className="form-select mt-2"
                                                    onChange={(e) => {
                                                        handleChangeProperty(e)
                                                    }}
                                                >
                                                    <option>Popularity</option>
                                                    <option value='increase' >Price: low to high</option>
                                                    <option value='decrease' >Price: high to low</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* price-opt end*/}
                                        {/* price-opt*/}
                                        <div className="grid-opt">
                                            <ul className="no-list-style">
                                                <li className="grid-opt_act">
                                                    <span
                                                        className="two-col-grid act-grid-opt tolt"
                                                        data-microtip-position="bottom"
                                                        data-tooltip="Grid View"
                                                    >
                                                        <i className="fa-solid fa-th" />
                                                    </span>
                                                </li>
                                                <li className="grid-opt_act">
                                                    <span
                                                        className="one-col-grid tolt"
                                                        data-microtip-position="bottom"
                                                        data-tooltip="List View"
                                                    >
                                                        <i className="fa-solid fa-list" />
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* price-opt end*/}
                                    </div>
                                    {/* list-main-wrap-opt end*/}
                                </div>
                            </div>
                            {/* list-main-wrap-header end*/}
                            {/* listing-item-wrap*/}
                            <div className="listing-item-container fl-wrap">
                                <div>
                                    {propertyList.map((prop, i) => (
                                        <div
                                            className={`listing-item `}
                                            role='button'
                                            onClick={() => {
                                                if (selectedProperty?.id === prop?.id) {
                                                    window.location.href = prop.slug
                                                } else {
                                                    setSelectedProperty(prop)
                                                    setDistance(null)
                                                }
                                            }}
                                        >
                                            {/* {console.log('propertyList =>', propertyList)} */}
                                            <article className="geodir-category-listing fl-wrap">
                                                <div className="geodir-category-img fl-wrap">
                                                    <div
                                                        className="geodir-category-img_item"
                                                        style={{ padding: '2px' }}
                                                    >
                                                        <p style={{ paddingBottom: '2px' }}>
                                                            <img src={`${apiUrl}/uploads/${prop.photo1 ? prop.photo1 : prop.photo2}`} alt="property" style={{ height: '150px' }} />
                                                            {/* <div className="overlay" /> */}
                                                        </p>
                                                        <div className='geodir-category-img_item' style={{ display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ paddingRight: '1px', paddingBottom: "0px" }}>
                                                                <img src={`${apiUrl}/uploads/${prop.photo2 ? prop.photo2 : prop.photo3}`} alt="property" style={{ height: '150px', width: '187.5px' }} />
                                                                {/* <div className="overlay" /> */}
                                                            </p>
                                                            <p style={{ paddingLeft: '1px', paddingBottom: "0px" }}>
                                                                <img src={`${apiUrl}/uploads/${prop.photo3 ? prop.photo3 : prop.photo4}`} alt="property" style={{ height: '150px', width: '187.5px' }} />
                                                                {/* <div className="overlay" /> */}
                                                            </p>
                                                        </div>

                                                    </div>

                                                    {/* <p
                                                        className="geodir-category-img_item"
                                                        style={{ padding: '5px' }}
                                                    >
                                                        <img src={`${apiUrl}/uploads/${prop.photo1 ? prop.photo1 : prop.photo2}`} alt="property" style={{ height: '250px' }} />
                                                        <div className="overlay" />
                                                    </p> */}

                                                    <div className="geodir-category-location">
                                                        <a
                                                            href="#1"
                                                            className="map-item tolt"
                                                            data-microtip-position="top-left"
                                                            data-tooltip="On the map"
                                                        >
                                                            <FontAwesomeIcon icon="fa-solid  fa-map-marker-alt" /> {prop.cities.name} , {prop.states.name}
                                                        </a>
                                                    </div>
                                                    <ul className="list-single-opt_header_cat">
                                                        {prop.purpose === 'bothSellRent' &&
                                                            <li>

                                                                <a href="#2" className="cat-opt blue-bg">
                                                                    Rent & Sale
                                                                </a>
                                                            </li>
                                                        }
                                                        {prop.purpose !== 'bothSellRent' &&
                                                            <li>

                                                                <a href="#2" className="cat-opt blue-bg">
                                                                    {prop.purpose !== 'wantToRent' ? 'Sale' : 'Rent'}
                                                                </a>
                                                            </li>
                                                        }


                                                        {/* <li>
                                                            <a href="#3" className="cat-opt color-bg">
                                                                {prop.type}
                                                            </a>
                                                        </li> */}
                                                        <li>
                                                            <a href="#" className="cat-opt color-bg">
                                                                {prop.category}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <p
                                                        className={`geodir_save-btn tolt text-center ${wishlist?.find((item) => item === prop.id) ? "text-danger" : ""}`}
                                                        data-microtip-position="left"
                                                        data-tooltip="Save"
                                                        onClick={(e) => {
                                                            handleWishlist(e, prop.id, user.id)
                                                        }}
                                                    >
                                                        <span>
                                                            <FontAwesomeIcon icon="fa-solid fa-heart" />
                                                        </span>
                                                    </p>
                                                    <p
                                                        className="compare-btn tolt text-center"
                                                        data-microtip-position="left"
                                                        data-tooltip="Compare"
                                                        onClick={(e) => {
                                                            handleCompare(e, prop.id, user.id)
                                                        }}
                                                    >
                                                        <span>
                                                            <FontAwesomeIcon icon="fa-solid  fa-random" />
                                                        </span>
                                                    </p>

                                                    <div className="geodir-category-listing_media-list">
                                                        <span>
                                                            <FontAwesomeIcon icon="fa-solid  fa-camera" /> {photoCount[i]}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`geodir-category-content fl-wrap ${selectedProperty?.id === prop?.id ? 'property-list-selected' : ''} `}
                                                >
                                                    <h3>
                                                        {
                                                            prop.name ?
                                                                <>{prop.name.slice(0, 30)}</> :
                                                                <>Gorgeous house for sale</>
                                                        }
                                                    </h3>
                                                    {prop.purpose !== 'wantToRent' ? <div className="geodir-category-content_price">
                                                        $ {prop.maxPrice}
                                                    </div> : <div className="geodir-category-content_price">
                                                        $ {prop.rentalPrice}/Month
                                                    </div>
                                                    }

                                                    {
                                                        prop.description ?
                                                            <p>{prop.description.slice(0, 50)}...</p> :
                                                            <p>
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                                                                Donec a consectetur nulla.
                                                            </p>
                                                    }
                                                    <div className="geodir-category-content-details">
                                                        <ul>
                                                            <li>
                                                                <FontAwesomeIcon icon="fa-solid  fa-bed" />
                                                                <span>{" "}{prop?.listingDetails?.Bedrooms}</span>
                                                            </li>
                                                            <li>
                                                                <FontAwesomeIcon icon="fa-solid  fa-bath" />
                                                                <span>{" "}{prop?.listingDetails?.Bathroom}</span>
                                                            </li>
                                                            <li>
                                                                <FontAwesomeIcon icon="fa-solid  fa-cube" />
                                                                <span>{" "}{prop.maxSize} Sq Ft</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="geodir-category-footer fl-wrap">
                                                        <a href={'#'} className="gcf-company">
                                                            <img src={prop?.Customer?.userDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.userDetails[0]?.photo}` : (prop?.Customer?.agentDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agentDetails[0]?.photo}` : (prop?.Customer?.agencyDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agencyDetails[0]?.photo}` : "images/avatar/2.jpg"))} style={{ objectFit: 'cover' }} />
                                                            {/* <img src={prop?.Customer?.userDetails ? `${apiUrl}/uploads/${prop?.Customer?.userDetails[0]?.photo}` : "images/avatar/2.jpg"} alt="poster" /> */}
                                                            <span>By {prop.postedBy}</span>
                                                        </a>
                                                        {/* <div
                                                            className="listing-rating card-popup-rainingvis"
                                                            data-microtip-position="top"
                                                            data-tooltip="Good"
                                                            data-starrating2={4}
                                                        /> */}
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    ))}
                                </div>

                                {/* <div className="listing-item">
                                    <article className="geodir-category-listing fl-wrap">
                                        <div className="geodir-category-img fl-wrap">
                                            <a
                                                href="listing-single.html"
                                                className="geodir-category-img_item"
                                            >
                                                <img src="images/all/3.jpg" alt="property-image" />
                                                <div className="overlay" />
                                            </a>
                                            <div className="geodir-category-location">
                                                <a
                                                    href="#1"
                                                    className="map-item tolt"
                                                    data-microtip-position="top-left"
                                                    data-tooltip="On the map"
                                                >
                                                    <FontAwesomeIcon icon="fa-solid  fa-map-marker-alt" /> 70 Bright St New
                                                    York, USA
                                                </a>
                                            </div>
                                            <ul className="list-single-opt_header_cat">
                                                <li>
                                                    <a href="#" className="cat-opt blue-bg">
                                                        Sale
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="cat-opt color-bg">
                                                        Apartment
                                                    </a>
                                                </li>
                                            </ul>
                                            <a
                                                href="#"
                                                className="geodir_save-btn tolt"
                                                data-microtip-position="left"
                                                data-tooltip="Save"
                                            >
                                                <span>
                                                    <FontAwesomeIcon icon="fa-solid  fa-heart" />
                                                </span>
                                            </a>
                                            <a
                                                href="#"
                                                className="compare-btn tolt"
                                                data-microtip-position="left"
                                                data-tooltip="Compare"
                                            >
                                                <span>
                                                    <FontAwesomeIcon icon="fa-solid  fa-random" />
                                                </span>
                                            </a>
                                            <div className="geodir-category-listing_media-list">
                                                <span>
                                                    <FontAwesomeIcon icon="fa-solid  fa-camera" /> 8
                                                </span>
                                            </div>
                                        </div>
                                        <div className="geodir-category-content fl-wrap">
                                            <h3>
                                                <a href="listing-single.html">Gorgeous house for sale</a>
                                            </h3>
                                            <div className="geodir-category-content_price">$ 600,000</div>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                                                Donec a consectetur nulla.
                                            </p>
                                            <div className="geodir-category-content-details">
                                                <ul>
                                                    <li>
                                                        <FontAwesomeIcon icon="fa-solid  fa-bed" />
                                                        <span>3</span>
                                                    </li>
                                                    <li>
                                                        <FontAwesomeIcon icon="fa-solid  fa-bath" />
                                                        <span>2</span>
                                                    </li>
                                                    <li>
                                                        <FontAwesomeIcon icon="fa-solid  fa-cube" />
                                                        <span>450 ft2</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="geodir-category-footer fl-wrap">
                                                <a href="agent-single.html" className="gcf-company">
                                                    <img src="images/avatar/2.jpg" alt />
                                                    <span>By Liza Rose</span>
                                                </a>
                                                <div
                                                    className="listing-rating card-popup-rainingvis"
                                                    data-microtip-position="top"
                                                    data-tooltip="Good"
                                                    data-starrating2={4}
                                                />
                                            </div>
                                        </div>
                                    </article>
                                </div> */}
                                {/* listing-item end*/}
                            </div>
                            {/* listing-item-wrap end*/}
                            {/* pagination*/}
                            {/* <div className="pagination">
                                <a href="#" className="prevposts-link">
                                    <i className="fa fa-caret-left" />
                                </a>
                                <a href="#">1</a>
                                <a href="#" className="current-page">
                                    2
                                </a>
                                <a href="#">3</a>
                                <a href="#">4</a>
                                <a href="#" className="nextposts-link">
                                    <i className="fa fa-caret-right" />
                                </a>
                            </div> */}
                            {/* pagination end*/}
                            <div className="small-footer fl-wrap">
                                <div className="copyright">
                                    {" "}
                                    © Webkype 2024 . All rights reserved.
                                </div>
                            </div>
                        </div>
                        {/* col-list-wrap end */}
                    </div>
                    {/* content end */}
                </div>
                {/* wrapper end */}
                {/*register form */}
                {/* <div className="main-register-wrap modal">
                    <div className="reg-overlay" />
                    <div className="main-register-holder tabs-act">
                        <div className="main-register-wrapper modal_main fl-wrap">
                            <div className="main-register-header color-bg">
                                <div className="main-register-logo fl-wrap">
                                    <img src="images/white-logo.png" alt />
                                </div>
                                <div className="main-register-bg">
                                    <div className="mrb_pin" />
                                    <div className="mrb_pin mrb_pin2" />
                                </div>
                                <div className="mrb_dec" />
                                <div className="mrb_dec mrb_dec2" />
                            </div>
                            <div className="main-register">
                                <div className="close-reg">
                                    <i className="fa-solid  fa-times" />
                                </div>
                                <ul className="tabs-menu fl-wrap no-list-style">
                                    <li className="current">
                                        <a href="#tab-1">
                                            <i className="fa-solid  fa-sign-in-alt" /> Login
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#tab-2">
                                            <i className="fa-solid  fa-user-plus" /> Register
                                        </a>
                                    </li>
                                </ul>
                                <div className="tabs-container">
                                    <div className="tab">
                                        <div id="tab-1" className="tab-content first-tab">
                                            <div className="custom-form">
                                                <form method="post" name="registerform">
                                                    <label>
                                                        Username or Email Address *{" "}
                                                        <span className="dec-icon">
                                                            <i className="fa-solid  fa-user" />
                                                        </span>
                                                    </label>
                                                    <input
                                                        name="email"
                                                        type="text"
                                                        onclick="this.select()"
                                                        defaultValue
                                                    />
                                                    <div className="pass-input-wrap fl-wrap">
                                                        <label>
                                                            Password *{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid  fa-key" />
                                                            </span>
                                                        </label>
                                                        <input
                                                            name="password"
                                                            type="password"
                                                            autoComplete="off"
                                                            onclick="this.select()"
                                                            defaultValue
                                                        />
                                                        <span className="eye">
                                                            <i className="fa-solid  fa-eye" />{" "}
                                                        </span>
                                                    </div>
                                                    <div className="lost_password">
                                                        <a href="#">Lost Your Password?</a>
                                                    </div>
                                                    <div className="filter-tags">
                                                        <input id="check-a3" type="checkbox" name="check" />
                                                        <label htmlFor="check-a3">Remember me</label>
                                                    </div>
                                                    <div className="clearfix" />
                                                    <button type="submit" className="log_btn color-bg">
                                                        {" "}
                                                        LogIn{" "}
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="tab">
                                            <div id="tab-2" className="tab-content">
                                                <div className="custom-form">
                                                    <form
                                                        method="post"
                                                        name="registerform"
                                                        className="main-register-form"
                                                        id="main-register-form2"
                                                    >
                                                        <label>
                                                            Full Name *{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid  fa-user" />
                                                            </span>
                                                        </label>
                                                        <input
                                                            name="name"
                                                            type="text"
                                                            onclick="this.select()"
                                                            defaultValue
                                                        />
                                                        <label>
                                                            Email Address *{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid  fa-envelope" />
                                                            </span>
                                                        </label>
                                                        <input
                                                            name="email"
                                                            type="text"
                                                            onclick="this.select()"
                                                            defaultValue
                                                        />
                                                        <div className="pass-input-wrap fl-wrap">
                                                            <label>
                                                                Password *{" "}
                                                                <span className="dec-icon">
                                                                    <i className="fa-solid  fa-key" />
                                                                </span>
                                                            </label>
                                                            <input
                                                                name="password"
                                                                type="password"
                                                                autoComplete="off"
                                                                onclick="this.select()"
                                                                defaultValue
                                                            />
                                                            <span className="eye">
                                                                <i className="fa-solid  fa-eye" />{" "}
                                                            </span>
                                                        </div>
                                                        <div className="filter-tags ft-list">
                                                            <input id="check-a2" type="checkbox" name="check" />
                                                            <label htmlFor="check-a2">
                                                                I agree to the <a href="#">Privacy Policy</a> and{" "}
                                                                <a href="#">Terms and Conditions</a>
                                                            </label>
                                                        </div>
                                                        <div className="clearfix" />
                                                        <button type="submit" className="log_btn color-bg">
                                                            {" "}
                                                            Register{" "}
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="log-separator fl-wrap">
                                        <span>or</span>
                                    </div>
                                    <div className="soc-log fl-wrap">
                                        <p>For faster login or register use your social account.</p>
                                        <a href="#" className="facebook-log">
                                            {" "}
                                            Facebook
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/*register form end */}
            </div>
            {/* Main end */}
            {/*=============== scripts  ===============*/}
        </div >
    )
}

export default Listing
