import React, { useEffect, useState } from "react";
import SidePanel from "./SIdePanel";
import ProfileHeader from "./ProfileHeader";
import ProfileFooter from "./ProfileFooter";
import { json } from "react-router-dom";
import DashboardMenu from "./DashboardMenu";

function ProfileEnquiry() {


    const [masterData, setMasterData] = useState([])
    const [message, setMessage] = useState([])
    const [user, setUser] = useState({})
    const [sentTo, setSentTo] = useState([])
    const [current, setCurrent] = useState({})

    const apiUrl = process.env.REACT_APP_URL;
    // const apiKey = process.env.REACT_APP_API_KEY;
    const Token = localStorage.getItem("Token");


    function handleDelete(e, id) {

        if (window.confirm("Do you want to delete")) {
            fetch(`${apiUrl}/profile-enquiry/delete/` + id, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            }).then((res) => {

                alert('Record Deleted');
                window.location.reload()
            }).catch((err) => {
                console.log(err);
            })
        }

    }


    useEffect(() => {
        async function getUser() {
            const url = `${apiUrl}/employee/verify-customer`;


            let response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setUser(response.data)
            }
        }
        getUser();
    }, [apiUrl, Token]);

    // useEffect(() => {
    //     async function getUser() {
    //         const url = `${apiUrl}/profile-enquiry/getByUser/${user.id}`;


    //         let response = await fetch(url, {
    //             headers: {
    //                 Authorization: `Bearer ${Token}`,
    //             },
    //         });
    //         response = await response.json();

    //         if (response.status !== "error") {
    //             setMasterData(response.data)
    //             let newArr = sentTo
    //             for (const data of response.data) {
    //                 newArr = [...newArr, data.customer]
    //             }
    //         }
    //     }
    //     if ((user?.userType === 0) && (user?.id)) {
    //         getUser();
    //     }
    // }, [user, apiUrl, Token]);

    useEffect(() => {

        async function getCustomer() {
            const resData = await fetch(`${apiUrl}/profile-enquiry/getByCustomerId/${user.id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            })

            const response = await resData.json();

            if (response.status !== "error") {
                setMasterData(response.data)
                let newArr = sentTo
                for (const data of response.data) {
                    newArr = [...newArr, data.customer]
                }
            }

        }

        if (user?.userType !== 0 && user?.id) {
            getCustomer()
        }

    }, [user, apiUrl, Token])

    const getMessage = async (senderId, receiverId, index) => {


        const formDataToSend = new FormData();
        formDataToSend.append('senderId', senderId);
        formDataToSend.append('receiverId', receiverId);

        setCurrent(sentTo[index])

        const url = `${apiUrl}/message/getByBoth`;


        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${Token}`,
            },
            body: formDataToSend
        });
        response = await response.json();

        if (response.status !== "error") {
            setMessage(response.data)
        }
    }



    return (
        <>
            <div>
                {/*=============== basic  ===============*/}
                <meta charSet="UTF-8" />
                {/*=============== css  ===============*/}
                <link type="text/css" rel="stylesheet" href="css/plugins.css" />
                <link type="text/css" rel="stylesheet" href="css/style.css" />
                <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
                <link type="text/css" rel="stylesheet" href="css/color.css" />
                {/*=============== favicons ===============*/}
                <link rel="shortcut icon" href="images/favicon.ico" />
                {/*loader*/}
                <div className="loader-wrap">
                    <div className="loader-inner">
                        <svg>
                            <defs>
                                <filter id="goo">
                                    <feGaussianBlur
                                        in="SourceGraphic"
                                        stdDeviation={2}
                                        result="blur"
                                    />
                                    <feColorMatrix
                                        in="blur"
                                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                                        result="gooey"
                                    />
                                    <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </div>
                {/*loader end*/}
                {/* main */}
                <div id="main">
                    {/* header */}

                    {/* header end  */}
                    {/* wrapper  */}
                    <div id="wrapper">
                        {/* dashbard-menu-wrap */}
                        <div className="dashbard-menu-overlay" />
                        <SidePanel></SidePanel>
                        {/* dashbard-menu-wrap end  */}
                        {/* content */}
                        <div className="dashboard-content">
                            <DashboardMenu user={user} />
                            <div className="container dasboard-container">
                                {/* dashboard-title */}
                                <div className="dashboard-title fl-wrap">
                                    <div className="dashboard-title-item">
                                        <span>Profile Enquiry</span>
                                    </div>

                                    <ProfileHeader />

                                </div>
                                {/* dashboard-title end */}
                                <div className="dasboard-wrapper fl-wrap no-pag">
                                    {/* dashboard-list-box*/}
                                    <div className="dashboard-list-box fl-wrap">
                                        <div className="dasboard-widget-title fl-wrap">
                                            <h5>
                                                <i className="fa-solid fa-comment-alt" />
                                                Last Enquiry
                                                {/* <span> ( +3 New ) </span> */}
                                            </h5>
                                            <a
                                                href="#"
                                                className="mark-btn  tolt"
                                                data-microtip-position="bottom"
                                                data-tooltip="Mark all as read"
                                            >
                                                <i className="fa-solid fa-comments" />{" "}
                                            </a>
                                        </div>
                                        <div className="chat-wrapper fl-wrap">
                                            {/* chat-contacts*/}
                                            <div className="chat-contacts" style={{ right: '0px', width: '100%' }}>
                                                {/* chat-contacts-item*/}
                                                {masterData.length === 0 &&
                                                <p style={{textAlign:'center'}}>No Enquiry</p>
                                                }
                                                {masterData?.map((data, index) => (
                                                    <a
                                                        className="chat-contacts-item"
                                                        href="#1"
                                                        // onClick={(e) => {
                                                        //     e.preventDefault()
                                                        //     e.stopPropagation()
                                                        //     getMessage(user.id, data.customer.id, index)
                                                        // }}

                                                        style={{ marginBottom: '5px', cursor: 'default' }}
                                                    >
                                                        <div className="dashboard-message-avatar">
                                                            <img
                                                                src={data?.user?.photo ? `${apiUrl}/uploads/${data?.user?.photo}` : "images/avatar/4.jpg"}
                                                                alt="profile"
                                                            />
                                                            <div className="message-counter">1</div>
                                                        </div>
                                                        <div className="chat-contacts-item-text" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '4rem' }}>
                                                            {/* {user?.userType == 0 && <h4>{data.customer.name}</h4>} */}
                                                            <div>
                                                                <h4>{data.name.toUpperCase()}</h4>
                                                                <p style={{ width: '200px' }}>{data.email} </p>
                                                                <p style={{ width: '150px' }}>{data.user.phone}</p>
                                                            </div>
                                                            <div >
                                                                <p>
                                                                    {data.message}
                                                                </p>
                                                            </div>

                                                            <span>{new Date(data.timeOfEnquiry).toDateString()} </span>
                                                            <span
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    handleDelete(e, data.id)
                                                                }}

                                                                style={{ marginTop: "50px", cursor: 'pointer', zIndex: '2', fontSize: '10px', color: 'red' }}>
                                                                <i className="fa-solid fa-trash-alt" />
                                                            </span>


                                                        </div>
                                                    </a>
                                                ))}

                                            </div>
                                            {/* chat-contacts end*/}
                                            {/* chat-box*/}
                                            <div className="chat-box fl-wrap" style={{ zIndex: '0' }}>
                                                <div
                                                    className="chat-box-scroll fl-wrap full-height"
                                                    data-simplebar="init"
                                                >

                                                </div>
                                            </div>

                                            {/* chat-box end*/}
                                        </div>
                                        {/* dashboard-list-box end*/}
                                    </div>
                                </div>
                            </div>
                            {/* dashboard-footer */}
                            <ProfileFooter />
                            {/* dashboard-footer end */}
                        </div>
                        {/* content end */}
                        <div className="dashbard-bg gray-bg" />
                    </div>
                    {/* wrapper end */}
                </div>
                {/* Main end */}
                {/*=============== scripts  ===============*/}
            </div>
            ;
        </>
    );
}

export default ProfileEnquiry;
