import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function AddListSideBar() {
    return (<div className="dashbard-menu-container" style={{zIndex:5 , background:'white' , height: '80vh' , overflow:'scroll'}}>
        <div className="user-profile-menu mt-5 dashboard-submenu-title fl-wrap">
            <h3>CUSTOMER/BUYER</h3>
            <ul className="no-list-style">
                <li><a href="dashboard.html"><span><FontAwesomeIcon icon="fa-solid  fa-line-chart" /></span> Dashboard</a></li>
                <li><a href="dashboard-myprofile.html"><span><FontAwesomeIcon icon="fa-solid  fa-user-edit" /></span> Profile</a></li>
                <li><a href="dashboard-messages.html"><span><FontAwesomeIcon icon="fa-solid  fa-envelope" /></span> Messages</a></li>
                <li><a href="dashboard-review.html"><span><FontAwesomeIcon icon="fa-solid  fa-comments" /></span> My Reviews</a></li>
                <li><a href="dashboard-agents.html"><span><FontAwesomeIcon icon="fa-solid  fa-users" /></span> My Agents</a></li>
                <li><a href="dashboard-agency.html"><span><FontAwesomeIcon icon="fa-solid  fa-users" /></span> My Agents</a></li>
                <li><a href="dashboard-properties.html"><span><FontAwesomeIcon icon="fa-solid  fa-users" /></span> My Agents</a></li>
                <li><a href="dashboard-bookings.html"><span><FontAwesomeIcon icon="fa-solid  fa-calendar-check" /></span> My Leads</a></li>
                <li><a href="dashboard-mortgage.html"><span><FontAwesomeIcon icon="fa-solid  fa-calendar-check" /></span> Apply For Mortgage</a></li>
                <li><a href="dashboard-mortgage-queries.html"><span><FontAwesomeIcon icon="fa-solid  fa-calendar-check" /></span> Mortgage Queries</a></li>
            </ul>
        </div>
        <div className="user-profile-menu mt-3">
            <h3>OWNER/AGENT/AGENCY</h3>
            <ul className="no-list-style">
                <li><a href="dashboard-listing-properties.html"><span><FontAwesomeIcon icon="fa-solid  fa-th-list" /></span> List Property</a></li>
                <li><a href="dashboard-myListing.html"><span><FontAwesomeIcon icon="fa-solid  fa-th-list" /></span> My Listing</a></li>
                <li><a href="dashboard-bookings.html"><span className="fa fa-file-plus"></span> My Leads</a></li>
                <li><a href="#"><span className="fa fa-file-plus"></span> Customers</a></li>
                <li><a href="#"><span className="fa fa-file-plus"></span> My Agency</a></li>
                <li><a href="#"><span className="fa fa-file-plus"></span> Agent Team:</a></li>
            </ul>
        </div>
    </div>);
}

export default AddListSideBar
