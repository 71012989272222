import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './Testimonial.module.css';



const apiUrl = process.env.REACT_APP_URL;


function Testimonial({ testimonial }) {


    const settings = {
        focusOnSelect: true,
        dots: true,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className={classes.testimonialsSlider}>
            <Slider {...settings}>
                {testimonial.map((testimonial, index) => (
                    <div key={index} className={classes.testimonial}>

                        <div className={classes.testimonialContain} >
                            <img src={`${apiUrl}/uploads/${testimonial.photo}`}
                                alt={testimonial.name}
                                className={classes.testimonialImage} />
                            {/* <div>
                                {[1, 2, 3, 4, 5].map((star) => {
                                    return (
                                        <span
                                            className='start'
                                            style={{
                                                color: Number(testimonial?.rating) >= star ? 'gold' : 'gray',
                                                fontSize: `30px`
                                            }}
                                        >
                                            {' '}
                                            ★{' '}
                                        </span>
                                    )
                                })}
                            </div> */}
                            <div>
                                <h3 className={classes.testimonialName}>{testimonial.name}</h3>
                            </div>

                        </div>

                        <p className={classes.testimonialText}>{testimonial.content}</p>

                    </div>
                ))}
            </Slider>
        </div>
    )
};



export default Testimonial;

/*
<div className={classes.testimonialsSlider}>
                <Slider {...settings}>
                    {testimonial.map((testimonial, index) => (
                        <div key={index} className={classes.testimonial}>

                            <div className={classes.testimonialContain} >
                                <img src={`${apiUrl}/uploads/${testimonial.photo}`}
                                    alt={testimonial.name}
                                    className={classes.testimonialImage} />
                                <div>
                                    <h3 className={classes.testimonialName}>{testimonial.name}</h3>
                                </div>

                            </div>

                            <p className={classes.testimonialText}>{testimonial.content}</p>

                        </div>
                    ))}
                </Slider>
            </div>

*/


