import React from "react";
import { useState, useEffect } from "react";
import StarRatings from "react-star-ratings";
import HeroSection from "./HeroSection";
import Footer from "../Header/Footer";
import Testimonial from "./Testimonial/Testimonial";


function About() {
  const [testimonial, setTestimonial] = useState([])

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");

  useEffect(() => {
    fetch(`${apiUrl}/testimonial/getAll`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setTestimonial(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])


  return (
    <div>
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />

      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />

      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />
      {/*loader*/}
      <div className="loader-wrap">
        <div className="loader-inner">
          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation={2}
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                  result="gooey"
                />
                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}
        {/* put Header here */}

        {/* wrapper  */}
        <div id="wrapper p-0">
          {/* content */}
          <div className="content">
            {/*  section  */}
            {/*  section  */}
            <HeroSection />
            {/*  section  end*/}
            {/*  section  end*/}
            {/* breadcrumbs*/}
            <div className="breadcrumbs fw-breadcrumbs sp-brd fl-wrap">
              <div className="container">
                <div className="breadcrumbs-list">
                  <a href="#">Home</a> <span>About</span>
                </div>
                <div className="share-holder hid-share">
                  <a href="#" className="share-btn showshare sfcs">
                    {" "}
                    <i className="fa-solid fa-share-alt" /> Share{" "}
                  </a>
                  <div className="share-container  isShare" />
                </div>
              </div>
            </div>
            {/* breadcrumbs end */}
            {/* section */}
            <section className="gray-bg small-padding">
              <div className="container">
                <div className="row">
                  {/* services-item */}
                  <div className="col-md-4">
                    <div className="services-item fl-wrap">
                      <i className="fa-solid fa-headset" />
                      <h4>
                        24 Hours Support <span>01</span>
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Maecenas in pulvinar neque. Nulla finibus lobortis
                        pulvinar.
                      </p>
                      <a href="#" className="serv-link">
                        Read more
                      </a>
                    </div>
                  </div>
                  {/* services-item  end*/}
                  {/* services-item */}
                  <div className="col-md-4">
                    <div className="services-item fl-wrap">
                      <i className="fa-solid fa-users-cog" />
                      <h4>
                        User Admin Panel <span>02</span>
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Maecenas in pulvinar neque. Nulla finibus lobortis
                        pulvinar.
                      </p>
                      <a href="#" className="serv-link">
                        Read more
                      </a>
                    </div>
                  </div>
                  {/* services-item  end*/}
                  {/* services-item */}
                  <div className="col-md-4">
                    <div className="services-item fl-wrap">
                      <i className="fa-solid fa-phone" />
                      <h4>
                        Mobile Friendly <span>03</span>
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Maecenas in pulvinar neque. Nulla finibus lobortis
                        pulvinar.
                      </p>
                      <a href="#" className="serv-link">
                        Read more
                      </a>
                    </div>
                  </div>
                  {/* services-item  end*/}
                </div>
              </div>
            </section>
            {/* section end*/}
            {/* section */}
            <section>
              <div className="container">
                {/*about-wrap */}
                <div className="about-wrap">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="about-title fl-wrap">
                        <h2>
                          Our Company <span>Story</span>
                        </h2>
                        <h4>
                          Check video presentation to find out more about us .
                        </h4>
                      </div>
                      <p>
                        At Hauzzi, our motto is simple yet powerful: "SEARCH, COMPARE, AND CHOOSE." We are much more than a real estate listing platform; we are your trusted companion on the journey to finding the perfect home.

                      </p>
                      <p>
                        In a market saturated with options, we aim to simplify the process for you. We offer you the freedom to explore a wide range of properties, from cozy urban apartments to charming idyllic country houses, all in one place.

                      </p>
                      <p>
                      Our mission is to connect people with their ideal homes in a way that is transparent, efficient, and exciting. It’s not just about finding a property; it’s about discovering the place where your next great adventure begins, where unforgettable memories are made, and where your dreams take shape.

                      </p>
                      <p>
                      At Hauzzi, we believe in the importance of choice. We provide you with the necessary tools to compare different options and make informed decisions that align with your unique needs and desires.
                      </p>
                      <p>
                      Whether you’re an individual searching for your first home, a real estate agent looking for the perfect property for your clients, or an investor seeking lucrative opportunities, at Hauzzi you will find an unparalleled and personalized experience that will guide you to the place you have always dreamed of calling home.
                      </p>
                      <p>
                      At Hauzzi, the search for your ideal home is more than a task; it’s an exciting odyssey full of possibilities. Join us as we explore together the path to your next chapter in life. Welcome to Hauzzi, where searching, comparing, and choosing is just the beginning of your story!
                      </p>
                    </div>
                    <div className="col-md-1" />
                    <div className="col-md-6">
                      <div className="about-img fl-wrap">
                        <img
                          src="https://hauzzi.webkype.net/images/all/18.jpg"
                          className="respimg"
                          alt
                        />
                        <div className="about-img-hotifer color-bg">
                          <p>
                            Your website is fully responsive so visitors can
                            view your content from their choice of device.
                          </p>
                          <h4>Mark Antony</h4>
                          <h5>Webkype CEO</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* about-wrap end  */}
              </div>
            </section>
            {/* section end*/}
            {/* section */}
            <section className="color-bg small-padding">
              <div className="container">
                <div className="main-facts fl-wrap">
                  {/* inline-facts  */}
                  <div className="inline-facts-wrap">
                    <div className="inline-facts">
                      <div className="milestone-counter">
                        <div className="stats animaper">
                          <div className="num" data-content={0} data-num={578}>
                            0
                          </div>
                        </div>
                      </div>
                      <h6>Agents and Agencys</h6>
                    </div>
                  </div>
                  {/* inline-facts end */}
                  {/* inline-facts  */}
                  <div className="inline-facts-wrap">
                    <div className="inline-facts">
                      <div className="milestone-counter">
                        <div className="stats animaper">
                          <div
                            className="num"
                            data-content={0}
                            data-num={12168}
                          >
                            0
                          </div>
                        </div>
                      </div>
                      <h6>Happy Customers Every Year</h6>
                    </div>
                  </div>
                  {/* inline-facts end */}
                  {/* inline-facts  */}
                  <div className="inline-facts-wrap">
                    <div className="inline-facts">
                      <div className="milestone-counter">
                        <div className="stats animaper">
                          <div className="num" data-content={0} data-num={2172}>
                            0
                          </div>
                        </div>
                      </div>
                      <h6>Won Awards</h6>
                    </div>
                  </div>
                  {/* inline-facts end */}
                  {/* inline-facts  */}
                  <div className="inline-facts-wrap">
                    <div className="inline-facts">
                      <div className="milestone-counter">
                        <div className="stats animaper">
                          <div className="num" data-content={0} data-num={732}>
                            0
                          </div>
                        </div>
                      </div>
                      <h6>New Listing Every Week</h6>
                    </div>
                  </div>
                  {/* inline-facts end */}
                </div>
              </div>
              <div className="svg-bg">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="100%"
                  height="100%"
                  viewBox="0 0 1600 900"
                  preserveAspectRatio="xMidYMax slice"
                >
                  <defs>
                    <linearGradient id="bg">
                      <stop
                        offset="0%"
                        style={{ stopColor: "rgba(255, 255, 255, 0.6)" }}
                      />
                      <stop
                        offset="50%"
                        style={{ stopColor: "rgba(255, 255, 255, 0.1)" }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: "rgba(255, 255, 255, 0.6)" }}
                      />
                    </linearGradient>
                    <path
                      id="wave"
                      stroke="url(#bg)"
                      fill="none"
                      d="M-363.852,502.589c0,0,236.988-41.997,505.475,0
                                  s371.981,38.998,575.971,0s293.985-39.278,505.474,5.859s493.475,48.368,716.963-4.995v560.106H-363.852V502.589z"
                    />
                  </defs>
                  <g>
                    <use xlinkHref="#wave">
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="translate"
                        dur="10s"
                        calcMode="spline"
                        values="270 230; -334 180; 270 230"
                        keyTimes="0; .5; 1"
                        keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                        repeatCount="indefinite"
                      />
                    </use>
                    <use xlinkHref="#wave">
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="translate"
                        dur="8s"
                        calcMode="spline"
                        values="-270 230;243 220;-270 230"
                        keyTimes="0; .6; 1"
                        keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                        repeatCount="indefinite"
                      />
                    </use>
                    <use xlinkHref="#wave">
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="translate"
                        dur="6s"
                        calcMode="spline"
                        values="0 230;-140 200;0 230"
                        keyTimes="0; .4; 1"
                        keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                        repeatCount="indefinite"
                      />
                    </use>
                    <use xlinkHref="#wave">
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="translate"
                        dur="12s"
                        calcMode="spline"
                        values="0 240;140 200;0 230"
                        keyTimes="0; .4; 1"
                        keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                        repeatCount="indefinite"
                      />
                    </use>
                  </g>
                </svg>
              </div>
            </section>
            {/* section end*/}
            {/* section */}
            <section>
              <div className="container">
                {/* section-title */}
                <div className="section-title st-center fl-wrap">
                  <h4>The Crew</h4>
                  <h2>Our Awesome team</h2>
                </div>
                {/* section-title end */}
                <div className="clearfix" />
                <div className="row">
                  {/* team-item */}
                  <div className="col-md-4">
                    <div className="team-item fl-wrap">
                      <div className="team-img fl-wrap">
                        <img
                          src="https://hauzzi.webkype.net/images/agency/agent/1.jpg"
                          className="respimg"
                          alt
                        />
                      </div>
                      <div className="team-content fl-wrap">
                        <h4>Alisa Gray</h4>
                        <h5>Business consultant</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                      <div className="team-footer fl-wrap">
                        <ul className="team-social">
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-vk" />
                            </a>
                          </li>
                        </ul>
                        <a
                          href="mailto:yourmail@email.com"
                          className="tolt tf-btn"
                          data-microtip-position="top-right"
                          data-tooltip="Write Message"
                        >
                          <i className="fa-solid fa-envelope" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* team-item end */}
                  {/* team-item */}
                  <div className="col-md-4">
                    <div className="team-item fl-wrap">
                      <div className="team-img fl-wrap">
                        <img
                          src="https://hauzzi.webkype.net/images/agency/agent/4.jpg"
                          className="respimg"
                          alt
                        />
                      </div>
                      <div className="team-content fl-wrap">
                        <h4>Austin Evon</h4>
                        <h5>Developer / Designer</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                      <div className="team-footer fl-wrap">
                        <ul className="team-social">
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-vk" />
                            </a>
                          </li>
                        </ul>
                        <a
                          href="mailto:yourmail@email.com"
                          className="tolt tf-btn"
                          data-microtip-position="top-right"
                          data-tooltip="Write Message"
                        >
                          <i className="fa-solid fa-envelope" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* team-item end */}
                  {/* team-item */}
                  <div className="col-md-4">
                    <div className="team-item fl-wrap">
                      <div className="team-img fl-wrap">
                        <img
                          src="https://hauzzi.webkype.net/images/agency/agent/5.jpg"
                          className="respimg"
                          alt
                        />
                      </div>
                      <div className="team-content fl-wrap">
                        <h4>Taylor Roberts</h4>
                        <h5>Software Engineer</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                      <div className="team-footer fl-wrap">
                        <ul className="team-social">
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-vk" />
                            </a>
                          </li>
                        </ul>
                        <a
                          href="mailto:yourmail@email.com"
                          className="tolt tf-btn"
                          data-microtip-position="top-right"
                          data-tooltip="Write Message"
                        >
                          <i className="fa-solid fa-envelope" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* team-item end */}
                </div>
              </div>
            </section>
            {/* section end*/}
            {/*section  */}
            <section
              className="parallax-section ps-bg video-section"
              data-scrollax-parent="true"
              id="sec2"
            >
              <div className="bg-wrap">
                <div
                  className="bg par-elem "
                  data-bg="https://hauzzi.webkype.net/images/bg/5.jpg"
                  data-scrollax="properties: { translateY: '30%' }"
                />
              </div>
              <div className="overlay" />
              {/*container*/}
              <div className="container">
                <div className="video_section-title fl-wrap">
                  <h2>Our Story Video</h2>
                  <h4>
                    Get ready to start your exciting journey. <br /> Our agency
                    will lead you through the amazing digital world
                  </h4>
                </div>
                <a
                  href="https://vimeo.com/158059890"
                  className="promo-link big_prom color-bg   image-popup"
                >
                  <i className="fa-solid fa-play" />
                </a>
              </div>
            </section>
            {/*section end*/}
            {/* section */}
            <section className="gray-bg ">
              <div className="container">
                <div className="section-title st-center fl-wrap">
                  <h4>Testimonilas</h4>
                  <h2>What Our Clients Say</h2>
                </div>
              </div>
              <div className="clearfix" />

              <div className="custom-slider-testimonial">
                {testimonial?.length ?
                  <Testimonial testimonial={testimonial} />
                  : <></>}
              </div>

            </section>
            {/* section end*/}
          </div>
          {/* content end */}
          {/* subscribe-wrap */}
          {/* <div className="subscribe-wrap fl-wrap">
            <div className="container">
              <div className="subscribe-container fl-wrap color-bg">
                <div className="pwh_bg" />
                <div className="mrb_dec mrb_dec3" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="subscribe-header">
                      <h4>newsletter</h4>
                      <h3>
                        Sign up for newsletter and get latest news and update
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-5">
                    <div className="footer-widget fl-wrap">
                      <div className="subscribe-widget fl-wrap">
                        <div className="subcribe-form">
                          <form id="subscribe">
                            <input
                              className="enteremail fl-wrap"
                              name="email"
                              id="subscribe-email"
                              placeholder="Enter Your Email"
                              spellCheck="false"
                              type="text"
                            />
                            <button
                              type="submit"
                              id="subscribe-button"
                              className="subscribe-button color-bg"
                            >
                              {" "}
                              Subscribe
                            </button>
                            <label
                              htmlFor="subscribe-email"
                              className="subscribe-message"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* subscribe-wrap end */}
          {/* footer */}
          <Footer />
          {/* footer end */}
        </div>
      </div>
    </div>
  );
}

export default About;
