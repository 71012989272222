import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF, InfoWindow, DirectionsRenderer } from '@react-google-maps/api';

const API_KEY = process.env.REACT_APP_MAP_API_KEY;

function MapMarker({ propertyList, selectedProperty, setSelectedProperty, distance, setDistance }) {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [directions, setDirections] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  console.log("distance=>", distance)

  const [defaultCenter, setDefaultCenter] = useState({
    // lat: 40.41345088305735,
    // lng: -3.708791763896553,
    lat:  9.66102037587534,
    lng: -66.83526337013357,
  });

  const mapStyles = {
    height: '75vh',
    width: '100%',
  };

  // CSS Styles
const styles = {
  controlsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderBottom: '1px solid #ddd',
  },
  searchInput: {
    padding: '8px',
    marginRight: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '300px',
  },
  button: {
    padding: '8px 12px',
    marginLeft: '5px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: '#fff',
    cursor: 'pointer',
  },
  distance: {
    marginTop: '10px',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
  },
};

  // Load Google Maps API
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places,directions`;
    script.async = true;
    script.onload = () => setMapLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Get user's geolocation if available
  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setDefaultCenter({
            lat: latitude,
            lng: longitude,
          });
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
    }
  }, []);

  // Handle location search
  const handleSearch = () => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: searchQuery.trim().toLowerCase() }, (results, status) => {
      if (status === 'OK' && results[0]) {
        setDefaultCenter({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  };

   

  // Calculate directions to the selected property
  const calculateRoute = () => {
    if (!selectedProperty) return;

    const directionsService = new window.google.maps.DirectionsService();
    const origin = new window.google.maps.LatLng(defaultCenter.lat, defaultCenter.lng);
    const destination = new window.google.maps.LatLng(selectedProperty.latitude, selectedProperty.longitude);

    directionsService.route(
      {
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === 'OK') {
          setDirections(result);
          const routeLeg = result.routes[0].legs[0];
          setDistance(routeLeg.distance.text);
        } else {
          console.error('Directions request failed due to ' + status);
        }
      }
    );
  };

  return (
    <div>
      {/* Controls above the map */}
      <div style={styles.controlsContainer}>
        {/* <input
          type="text"
          placeholder="Search by address or place"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={styles.searchInput}
        />
        <button onClick={handleSearch} style={styles.button}>
          Search
        </button> */}

        {/* Calculate Route Button */}
        {selectedProperty && (
          <button onClick={calculateRoute} style={styles.button}>
            Get Directions From Your Location
          </button>
        )}
      </div>

      {/* Map Container */}
      {mapLoaded ? (
        <GoogleMap
          mapContainerStyle={mapStyles} 
          zoom={12}
          center={defaultCenter}
         >

          {propertyList.map((prop) => (
            <MarkerF
              key={prop.slug}
              position={{ lat: +prop.latitude, lng: +prop.longitude }}
              onClick={() => setSelectedProperty(prop)}
            />
          ))}

          {selectedProperty && (
            <InfoWindow
              position={{ lat: +selectedProperty.latitude, lng: +selectedProperty.longitude }}
              onCloseClick={() => setSelectedProperty(null)}
            >
              <div style={{paddingTop:'10px', paddingBottom:"10px"}}>
                <img src={selectedProperty?.images[0]} alt="" style={{ height: '100px', width: '100%' }} />
                <h4>{selectedProperty?.name?.slice(0, 30)}</h4>
                <p>Price: ${selectedProperty?.maxPrice}</p>
                {distance && 
                <p>Distance: {distance}</p>
                }
              </div>
            </InfoWindow>
          )}
          
          {directions && <DirectionsRenderer directions={directions} />}

        </GoogleMap>
      ) : (
        <div>Loading Maps...</div>
      )}

      {/* Display the distance */}
      {distance && <div style={styles.distance}>Distance to Selected Property: {distance}</div>}
    </div>
  );
}



export default MapMarker;



// import React, { useEffect, useState } from 'react';
// import { GoogleMap, MarkerF, InfoWindow, DirectionsRenderer } from '@react-google-maps/api';

// const API_KEY = process.env.REACT_APP_MAP_API_KEY;

// function MapMarker({ propertyList, selectedProperty, setSelectedProperty }) {
//   const [mapLoaded, setMapLoaded] = useState(false);
//   const [directions, setDirections] = useState(null);
//   const [distance, setDistance] = useState(null); // State for storing distance
//   const [searchQuery, setSearchQuery] = useState('');
//   const [defaultCenter, setDefaultCenter] = useState({
//     lat: 40.41345088305735,
//     lng: -3.708791763896553,
//   });

//   const mapStyles = {
//     height: '75vh',
//     width: '100%',
//   };
  

//   // Load the Google Maps API
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places,directions`;
//     script.async = true;
//     script.onload = () => setMapLoaded(true);
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   // Get user's geolocation if available
//   useEffect(() => {
//     if ('geolocation' in navigator) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setDefaultCenter({
//             lat: latitude,
//             lng: longitude,
//           });
//         },
//         (error) => {
//           console.error('Error getting location:', error.message);
//         }
//       );
//     }
//   }, []);

//   // Handle location search
//   const handleSearch = () => {
//     const geocoder = new window.google.maps.Geocoder();
//     geocoder.geocode({ address: searchQuery }, (results, status) => {
//       if (status === 'OK' && results[0]) {
//         setDefaultCenter({
//           lat: results[0].geometry.location.lat(),
//           lng: results[0].geometry.location.lng(),
//         });
//       } else {
//         console.error('Geocode was not successful for the following reason: ' + status);
//       }
//     });
//   };

//   // Calculate directions to the selected property
//   const calculateRoute = () => {
//     if (!selectedProperty) return;

//     const directionsService = new window.google.maps.DirectionsService();
//     const origin = new window.google.maps.LatLng(defaultCenter.lat, defaultCenter.lng); // User's current location
//     // const origin = new window.google.maps.LatLng(9.66102037587534, -66.83526337013357); // User's current location
//     const destination = new window.google.maps.LatLng(selectedProperty.latitude, selectedProperty.longitude); // Property location

//     directionsService.route(
//       {
//         origin,
//         destination,
//         travelMode: window.google.maps.TravelMode.DRIVING,
//       },
//       (result, status) => {
//         if (status === 'OK') {
//           setDirections(result);
          
//           // Get the distance from the route
//           const routeLeg = result.routes[0].legs[0];
//           setDistance(routeLeg.distance.text); // Distance is in the format "X km" or "X miles"
//         } else {
//           console.error('Directions request failed due to ' + status);
//         }
//       }
//     );
//   };

//   return (
//     <div>
//       {/* Search input for location */}
//       <div>
//         <input
//           type="text"
//           placeholder="Search by address or place"
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//         />
//         <button onClick={handleSearch}>Search</button>
//       </div>

//       {mapLoaded && (
//         <GoogleMap
//           mapContainerStyle={mapStyles}
//           zoom={10}
//           center={defaultCenter}
//         >
//             {/* Render the directions */}
//             {directions && <DirectionsRenderer directions={directions} />}

//           {propertyList.map((prop) => (
//             <MarkerF
//               key={prop.slug}
//               position={{
//                 lat: +prop.latitude,
//                 lng: +prop.longitude,
//               }}
//               onClick={() => setSelectedProperty(prop)}
//             />
//           ))}

//           {/* InfoWindow to display property details */}
//           {selectedProperty && (
//             <InfoWindow
//               position={{
//                 lat: +selectedProperty.latitude,
//                 lng: +selectedProperty.longitude,
//               }}
//               onCloseClick={() => setSelectedProperty(null)}
//             >
//               <div>
//                 <img src={selectedProperty?.images[0]} alt="" style={{ height: '100px', width: '100%' }} />
//                 <h4>{selectedProperty?.name?.slice(0, 30)}</h4>
//                 <p>Price: ${selectedProperty?.maxPrice}</p>
//               </div>
//             </InfoWindow>
//           )}

        
//         </GoogleMap>
//       )}
//       {!mapLoaded && <div>Loading Maps...</div>}

//       {/* Display the calculated distance */}
//       {distance && (
//         <div>
//           <h4>Distance to Selected Property: {distance}</h4>
//         </div>
//       )}

//       {/* Calculate route button */}
//       {selectedProperty && (
//         <button onClick={calculateRoute}>Get Directions</button>
//       )}
//     </div>
//   );
// }

// export default MapMarker;



// import React, { useEffect, useState } from 'react';
// import { GoogleMap, MarkerF, InfoWindow, DirectionsRenderer } from '@react-google-maps/api';

// const API_KEY = process.env.REACT_APP_MAP_API_KEY;

// function MapMarker({ propertyList, selectedProperty, setSelectedProperty }) {
//   const [mapLoaded, setMapLoaded] = useState(false);
//   const [directions, setDirections] = useState(null);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [defaultCenter, setDefaultCenter] = useState({
//     lat: 40.41345088305735,
//     lng: -3.708791763896553,
//   });

//   const mapStyles = {
//     height: '75vh',
//     width: '100%',
//   };

//   // Load the Google Maps API
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places,directions`;
//     script.async = true;
//     script.onload = () => setMapLoaded(true);
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   // Get user's geolocation if available
//   useEffect(() => {
//     if ('geolocation' in navigator) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setDefaultCenter({
//             lat: latitude,
//             lng: longitude,
//           });
//         },
//         (error) => {
//           console.error('Error getting location:', error.message);
//         }
//       );
//     }
//   }, []);

//   // Handle location search
//   const handleSearch = () => {
//     const geocoder = new window.google.maps.Geocoder();
//     geocoder.geocode({ address: searchQuery }, (results, status) => {
//       if (status === 'OK' && results[0]) {
//         setDefaultCenter({
//           lat: results[0].geometry.location.lat(),
//           lng: results[0].geometry.location.lng(),
//         });
//       } else {
//         console.error('Geocode was not successful for the following reason: ' + status);
//       }
//     });
//   };

//   // Calculate directions to the selected property
//   const calculateRoute = () => {
//     if (!selectedProperty) return;

//     const directionsService = new window.google.maps.DirectionsService();
//     const origin = new window.google.maps.LatLng(defaultCenter.lat, defaultCenter.lng); // User's current location
//     const destination = new window.google.maps.LatLng(selectedProperty.latitude, selectedProperty.longitude); // Property location

//     directionsService.route(
//       {
//         origin,
//         destination,
//         travelMode: window.google.maps.TravelMode.DRIVING,
//       },
//       (result, status) => {
//         if (status === 'OK') {
//           setDirections(result);
//         } else {
//           console.error('Directions request failed due to ' + status);
//         }
//       }
//     );
//   };

//   return (
//     <div>
//       {/* Search input for location */}
//       <div>
//         <input
//           type="text"
//           placeholder="Search by address or place"
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//         />
//         <button onClick={handleSearch}>Search</button>
//       </div>

//       {mapLoaded && (
//         <GoogleMap
//           mapContainerStyle={mapStyles}
//           zoom={10}
//           center={defaultCenter}
//         >
//           {propertyList.map((prop) => (
//             <MarkerF
//               key={prop.slug}
//               position={{
//                 lat: +prop.latitude,
//                 lng: +prop.longitude,
//               }}
//               onClick={() => setSelectedProperty(prop)}
//             />
//           ))}

//           {/* InfoWindow to display property details */}
//           {selectedProperty && (
//             <InfoWindow
//               position={{
//                 lat: +selectedProperty.latitude,
//                 lng: +selectedProperty.longitude,
//               }}
//               onCloseClick={() => setSelectedProperty(null)}
//             >
//               <div>
//                 <img src={selectedProperty?.images[0]} alt="" style={{ height: '100px', width: '100%' }} />
//                 <h4>{selectedProperty?.name?.slice(0, 30)}</h4>
//                 <p>Price: ${selectedProperty?.maxPrice}</p>
//               </div>
//             </InfoWindow>
//           )}

//           {/* Render the directions */}
//           {directions && <DirectionsRenderer directions={directions} />}
//         </GoogleMap>
//       )}
//       {!mapLoaded && <div>Loading Maps...</div>}

//       {/* Calculate route button */}
//       {selectedProperty && (
//         <button onClick={calculateRoute}>Get Directions</button>
//       )}
//     </div>
//   );
// }

// export default MapMarker;








// import React, { useEffect, useState } from 'react';
// import { GoogleMap, MarkerF, InfoWindow } from '@react-google-maps/api';

// const API_KEY = process.env.REACT_APP_MAP_API_KEY;

// function MapMarker({ propertyList, selectedProperty, setSelectedProperty }) {
//   const [mapLoaded, setMapLoaded] = useState(false);
//   // State for selected property

//   console.log("Click=>", selectedProperty)

//   const [defaultCenter, setDefaultCenter] = useState({
//     lat: 40.41345088305735,
//     lng: -3.708791763896553
//   });

  
//   useEffect(() => {
//     if ('geolocation' in navigator) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setDefaultCenter({
//             lat: latitude,
//             lng: longitude
//           });
//         },
//         (error) => {
//           console.error('Error getting location:', error.message);
//         }
//       );
//     } else {
//       console.error('Geolocation is not available in this browser.');
//     }
//   }, []);

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
//     script.async = true;
//     script.onload = () => setMapLoaded(true);
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   const mapStyles = {
//     height: "75vh",
//     width: "100%"
//   };

//   return (
//     <div>
//       {mapLoaded && (
//         <GoogleMap
//           mapContainerStyle={mapStyles}
//           zoom={10}
//           center={defaultCenter}
//         >
//           {propertyList.map((prop) => (
//             <MarkerF
//               key={prop.slug}
//               position={{
//                 lat: +prop.latitude,
//                 lng: +prop.longitude
//               }}
//             />
//           ))}

//           {/* InfoWindow to display name and price */}
//           {selectedProperty && (
//             <InfoWindow
//               position={{
//                 lat: +selectedProperty.latitude,
//                 lng: +selectedProperty.longitude
//               }}
//               onCloseClick={() => setSelectedProperty(null)} // Close InfoWindow
//             >
//               <div>
//                 <img src={selectedProperty?.images[0]} alt='' style={{ height: '100px', width: '100%' }} />
//                 <h4>{selectedProperty?.name?.slice(0, 30)}</h4>
//                 <p>Price: ${selectedProperty?.maxPrice}</p>
//               </div>
//             </InfoWindow>
//           )}
//         </GoogleMap>
//       )}
//       {!mapLoaded && <div>Loading Maps...</div>}
//     </div>
//   );
// }

// export default MapMarker;





// import React, { useEffect, useState } from 'react';
// import { GoogleMap, MarkerF } from '@react-google-maps/api';

// const API_KEY = process.env.REACT_APP_MAP_API_KEY;

// function MapMarker({ propertyList }) {
//   const [mapLoaded, setMapLoaded] = useState(false);

//   const [defaultCenter, setDefaultCenter] = useState({
//     lat: 40.41345088305735,
//     lng: -3.708791763896553
//   })

//   console.log('propertyList =>', propertyList)

//   useEffect(() => {
//     // Check if geolocation is available in the browser
//     if ('geolocation' in navigator) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           console.log(latitude, longitude, 'jjjjjjjjj')
//           setDefaultCenter({
//             lat: latitude,
//             lng: longitude
//           })
//         },
//         (error) => {
//           console.error('Error getting location:', error.message);
//         }
//       );
//     } else {
//       console.error('Geolocation is not available in this browser.');
//     }
//   }, []);

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
//     script.async = true;
//     script.onload = () => setMapLoaded(true);
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   const mapStyles = {
//     height: "75vh",
//     width: "100%"
//   };

//   return (
//     <div>
//       {mapLoaded && (
//         <GoogleMap
//           mapContainerStyle={mapStyles}
//           zoom={10}
//           center={defaultCenter}
//         >
//           {/* {mapLoaded && <MarkerF position={defaultCenter} />}
//           {mapLoaded && <MarkerF position={markerPosition} />} */}
//           {propertyList.map((prop) => (
//             <MarkerF
//               key={prop.slug}
//               position={{
//                 lat: +prop.latitude,
//                 lng: +prop.longitude
//               }}
//               data-toggle="tooltip"
//               data-placement="top"
//               title={`${prop.name}`}
//               onClick={() => {
//                 window.location.href = prop.slug
//               }}
//             />
//           ))}
//         </GoogleMap>
//       )}
//       {!mapLoaded && <div>Loading Maps...</div>}
//     </div>
//   );
// }

// export default MapMarker;

