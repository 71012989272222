import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function DashboardMenu({ user }) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <div className="dashboard-menu-btn color-bg">
                <span onClick={() => { setIsOpen(true) }}>
                    <i className="fa-solid fa-bars" />
                </span>
                Dasboard Menu
            </div>
            {/*Slidebar For Mobile  */}
            {isOpen &&
                <div id="wrapper">
                    <div className="dashbard-menu-overlay"></div>
                    <div className="dashbard-menu-wrap dashbard-menu-wrap_vis" style={{ overflowY: "scroll" }}>
                        <div className="dashbard-menu-close" onClick={() => { setIsOpen(false) }}>
                            <i className="fa-solid fa-times" />
                        </div>
                        <div className="dashbard-menu-container" style={{ height: '75vh', overflow: 'scroll', width: "22rem", scrollbarWidth: "none" }}>
                            {
                                /* user-profile-menu*/
                            }
                            <div className="user-profile-menu">
                                <h3>BUYER OPERATIONS</h3>
                                <ul className="no-list-style">
                                    <li>
                                        <a href="/dashboard" className="user-profile-act">
                                            <FontAwesomeIcon icon="fa-solid fa-chart-line" />
                                            {" "}{" "}Dashboard
                                        </a>
                                    </li>
                                    <li>
                                        <a href="profile">
                                            <FontAwesomeIcon icon="fa-solid fa-user-edit" /> {" "}{" "}Profile
                                        </a>
                                    </li>
                                    {user?.userType === 0 && <li>
                                        <a href="review">
                                            <FontAwesomeIcon icon="fa-solid fa-comment-alt" />{" "}{" "} My Reviews{" "}
                                        </a>
                                    </li>}
                                    {user?.userType === 0 && <li>
                                        <a href="agent">
                                            <FontAwesomeIcon icon="fa-solid fa-users" />{" "}{" "} My Agents
                                        </a>
                                    </li>}
                                    {user?.userType === 0 && <li>
                                        <a href="agency">
                                            <FontAwesomeIcon icon="fa-solid fa-users" />{" "}{" "} My Agencies
                                        </a>
                                    </li>}
                                    {user?.userType === 0 && <li>
                                        <a href="saved-property">
                                            <FontAwesomeIcon icon="fa-solid fa-heart" />{" "}{" "} Saved Properties
                                        </a>
                                    </li>}
                                    {user?.userType === 0 && <li>
                                        <a href="enquiry">
                                            {" "}
                                            <FontAwesomeIcon icon="fa-solid fa-calendar-check" />{" "}{" "} My Enquiry{" "}
                                        </a>
                                    </li>}
                                    {user?.userType === 0 && <li>
                                        <a href="mortgage">
                                            {" "}
                                            <FontAwesomeIcon icon="fa-solid fa-calendar-check" />{" "}{" "} Apply For
                                            Mortgage{" "}
                                        </a>
                                    </li>}
                                    {user?.userType === 0 && <li>
                                        <a href="mortgage-query">
                                            {" "}
                                            <FontAwesomeIcon icon="fa-solid fa-calendar-check" />{" "}{" "} Mortgage
                                            Queries{" "}
                                        </a>
                                    </li>}
                                </ul>
                            </div>
                            <div className="user-profile-menu">
                                <h3>SELLER OPERATIONS</h3>
                                <ul className="no-list-style">
                                    <li>
                                        <a href="add">
                                            <FontAwesomeIcon icon="fa-solid fa-th-list" />{" "}{" "} List Property{" "}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="my-listing">
                                            <FontAwesomeIcon icon="fa-solid fa-th-list" />{" "}{" "} My Listing{" "}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="profile-enquiry">
                                            <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}{" "} Profile Enquiry
                                        </a>
                                    </li>
                                    <li>
                                        <a href="booking">
                                            <FontAwesomeIcon icon="fa-solid fa-file" />{" "}{" "} My Leads
                                        </a>
                                    </li>
                                    {/* {user?.userType !== 0 && <li> */}
                                    <li>
                                        <a href="customers">
                                            <FontAwesomeIcon icon="fa-solid fa-file" /> {" "}{" "}Customers
                                        </a>
                                    </li>
                                    {/* </li>} */}
                                    {user?.userType === 1 && <li>
                                        <a href="agency">
                                            <FontAwesomeIcon icon="fa-solid fa-file-plus" />{" "}{" "} My Agency
                                        </a>
                                    </li>}
                                    {user?.userType === 2 && <li>
                                        <a href="agent">
                                            <FontAwesomeIcon icon="fa-solid fa-file-plus" />{" "}{" "} Agent Team
                                        </a>
                                    </li>}
                                </ul>
                            </div>
                            {
                                /* user-profile-menu end*/
                            }
                        </div>
                        <div className="dashbard-menu-footer" >
                            {" "}
                            © Webkype 2024 . All rights reserved.
                        </div>
                    </div>
                </div>
            }
        </>

    )

}



export default DashboardMenu;