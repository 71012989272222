import React from 'react'
import MapContainer from './mapContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect } from 'react'
// import Multiselect from 'multiselect-react-dropdown';
import { toast } from 'react-toastify'
import SidePanel from '../Profile/SIdePanel'
import ProfileHeader from '../Profile/ProfileHeader.js'
import { useParams } from 'react-router-dom'


function Edit() {
  const { slug } = useParams()
  const [purpose, setPurpose] = useState({
    wantToRent: false,
    wantToSEll: false
  });

  const initialFormData = {
    name: "",
    description: "",
    purpose: "",
    category: "",
    type: "",
    address1: "",
    country: "239",
    state: "",
    city: "",
    builderName: "",
    societyName: "",
    zip: "",
    latitude: "",
    longitude: "",
    mapUrl: "",
    listingDetails: "",
    maxPrice: 0,
    maxSize: 0,
    maxPSF: 0,
    rentalPrice: 0,
    maintenancePrice: 0,
    SecurityAmount: 0,
    propertySize: 0,
    propertyFor: "",
    pets: "",
    smoking: "",
    food: "",
    sharingType: "",
    bedPerRoom: "",
    shareWith: "",
    photo1: null,
    photo2: null,
    photo3: null,
    photo4: null,
    photo5: null,
    photo6: null,
    photo7: null,
    photo8: null,
    photo9: null,
    photo10: null,
    youtubeLink: "",
    propertyDoc: null,
    propertyDoc1: null,
    propertyDoc2: null,
    postedBy: "",
    customerId: 0,
  }

  const [formData, setFormData] = useState(initialFormData)
  const [listingDetails, setListingDetails] = useState({})
  const [user, setUser] = useState({})
  const [mapUrl, setMapUrl] = useState(` <iframe
    title="Google Map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.7422365569396!2d77.43369877549985!3d28.607508675678467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4faf77fb773%3A0xe0809fc938620cad!2sWEBKYPE!5e0!3m2!1sen!2sin!4v1706864052846!5m2!1sen!2sin"
    width="100%"
    height="350"
    frameBorder="0"
    style={{ border: 0 }}
    allowFullScreen=""
    aria-hidden="false"
    tabIndex="0"
></iframe>`)

  const newSlug = slug.slice(5)
  console.log('formData ====>', formData)

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");

  const [propertyPhoto, setPropertyPhoto] = useState(null);
  const [propertyPhoto1, setPropertyPhoto1] = useState(null);
  const [propertyPhoto2, setPropertyPhoto2] = useState(null);
  const [propertyPhoto3, setPropertyPhoto3] = useState(null);
  const [propertyPhoto4, setPropertyPhoto4] = useState(null);
  const [propertyPhoto5, setPropertyPhoto5] = useState(null);
  const [propertyPhoto6, setPropertyPhoto6] = useState(null);
  const [propertyPhoto7, setPropertyPhoto7] = useState(null);
  const [propertyPhoto8, setPropertyPhoto8] = useState(null);
  const [propertyPhoto9, setPropertyPhoto9] = useState(null);
  const [propertyDoc, setPropertyDoc] = useState(null);
  const [typeOptions, setTypeOptions] = useState([])
  const [typeId, setTypeId] = useState(1)
  const [categoryOptions, setCategoryOptions] = useState([])
  const [categoryId, setCategoryId] = useState(1)
  const [propertyForOptions, setpropertyForOptions] = useState([])
  const [petsOptions, setPetsOptions] = useState([])
  const [foodOptions, setFoodOptions] = useState([])
  const [smokingOptions, setSmokingOptions] = useState([])
  const [sharingTypeOptions, setSharingTypeOptions] = useState([])
  const [bedPerRoomOptions, setBedPerRoomOptions] = useState([])
  const [shareWithOptions, setShareWithOptions] = useState([])
  const [listingDetailsOption, setListingDetailsOption] = useState([])
  const [isHovered, setIsHovered] = useState(false)
  const [countryId, setCountryId] = useState('')
  const [stateId, setStateId] = useState('')
  const [countryOptions, setCountryOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [markedCoordinates, setMarkedCoordinates] = useState(null);
  const [isImageRemoved, setIsImageRemoved] = useState({
    photo1: false,
    photo2: false,
    photo3: false,
    photo4: false,
    photo5: false,
    photo6: false,
    photo7: false,
    photo8: false,
    photo9: false,
    photo10: false
  })

  // Callback function to receive the marked coordinates from MapContainer
  const handleMarkerClick = (coordinates) => {
    setMarkedCoordinates(coordinates);
  };


  // A function to handle the change of the input fields
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    }
    else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }

    if (name === 'maxPrice') {
      let temp = (parseInt(value) / parseInt(formData['maxSize'])).toFixed(2)
      setFormData((prev) => ({ ...prev, maxPSF: temp }));
    }

    if (name === 'maxSize') {
      let temp = (parseInt(formData['maxPrice']) / parseInt(value)).toFixed(2)
      setFormData((prev) => ({ ...prev, maxPSF: temp }));
    }

    if (name === 'type') {
      const selectedDataKey = e.target.options[e.target.selectedIndex].getAttribute('dataKey')
      console.log('selectedDataKey =>', selectedDataKey)
      setTypeId(selectedDataKey)
    }


    if (name === 'category') {
      let linkedId = e.target.options[e.target.selectedIndex].getAttribute('linkedMasters')
      setCategoryId(linkedId)
    }
  };

  // A function to handle the submit of the property form
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (purpose.wantToRent && !purpose.wantToSEll) {
      formData.purpose = 'wantToRent'
    } else if (purpose.wantToSEll && !purpose.wantToRent) {
      formData.purpose = 'wantToSell'
    } else {
      formData.purpose = 'bothSellRent'
    }

    // let postedBy = user?.userType === 2 ? 'Agency' : (user.userType === 1 ? 'Agent' : 'Owner')

    console.log('listingDetailsOnSubmit =>', listingDetails)

    formData.listingDetails = JSON.stringify(listingDetails)
    formData.postedBy = user.name
    formData.latitude = markedCoordinates.lat ? markedCoordinates.lat : formData.latitude
    formData.longitude = markedCoordinates.lng ? markedCoordinates.lng : formData.longitude


    delete formData.Customer
    delete formData.countries
    delete formData.states
    delete formData.cities
    delete formData.views
    delete formData.cancelledBy
    delete formData.wishlisted
    delete formData.bookedBy
    delete formData.propertyReview
    delete formData.images
    delete formData.propertyDocs
    delete formData.saved


    console.log(formData, 'formData======???>>')

    const Token = localStorage.getItem("Token");

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }
      console.log('formDataToSend =>', formDataToSend)
      const response = await fetch(`${apiUrl}/property/update-property`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      window.location.reload()
      // navigate("/Master");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRemoveClick = (e, setPic, picName) => {
    e.preventDefault();
    setPic(null);
    setFormData((prev) => ({ ...prev, [picName]: '' }));
    setIsImageRemoved((prev) => ({ ...prev, [picName]: true }))
  }

  const handleRemoveDocs = (e, setDoc, docName) => {
    e.preventDefault();
    setDoc('')
    setFormData((prev) => ({ ...prev, [docName]: '' }))
  }


  const handleSingleSelector = (id, name) => {
    // console.log('listName=>', listName)
    // console.log('id=>', id)
    // console.log('name=>', name)
    for (const key in formData.listingDetails) {
      // console.log('key=>', key)
      if (formData.listingDetails[key][0] === name) {
        return id
      }
    }
  }

  const handleMultipleSelector = (listName, name) => {
    console.log('listingDetails =>', listingDetails)

    for (const key in listingDetails) {
      if (key === listName) {
        const arr = listingDetails[key].split(",")
        if (arr.length > 0) {
          if (arr.includes(name) === true) {
            return true
          }
        }

        // if (formData.listingDetails[key][0].includes(name) === true) {
        //   return true
        // }
      }


    }
  }


  useEffect(() => {

    fetch(`${apiUrl}/property/propertyBySlug/${newSlug}`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          for (const key in data.data) {
            // console.log(key, data.data[key])
            setFormData((prev) => ({ ...prev, [key]: data.data[key] }))

            if (key === "purpose") {

              if (data.data[key] === 'wantToRent') {
                setPurpose({ wantToRent: true, wantToSEll: false })
              } else if (data.data[key] === 'wantToSell') {
                setPurpose({ wantToRent: false, wantToSEll: true })
              } else {
                setPurpose({ wantToRent: true, wantToSEll: true })
              }
            }

            if (key === "type") {

              for (let i = 0; i < typeOptions.length; i++) {
                // console.log('typeOptions =>', typeOptions[i])
                if (data.data[key] === typeOptions[i].name) {
                  setTypeId(typeOptions[i].id)
                }
              }
            }

            if (key === "countries") {
              // console.log('counties =>', data.data[key])
              setCountryId(data.data[key].id)
            }

            if (key === "state") {
              setStateId(String(data.data[key]))
            }

            if (key === "listingDetails") {
              let obj = {}
              for (const key in formData.listingDetails) {
                if (formData.listingDetails[key].length > 0) {
                  if (formData.listingDetails[key].length === 1) {
                    obj[key] = formData.listingDetails[key][0]
                  } else {
                    obj[key] = formData.listingDetails[key].join(',')
                  }
                }
              }
              console.log('obj=>', obj)
              setListingDetails(obj)

            }
            if (key === 'latitude') {
              setMarkedCoordinates((prev) => ({ ...prev, [key]: data.data[key] }))
            }
            if (key === 'longitude') {
              setMarkedCoordinates((prev) => ({ ...prev, [key]: data.data[key] }))
            }

            if (key === "photo1" && data.data[key]) {

              setPropertyPhoto(`${apiUrl}/uploads/${data.data[key]}`)
            }
            if (key === "photo2" && data.data[key]) {
              setPropertyPhoto1(`${apiUrl}/uploads/${data.data[key]}`)
            }
            if (key === "photo3" && data.data[key]) {
              setPropertyPhoto2(`${apiUrl}/uploads/${data.data[key]}`)
            }
            if (key === "photo4" && data.data[key]) {
              setPropertyPhoto3(`${apiUrl}/uploads/${data.data[key]}`)
            }
            if (key === "photo5" && data.data[key]) {
              setPropertyPhoto4(`${apiUrl}/uploads/${data.data[key]}`)
            }
            if (key === "photo6" && data.data[key]) {
              setPropertyPhoto5(`${apiUrl}/uploads/${data.data[key]}`)
            }
            if (key === "photo7" && data.data[key]) {
              setPropertyPhoto6(`${apiUrl}/uploads/${data.data[key]}`)
            }
            if (key === "photo8" && data.data[key]) {
              setPropertyPhoto7(`${apiUrl}/uploads/${data.data[key]}`)
            }
            if (key === "photo9" && data.data[key]) {
              setPropertyPhoto8(`${apiUrl}/uploads/${data.data[key]}`)
            }
            if (key === "photo10" && data.data[key]) {
              setPropertyPhoto9(`${apiUrl}/uploads/${data.data[key]}`)
            }

            if (key === "propertyDoc" && data.data[key]) {
              setPropertyDoc(`${apiUrl}/uploads/${data.data[key]}`)
            }

            // if(key === "propertyDoc1"){
            //   setPropertyDoc(`${apiUrl}/uploads/${data.data[key]}`)
            // }

            // if(key === "propertyDoc2"){
            //   setPropertyDoc(`${apiUrl}/uploads/${data.data[key]}`)
            // }


          }





          // console.log("formData =>", formData)

          // let count = [];
          // for (let i = 0; i < 5; i++) {
          //     if (data.data[`photo${i + 1}`]) {
          //         count.push(data.data[`photo${i + 1}`]);
          //     }
          // }
          // console.log(count, 'count=======');
          // setPhoto(count);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiUrl, apiKey, newSlug, typeOptions])


  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        console.log('User =>', response.data)
        setUser(response.data)
        setFormData((prev) => ({ ...prev, customerId: response.data.id }));
      }
    }
    getUser();
  }, [apiUrl, Token]);

  useEffect(() => {
    fetch(`${apiUrl}/employee/allCountry`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          console.log('dataaaaaaaaaaa', data.data)
          setCountryOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiKey, apiUrl])

  useEffect(() => {
    fetch(`${apiUrl}/employee/allStates/${countryId}`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          console.log('dataaaaaaaaaaa', data.data)
          setStateOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [countryId, apiUrl, apiKey])


  useEffect(() => {
    fetch(`${apiUrl}/employee/allcities/${stateId}`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          console.log('dataaaaaaaaaaa', data.data)
          setCityOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [stateId, apiUrl, apiKey])

  useEffect(() => {
    fetch(`${apiUrl}/master/getMasterDataByCatId/${categoryId}`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {

          console.log('listingDetailsOptions =>', data.data)

          setListingDetailsOption(data.data)
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [categoryId, apiUrl, apiKey])


  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/15`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setpropertyForOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiUrl, apiKey])

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/16`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setPetsOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiKey, apiUrl])

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/17`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setFoodOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiKey, apiUrl])

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/18`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setSmokingOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiUrl, apiKey])

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/19`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setSharingTypeOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiUrl, apiKey])

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/20`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setBedPerRoomOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiUrl, apiKey])

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/20`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setShareWithOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiUrl, apiKey])


  useEffect(() => {
    fetch(`${apiUrl}/category/getAllCategory`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          console.log('dataaaaaaaaaaa', data.data)
          setTypeOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiUrl, apiKey])

  useEffect(() => {
    fetch(`${apiUrl}/category/getAllCategoryData/${typeId}`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          console.log('dataaaaaaaaaaa', data.data)
          setCategoryOptions(data.data);
          data.data.forEach((ele) => {
            if (formData.category === ele.name) {
              setCategoryId(ele.id)
            }
          })

        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [typeId, apiKey, apiUrl, formData])


  return (
    <>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*loader*/}
      <div className="loader-wrap">
        <div className="loader-inner">
          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
                <feColorMatrix
                  in="blur"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                  result="gooey"
                />
                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* wrapper  */}
        <div id="wrapper">
          {/* dashbard-menu-wrap */}
          <div className="dashbard-menu-overlay" />
          <SidePanel />
          {/* dashbard-menu-wrap end  */}
          {/* content */}
          <div className="dashboard-content">
            <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fas fa-bars" />
              </span>
              Dasboard Menu
            </div>
            <div className="container dasboard-container">
              {/* dashboard-title */}
              <div className="dashboard-title fl-wrap">
                <div className="dashboard-title-item">
                  <span>Add Listing</span>
                </div>
                <ProfileHeader />
                {/*Tariff Plan menu*/}
                {/* <div className="tfp-det-container">
                                    <div className="tfp-btn">
                                        <span>Your Tariff Plan : </span> <strong>Extended</strong>
                                    </div>
                                    <div className="tfp-det">
                                        <p>
                                            You Are on <a href="#">Extended</a> . Use link bellow to view
                                            details or upgrade.{" "}
                                        </p>
                                        <a href="#" className="tfp-det-btn color-bg">
                                            Details
                                        </a>
                                    </div>
                                </div> */}
                {/*Tariff Plan menu end*/}
              </div>
              {/* dashboard-title end */}
              <div className="dasboard-wrapper fl-wrap no-pag">
                <div className="dasboard-scrollnav-wrap scroll-to-fixed-fixed scroll-init2 fl-wrap">
                  <ul>
                    <li>
                      <a href="#sec1" className="act-scrlink">
                        Info
                      </a>
                    </li>
                    <li>
                      <a href="#sec2">Location</a>
                    </li>
                    <li>
                      <a href="#sec4">Details</a>
                    </li>
                    <li>
                      <a href="#sec5">Commercials</a>
                    </li>
                    {purpose.wantToRent && <li>
                      <a href="#sec6">Tenant</a>
                    </li>}
                    <li>
                      <a href="#sec3">Photo</a>
                    </li>
                    <li>
                      <a href="#sec8">Video</a>
                    </li>
                    <li>
                      <a href="#sec7">Widgets</a>
                    </li>
                  </ul>
                  <div className="progress-indicator">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                      <circle
                        cx={16}
                        cy={16}
                        r="15.9155"
                        className="progress-bar__background"
                      />
                      <circle
                        cx={16}
                        cy={16}
                        r="15.9155"
                        className="progress-bar__progress 
                                      js-progress-bar"
                      />
                    </svg>
                  </div>
                </div>
                {/* dasboard-widget-title */}
                <div className="dasboard-widget-title fl-wrap" id="sec1">
                  <h5>
                    <FontAwesomeIcon icon="fas fa-info" /> Basic Informations
                  </h5>
                </div>
                {/* dasboard-widget-title end */}
                {/* dasboard-widget-box  */}
                <div className="dasboard-widget-box fl-wrap">
                  <div className="custom-form">
                    <div className=" add-list-tags fl-wrap">
                      {/* Checkboxes */}
                      <ul className="fl-wrap filter-tags no-list-style ds-tg">
                        <li>
                          <input
                            id="check-aaa5"
                            type="checkbox"
                            name="wantToSell"
                            checked={purpose.wantToSEll}
                            // checked={formData.purpose === 'wantToSell'}
                            onChange={(e) => {
                              console.log(e.target.name, e.target.checked)
                              let data = purpose
                              if (e.target.checked) {
                                setPurpose({ ...data, wantToSEll: true })
                              } else {
                                setPurpose({ ...data, wantToSEll: false })
                              }
                            }}
                          />
                          <label htmlFor="check-aaa5"> I Want to Sell</label>
                        </li>
                        <li>
                          <input
                            id="check-bb5"
                            type="checkbox"
                            name="wantToRent"
                            checked={purpose.wantToRent}
                            // checked={formData.purpose === "wantToRent"}
                            onChange={(e) => {
                              console.log(e.target.name, e.target.checked)
                              let data = purpose
                              if (e.target.checked) {
                                setPurpose({ ...data, wantToRent: true })
                              } else {
                                setPurpose({ ...data, wantToRent: false })
                              }
                            }}
                          />
                          <label htmlFor="check-bb5"> I Want to Rent</label>
                        </li>
                      </ul>
                      {/* Checkboxes end */}
                    </div>
                    <div className="row">
                      {/* <div className="col-sm-4">
                                                <label>
                                                    Listing Title{" "}
                                                    <span className="dec-icon">
                                                        <i className="far fa-briefcase" />
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Name of your business"
                                                    defaultValue=""
                                                />
                                            </div> */}
                      <div className="col-sm-4">
                        <label>Type</label>
                        <div className="listsearch-input-item">
                          <select
                            data-placeholder="All Types"
                            className="form-select"
                            name='type'
                            value={formData.type}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          >
                            <option>Select</option>
                            {typeOptions.map((department) => (
                              <option
                                key={department.id}
                                value={department.name}
                                dataKey={department.id}
                              >
                                {department.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-sm-4">
                                                <label>
                                                    Listing Price{" "}
                                                    <span className="dec-icon">
                                                        <i className="far fa-money-bill-wave" />
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Listing Price"
                                                    defaultValue=""
                                                />
                                            </div> */}
                      <div className="col-sm-4">
                        <label>Category</label>
                        <div className="listsearch-input-item">
                          <select
                            data-placeholder="Apartments"
                            className="form-select"
                            name='category'
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          >
                            <option>{formData.category}</option>
                            {categoryOptions.map((department) => (
                              <option key={department.id} value={department.name} linkedMasters={department.id}>
                                {department.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label>Name</label>
                        <div className="listsearch-input-item">
                          <input
                            placeholder='name of property'
                            className="form-control"
                            name='name'
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.name}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <label>Description</label>
                        <div className="listsearch-input-item">
                          <textarea
                            placeholder='description of property !'
                            className="form-control"
                            name='description'
                            value={formData.description}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-sm-8">
                                                <label>
                                                    Keywords{" "}
                                                    <span className="dec-icon">
                                                        <i className="far fa-key" />
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Maximum 15 , should be separated by commas"
                                                    defaultValue=""
                                                />
                                            </div> */}
                    </div>
                  </div>
                </div>
                {/* dasboard-widget-box  end*/}
                {/* dasboard-widget-title */}
                <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec2">
                  <h5>
                    <i className="fas fa-street-view" />
                    Location / Contacts
                  </h5>
                </div>
                {/* dasboard-widget-title end */}
                {/* dasboard-widget-box  */}
                <div className="dasboard-widget-box   fl-wrap">
                  <div className="custom-form">
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          Address 1{" "}
                          <span className="dec-icon">
                            <FontAwesomeIcon icon="fa fa-map-marker" />
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="Address of your business"
                          defaultValue=""
                          name='address1'
                          value={formData.address1}
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label>Country</label>
                        <div className="listsearch-input-item">
                          <select
                            data-placeholder="Apartments"
                            className="form-select"
                            name='country'
                            onChange={(e) => {
                              handleChange(e)
                              setCountryId(e.target.value)
                            }}
                          >
                            <option value="">Select</option>
                            {countryOptions.map((department) => (
                              <option
                                key={department.id}
                                value={department.id}
                                selected={department.id === formData.country}
                              >
                                {department.name}
                              </option>
                            ))}
                          </select>

                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label>Province</label>
                        <div className="listsearch-input-item">
                          <select
                            data-placeholder="Apartments"
                            className="form-select"
                            name='state'

                            onChange={(e) => {
                              handleChange(e)
                              setStateId(e.target.value)
                            }}
                          >
                            <option value="">Select</option>
                            {stateOptions.map((department) => (
                              <option
                                key={department.id}
                                value={department.id}
                                selected={department.id === formData.state}
                              >
                                {department.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label>City</label>
                        <div className="listsearch-input-item">
                          <select
                            data-placeholder="Apartments"
                            className="form-select"
                            name='city'
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          >
                            <option>Select</option>
                            {cityOptions.map((department) => (
                              <option
                                key={department.id}
                                value={department.id}
                                selected={department.id === formData.city}
                              >
                                {department.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label>Builder Name</label>
                        <div className="listsearch-input-item">
                          <input
                            placeholder='Name of Builder'
                            className="form-control"
                            name='builderName'
                            value={formData.builderName}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label>Society Name</label>
                        <div className="listsearch-input-item">
                          <input
                            placeholder='Name of Society'
                            className="form-control"
                            name='societyName'
                            value={formData.societyName}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label>Zip Code</label>
                        <div className="listsearch-input-item">
                          <input
                            placeholder='Zip code'
                            className="form-control"
                            name='zip'
                            value={formData.zip}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label>
                          Longitude (Drag marker on the map){" "}
                          {/* <span className="dec-icon">
                                                        <i className="far fa-long-arrow-alt-right" />
                                                    </span> */}
                        </label>
                        <input
                          id="long"
                          className="form-control"
                          placeholder="Map Longitude"
                          value={markedCoordinates?.lng ? markedCoordinates?.lng : formData.longitude}
                          name='longitude'
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label>
                          Latitude (Drag marker on the map)
                          {/* <span className="dec-icon">
                                                        <i className="far fa-long-arrow-alt-down" />{" "}
                                                    </span> */}
                        </label>
                        <input
                          id="lat"
                          className="form-control"
                          placeholder="Map Latitude"
                          value={markedCoordinates?.lat ? markedCoordinates?.lat : formData.latitude}
                          defaultValue={formData.latitude}
                          name='latitude'
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="map-container">
                      <MapContainer onMarkerClick={handleMarkerClick} />
                    </div>
                    {/* <div className="col-sm-4 p-0">
                                            <label>
                                                Location URL
                                            </label>
                                            <input
                                                className="form-control"
                                                placeholder="Location URL"
                                                name='mapUrl'
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setMapUrl(e.target.value)
                                                }}
                                            />
                                        </div> */}
                    {/* <div className="google-map-code">
                                            <div dangerouslySetInnerHTML={{ __html: mapUrl }} />
                                        </div>
                                        {(formData.latitude && formData.longitude) ? <iframe
                                            title="Google Map"
                                            src={`https://maps.google.com/maps?q=${formData.latitude}, ${formData.longitude}&z=15&output=embed`}
                                            width="100%"
                                            height="350"
                                            frameBorder="0"
                                            style={{ border: 0 }}
                                            allowFullScreen=""
                                            aria-hidden="false"
                                            tabIndex="0"
                                        ></iframe> : <></>} */}
                  </div>
                </div>
                {/* dasboard-widget-box  end*/}

                {/* dasboard-widget-title */}
                <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec4">
                  <h5>
                    <i className="fas fa-list" />
                    Listing Details
                  </h5>
                </div>
                {/* {listingDetailsOption.map((listDet , index) => (
                                    listDet[0]?.masters?.type === 'Multiple Select' ? (
                                        <div className=" add-list-tags fl-wrap">
                                            <label className='mt-3'>{listDet[0]?.masters?.name}: </label>
                                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                                {listDet.map((department) => (
                                                    <li>
                                                        <input
                                                            id="check-aaa5"
                                                            type="checkbox"
                                                            name="amenities"
                                                            value={department.name}
                                                            onChange={(e) => {
                                                                let data = formData.amenities
                                                                if (e.target.checked) {
                                                                    setFormData((prev) =>
                                                                    ({
                                                                        ...prev,
                                                                        [e.target.name]: {
                                                                            ...data,
                                                                            [e.target.value]: e.target.value
                                                                        }
                                                                    }));
                                                                } else {
                                                                    // Remove the key-value pair associated with e.target.value
                                                                    const { [e.target.value]: removedValue, ...updatedData }
                                                                        = data;
                                                                    setFormData((prev) => ({
                                                                        ...prev,
                                                                        amenities: updatedData
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                        <label htmlFor="check-aaa5">{department.name}</label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ) :  <>
                                    <label className='mt-3'>{listDet[0]?.masters?.name}: </label>
                                    <select
                                        data-placeholder="Apartments"
                                        className="form-select"
                                        name='amenities'
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                    >
                                        <option>Select</option>
                                        {listDet.map((department) => (
                                            <option key={department.id} value={department.name}>
                                                {department.name}
                                            </option>
                                        ))}
                                    </select>
                                </>
                                ))} */}


                {/* dasboard-widget-title end */}
                {/* dasboard-widget-box  */}
                <div className="dasboard-widget-box   fl-wrap">
                  <div className="custom-form">
                    <div className="row">

                      {listingDetailsOption.map((listDet) => (

                        (listDet[0]?.masters?.type !== 'Multiple Select' && listDet.length > 0) &&
                        <div className='col-4'>
                          <label className='mt-3'>{listDet[0]?.masters?.name}: </label>
                          <select
                            data-placeholder="Apartments"
                            className="form-select"
                            name={listDet[0]?.masters?.name}
                            onChange={(e) => {
                              let data = listingDetails
                              data = { ...data, [e.target.name]: e.target.value }
                              setListingDetails(data)
                            }}
                          >

                            <option> Select </option>
                            {listDet.map((department) => (
                              <option key={department.id} value={department.name}
                                selected={handleSingleSelector(department.id, department.name)}
                              >
                                {department.name}
                              </option>
                            ))}
                          </select>
                        </div>

                      ))}

                      {listingDetailsOption.map((listDet) => (
                        listDet[0]?.masters?.type === 'Multiple Select' && (
                          <div className=" add-list-tags fl-wrap">
                            <label className='mt-3'>{listDet[0]?.masters?.name}:  </label>
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              {listDet.map((department) => (
                                <li>
                                  <input
                                    id="check-aaa5"
                                    type="checkbox"
                                    name={listDet[0]?.masters?.name}
                                    value={department.name}
                                    defaultChecked={handleMultipleSelector(listDet[0]?.masters?.name, department.name)}
                                    onChange={(e) => {
                                      let data = listingDetails
                                      if (e.target.checked) {
                                        data[e.target.name] = data[e.target.name] ? `${data[e.target.name]},${e.target.value}` : `${e.target.value}`
                                        setListingDetails(data);
                                      } else {
                                        let str = data[e.target.name]
                                        str = str.split(',')
                                        str = str.filter(t => t !== e.target.value)
                                        str = str.join(',')
                                        data[e.target.name] = str
                                        setListingDetails(data)
                                      }
                                    }}
                                  />
                                  <label htmlFor="check-aaa5">{department.name}</label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )
                      ))}
                    </div>
                    {/* <div className="clearfix" />
                                        <label className='mt-3'>Amenities: </label>
                                        {amenitiesOptions[0]?.masters?.type === 'Multiple Select' ? <div className=" add-list-tags fl-wrap">
                                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                                {amenitiesOptions.map((department) => (
                                                    <li>
                                                        <input
                                                            id="check-aaa5"
                                                            type="checkbox"
                                                            name="amenities"
                                                            value={department.name}
                                                            onChange={(e) => {
                                                                let data = formData.amenities
                                                                if (e.target.checked) {
                                                                    setFormData((prev) =>
                                                                    ({
                                                                        ...prev,
                                                                        [e.target.name]: {
                                                                            ...data,
                                                                            [e.target.value]: e.target.value
                                                                        }
                                                                    }));
                                                                } else {
                                                                    // Remove the key-value pair associated with e.target.value
                                                                    const { [e.target.value]: removedValue, ...updatedData }
                                                                        = data;
                                                                    setFormData((prev) => ({
                                                                        ...prev,
                                                                        amenities: updatedData
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                        <label htmlFor="check-aaa5">{department.name}</label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div> : <>
                                            <select
                                                data-placeholder="Apartments"
                                                className="form-select"
                                                name='amenities'
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                            >
                                                <option>Select</option>
                                                {amenitiesOptions.map((department) => (
                                                    <option key={department.id} value={department.name}>
                                                        {department.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </>} */}
                  </div>
                </div>
                {/* dasboard-widget-box end */}
                {/* dasboard-widget-title  */}
                <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec5">
                  <h5>
                    <i className="fas fa-home-lg-alt" />
                    Commercials
                  </h5>
                  {/* <div className="onoffswitch">
                                        <input
                                            type="checkbox"
                                            name="onoffswitch"
                                            className="onoffswitch-checkbox"
                                            id="myonoffswitch5"
                                            defaultChecked=""
                                        />
                                        <label className="onoffswitch-label" htmlFor="myonoffswitch5">
                                            <span className="onoffswitch-inner" />
                                            <span className="onoffswitch-switch" />
                                        </label>
                                    </div> */}
                </div>
                {/* dasboard-widget-title end */}
                {/* dasboard-widget-box  */}
                <div className="dasboard-widget-box fl-wrap">
                  <div className="custom-form add_room-item-wrap">
                    <div className="add_room-container fl-wrap">
                      {/* add_room-item   */}
                      <div className="row">
                        {purpose.wantToSEll && <div>
                          <div className="col-md-4">
                            <label>
                              Sale Price{" "}
                              <span className="dec-icon">
                                <FontAwesomeIcon icon="fa fa-dollar" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="quoted price"
                              defaultValue={formData.maxPrice}
                              name='maxPrice'
                              max={9999999}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-md-4">
                            <label>
                              Property Size{" "}
                              <span className="dec-icon">
                                <FontAwesomeIcon icon="fa fa-ruler" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="property size"
                              defaultValue={formData.maxSize}
                              name='maxSize'
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-md-4">
                            <label>
                              Rate Per Square Ft{" "}
                              <span className="dec-icon">
                                <FontAwesomeIcon icon="fa fa-dollar" />
                              </span>
                            </label>
                            <input
                              type="text"
                              defaultValue={formData.maxPSF}
                              placeholder="rate per square feet"
                              value={(parseInt(formData.maxPrice) / parseInt(formData.maxSize)).toFixed(2)}
                              name='maxPSF'
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </div>}
                        {purpose.wantToRent && <div>
                          <div className="col-md-4">
                            <label>
                              Rental / Month{" "}
                              <span className="dec-icon">
                                <FontAwesomeIcon icon="fa fa-ruler" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Rental Per Month"
                              value={formData.rentalPrice}
                              defaultValue={formData.rentalPrice}
                              name='rentalPrice'
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-md-4">
                            <label>
                              Maintenance Charges{" "}
                              <span className="dec-icon">
                                <FontAwesomeIcon icon="fa fa-dollar" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Maintenance Charges"
                              value={formData.maintenancePrice}
                              defaultValue={formData.maintenancePrice}
                              name='maintenancePrice'
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-md-4">
                            <label>
                              Security Amount{" "}
                              <span className="dec-icon">
                                <FontAwesomeIcon icon="fa fa-dollar" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Security Amount"
                              value={formData.SecurityAmount}
                              defaultValue={formData.SecurityAmount}
                              name='SecurityAmount'
                              max={9999999}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-md-4">
                            <label>
                              Rental Area Size{" (sq ft) "}
                              <span className="dec-icon">
                                <FontAwesomeIcon icon="fa fa-ruler" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Property Size"
                              value={formData.propertySize}
                              defaultValue={formData.propertySize}
                              name='propertySize'
                              max={9999999}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </div>}
                      </div>
                      {/* <div className="col-md-4">
                                                <label className='mt-2'>
                                                    Multi Select:{" "}
                                                </label>
                                                <Multiselect
                                                    data-placeholder="Apartments"
                                                    className="form-select"
                                                    options={amenitiesOptions}
                                                >
                                                </Multiselect>
                                            </div> */}
                      {/*add_room-item end  */}
                    </div>
                  </div>
                </div>
                {/* dasboard-widget-box  end*/}
                {/* dasboard-widget-title */}
                {/* {purpose.wantToRent && <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec6">
                  <h5>
                    <FontAwesomeIcon icon="fa-solid  fa-ruler-combined" />
                    {" "}Tenant Profile{" "}
                  </h5>
                  <div className="onoffswitch">
                                        <input
                                            type="checkbox"
                                            name="onoffswitch"
                                            className="onoffswitch-checkbox"
                                            id="myonoffswitch22"
                                        />
                                        <label className="onoffswitch-label" htmlFor="myonoffswitch22">
                                            <span className="onoffswitch-inner" />
                                            <span className="onoffswitch-switch" />
                                        </label>
                                    </div>
                </div>} */}
                {/* dasboard-widget-title end */}
                {/* dasboard-widget-box  */}
                {/* {purpose.wantToRent && <div className="dasboard-widget-box   fl-wrap">
                  <div className="custom-form add_room-item-wrap">

                    <div className="row">
                      <div className=" add-list-tags fl-wrap">
                        <label className='mt-3'>Property For:{" "} </label>
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          {propertyForOptions.map((department) => (
                            <li>
                              <input
                                id="check-aaa5"
                                type="checkbox"
                                name="propertyFor"
                                value={department.name}
                                onChange={(e) => {
                                  let data = formData.propertyFor
                                  const { name, value } = e.target
                                  if (e.target.checked) {
                                    data = data ? `${data},${value}` : `${value}`
                                    setFormData((prev) => ({ ...prev, [name]: value }));
                                  } else {
                                    let str = data
                                    str = str.split(',')
                                    str = str.filter(t => t !== value)
                                    str = str.join(',')
                                    data = str
                                    setFormData((prev) => ({ ...prev, [name]: data }));
                                  }
                                }}
                              />
                              <label htmlFor="check-aaa5">{department.name}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className=" add-list-tags fl-wrap">
                        <label className='mt-3'>Sharing Type:{" "} </label>
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          {sharingTypeOptions.map((department) => (
                            <li>
                              <input
                                id="check-aaa5"
                                type="checkbox"
                                name="sharingType"
                                value={department.name}
                                onChange={(e) => {
                                  let data = formData.sharingType
                                  const { name, value } = e.target
                                  if (e.target.checked) {
                                    data = data ? `${data},${value}` : `${value}`
                                    setFormData((prev) => ({ ...prev, [name]: data }));
                                  } else {
                                    let str = data
                                    str = str.split(',')
                                    str = str.filter(t => t !== value)
                                    str = str.join(',')
                                    data = str
                                    setFormData((prev) => ({ ...prev, [name]: data }));
                                  }
                                }}
                              />
                              <label htmlFor="check-aaa5">{department.name}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className=" add-list-tags fl-wrap">
                        <label className='mt-3'>Bed Per Room:{" "} </label>
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          {bedPerRoomOptions.map((department) => (
                            <li>
                              <input
                                id="check-aaa5"
                                type="checkbox"
                                name="bedPerRoom"
                                value={department.name}
                                onChange={(e) => {
                                  let data = formData.bedPerRoom
                                  const { name, value } = e.target
                                  if (e.target.checked) {
                                    data = data ? `${data},${value}` : `${value}`
                                    setFormData((prev) => ({ ...prev, [name]: value }));
                                  } else {
                                    let str = data
                                    str = str.split(',')
                                    str = str.filter(t => t !== value)
                                    str = str.join(',')
                                    data = str
                                    setFormData((prev) => ({ ...prev, [name]: value }));
                                  }
                                }}
                              />
                              <label htmlFor="check-aaa5">{department.name}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className=" add-list-tags fl-wrap">
                        <label className='mt-3'>Share With:{" "} </label>
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          {shareWithOptions.map((department) => (
                            <li>
                              <input
                                id="check-aaa5"
                                type="checkbox"
                                name="shareWith"
                                value={department.name}
                                onChange={(e) => {
                                  let data = formData.shareWith
                                  const { name, value } = e.target
                                  if (e.target.checked) {
                                    data = data ? `${data},${value}` : `${value}`
                                    setFormData((prev) => ({ ...prev, [name]: data }));
                                  } else {
                                    let str = data
                                    str = str.split(',')
                                    str = str.filter(t => t !== value)
                                    str = str.join(',')
                                    data = str
                                    setFormData((prev) => ({ ...prev, [name]: data }));
                                  }
                                }}
                              />
                              <label htmlFor="check-aaa5">{department.name}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className=" add-list-tags fl-wrap">
                        <label className='mt-3'>Smoking :{" "} </label>
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          {smokingOptions.map((department) => (
                            <li>
                              <input
                                id="check-aaa5"
                                type="checkbox"
                                name="smoking"
                                value={department.name}
                                onChange={(e) => {
                                  let data = formData.smoking
                                  const { name, value } = e.target
                                  if (e.target.checked) {
                                    data = data ? `${data},${value}` : `${value}`
                                    setFormData((prev) => ({ ...prev, [name]: data }));
                                  } else {
                                    let str = data
                                    str = str.split(',')
                                    str = str.filter(t => t !== value)
                                    str = str.join(',')
                                    data = str
                                    setFormData((prev) => ({ ...prev, [name]: data }));
                                  }
                                }}
                              />
                              <label htmlFor="check-aaa5">{department.name}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className=" add-list-tags fl-wrap">
                        <label className='mt-3'>Food :{" "} </label>
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          {foodOptions.map((department) => (
                            <li>
                              <input
                                id="check-aaa5"
                                type="checkbox"
                                name="food"
                                value={department.name}
                                onChange={(e) => {
                                  let data = formData.food
                                  const { name, value } = e.target
                                  if (e.target.checked) {
                                    data = data ? `${data},${value}` : `${value}`
                                    setFormData((prev) => ({ ...prev, [name]: data }));
                                  } else {
                                    let str = data
                                    str = str.split(',')
                                    str = str.filter(t => t !== value)
                                    str = str.join(',')
                                    data = str
                                    setFormData((prev) => ({ ...prev, [name]: data }));
                                  }
                                }}
                              />
                              <label htmlFor="check-aaa5">{department.name}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className=" add-list-tags fl-wrap">
                        <label className='mt-3'>Pets :{" "} </label>
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          {petsOptions.map((department) => (
                            <li>
                              <input
                                id="check-aaa5"
                                type="checkbox"
                                name="pets"
                                value={department.name}
                                onChange={(e) => {
                                  let data = formData.pets
                                  const { name, value } = e.target
                                  if (e.target.checked) {
                                    data = data ? `${data},${value}` : `${value}`
                                    setFormData((prev) => ({ ...prev, [name]: data }));
                                  } else {
                                    let str = data
                                    str = str.split(',')
                                    str = str.filter(t => t !== value)
                                    str = str.join(',')
                                    data = str
                                    setFormData((prev) => ({ ...prev, [name]: data }));
                                  }
                                }}
                              />
                              <label htmlFor="check-aaa5">{department.name}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>} */}
                {/* dasboard-widget-box  end*/}
                {/* dasboard-widget-title */}
                <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec3">
                  <h5>
                    <FontAwesomeIcon icon="fa-solid  fa-image" /> Property Photos
                  </h5>
                </div>
                {/* dasboard-widget-title end */}
                {/* dasboard-widget-box  */}
                <div className="dasboard-widget-box   fl-wrap">
                  <div className="custom-form">
                    <div className="clearfix" />
                    <div className="listsearch-input-item fl-wrap">
                      <form className="fuzone m-3" style={{ width: '28%' }}>
                        {!propertyPhoto && <div className="fu-text">
                          <span>
                            <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click here or
                            drop files to upload
                          </span>
                          <div className="photoUpload-files fl-wrap" />
                        </div>}
                        <div className='row'>
                          {!propertyPhoto && (
                            <input
                              type="file"
                              name="photo1"
                              className="upload"
                              onChange={(e) => {
                                const files = e.target.files;
                                if (files.length > 0) {
                                  const propertyFile = files[0];
                                  if (propertyFile.type.startsWith("image/") && propertyFile.size <= 1024 * 1024) {
                                    setPropertyPhoto(propertyFile);
                                    handleChange(e);

                                  } else {
                                    toast.error("Upload Image Files Less Than 1 MB");
                                  }
                                }
                              }}
                            />
                          )}

                          {propertyPhoto && !isImageRemoved.photo1 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '200px' }}
                            >
                              <img
                                src={propertyPhoto}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto, 'photo1')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                          {propertyPhoto && isImageRemoved.photo1 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '200px' }}
                            >
                              <img
                                src={URL.createObjectURL(propertyPhoto)}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto, 'photo1')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                        </div>
                      </form>
                      <form className="fuzone m-3" style={{ width: '28%' }}>
                        {!propertyPhoto1 && <div className="fu-text">
                          <span>
                            <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click here or
                            drop files to upload
                          </span>
                          <div className="photoUpload-files fl-wrap" />
                        </div>}
                        <div className='row'>
                          {!propertyPhoto1 && <input type="file" name='photo2' className="upload" onChange={(e) => {
                            const files = e.target.files
                            if (files.length > 0) {
                              const propertyFile = files[0]
                              if (propertyFile.type.startsWith("image/") && propertyFile.size <= 1024 * 1024) {
                                setPropertyPhoto1(propertyFile);
                                setIsImageRemoved((prev) => ({ ...prev, photo2: true }))
                                handleChange(e)

                              } else {
                                toast.error("Upload Image Files Less Than 1 Mb")
                              }
                            }
                          }} />}
                          {propertyPhoto1 && !isImageRemoved.photo2 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '200px' }}
                            >
                              <img
                                src={propertyPhoto1}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto1, 'photo2')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                          {propertyPhoto1 && isImageRemoved.photo2 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '200px' }}
                            >
                              <img
                                src={URL.createObjectURL(propertyPhoto1)}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto1, 'photo2')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </form>
                      <form className="fuzone m-3" style={{ width: '28%' }}>
                        {!propertyPhoto2 && <div className="fu-text">
                          <span>
                            <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click here or
                            drop files to upload
                          </span>
                          <div className="photoUpload-files fl-wrap" />
                        </div>}
                        <div className='row'>
                          {!propertyPhoto2 && <input type="file" name='photo3' className="upload" onChange={(e) => {
                            const files = e.target.files
                            if (files.length > 0) {
                              const propertyFile = files[0]
                              if (propertyFile.type.startsWith("image/") && propertyFile.size <= 1024 * 1024) {
                                setPropertyPhoto2(propertyFile);
                                setIsImageRemoved((prev) => ({ ...prev, photo3: true }))
                                handleChange(e)

                              } else {
                                toast.error("Upload Image Files Less Than 1 Mb")
                              }
                            }
                          }} />}
                          {propertyPhoto2 && !isImageRemoved.photo3 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '200px' }}
                            >
                              <img
                                src={propertyPhoto2}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto2, 'photo3')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}
                          {propertyPhoto2 && isImageRemoved.photo3 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '200px' }}
                            >
                              <img
                                src={URL.createObjectURL(propertyPhoto2)}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto2, 'photo3')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}


                        </div>
                      </form>
                      <form className="fuzone m-3" style={{ width: '28%' }}>
                        {!propertyPhoto3 && <div className="fu-text">
                          <span>
                            <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click here or
                            drop files to upload
                          </span>
                          <div className="photoUpload-files fl-wrap" />
                        </div>}
                        <div className='row'>
                          {!propertyPhoto3 && <input type="file" name='photo4' className="upload" onChange={(e) => {
                            const files = e.target.files
                            if (files.length > 0) {
                              const propertyFile = files[0]
                              if (propertyFile.type.startsWith("image/") && propertyFile.size <= 1024 * 1024) {
                                setPropertyPhoto3(propertyFile);
                                setIsImageRemoved((prev) => ({ ...prev, photo4: true }))
                                handleChange(e)
                                // setFormData((prevData) => ({
                                //   ...prevData,
                                //   profilePic: profilePicFile,
                                // }));
                              } else {
                                toast.error("Upload Image Files Less Than 1 Mb")
                              }
                            }
                          }} />}
                          {propertyPhoto3 && !isImageRemoved.photo4 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={propertyPhoto3}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto3, 'photo4')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                          {propertyPhoto3 && isImageRemoved.photo4 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={URL.createObjectURL(propertyPhoto3)}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto3, 'photo4')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                        </div>
                      </form>
                      <form className="fuzone m-3" style={{ width: '28%' }}>
                        {!propertyPhoto4 && <div className="fu-text">
                          <span>
                            <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click here or
                            drop files to upload
                          </span>
                          <div className="photoUpload-files fl-wrap" />
                        </div>}
                        <div className='row'>
                          {!propertyPhoto4 && <input type="file" name='photo5' className="upload" onChange={(e) => {
                            const files = e.target.files
                            if (files.length > 0) {
                              const propertyFile = files[0]
                              if (propertyFile.type.startsWith("image/") && propertyFile.size <= 1024 * 1024) {
                                setPropertyPhoto4(propertyFile);
                                setIsImageRemoved((prev) => ({ ...prev, photo5: true }))
                                handleChange(e)
                              } else {
                                toast.error("Upload Image Files Less Than 1 Mb")
                              }
                            }
                          }} />}
                          {propertyPhoto4 && !isImageRemoved.photo5 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={propertyPhoto4}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto4, 'photo5')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                          {propertyPhoto4 && isImageRemoved.photo5 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={URL.createObjectURL(propertyPhoto4)}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto4, 'photo5')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                        </div>
                      </form>
                      <form className="fuzone m-3" style={{ width: '28%' }}>
                        {!propertyPhoto5 && <div className="fu-text">
                          <span>
                            <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click here or
                            drop files to upload
                          </span>
                          <div className="photoUpload-files fl-wrap" />
                        </div>}
                        <div className='row'>
                          {!propertyPhoto5 && <input type="file" name='photo6' className="upload" onChange={(e) => {
                            const files = e.target.files
                            if (files.length > 0) {
                              const propertyFile = files[0]
                              if (propertyFile.type.startsWith("image/") && propertyFile.size <= 1024 * 1024) {
                                setPropertyPhoto5(propertyFile);
                                setIsImageRemoved((prev) => ({ ...prev, photo6: true }))
                                handleChange(e)
                              } else {
                                toast.error("Upload Image Files Less Than 1 Mb")
                              }
                            }
                          }} />}
                          {propertyPhoto5 && !isImageRemoved.photo5 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={propertyPhoto5}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto5, 'photo6')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                          {propertyPhoto5 && isImageRemoved.photo5 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={URL.createObjectURL(propertyPhoto5)}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto5, 'photo6')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                        </div>
                      </form>
                      <form className="fuzone m-3" style={{ width: '28%' }}>
                        {!propertyPhoto6 && <div className="fu-text">
                          <span>
                            <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click here or
                            drop files to upload
                          </span>
                          <div className="photoUpload-files fl-wrap" />
                        </div>}
                        <div className='row'>
                          {!propertyPhoto6 && <input type="file" name='photo7' className="upload" onChange={(e) => {
                            const files = e.target.files
                            if (files.length > 0) {
                              const propertyFile = files[0]
                              if (propertyFile.type.startsWith("image/") && propertyFile.size <= 1024 * 1024) {
                                setPropertyPhoto6(propertyFile);
                                setIsImageRemoved((prev) => ({ ...prev, photo7: true }))
                                handleChange(e)
                              } else {
                                toast.error("Upload Image Files Less Than 1 Mb")
                              }
                            }
                          }} />}
                          {propertyPhoto6 && !isImageRemoved.photo7 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={propertyPhoto6}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto6, 'photo7')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                          {propertyPhoto6 && isImageRemoved.photo7 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={URL.createObjectURL(propertyPhoto6)}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto6, 'photo7')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </form>
                      <form className="fuzone m-3" style={{ width: '28%' }}>
                        {!propertyPhoto7 && <div className="fu-text">
                          <span>
                            <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click here or
                            drop files to upload
                          </span>
                          <div className="photoUpload-files fl-wrap" />
                        </div>}
                        <div className='row'>
                          {!propertyPhoto7 && <input type="file" name='photo8' className="upload" onChange={(e) => {
                            const files = e.target.files
                            if (files.length > 0) {
                              const propertyFile = files[0]
                              if (propertyFile.type.startsWith("image/") && propertyFile.size <= 1024 * 1024) {
                                setPropertyPhoto7(propertyFile);
                                setIsImageRemoved((prev) => ({ ...prev, photo8: true }))
                                handleChange(e)
                              } else {
                                toast.error("Upload Image Files Less Than 1 Mb")
                              }
                            }
                          }} />}
                          {propertyPhoto7 && !isImageRemoved.photo8 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={propertyPhoto7}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto7, 'photo8')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                          {propertyPhoto7 && isImageRemoved.photo8 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={URL.createObjectURL(propertyPhoto7)}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto7, 'photo8')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </form>
                      <form className="fuzone m-3" style={{ width: '28%' }}>
                        {!propertyPhoto8 && <div className="fu-text">
                          <span>
                            <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click here or
                            drop files to upload
                          </span>
                          <div className="photoUpload-files fl-wrap" />
                        </div>}
                        <div className='row'>
                          {!propertyPhoto8 && <input type="file" name='photo9' className="upload" onChange={(e) => {
                            const files = e.target.files
                            if (files.length > 0) {
                              const propertyFile = files[0]
                              if (propertyFile.type.startsWith("image/") && propertyFile.size <= 1024 * 1024) {
                                setPropertyPhoto8(propertyFile);
                                setIsImageRemoved((prev) => ({ ...prev, photo9: true }))
                                handleChange(e)
                              } else {
                                toast.error("Upload Image Files Less Than 1 Mb")
                              }
                            }
                          }} />}
                          {propertyPhoto8 && !isImageRemoved.photo9 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={propertyPhoto8}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto8, 'photo9')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                          {propertyPhoto8 && isImageRemoved.photo9 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={URL.createObjectURL(propertyPhoto8)}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto8, 'photo9')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </form>
                      <form className="fuzone m-3" style={{ width: '28%' }}>
                        {!propertyPhoto9 && <div className="fu-text">
                          <span>
                            <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click here or
                            drop files to upload
                          </span>
                          <div className="photoUpload-files fl-wrap" />
                        </div>}
                        <div className='row'>
                          {!propertyPhoto9 && <input type="file" name='photo10' className="upload" onChange={(e) => {
                            const files = e.target.files
                            if (files.length > 0) {
                              const propertyFile = files[0]
                              if (propertyFile.type.startsWith("image/") && propertyFile.size <= 1024 * 1024) {
                                setPropertyPhoto9(propertyFile);
                                setIsImageRemoved((prev) => ({ ...prev, photo10: true }))
                                handleChange(e)
                              } else {
                                toast.error("Upload Image Files Less Than 1 Mb")
                              }
                            }
                          }} />}
                          {propertyPhoto9 && !isImageRemoved.photo10 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={propertyPhoto9}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto9, 'photo10')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}

                          {propertyPhoto9 && isImageRemoved.photo10 && (
                            <div
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{ maxWidth: '250px' }}
                            >
                              <img
                                src={URL.createObjectURL(propertyPhoto9)}
                                alt="Selected File"
                                style={{
                                  maxWidth: "250px",
                                  border: isHovered ? "2px solid red" : "none"
                                }}
                              />
                              {isHovered && (
                                <button
                                  className="btn-sm text-danger"
                                  onClick={(e) => {
                                    handleRemoveClick(e, setPropertyPhoto9, 'photo10')
                                  }}>
                                  Remove
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec8">
                  <h5>
                    <FontAwesomeIcon icon="fa-solid  fa-video" /> Property Videos
                  </h5>
                </div>

                <div className="dasboard-widget-box fl-wrap">
                  <div className="custom-form">

                    <div className="col-md-10">
                      <div className="content-widget-switcher fl-wrap">
                        <span className="content-widget-switcher-title">
                          Video Presentation
                        </span>

                        <div className="content-widget-switcher-item fl-wrap mt-5">
                          <label>
                            Video Youtube:{" "}
                            <span className="dec-icon">
                              <FontAwesomeIcon icon={["fa-solid ", "fa-video"]} />
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="Youtube Link"
                            name="youtubeLink"
                            defaultValue={formData.youtubeLink}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec7">
                  <h5>
                    <i className="fa-solid fa-sliders-h" />
                    Property Documents [PDF format]
                  </h5>
                </div>

                <div className="dasboard-widget-box   fl-wrap">
                  <div className="custom-form">
                    <div className="row">

                      <div className="col-md-4">
                        <div className="content-widget-switcher fl-wrap">
                          <span className="content-widget-switcher-title">
                            Property Documents 1
                          </span>

                          <div className="content-widget-switcher-item fl-wrap">
                            <form className="fuzone">
                              <div className="fu-text">
                                <span>
                                  <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click
                                  here or drop files to upload
                                </span>
                                <div className="photoUpload-files fl-wrap" />
                              </div>

                              <input
                                type="file"
                                className="upload"
                                name='propertyDoc'
                                onChange={(e) => {
                                  const files = e.target.files
                                  if (files.length > 0) {
                                    const propertyFile = files[0]
                                    if (propertyFile.type.startsWith("application/pdf") && propertyFile.size <= 1024 * 1024) {
                                      setPropertyDoc(propertyFile);
                                      handleChange(e)
                                    } else {
                                      toast.error("Upload Pdf Files Less Than 1 Mb")
                                    }
                                  }
                                }}
                              />

                              {propertyDoc && (
                                <div
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                  style={{ maxWidth: '250px' }}
                                >
                                  <img
                                    src={propertyDoc}
                                    alt={propertyDoc.name}
                                    style={{
                                      maxWidth: "250px",
                                      border: isHovered ? "2px solid red" : "none"
                                    }}
                                  />
                                  {isHovered && (
                                    <button
                                      className="btn-sm text-danger"
                                      onClick={(e) => {
                                        handleRemoveClick(e, setPropertyDoc, 'propertyDoc')
                                      }}>
                                      Remove
                                    </button>
                                  )}
                                </div>
                              )}

                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="content-widget-switcher fl-wrap">
                          <span className="content-widget-switcher-title">
                            Property Documents 2
                          </span>

                          <div className="content-widget-switcher-item fl-wrap">
                            <form className="fuzone">
                              <div className="fu-text">
                                <span>
                                  <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click
                                  here or drop files to upload
                                </span>
                                <div className="photoUpload-files fl-wrap" />
                              </div>
                              <input
                                type="file"
                                className="upload"
                                name='propertyDoc1'
                                onChange={(e) => {
                                  const files = e.target.files
                                  if (files.length > 0) {
                                    const propertyFile = files[0]
                                    if (propertyFile.type.startsWith("application/pdf") && propertyFile.size <= 1024 * 1024) {
                                      handleChange(e)
                                    } else {
                                      toast.error("Upload Pdf Files Less Than 1 Mb")
                                    }
                                  }
                                }}
                              />

                              {formData.propertyDoc1 &&
                                <div
                                  style={{ maxWidth: '250px' }}
                                >
                                  <img
                                    src={formData.propertyDoc1}
                                    alt={formData.propertyDoc1.name}
                                    style={{
                                      maxWidth: "250px",
                                      border: isHovered ? "2px solid red" : "none"
                                    }}
                                  />
                                  <button
                                    className="btn-sm text-danger"
                                    onClick={(e) => {
                                      handleRemoveDocs(e, formData.propertyDoc1, 'propertyDoc1')
                                    }}
                                    style={{ zIndex: '1' }}
                                  >
                                    Remove
                                  </button>

                                </div>
                              }
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="content-widget-switcher fl-wrap">
                          <span className="content-widget-switcher-title">
                            Property Documents 3
                          </span>

                          <div className="content-widget-switcher-item fl-wrap">
                            <form className="fuzone">
                              <div className="fu-text">
                                <span>
                                  <FontAwesomeIcon icon="fa-solid  fa-cloud-upload-alt" /> Click
                                  here or drop files to upload
                                </span>
                                <div className="photoUpload-files fl-wrap" />
                              </div>
                              <input
                                type="file"
                                className="upload"
                                name='propertyDoc2'
                                onChange={(e) => {
                                  const files = e.target.files
                                  if (files.length > 0) {
                                    const propertyFile = files[0]
                                    if (propertyFile.type.startsWith("application/pdf") && propertyFile.size <= 1024 * 1024) {
                                      handleChange(e)
                                    } else {
                                      toast.error("Upload Pdf Files Less Than 1 Mb")
                                    }
                                  }
                                }}
                              />
                              {formData.propertyDoc2 &&
                                <div
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                  style={{ maxWidth: '250px' }}
                                >
                                  <img
                                    src={formData.propertyDoc2}
                                    alt={formData.propertyDoc2.name}
                                    style={{
                                      maxWidth: "250px",
                                      border: isHovered ? "2px solid red" : "none"
                                    }}
                                  />
                                  {isHovered && (
                                    <button
                                      className="btn-sm text-danger"
                                      onClick={(e) => {
                                        handleRemoveDocs(e, formData.propertyDoc2, 'propertyDoc2')
                                      }}>
                                      Remove
                                    </button>
                                  )}
                                </div>
                              }
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* content-widget-switcher end*/}
                      {/* content-widget-switcher */}
                      {/* <div className="col-md-4">
                                                <div className="content-widget-switcher fl-wrap">
                                                    <span className="content-widget-switcher-title">
                                                        Mortgage Calculator
                                                    </span>
                                                    <div className="onoffswitch">
                                                        <input
                                                            type="checkbox"
                                                            name="onoffswitch"
                                                            className="onoffswitch-checkbox"
                                                            id="myonoffswitchmc423"
                                                            defaultChecked=""
                                                        />
                                                        <label
                                                            className="onoffswitch-label"
                                                            htmlFor="myonoffswitchmc423"
                                                        >
                                                            <span className="onoffswitch-inner" />
                                                            <span className="onoffswitch-switch" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    className="content-widget-switcher fl-wrap"
                                                    style={{ marginTop: 20 }}
                                                >
                                                    <span className="content-widget-switcher-title">
                                                        Google Map
                                                    </span>
                                                    <div className="onoffswitch">
                                                        <input
                                                            type="checkbox"
                                                            name="onoffswitch"
                                                            className="onoffswitch-checkbox"
                                                            id="myonoffswitchmc923"
                                                        />
                                                        <label
                                                            className="onoffswitch-label"
                                                            htmlFor="myonoffswitchmc923"
                                                        >
                                                            <span className="onoffswitch-inner" />
                                                            <span className="onoffswitch-switch" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    className="content-widget-switcher fl-wrap"
                                                    style={{ marginTop: 20 }}
                                                >
                                                    <span className="content-widget-switcher-title">
                                                        Contact Form
                                                    </span>
                                                    <div className="onoffswitch">
                                                        <input
                                                            type="checkbox"
                                                            name="onoffswitch"
                                                            className="onoffswitch-checkbox"
                                                            id="myonoffswitchmc`23"
                                                        />
                                                        <label
                                                            className="onoffswitch-label"
                                                            htmlFor="myonoffswitchmc`23"
                                                        >
                                                            <span className="onoffswitch-inner" />
                                                            <span className="onoffswitch-switch" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> */}
                      {/* content-widget-switcher end*/}
                    </div>
                  </div>
                </div>

                <button
                  className="btn color-bg float-btn text-white"
                  onClick={(e) => {
                    handleSubmit(e)
                  }}
                >
                  Save Changes{" "}
                </button>
              </div>
            </div>
            <div className="limit-box fl-wrap" />
            {/* dashboard-footer */}
            <div className="dashboard-footer">
              <div className="dashboard-footer-links fl-wrap">
                <span>Helpfull Links:</span>
                <ul>
                  <li>
                    <a href="about">About</a>
                  </li>
                  <li>
                    <a href="blog">Blog</a>
                  </li>
                  <li>
                    <a href="pricing">Pricing Plans</a>
                  </li>
                  <li>
                    <a href="contact">Contacts</a>
                  </li>
                  <li>
                    <a href="help">Help Center</a>
                  </li>
                </ul>
              </div>
              <a href="#main" className="dashbord-totop  custom-scroll-link">
                <i className="fas fa-caret-up" />
              </a>
            </div>
            {/* dashboard-footer end */}
          </div>
          {/* content end */}
          <div className="dashbard-bg gray-bg" />
        </div>
        {/* wrapper end */}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </>

  )
}

export default Edit
