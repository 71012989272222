import React, { useEffect, useState } from "react";
import SidePanel from "./SIdePanel";
import ProfileHeader from "./ProfileHeader";
import ProfileFooter from "./ProfileFooter";
import DashboardMenu from "./DashboardMenu";

function MyAgent() {

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");

  const [agents, setAgents] = useState([])
  const [user, setUser] = useState({})

  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
      }
    }
    getUser();
  }, []);


  useEffect(() => {

    if (user?.id) {
      fetch(`${apiUrl}/profile/savedAgent/${user.id}?userType=1`, {
        headers: {
          'Authorization': `Bearer ${Token}`,
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data && Array.isArray(data.data)) {
            console.log('dataaaaaaaaaaa', data.data)
            setAgents(data.data);
          } else {
            console.error('API response does not contain an array:', data);
          }
        })
        .catch(error => {
          console.error('Error fetching masters data:', error);
        });
    }

  }, [apiUrl, Token, user])


  return (
    <>
      <div>
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* header put here */}
          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            <SidePanel></SidePanel>
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div
              className="dashboard-content"
              // style={{ padding: "40px 50px 70px 351px" }}
            >
              <DashboardMenu user={user}/>
              <div className="container dasboard-container">
                {/* dashboard-title */}
                <div class="dashboard-title fl-wrap">
                  <div class="dashboard-title-item">
                    <span>Your Agents</span>
                  </div>

                  <ProfileHeader />

                  {/* <div class="tfp-det-container">
                    <div class="tfp-btn">
                      <span>Your Tariff Plan : </span> <strong>Extended</strong>
                    </div>
                    <div class="tfp-det">
                      <p>
                        You Are on <a href="#">Extended</a> . Use link bellow to
                        view details or upgrade.{" "}
                      </p>
                      <a href="#" class="tfp-det-btn color-bg">
                        Details
                      </a>
                    </div>
                  </div> */}
                </div>
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap">
                  <div className="dasboard-listing-box fl-wrap">
                    {/* dashboard-listings-wrap*/}
                    <div className="dashboard-listings-wrap fl-wrap">
                      <div className="row">
                        {/* dashboard-listings-item*/}
                        {agents.map((data) => (<div className="col-md-4">
                          {/*  agent card item */}
                          <div className="listing-item">
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img fl-wrap  agent_card">
                                <a                               
                                  className="geodir-category-img_item"
                                >
                                  <img
                                    src={ data?.agentDetails[0]?.photo ? `${apiUrl}/uploads/${data.agentDetails[0].photo}` : 'images/agent-img2.jpg'}
                                    alt="agent"
                                    style={{width:'300px', height:'300px', objectFit:'cover'}}
                                  />
                                </a>
                              </div>
                              <div className="geodir-category-content fl-wrap" style={{ height: '150px' }}>
                                <div
                                  className="card-verified tolt"
                                  data-microtip-position="left"
                                  data-tooltip="Verified"
                                >
                                  <i className="fa-solid fa-user-check" />
                                </div>
                                <div className="agent_card-title fl-wrap">
                                  <h4>
                                    <a>{data.name}</a>
                                  </h4>
                                  <h5>
                                    <a>{data.agentDetails[0].phone = (!data.agentDetails[0].phone || data.agentDetails[0].phone == ("undefined"|| "null") ) ? '' : data.agentDetails[0].phone}</a>
                                  </h5>
                                  <h5>
                                    <a>
                                      E:{" "}
                                      <span
                                        className="__cf_email__"
                                        data-cfemail="8efeebfaebfcceefe9ebe0edf7a0ede1e3"
                                      >
                                        {/* [email&nbsp;protected] */}
                                        {data.email}
                                      </span>
                                    </a>
                                  </h5>
                                </div>
                                <div className="geodir-category-footer fl-wrap">
                                  {/* <div
                                    className="listing-rating card-popup-rainingvis tolt pull-left"
                                    data-microtip-position="top"
                                    data-tooltip="Good"
                                    data-starrating2={4}
                                  /> */}
                                  <a                                  
                                    className="tolt ftr-btn"
                                    data-microtip-position="left"
                                    data-tooltip="Delete"
                                  >
                                    <i className="fa-solid fa-trash" />
                                  </a>
                                  {/* <a                                  
                                    className="tolt ftr-btn"
                                    data-microtip-position="left"
                                    data-tooltip="Verify"
                                  >
                                    <i className="fa-solid fa-user-check" />
                                  </a> */}
                                </div>
                              </div>
                            </article>
                          </div>
                          {/*  agent card item end */}
                        </div>
                        ))}
                        {/* dashboard-listings-item end*/}
                        {/* dashboard-listings-item*/}
                        {/* <div className="col-md-4">
                          <div className="listing-item">
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img fl-wrap  agent_card">
                                <a
                                  href="#"
                                  className="geodir-category-img_item"
                                >
                                  <img
                                    src="https://hauzzi.webkype.net/images/agent-img.jpeg"
                                    alt
                                  />
                                </a>
                              </div>
                              <div className="geodir-category-content fl-wrap">
                                <div
                                  className="card-verified tolt"
                                  data-microtip-position="left"
                                  data-tooltip="Verified"
                                >
                                  <i className="fa-solid fa-user-check" />
                                </div>
                                <div className="agent_card-title fl-wrap">
                                  <h4>
                                    <a href="#">San Dues Miller</a>
                                  </h4>
                                  <h5>
                                    <a href="#">M: +91 8130352808</a>
                                  </h5>
                                  <h5>
                                    <a href="#">
                                      E:{" "}
                                      <span
                                        className="__cf_email__"
                                        data-cfemail="057560716077456462606b667c2b666a68"
                                      >
                                        [email&nbsp;protected]
                                      </span>
                                    </a>
                                  </h5>
                                </div>
                                <div className="geodir-category-footer fl-wrap">
                                  <div
                                    className="listing-rating card-popup-rainingvis tolt pull-left"
                                    data-microtip-position="top"
                                    data-tooltip="Good"
                                    data-starrating2={4}
                                  />
                                  <a
                                    href="#"
                                    className="tolt ftr-btn"
                                    data-microtip-position="left"
                                    data-tooltip="Delete"
                                  >
                                    <i className="fa-solid fa-trash" />
                                  </a>
                                  <a
                                    href="#"
                                    className="tolt ftr-btn"
                                    data-microtip-position="left"
                                    data-tooltip="Verify"
                                  >
                                    <i className="fa-solid fa-user-check" />
                                  </a>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div> */}
                        {/* dashboard-listings-item end*/}
                        {/* dashboard-listings-item*/}
                        {/* <div className="col-md-4">
                          <div className="listing-item">
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img fl-wrap  agent_card">
                                <a
                                  href="#"
                                  className="geodir-category-img_item"
                                >
                                  <img
                                    src="https://hauzzi.webkype.net/images/agent-img.jpeg"
                                    alt
                                  />
                                </a>
                              </div>
                              <div className="geodir-category-content fl-wrap">
                                <div
                                  className="card-verified tolt"
                                  data-microtip-position="left"
                                  data-tooltip="Verified"
                                >
                                  <i className="fa-solid fa-user-check" />
                                </div>
                                <div className="agent_card-title fl-wrap">
                                  <h4>
                                    <a href="#">San Dues Miller</a>
                                  </h4>
                                  <h5>
                                    <a href="#">M: +91 8130352808</a>
                                  </h5>
                                  <h5>
                                    <a href="#">
                                      E:{" "}
                                      <span
                                        className="__cf_email__"
                                        data-cfemail="84f4e1f0e1f6c4e5e3e1eae7fdaae7ebe9"
                                      >
                                        [email&nbsp;protected]
                                      </span>
                                    </a>
                                  </h5>
                                </div>
                                <div className="geodir-category-footer fl-wrap">
                                  <div
                                    className="listing-rating card-popup-rainingvis tolt pull-left"
                                    data-microtip-position="top"
                                    data-tooltip="Good"
                                    data-starrating2={4}
                                  />
                                  <a
                                    href="#"
                                    className="tolt ftr-btn"
                                    data-microtip-position="left"
                                    data-tooltip="Delete"
                                  >
                                    <i className="fa-solid fa-trash" />
                                  </a>
                                  <a
                                    href="#"
                                    className="tolt ftr-btn"
                                    data-microtip-position="left"
                                    data-tooltip="Verify"
                                  >
                                    <i className="fa-solid fa-user-check" />
                                  </a>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div> */}
                        {/* dashboard-listings-item end*/}
                        {/* dashboard-listings-item*/}
                        {/* <div className="col-md-4">
                          <div className="listing-item">
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img fl-wrap  agent_card">
                                <a
                                  href="#"
                                  className="geodir-category-img_item"
                                >
                                  <img
                                    src="https://hauzzi.webkype.net/images/agent-img.jpeg"
                                    alt
                                  />
                                </a>
                              </div>
                              <div className="geodir-category-content fl-wrap">
                                <div
                                  className="card-verified tolt"
                                  data-microtip-position="left"
                                  data-tooltip="Verified"
                                >
                                  <i className="fa-solid fa-user-check" />
                                </div>
                                <div className="agent_card-title fl-wrap">
                                  <h4>
                                    <a href="#">San Dues Miller</a>
                                  </h4>
                                  <h5>
                                    <a href="#">M: +91 8130352808</a>
                                  </h5>
                                  <h5>
                                    <a href="#">
                                      E:{" "}
                                      <span
                                        className="__cf_email__"
                                        data-cfemail="dbabbeafbea99bbabcbeb5b8a2f5b8b4b6"
                                      >
                                        [email&nbsp;protected]
                                      </span>
                                    </a>
                                  </h5>
                                </div>
                                <div className="geodir-category-footer fl-wrap">
                                  <div
                                    className="listing-rating card-popup-rainingvis tolt pull-left"
                                    data-microtip-position="top"
                                    data-tooltip="Good"
                                    data-starrating2={4}
                                  />
                                  <a
                                    href="#"
                                    className="tolt ftr-btn"
                                    data-microtip-position="left"
                                    data-tooltip="Delete"
                                  >
                                    <i className="fa-solid fa-trash" />
                                  </a>
                                  <a
                                    href="#"
                                    className="tolt ftr-btn"
                                    data-microtip-position="left"
                                    data-tooltip="Verify"
                                  >
                                    <i className="fa-solid fa-user-check" />
                                  </a>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div> */}
                        {/* dashboard-listings-item end*/}
                      </div>
                    </div>
                    {/* dashboard-listings-wrap end*/}
                  </div>
                  {/* pagination*/}
                  {/* <div className="pagination float-pagination">
                    <a href="#" className="prevposts-link">
                      <i className="fa fa-caret-left" />
                    </a>
                    <a href="#">1</a>
                    <a href="#" className="current-page">
                      2
                    </a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#" className="nextposts-link">
                      <i className="fa fa-caret-right" />
                    </a>
                  </div> */}
                  {/* pagination end*/}
                </div>
              </div>
              {/* dashboard-footer */}
              <ProfileFooter />
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
      ;
    </>
  );
}

export default MyAgent;
