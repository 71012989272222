import React, { useState, useEffect } from "react";
import SidePanel from "./SIdePanel";
import ProfileHeader from "./ProfileHeader";
import ProfileFooter from "./ProfileFooter";
import DashboardMenu from "./DashboardMenu";



function DashBoard() {


  const [profile, setProfile] = useState({})
  const [user, setUser] = useState({})



  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");


  useEffect(() => {
    if (user?.id) {
      fetch(`${apiUrl}/profile/getById/${user.id}`, {
        headers: {
          'Authorization': `Bearer ${Token}`,
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data) {
            setProfile(data.data)
            console.log(data.data)
            // let count = []
            // for (let j = 0; j < data.data.property.length; j++) {
            //   let temp = 0;
            //   for (let i = 0; i < 5; i++) {
            //     if (data.data.property[j][`photo${i + 1}`]) {
            //       temp++;
            //     }
            //   }
            //   count = [...count, temp]
            // }
            // console.log(count, 'count=======')
            // setPhotoCount(count)
          } else {
            console.error('API response does not contain an array:', data);
          }
        })
        .catch(error => {
          console.error('Error fetching masters data:', error);
        });
    }
  }, [Token, apiUrl, user])


  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
      }
    }
    getUser();
  }, []);

  return (
    <>
      <div>
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />

        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* header */}
          {/* add dashboard here */}
          {/* header end  */}
          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}

            <SidePanel />

            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div
              className="dashboard-content"
            // style={{ padding: "40px 50px 70px 351px" }}
            >

              <DashboardMenu user={user} />

              <div className="container dasboard-container">
                {/* dashboard-title */}
                <div className="dashboard-title fl-wrap">
                  <div className="dashboard-title-item">
                    <span>Dashboard</span>
                  </div>
                  <ProfileHeader />
                  {/*Tariff Plan menu*/}
                  {/* <div className="tfp-det-container">
                    <div className="tfp-btn">
                      <span>Your Tariff Plan : </span> <strong>Extended</strong>
                    </div>
                    <div className="tfp-det">
                      <p>
                        You Are on <a href="#">Extended</a> . Use link bellow to
                        view details or upgrade.{" "}
                      </p>
                      <a href="#" className="tfp-det-btn color-bg">
                        Details
                      </a>
                    </div>
                  </div> */}
                  {/*Tariff Plan menu end*/}
                </div>
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap no-pag">
                  <div className="dashboard-stats-container fl-wrap">
                    <div className="row">
                      {/*dashboard-stats*/}
                      <div className="col-md-4">
                        <div className="dashboard-stats fl-wrap">
                          <i className="fa-solid fa-map-marked" />
                          <h4>Active Listings</h4>
                          <div className="dashboard-stats-count">{profile?.property?.length}</div>
                        </div>
                      </div>
                      {/* dashboard-stats end */}
                      {/*dashboard-stats*/}
                      <div className="col-md-4">
                        <div className="dashboard-stats fl-wrap">
                          <i className="fa-solid fa-chart-bar" />
                          <h4>Profile Views</h4>
                          <div className="dashboard-stats-count">
                            {profile?.views}
                            {/* <span>
                              (<strong>+356</strong> this week)
                            </span> */}
                          </div>
                        </div>
                      </div>
                      {/* dashboard-stats end */}
                      {/*dashboard-stats*/}
                      <div className="col-md-4">
                        <div className="dashboard-stats fl-wrap">
                          <i className="fa-solid fa-comment-alt" />
                          <h4>Your Reviews</h4>
                          <div className="dashboard-stats-count">
                            {profile?.profileReview?.length}
                            {/* <span>
                              (<strong>+12</strong> this week)
                            </span> */}
                          </div>
                        </div>
                      </div>
                      {/* dashboard-stats end */}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="row">
                    <div className="col-md-8">
                      {/* <div className="notification success-notif  fl-wrap">
                        <p>
                          Your listing <a href="#">Family house in Brooklyn</a>{" "}
                          has been approved!
                        </p>
                        <a className="notification-close" href="#">
                          <i className="fa-solid fa-times" />
                        </a>
                      </div> */}
                      <div className="dashboard-widget-title fl-wrap">
                        Last Activites
                      </div>
                      <div className="dashboard-list-box  fl-wrap">
                        {/* dashboard-list end*/}
                        {/* <div className="dashboard-list fl-wrap">
                          <div className="dashboard-message">
                            <span className="close-dashboard-item color-bg">
                              <i className="fa-solid fa-times" />
                            </span>
                            <div className="main-dashboard-message-icon color-bg">
                              <i className="fa-solid fa-check" />
                            </div>
                            <div className="main-dashboard-message-text">
                              <p>
                                Your listing <a href="#">Urban Appartmes</a> has
                                been approved!{" "}
                              </p>
                            </div>
                            <div className="main-dashboard-message-time">
                              <i className="fa-solid fa-calendar-week" /> 28 may
                              2020
                            </div>
                          </div>
                        </div> */}
                        {/* dashboard-list end*/}
                        {/* dashboard-list end*/}
                        {profile?.profileReview?.map((data) => (<div className="dashboard-list fl-wrap">
                          <div className="dashboard-message">
                            <span className="close-dashboard-item color-bg">
                              <i className="fa-solid fa-times" />
                            </span>
                            <div className="main-dashboard-message-icon color-bg">
                              <i className="fa-solid fa-comment-alt" />
                            </div>
                            <div className="main-dashboard-message-text">
                              <p>
                                {" "}
                                {data?.reviewer?.customer?.name} left a review on{" "}
                                <a href={`/profile-info-${profile.id}`}>Your Profile</a>!
                              </p>
                            </div>
                            <div className="main-dashboard-message-time">
                              <i className="fa-solid fa-calendar-week" /> {new Date(data?.createdAt).toDateString()}
                            </div>
                          </div>
                        </div>)
                        )}
                        {/* dashboard-list end*/}
                        {/* dashboard-list end*/}
                        {profile?.property?.map((data) => (
                          <div className="dashboard-list fl-wrap">
                            <div className="dashboard-message">
                              <span className="close-dashboard-item color-bg">
                                <i className="fa-solid fa-times" />
                              </span>
                              <div className="main-dashboard-message-icon color-bg">
                                <i className="fa-solid fa-heart" />
                              </div>
                              <div className="main-dashboard-message-text">
                                <p>
                                  You Listed <a href={data.slug}>{data?.name}</a>{" "}
                                  <a href="#">Property </a> listing!
                                </p>
                              </div>
                              <div className="main-dashboard-message-time">
                                <i className="fa-solid fa-calendar-week" />  {new Date(data?.createdAt).toDateString()}
                              </div>
                            </div>
                          </div>)
                        )}
                        {/* dashboard-list end*/}
                      </div>
                      {/* <div className="dashboard-widget-title fl-wrap">
                        New Booking
                      </div>
                      <div className="dashboard-list-box  fl-wrap">
                        <div className="dashboard-list fl-wrap">
                          <div className="dashboard-message">
                            <span className="close-dashboard-item color-bg">
                              <i className="fa-solid fa-times" />
                            </span>
                            <div className="main-dashboard-message-icon color-bg">
                              <i className="fa-solid fa-check" />
                            </div>
                            <div className="main-dashboard-message-text">
                              <p>
                                Your listing <a href="#">Urban Appartmes</a> has
                                been approved!{" "}
                              </p>
                            </div>
                            <div className="main-dashboard-message-time">
                              <i className="fa-solid fa-calendar-week" /> 28 may
                              2020
                            </div>
                          </div>
                        </div>
                        <div className="dashboard-list fl-wrap">
                          <div className="dashboard-message">
                            <span className="close-dashboard-item color-bg">
                              <i className="fa-solid fa-times" />
                            </span>
                            <div className="main-dashboard-message-icon color-bg">
                              <i className="fa-solid fa-comment-alt" />
                            </div>
                            <div className="main-dashboard-message-text">
                              <p>
                                {" "}
                                Someone left a review on{" "}
                                <a href="#">Park Central</a> listing!
                              </p>
                            </div>
                            <div className="main-dashboard-message-time">
                              <i className="fa-solid fa-calendar-week" /> 28 may
                              2020
                            </div>
                          </div>
                        </div>
                        <div className="dashboard-list fl-wrap">
                          <div className="dashboard-message">
                            <span className="close-dashboard-item color-bg">
                              <i className="fa-solid fa-times" />
                            </span>
                            <div className="main-dashboard-message-icon color-bg">
                              <i className="fa-solid fa-heart" />
                            </div>
                            <div className="main-dashboard-message-text">
                              <p>
                                <a href="#">Fider Mamby</a> bookmarked your{" "}
                                <a href="#">Holiday Home</a> listing!
                              </p>
                            </div>
                            <div className="main-dashboard-message-time">
                              <i className="fa-solid fa-calendar-week" /> 28 may
                              2020
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="col-md-4">
                      {/*box-widget*/}
                      <div className="dasboard-widget fl-wrap">
                        <div className="dasboard-widget-title fl-wrap">
                          <h5>
                            <i className="fa-solid fa-comment-alt" />
                            Last Messages
                          </h5>
                        </div>
                        <div className="chat-contacts fl-wrap">
                          {/* chat-contacts-item*/}
                          {profile?.profileReview?.map((data) => (
                            <a className="chat-contacts-item" href="#">
                              <div className="dashboard-message-avatar">
                                <img
                                  src={(data?.reviewer?.photo ? `${apiUrl}/uploads/${data?.reviewer?.photo}` : "https://hauzzi.webkype.net/images/avatar/2.jpg")}
                                  alt="customer"
                                />
                                <div className="message-counter">2</div>
                              </div>
                              <div className="chat-contacts-item-text">
                                <h4>{data?.reviewer?.customer?.name}</h4>
                                <span>{new Date(data?.createdAt).toDateString()}</span>
                                <p>
                                  {data.content}
                                </p>
                              </div>
                            </a>
                          ))}
                          {/* chat-contacts-item */}
                          {/* chat-contacts-item*/}
                          {/* <a className="chat-contacts-item" href="#">
                            <div className="dashboard-message-avatar">
                              <img
                                src="https://hauzzi.webkype.net/images/avatar/1.jpg"
                                alt
                              />
                              <div className="message-counter">1</div>
                            </div>
                            <div className="chat-contacts-item-text">
                              <h4>Adam Koncy</h4>
                              <span>27 Dec 2018 </span>
                              <p>
                                Vivamus lobortis vel nibh nec maximus. Donec
                                dolor erat, rutrum ut feugiat sed, ornare vitae
                                nunc. Donec massa nisl, bibendum id ultrices
                                sed, accumsan sed dolor.
                              </p>
                            </div>
                          </a> */}
                          {/* chat-contacts-item */}
                          {/* chat-contacts-item*/}
                          {/* <a
                            className="chat-contacts-item chat-contacts-item_active"
                            href="#"
                          >
                            <div className="dashboard-message-avatar">
                              <img
                                src="https://hauzzi.webkype.net/images/avatar/3.jpg"
                                alt
                              />
                              <div className="message-counter">3</div>
                            </div>
                            <div className="chat-contacts-item-text">
                              <h4>Andy Smith</h4>
                              <span>27 Dec 2018 </span>
                              <p>
                                Vivamus lobortis vel nibh nec maximus. Donec
                                dolor erat, rutrum ut feugiat sed, ornare vitae
                                nunc. Donec massa nisl, bibendum id ultrices
                                sed, accumsan sed dolor.
                              </p>
                            </div>
                          </a> */}
                          {/* chat-contacts-item */}
                          {/* chat-contacts-item*/}
                          {/* <a className="chat-contacts-item" href="#">
                            <div className="dashboard-message-avatar">
                              <img
                                src="https://hauzzi.webkype.net/images/avatar/5.jpg"
                                alt
                              />
                              <div className="message-counter">4</div>
                            </div>
                            <div className="chat-contacts-item-text">
                              <h4>Joe Frick</h4>
                              <span>27 Dec 2018 </span>
                              <p>
                                Vivamus lobortis vel nibh nec maximus. Donec
                                dolor erat, rutrum ut feugiat sed, ornare vitae
                                nunc. Donec massa nisl, bibendum id ultrices
                                sed, accumsan sed dolor.
                              </p>
                            </div>
                          </a> */}
                          {/* chat-contacts-item */}
                          {/* chat-contacts-item*/}
                          {/* <a className="chat-contacts-item" href="#">
                            <div className="dashboard-message-avatar">
                              <img
                                src="https://hauzzi.webkype.net/images/avatar/4.jpg"
                                alt
                              />
                            </div>
                            <div className="chat-contacts-item-text">
                              <h4>Alise Goovy</h4>
                              <span>27 Dec 2018 </span>
                              <p>
                                Vivamus lobortis vel nibh nec maximus. Donec
                                dolor erat, rutrum ut feugiat sed, ornare vitae
                                nunc. Donec massa nisl, bibendum id ultrices
                                sed, accumsan sed dolor.
                              </p>
                            </div>
                          </a> */}
                          {/* chat-contacts-item */}
                          {/* chat-contacts-item*/}
                          {/* <a className="chat-contacts-item" href="#">
                            <div className="dashboard-message-avatar">
                              <img
                                src="https://hauzzi.webkype.net/images/avatar/6.jpg"
                                alt
                              />
                            </div>
                            <div className="chat-contacts-item-text">
                              <h4>Cristiano Olando</h4>
                              <span>27 Dec 2018 </span>
                              <p>
                                Vivamus lobortis vel nibh nec maximus. Donec
                                dolor erat, rutrum ut feugiat sed, ornare vitae
                                nunc. Donec massa nisl, bibendum id ultrices
                                sed, accumsan sed dolor.
                              </p>
                            </div>
                          </a> */}
                          {/* chat-contacts-item */}
                        </div>
                      </div>
                      {/*box-widget end */}
                      {/*box-widget*/}
                      <div className="box-widget fl-wrap">
                        <div className="banner-widget fl-wrap">
                          <div className="bg-wrap bg-parallax-wrap-gradien">
                            <div
                              className="bg  "
                              data-bg="https://hauzzi.webkype.net/images/all/blog/1.jpg"
                            />
                          </div>
                          <div className="banner-widget_content">
                            <h5>
                              Participate in our loyalty program. Refer a friend
                              and get a discount.
                            </h5>
                            <a
                              href="#"
                              className="btn float-btn color-bg small-btn"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                      {/*box-widget end */}
                    </div>
                  </div>
                </div>
              </div>
              {/* dashboard-footer */}
              <ProfileFooter />
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
      ;
    </>
  );
}

export default DashBoard;
