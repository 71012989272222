import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import { useParams } from "react-router-dom";
import HeroSection from "./HeroSection";

function BlogSingle() {



  const [masterData, setMasterData] = useState([])
  const [blogList, setBlogList] = useState([])
  const [categoryCount, setCategoryCount] = useState([])
  const { slug } = useParams()

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;


  useEffect(() => {
    fetch(`${apiUrl}/blog/blogBySlug/${slug}`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          setMasterData(data.data);
          console.log(data.categoryCounts, 'counts')
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiKey, apiUrl, slug])

  useEffect(() => {
    fetch(`${apiUrl}/blog/getAll?limit=5`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        setCategoryCount(data.categoryCounts);
        if (data && Array.isArray(data.data)) {
          setBlogList(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])


  return (
    <div>
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />

      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />

      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />

      <div className="loader-wrap">
        <div className="loader-inner">
          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation={2}
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                  result="gooey"
                />
                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}
        {/* put header here */}
        {/* header end  */}
        {/* wrapper  */}
        <div id="wrapper p-0">
          {/* content */}
          <div className="content">
            <HeroSection />
            {/* breadcrumbs*/}
            <div className="breadcrumbs fw-breadcrumbs sp-brd fl-wrap   top-smpar  ">
              <div className="container">
                <div className="breadcrumbs-list">
                  <a href="#">Home</a>
                  <a href="#">Blog</a> <span>Blog Single</span>
                </div>
                <div className="share-holder hid-share">
                  <a href="#" className="share-btn showshare sfcs">
                    {" "}
                    <i className="fa-solid fa-share-alt" /> Share{" "}
                  </a>
                  <div className="share-container  isShare" />
                </div>
              </div>
            </div>
            {/* breadcrumbs end */}
            {/* col-list-wrap */}
            <div className="gray-bg small-padding fl-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="post-container fl-wrap">
                      {/* article> */}
                      <article className="post-article fl-wrap">
                        <div className="list-single-main-media fl-wrap color-bg">
                          <img
                            src={`${apiUrl}/uploads/${masterData?.photo}`}
                            alt="blog"
                            height={'400px'}
                          />
                        </div>
                        <div className="list-single-main-item fl-wrap block_box">
                          <div className="single-article-header fl-wrap">
                            <h2 className="post-opt-title">
                              <a href="blog-single.html">
                                {masterData.title}
                              </a>
                            </h2>
                            <span className="fw-separator" />
                            <div className="clearfix" />
                            <div className="post-author">
                              <a href="#">
                                <img
                                  src="https://hauzzi.webkype.net/images/avatar/5.jpg"
                                  alt
                                />
                                <span>By , Hauzzi</span>
                              </a>
                            </div>
                            <div className="post-opt">
                              <ul className="no-list-style">
                                <li>
                                  <i className="fa-solid fa-calendar" />{" "}
                                  <span> {new Date(masterData.createdAt).toDateString()}</span>
                                </li>
                                {/* <li>
                                  <i className="fa-solid fa-eye" />{" "}
                                  <span>164</span>
                                </li>
                                <li>
                                  <i className="fa-solid fa-tags" />{" "}
                                  <a href="#">Shop</a> , <a href="#">Hotels</a>{" "}
                                </li> */}
                              </ul>
                            </div>
                          </div>
                          <span className="fw-separator fl-wrap" />
                          <p>
                            <div dangerouslySetInnerHTML={{ __html: masterData.content }} />
                          </p>
                          {/* <p>
                            Duis mattis laoreet neque, et ornare neque
                            sollicitudin at. Proin sagittis dolor sed mi
                            elementum pretium. Donec et justo ante. Vivamus
                            egestas sodales est, eu rhoncus urna semper eu. Cum
                            sociis natoque penatibus et magnis dis parturient
                            montes, nascetur ridiculus mus. Integer tristique
                            elit lobortis purus bibendum, quis dictum metus
                            mattis. Phasellus posuere felis sed eros porttitor
                            mattis. Curabitur massa magna, tempor in blandit id,
                            porta in ligula. Aliquam laoreet nisl massa, at
                            interdum mauris sollicitudin et
                          </p> */}
                          {/* <blockquote>
                            <p>
                              Vestibulum id ligula porta felis euismod semper.
                              Sed posuere consectetur est at lobortis. Aenean eu
                              leo quam. Pellentesque ornare sem lacinia quam
                              venenatis vestibulum. Duis mollis, est non commodo
                              luctus, nisi erat porttitor ligula, eget lacinia
                              odio sem nec elit. Donec ullamcorper nulla non
                              metus auctor fringilla. Vestibulum id ligula porta
                              felis euismod semper.
                            </p>
                          </blockquote> */}
                          {/* <p>
                            Ut nec hinc dolor possim. An eros argumentum vel,
                            elit diceret duo eu, quo et aliquid ornatus
                            delicatissimi. Cu nam tale ferri utroque, eu habemus
                            albucius mel, cu vidit possit ornatus eum. Eu ius
                            postulant salutatus definitionem, an e trud
                            erroribus explicari. Graeci viderer qui ut, at habeo
                            facer solet usu. Pri choro pertinax indoctum ne, ad
                            partiendo persecuti forensibus est.
                          </p> */}
                          <div className="clearfix" />
                          <span className="fw-separator fl-wrap" />
                          {/* <div className="list-single-tags tags-stylwrap">
                            <span className="tags-title"> Tags : </span>
                            <a href="#">Hotel</a>
                            <a href="#">Hostel</a>
                            <a href="#">Room</a>
                            <a href="#">Spa</a>
                            <a href="#">Restourant</a>
                            <a href="#">Parking</a>
                          </div> */}
                        </div>
                      </article>
                      {/* article end */}
                      {/*content-nav_holder*/}
                      {/* <div className="content-nav_holder fl-wrap color-bg">
                        <div className="content-nav">
                          <ul>
                            <li>
                              <a href="blog-single.html" className="ln">
                                <i className="fa-solid fa-long-arrow-left" />
                                <span>
                                  Prev <strong>- Post Title</strong>
                                </span>
                              </a>
                              <div className="content-nav-media">
                                <div
                                  className="bg"
                                  data-bg="https://hauzzi.webkype.net/images/bg/1.jpg"
                                />
                              </div>
                            </li>
                            <li>
                              <a href="blog-single.html" className="rn">
                                <span>
                                  Next <strong>- Post Title</strong>
                                </span>{" "}
                                <i className="fa-solid fa-long-arrow-right" />
                              </a>
                              <div className="content-nav-media">
                                <div
                                  className="bg"
                                  data-bg="https://hauzzi.webkype.net/images/bg/2.jpg"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                      {/*content-nav_holder end */}
                      {/* <div
                        className="list-single-main-container fl-wrap"
                        style={{ marginTop: 30 }}
                      >
                        <div
                          className="list-single-main-item fl-wrap"
                          id="sec6"
                        > */}
                      {/* <div className="list-single-main-item-title">
                            <h3>
                              Comments <span>2</span>
                            </h3>
                          </div> */}
                      {/* <div className="list-single-main-item_content fl-wrap">
                            <div className="reviews-comments-wrap fl-wrap">
                              <div className="reviews-comments-item">
                                <div className="review-comments-avatar">
                                  <img
                                    src="https://hauzzi.webkype.net/images/avatar/1.jpg"
                                    alt
                                  />
                                </div>
                                <div className="reviews-comments-item-text smpar">
                                  <div className="box-widget-menu-btn smact">
                                    <i className="fa-solid fa-ellipsis-h" />
                                  </div>
                                  <div className="show-more-snopt-tooltip bxwt">
                                    <a href="#">
                                      {" "}
                                      <i className="fa-solid fa-reply" /> Reply
                                    </a>
                                    <a href="#">
                                      {" "}
                                      <i className="fa-solid fa-exclamation-triangle" />{" "}
                                      Report{" "}
                                    </a>
                                  </div>
                                  <h4>
                                    <a href="#">Liza Rose</a>
                                  </h4>
                                  <div className="clearfix" />
                                  <p>
                                    " Donec quam felis, ultricies nec,
                                    pellentesque eu, pretium quis, sem. Nulla
                                    consequat massa quis enim. Donec pede justo,
                                    fringilla vel, aliquet nec, vulputate eget,
                                    arcu. In enim justo, rhoncus ut, imperdiet
                                    a, venenatis vitae, justo. Nullam dictum
                                    felis eu pede mollis pretium. "
                                  </p>
                                  <div className="reviews-comments-item-date">
                                    <span className="reviews-comments-item-date-item">
                                      <i className="fa-solid fa-calendar-check" />
                                      12 April 2018
                                    </span>
                                    <a href="#" className="rate-review">
                                      <i className="fa-solid fa-thumbs-up" />{" "}
                                      Helpful Comment <span>6</span>{" "}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="reviews-comments-item">
                                <div className="review-comments-avatar">
                                  <img
                                    src="https://hauzzi.webkype.net/images/avatar/4.jpg"
                                    alt
                                  />
                                </div>
                                <div className="reviews-comments-item-text smpar">
                                  <div className="box-widget-menu-btn smact">
                                    <i className="fa-solid fa-ellipsis-h" />
                                  </div>
                                  <div className="show-more-snopt-tooltip bxwt">
                                    <a href="#">
                                      {" "}
                                      <i className="fa-solid fa-reply" /> Reply
                                    </a>
                                    <a href="#">
                                      {" "}
                                      <i className="fa-solid fa-exclamation-triangle" />{" "}
                                      Report{" "}
                                    </a>
                                  </div>
                                  <h4>
                                    <a href="#">Adam Koncy</a>
                                  </h4>
                                  <div className="clearfix" />
                                  <p>
                                    " Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Nunc posuere convallis
                                    purus non cursus. Cras metus neque, gravida
                                    sodales massa ut. "
                                  </p>
                                  <div className="reviews-comments-item-date">
                                    <span className="reviews-comments-item-date-item">
                                      <i className="fa-solid fa-calendar-check" />
                                      03 December 2017
                                    </span>
                                    <a href="#" className="rate-review">
                                      <i className="fa-solid fa-thumbs-up" />{" "}
                                      Helpful Comment <span>2</span>{" "}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                    </div>
                    {/* list-single-main-item end */}
                    {/* list-single-main-item */}
                    {/* <div
                          className="list-single-main-item fl-wrap"
                          id="sec5"
                        >
                          <div className="list-single-main-item-title fl-wrap">
                            <h3>Add Your Comment</h3>
                          </div>
                          <div id="add-review" className="add-review-box">
                            <div className="leave-rating-wrap"></div>
                            <form className="add-comment custom-form">
                              <fieldset>
                                <div className="row">
                                  <div className="col-md-6">
                                    <label>
                                      Your name*{" "}
                                      <span className="dec-icon">
                                        <i className="fa-solid fa-user" />
                                      </span>
                                    </label>
                                    <input
                                      name="phone"
                                      type="text"
                                      onclick="this.select()"
                                      defaultValue
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label>
                                      Yourmail*{" "}
                                      <span className="dec-icon">
                                        <i className="fa-solid fa-envelope" />
                                      </span>
                                    </label>
                                    <input
                                      name="reviewwname"
                                      type="text"
                                      onclick="this.select()"
                                      defaultValue
                                    />
                                  </div>
                                </div>
                                <textarea
                                  cols={40}
                                  rows={3}
                                  placeholder="Your Review:"
                                  defaultValue={""}
                                />
                              </fieldset>
                              <button className="btn big-btn color-bg float-btn">
                                Submit Comment{" "}
                                <i
                                  className="fa fa-paper-plane-o"
                                  aria-hidden="true"
                                />
                              </button>
                            </form>
                          </div>
                        </div> */}
                    {/* list-single-main-item end */}
                    {/* </div>
                    </div> */}
                  </div>
                  {/* col-md 8 end */}
                  {/*  sidebar*/}
                  <div className="col-md-4">
                    <div className="box-widget-wrap fl-wrap fixed-bar">
                      {/*box-widget*/}
                      {/* <div className="box-widget fl-wrap">
                        <div className="search-widget fl-wrap">
                          <form action="#" className="fl-wrap custom-form">
                            <input
                              name="se"
                              id="se"
                              type="text"
                              className="search"
                              placeholder="Search.."
                              defaultValue
                            />
                            <button className="search-submit" id="submit_btn">
                              <i className="fa-solid fa-search" />
                            </button>
                          </form>
                        </div>
                      </div> */}
                      {/*box-widget end */}
                      {/*box-widget*/}
                      <div className="box-widget fl-wrap">
                        <div className="box-widget-title fl-wrap">
                          Popular Posts
                        </div>
                        <div className="box-widget-content fl-wrap">
                          {/*widget-posts*/}
                          <div className="widget-posts  fl-wrap">
                            <ul className="no-list-style">
                              {blogList?.map((data) => (
                                <li>
                                  <div className="widget-posts-img">
                                    <a href="blog-single.html">
                                      <img
                                        src={`${apiUrl}/uploads/${data.photo}`}
                                        alt="blog"
                                        style={{ height: '60px' }}
                                      />
                                    </a>
                                  </div>
                                  <div className="widget-posts-descr">
                                    <h4>
                                      <a href={data.slug}>
                                        {data.title}
                                      </a>
                                    </h4>
                                    <div className="geodir-category-location fl-wrap">
                                      <a href="#1">
                                        <i className="fa-solid fa-calendar" /> {new Date(data.createdAt).toDateString()}
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              ))}
                              {/* <li>
                                <div className="widget-posts-img">
                                  <a href="blog-single.html">
                                    <img
                                      src="https://hauzzi.webkype.net/images/all/blog/2.jpg"
                                      alt
                                    />
                                  </a>
                                </div>
                                <div className="widget-posts-descr">
                                  <h4>
                                    <a href="listing-single.html">
                                      Scrambled it to mak
                                    </a>
                                  </h4>
                                  <div className="geodir-category-location fl-wrap">
                                    <a href="#">
                                      <i className="fa-solid fa-calendar" /> 12
                                      May 2020
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="widget-posts-img">
                                  <a href="blog-single.html">
                                    <img
                                      src="https://hauzzi.webkype.net/images/all/blog/6.jpg"
                                      alt
                                    />
                                  </a>{" "}
                                </div>
                                <div className="widget-posts-descr">
                                  <h4>
                                    <a href="listing-single.html">
                                      Fermentum nis type
                                    </a>
                                  </h4>
                                  <div className="geodir-category-location fl-wrap">
                                    <a href="#">
                                      <i className="fa-solid fa-calendar" />
                                      22 Feb 2020
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="widget-posts-img">
                                  <a href="blog-single.html">
                                    <img
                                      src="https://hauzzi.webkype.net/images/all/blog/4.jpg"
                                      alt
                                    />
                                  </a>{" "}
                                </div>
                                <div className="widget-posts-descr">
                                  <h4>
                                    <a href="listing-single.html">
                                      Rutrum elementum
                                    </a>
                                  </h4>
                                  <div className="geodir-category-location fl-wrap">
                                    <a href="#">
                                      <i className="fa-solid fa-calendar" /> 7
                                      Mar 2019
                                    </a>
                                  </div>
                                </div>
                              </li> */}
                            </ul>
                          </div>
                          {/* widget-posts end*/}
                        </div>
                      </div>
                      {/*box-widget end */}
                      {/*box-widget*/}
                      <div className="box-widget fl-wrap">
                        <div className="box-widget-title fl-wrap">
                          Categories
                        </div>
                        <div className="box-widget-content fl-wrap">
                          <ul className="cat-item no-list-style">
                            {categoryCount?.map((data) => (
                              <li>
                                <a href="#">{data.category}</a> <span>{data.count}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      {/*box-widget end */}
                      {/*box-widget*/}
                      <div className="box-widget fl-wrap">
                        <div className="banner-widget fl-wrap">
                          <div className="bg-wrap bg-parallax-wrap-gradien">
                            <div
                              className="bg  "
                              data-bg="https://hauzzi.webkype.net/images/all/blog/1.jpg"
                            />
                          </div>
                          <div className="banner-widget_content">
                            <h5>
                              Do you want to join our real estate network?
                            </h5>
                            <a
                              href="#"
                              className="btn float-btn color-bg small-btn"
                            >
                              Become an Agent
                            </a>
                          </div>
                        </div>
                      </div>
                      {/*box-widget end */}
                      {/*box-widget*/}
                      {/* <div className="box-widget fl-wrap">
                        <div className="box-widget-title fl-wrap">Tags</div>
                        <div className="box-widget-content fl-wrap">
                          <div
                            className="list-single-tags fl-wrap tags-stylwrap"
                            style={{ marginTop: 20 }}
                          >
                            <a href="#">Hotel</a>
                            <a href="#">Hostel</a>
                            <a href="#">Room</a>
                            <a href="#">Spa</a>
                            <a href="#">Restourant</a>
                            <a href="#">Parking</a>
                          </div>
                        </div>
                      </div> */}
                      {/*box-widget end */}
                      {/*box-widget*/}
                      {/* <div className="box-widget fl-wrap">
                        <div className="box-widget-title fl-wrap">Archive</div>
                        <div className="box-widget-content fl-wrap">
                          <ul className="cat-item cat-item_dec no-list-style">
                            <li>
                              <a href="#">March 2020</a>
                            </li>
                            <li>
                              <a href="#">May 2019</a>
                            </li>
                            <li>
                              <a href="#">January 2016</a>
                            </li>
                            <li>
                              <a href="#">Decemder 2018</a>{" "}
                            </li>
                          </ul>
                        </div>
                      </div> */}
                      {/*box-widget end */}
                    </div>
                    {/* sidebar end*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="limit-box fl-wrap" />
          </div>
          {/* <div className="subscribe-wrap fl-wrap">
            <div className="container">
              <div className="subscribe-container fl-wrap color-bg">
                <div className="pwh_bg" />
                <div className="mrb_dec mrb_dec3" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="subscribe-header">
                      <h4>newsletter</h4>
                      <h3>
                        Sign up for newsletter and get latest news and update
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-5">
                    <div className="footer-widget fl-wrap">
                      <div className="subscribe-widget fl-wrap">
                        <div className="subcribe-form">
                          <form id="subscribe">
                            <input
                              className="enteremail fl-wrap"
                              name="email"
                              id="subscribe-email"
                              placeholder="Enter Your Email"
                              spellCheck="false"
                              type="text"
                            />
                            <button
                              type="submit"
                              id="subscribe-button"
                              className="subscribe-button color-bg"
                            >
                              {" "}
                              Subscribe
                            </button>
                            <label
                              htmlFor="subscribe-email"
                              className="subscribe-message"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default BlogSingle;
