import React, { useState, useEffect } from "react";
import SidePanel from "./SIdePanel";
import ProfileHeader from "./ProfileHeader";
import ProfileFooter from "./ProfileFooter";
import DashboardMenu from "./DashboardMenu";

function MyReview() {

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");

  const [user, setUser] = useState({})

  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
      }
    }
    getUser();
  }, []);


  const handleDelete = (e, id) => {
    if (window.confirm("Do you want to delete")) {
      fetch(`${apiUrl}/review/delete/` + id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      }).then((res) => {

        alert('Record Deleted');
        window.location.reload()
      }).catch((err) => {
        console.log(err);
      })
    }
  }

  return (
    <>
      <div>
        <meta charSet="UTF-8" />

        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* header put here */}
          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            <SidePanel />
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div
              className="dashboard-content"
              // style={{ padding: "40px 50px 70px 351px" }}
            >
              <DashboardMenu user={user} />

              <div className="container dasboard-container">
                {/* dashboard-title */}
                <div className="dashboard-title fl-wrap">
                  <div className="dashboard-title-item">
                    <span>Reviews</span>
                  </div>
                  <ProfileHeader />
                  {/*Tariff Plan menu*/}
                  {/* <div className="tfp-det-container">
                    <div className="tfp-btn">
                      <span>Your Tariff Plan : </span> <strong>Extended</strong>
                    </div>
                    <div className="tfp-det">
                      <p>
                        You Are on <a href="#">Extended</a> . Use link bellow to
                        view details or upgrade.{" "}
                      </p>
                      <a href="#" className="tfp-det-btn color-bg">
                        Details
                      </a>
                    </div>
                  </div> */}
                  {/*Tariff Plan menu end*/}
                </div>
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap">
                  <div className="dasboard-widget-title fl-wrap">
                    <h5>
                      <i className="fa-solid fa-comments" />
                      Last Reviews <span> ( {user?.propertyReview?.length} In Total ) </span>
                    </h5>
                    <a
                      href="#1"
                      className="mark-btn  tolt"
                      data-microtip-position="bottom"
                      data-tooltip="Mark all as read"
                    >
                      <i className="fa-solid fa-comment" />{" "}
                    </a>
                  </div>
                  <div className="dasboard-widget-box fl-wrap">
                    <div className="dasboard-opt fl-wrap">
                      {/* price-opt*/}
                      <div className="price-opt">
                        <span className="price-opt-title">Sort by:</span>
                        <div className="listsearch-input-item">
                          <select
                            data-placeholder="Lastes"
                            className="selectBox"
                          >
                            <option>Lastes</option>
                            <option>Oldes</option>
                            <option>Average rating</option>
                          </select>
                        </div>
                      </div>
                      {/* price-opt end*/}
                    </div>
                    {/* reviews-comments-item */}
                    {user?.propertyReview?.map((data) => (
                      <div className="reviews-comments-item">
                        <div className="review-comments-avatar">
                          <img
                            src={(data?.property?.photo1 ? `${apiUrl}/uploads/${data?.property?.photo1}` : "https://hauzzi.webkype.net/images/avatar/2.jpg")}
                            alt="reviewer"
                          />
                          {/* <div className="review-notifer">New</div> */}
                        </div>
                        <div className="reviews-comments-item-text smpar">
                          <div className="box-widget-menu-btn smact">
                            <i className="fa-solid fa-ellipsis-h" />
                          </div>
                          <div className="show-more-snopt-tooltip bxwt">
                            <a href="#">
                              {" "}
                              <i className="fa-solid fa-reply" /> Reply
                            </a>
                            <a href="#">
                              {" "}
                              <i className="fa-solid fa-exclamation-triangle" />{" "}
                              Report{" "}
                            </a>
                          </div>
                          <h4>
                            <a href={data?.property?.slug}>
                              {user?.name} <span>For {data?.property?.name} </span>
                            </a>
                          </h4>
                          {/* <div
                            className="listing-rating card-popup-rainingvis"
                            data-starrating2={3}
                          >
                            <span className="re_stars-title">Average</span>
                          </div> */}
                          <div className="clearfix" />
                          <p>
                            " {data.content} "
                          </p>
                          <div className="reviews-comments-item-date">
                            <span className="reviews-comments-item-date-item">
                              <i className="fa-solid fa-calendar-check" />
                              {new Date(data?.createdAt).toDateString()}
                            </span>
                            {/* <a href="#" className="rate-review">
                              <i className="fa-solid fa-thumbs-up" /> Helpful
                              Review <span>6</span>{" "}
                            </a> */}
                          </div>
                          <div>
                            <i
                              className="fa-solid fa-trash"
                              role="button"
                              onClick={(e) => {
                                handleDelete(e, data?.id)
                              }}
                              style={{ marginLeft: '45vw' }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    {/*reviews-comments-item end*/}
                    {/* reviews-comments-item */}
                    {/* <div className="reviews-comments-item">
                      <div className="review-comments-avatar">
                        <img
                          src="https://hauzzi.webkype.net/images/avatar/3.jpg"
                          alt
                        />
                        <div className="review-notifer">New</div>
                      </div>
                      <div className="reviews-comments-item-text smpar">
                        <div className="box-widget-menu-btn smact">
                          <i className="fa-solid fa-ellipsis-h" />
                        </div>
                        <div className="show-more-snopt-tooltip bxwt">
                          <a href="#">
                            {" "}
                            <i className="fa-solid fa-reply" /> Reply
                          </a>
                          <a href="#">
                            {" "}
                            <i className="fa-solid fa-exclamation-triangle" />{" "}
                            Report{" "}
                          </a>
                        </div>
                        <h4>
                          <a href="#">
                            Adam Koncy <span>Kayak Point House</span>
                          </a>
                        </h4>
                        <div
                          className="listing-rating card-popup-rainingvis"
                          data-starrating2={5}
                        >
                          <span className="re_stars-title">Excellent</span>
                        </div>
                        <div className="clearfix" />
                        <p>
                          " Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nunc posuere convallis purus non cursus. Cras
                          metus neque, gravida sodales massa ut. "
                        </p>
                        <div className="reviews-comments-item-date">
                          <span className="reviews-comments-item-date-item">
                            <i className="fa-solid fa-calendar-check" />
                            03 December 2019
                          </span>
                          <a href="#" className="rate-review">
                            <i className="fa-solid fa-thumbs-up" /> Helpful
                            Review <span>2</span>{" "}
                          </a>
                        </div>
                      </div>
                    </div> */}
                    {/*reviews-comments-item end*/}
                    {/* reviews-comments-item */}
                    {/* <div className="reviews-comments-item">
                      <div className="review-comments-avatar">
                        <img
                          src="https://hauzzi.webkype.net/images/avatar/4.jpg"
                          alt
                        />
                      </div>
                      <div className="reviews-comments-item-text smpar">
                        <div className="box-widget-menu-btn smact">
                          <i className="fa-solid fa-ellipsis-h" />
                        </div>
                        <div className="show-more-snopt-tooltip bxwt">
                          <a href="#">
                            {" "}
                            <i className="fa-solid fa-reply" /> Reply
                          </a>
                          <a href="#">
                            {" "}
                            <i className="fa-solid fa-exclamation-triangle" />{" "}
                            Report{" "}
                          </a>
                        </div>
                        <h4>
                          <a href="#">
                            Mark Rose <span>For Gorgeous House For Sale </span>
                          </a>
                        </h4>
                        <div
                          className="listing-rating card-popup-rainingvis"
                          data-starrating2={5}
                        >
                          <span className="re_stars-title">Excellent</span>
                        </div>
                        <div className="clearfix" />
                        <p>
                          " Donec quam felis, ultricies nec, pellentesque eu,
                          pretium quis, sem. Nulla consequat massa quis enim.
                          Donec pede justo, fringilla vel, aliquet nec,
                          vulputate eget, arcu. In enim justo, rhoncus ut,
                          imperdiet a, venenatis vitae, justo. Nullam dictum
                          felis eu pede mollis pretium. "
                        </p>
                        <div className="reviews-comments-item-date">
                          <span className="reviews-comments-item-date-item">
                            <i className="fa-solid fa-calendar-check" />
                            06 December 2018
                          </span>
                          <a href="#" className="rate-review">
                            <i className="fa-solid fa-thumbs-up" /> Helpful
                            Review <span>2</span>{" "}
                          </a>
                        </div>
                      </div>
                    </div> */}
                    {/*reviews-comments-item end*/}
                  </div>
                  {/* pagination*/}
                  {/* <div className="pagination float-pagination">
                    <a href="#" className="prevposts-link">
                      <i className="fa fa-caret-left" />
                    </a>
                    <a href="#">1</a>
                    <a href="#" className="current-page">
                      2
                    </a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#" className="nextposts-link">
                      <i className="fa fa-caret-right" />
                    </a>
                  </div> */}
                  {/* pagination end*/}
                </div>
              </div>
              {/* dashboard-footer */}
              <ProfileFooter />
              {/* dashboard-footer end */}
            </div>

            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
      </div>
      ;
    </>
  );
}

export default MyReview;
