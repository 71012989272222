import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'



function ProfileHeader() {


    const [user, setUser] = useState({})

    const apiUrl = process.env.REACT_APP_URL;

    const Token = localStorage.getItem("Token");


    const handleLogOut = () => {
        localStorage.removeItem('Token')
        window.location.href = '/'
        toast.success('Logged Out !!')
    }


    useEffect(() => {
        async function getUser() {
            const url = `${apiUrl}/employee/verify-customer`;


            let response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setUser(response.data)
                console.log(response)
            }
        }
        getUser();
    }, [apiUrl, Token]);

    return (
        <div className="dashbard-menu-header">
            <div className="dashbard-menu-avatar fl-wrap">
                <img src={user?.userDetails?.[0]?.photo ? `${apiUrl}/uploads/${user?.userDetails?.[0]?.photo}` : "images/avatar/5.jpg"} alt="user" />
                <h4>
                    Welcome, <span>{user?.name}</span>
                </h4>
            </div>
            <a
                className="log-out-btn tolt"
                data-microtip-position="bottom"
                data-tooltip="Log Out"
                onClick={handleLogOut}
                role="button"
            >
                <i className="fa-solid fa-power-off" />
            </a>
        </div>);
}

export default ProfileHeader
