import React, { useEffect, useState } from "react";
import SidePanel from "./SIdePanel";
import ProfileHeader from "./ProfileHeader";
import ProfileFooter from "./ProfileFooter";

function Message() {


  const [masterData, setMasterData] = useState([])
  const [message, setMessage] = useState([])
  const [user, setUser] = useState({})
  const [sentTo, setSentTo] = useState([])
  const [current, setCurrent] = useState({})

  const apiUrl = process.env.REACT_APP_URL;
  // const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");


  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
      }
    }
    getUser();
  }, [apiUrl, Token]);

  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/profile-enquiry/getByUser/${user.id}`;


      let response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setMasterData(response.data)
        let newArr = sentTo
        for (const data of response.data) {
          newArr = [...newArr, data.customer]
        }
      }
    }
    if (user?.id) {
      getUser();
    }
  }, [user, apiUrl, Token]);

  const getMessage = async (senderId, receiverId, index) => {


    const formDataToSend = new FormData();
    formDataToSend.append('senderId', senderId);
    formDataToSend.append('receiverId', receiverId);

    setCurrent(sentTo[index])

    const url = `${apiUrl}/message/getByBoth`;


    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend
    });
    response = await response.json();

    if (response.status !== "error") {
      setMessage(response.data)
    }
  }



  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link rel="shortcut icon" href="images/favicon.ico" />
        {/*loader*/}
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}

          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            <SidePanel></SidePanel>
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div className="dashboard-content">
              <div className="dashboard-menu-btn color-bg">
                <span>
                  <i className="fa-solid fa-bars" />
                </span>
                Dasboard Menu
              </div>
              <div className="container dasboard-container">
                {/* dashboard-title */}
                <div className="dashboard-title fl-wrap">
                  <div className="dashboard-title-item">
                    <span>Messages</span>
                  </div>

                  <ProfileHeader />
                  {/*Tariff Plan menu*/}
                  {/* <div className="tfp-det-container">
                    <div className="tfp-btn">
                      <span>Your Tariff Plan : </span> <strong>Extended</strong>
                    </div>
                    <div className="tfp-det">
                      <p>
                        You Are on <a href="#">Extended</a> . Use link bellow to
                        view details or upgrade.{" "}
                      </p>
                      <a href="#" className="tfp-det-btn color-bg">
                        Details
                      </a>
                    </div>
                  </div> */}
                  {/*Tariff Plan menu end*/}
                </div>
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap no-pag">
                  {/* dashboard-list-box*/}
                  <div className="dashboard-list-box fl-wrap">
                    <div className="dasboard-widget-title fl-wrap">
                      <h5>
                        <i className="fa-solid fa-comment-alt" />
                        Last Messages
                        {/* <span> ( +3 New ) </span> */}
                      </h5>
                      <a
                        href="#"
                        className="mark-btn  tolt"
                        data-microtip-position="bottom"
                        data-tooltip="Mark all as read"
                      >
                        <i className="far fa-comments" />{" "}
                      </a>
                    </div>
                    <div className="chat-wrapper fl-wrap">
                      {/* chat-contacts*/}
                      <div className="chat-contacts">
                        {/* chat-contacts-item*/}
                        {masterData?.map((data, index) => (
                          <a
                            className="chat-contacts-item"
                            href="#1"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              getMessage(user.id, data.customer.id, index)
                            }}
                          >
                            <div className="dashboard-message-avatar">
                              <img
                                src={data.customer?.userDetails?.[0]?.photo ? `${apiUrl}/uploads/${data.customer.userDetails[0].photo}` : "images/avatar/4.jpg"}
                                alt="profile"
                              />
                              <div className="message-counter">1</div>
                            </div>
                            <div className="chat-contacts-item-text">
                              <h4>{data.customer.name}</h4>
                              <span>{new Date(data.timeOfEnquiry).toDateString()} </span>
                              <p>
                                {data.message}
                              </p>
                            </div>
                          </a>
                        ))}
                        {/* chat-contacts-item */}
                        {/* chat-contacts-item*/}
                        {/* <a
                          className="chat-contacts-item chat-contacts-item_active"
                          href="#"
                        >
                          <div className="dashboard-message-avatar">
                            <img src="images/avatar/4.jpg" alt />
                          </div>
                          <div className="chat-contacts-item-text">
                            <h4>Adam Koncy</h4>
                            <span>27 Dec 2018 </span>
                            <p>
                              Vivamus lobortis vel nibh nec maximus. Donec dolor
                              erat, rutrum ut feugiat sed, ornare vitae nunc.
                              Donec massa nisl, bibendum id ultrices sed,
                              accumsan sed dolor.
                            </p>
                          </div>
                        </a> */}
                        {/* chat-contacts-item */}
                        {/* chat-contacts-item*/}
                        {/* <a className="chat-contacts-item" href="#">
                          <div className="dashboard-message-avatar">
                            <img src="images/avatar/1.jpg" alt />
                            <div className="message-counter">3</div>
                          </div>
                          <div className="chat-contacts-item-text">
                            <h4>Andy Smith</h4>
                            <span>27 Dec 2018 </span>
                            <p>
                              Vivamus lobortis vel nibh nec maximus. Donec dolor
                              erat, rutrum ut feugiat sed, ornare vitae nunc.
                              Donec massa nisl, bibendum id ultrices sed,
                              accumsan sed dolor.
                            </p>
                          </div>
                        </a> */}
                        {/* chat-contacts-item */}
                        {/* chat-contacts-item*/}
                        {/* <a className="chat-contacts-item" href="#">
                          <div className="dashboard-message-avatar">
                            <img src="images/avatar/5.jpg" alt />
                            <div className="message-counter">4</div>
                          </div>
                          <div className="chat-contacts-item-text">
                            <h4>Joe Frick</h4>
                            <span>27 Dec 2018 </span>
                            <p>
                              Vivamus lobortis vel nibh nec maximus. Donec dolor
                              erat, rutrum ut feugiat sed, ornare vitae nunc.
                              Donec massa nisl, bibendum id ultrices sed,
                              accumsan sed dolor.
                            </p>
                          </div>
                        </a> */}
                        {/* chat-contacts-item */}
                        {/* chat-contacts-item*/}
                        {/* <a className="chat-contacts-item" href="#">
                          <div className="dashboard-message-avatar">
                            <img src="images/avatar/2.jpg" alt />
                          </div>
                          <div className="chat-contacts-item-text">
                            <h4>Alise Goovy</h4>
                            <span>27 Dec 2018 </span>
                            <p>
                              Vivamus lobortis vel nibh nec maximus. Donec dolor
                              erat, rutrum ut feugiat sed, ornare vitae nunc.
                              Donec massa nisl, bibendum id ultrices sed,
                              accumsan sed dolor.
                            </p>
                          </div>
                        </a> */}
                        {/* chat-contacts-item */}
                        {/* chat-contacts-item*/}
                        {/* <a className="chat-contacts-item" href="#">
                          <div className="dashboard-message-avatar">
                            <img src="images/avatar/3.jpg" alt />
                          </div>
                          <div className="chat-contacts-item-text">
                            <h4>Freddy Kovalsky</h4>
                            <span>27 Dec 2018 </span>
                            <p>
                              Vivamus lobortis vel nibh nec maximus. Donec dolor
                              erat, rutrum ut feugiat sed, ornare vitae nunc.
                              Donec massa nisl, bibendum id ultrices sed,
                              accumsan sed dolor.
                            </p>
                          </div>
                        </a> */}
                        {/* chat-contacts-item */}
                        {/* chat-contacts-item*/}
                        {/* <a className="chat-contacts-item" href="#">
                          <div className="dashboard-message-avatar">
                            <img src="images/avatar/6.jpg" alt />
                          </div>
                          <div className="chat-contacts-item-text">
                            <h4>Cristiano Olando</h4>
                            <span>27 Dec 2018 </span>
                            <p>
                              Vivamus lobortis vel nibh nec maximus. Donec dolor
                              erat, rutrum ut feugiat sed, ornare vitae nunc.
                              Donec massa nisl, bibendum id ultrices sed,
                              accumsan sed dolor.
                            </p>
                          </div>
                        </a> */}
                        {/* chat-contacts-item */}
                      </div>
                      {/* chat-contacts end*/}
                      {/* chat-box*/}
                      <div className="chat-box fl-wrap">
                        <div
                          className="chat-box-scroll fl-wrap full-height"
                          data-simplebar="init"
                        >
                          {/* message*/}
                          {/* {message?.map((data) => ( */}
                            {/* <div className="chat-message   fl-wrap">
                              <div className="dashboard-message-avatar">
                                <img src="images/avatar/4.jpg" alt="sentTo" />
                                <span className="chat-message-user-name cmun_sm">
                                  Andy
                                </span>
                              </div>
                              <span className="massage-date">
                                25 may 2018 <span>7.51 PM</span>
                              </span>
                              <p>
                                Vivamus lobortis vel nibh nec maximus. Donec dolor
                                erat, rutrum ut feugiat sed, ornare vitae nunc.
                                Donec massa nisl, bibendum id ultrices sed,
                                accumsan sed dolor.
                              </p>
                            </div> */}
                          {/* ))} */}
                          {/* message end*/}
                          {/* message*/}
                          {/* <div className="chat-message chat-message_user fl-wrap">
                            <div className="dashboard-message-avatar">
                              <img src="images/avatar/5.jpg" alt />
                              <span className="chat-message-user-name cmun_sm">
                                Alica
                              </span>
                            </div>
                            <span className="massage-date">
                              25 may 2018 <span>7.51 PM</span>
                            </span>
                            <p>
                              Nulla eget erat consequat quam feugiat dapibus
                              eget sed mauris.
                            </p>
                          </div> */}
                          {/* message end*/}
                          {/* message*/}
                          {/* <div className="chat-message   fl-wrap">
                            <div className="dashboard-message-avatar">
                              <img src="images/avatar/4.jpg" alt />
                              <span className="chat-message-user-name cmun_sm">
                                Andy
                              </span>
                            </div>
                            <span className="massage-date">
                              25 may 2018 <span>7.51 PM</span>
                            </span>
                            <p>
                              Sed non neque faucibus, condimentum lectus at,
                              accumsan enim. Fusce pretium egestas cursus..
                            </p>
                          </div> */}
                          {/* message end*/}
                          {/* message*/}
                          {/* <div className="chat-message chat-message_user fl-wrap">
                            <div className="dashboard-message-avatar">
                              <img src="images/avatar/5.jpg" alt />
                              <span className="chat-message-user-name cmun_sm">
                                Alica
                              </span>
                            </div>
                            <span className="massage-date">
                              25 may 2018 <span>7.51 PM</span>
                            </span>
                            <p>
                              Donec a consectetur nulla. Nulla posuere sapien
                              vitae lectus suscipit, et pulvinar nisi tincidunt.
                              Aliquam erat volutpat. Curabitur convallis
                              fringilla diam sed aliquam. Sed tempor iaculis
                              massa faucibus feugiat. In fermentum facilisis
                              massa, a consequat .
                            </p>
                          </div> */}
                          {/* message end*/}
                        </div>
                      </div>
                      {/* <div className="chat-widget_input">
                        <textarea
                          placeholder="Type Message"
                          defaultValue={""}
                        />
                        <button type="submit" className="color-bg">
                          <i className="fa-solid fa-paper-plane" />
                        </button>
                      </div> */}
                      {/* chat-box end*/}
                    </div>
                    {/* dashboard-list-box end*/}
                  </div>
                </div>
              </div>
              {/* dashboard-footer */}
              <ProfileFooter />
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
      ;
    </>
  );
}

export default Message;
