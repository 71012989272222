import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Footer from '../Header/Footer';
import { toast } from 'react-toastify';
// import Testimonial from '../Home/Testimonial/Testimonial';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './Testimonial.module.css';
import GoogleMapUrl from "./GooglMapByUrl.js";
import MapMarker from './mapMarker';



function CityListing() {


    const [masterData, setMasterData] = useState([])
    const { slug } = useParams()
    const [photo, setPhoto] = useState([])
    const [testimonial, setTestimonial] = useState([])
    const [wishlist, setWishlist] = useState([])
    const [user, setUser] = useState({})

    const apiUrl = process.env.REACT_APP_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const Token = localStorage.getItem("Token");


    useEffect(() => {
        fetch(`${apiUrl}/testimonial/getAll`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    setTestimonial(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [])

    useEffect(() => {
        fetch(`${apiUrl}/employee/getCity/${slug.split('-').pop()}`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status !== "error") {
                    console.log('data =>', data.data)
                    setMasterData(data.data)
                    let count = [];
                    for (let i = 0; i < 5; i++) {
                        if (data.data.City[0][`photo${i}`]) {
                            count.push(data.data.City[0][`photo${i}`]);
                        }
                        if (data.data.City[0][`photo`]) {
                            count.push(data.data.City[0][`photo`]);
                        }
                    }
                    console.log(count, 'count=======');
                    setPhoto(count);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [apiKey, apiUrl, slug])


    useEffect(() => {
        async function getUser() {
            const url = `${apiUrl}/employee/verify-customer`;


            let response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setUser(response.data)
            }
        }
        getUser();
    }, []);


    // A function to handle the submit of the property form
    const handleWishlist = async (e, id, userId) => {
        e.preventDefault();
        e.stopPropagation()
        e.stopPropagation();
        if (!userId) {
            toast.error("Login First")
            return
        }

        try {

            const response = await fetch(`${apiUrl}/property/addToWishlist/${userId}-${id}`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                }
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);

            setTimeout(() => {
                window.location.reload()
            }, 2500)
        } catch (error) {
            toast.error(error.message);
        }
    };

    // A function to handle the submit of the property form
    const handleCompare = async (e, id, userId) => {
        e.preventDefault();
        e.stopPropagation()
        e.stopPropagation();
        if (!userId) {
            toast.error("Login First")
            return
        }

        try {

            const response = await fetch(`${apiUrl}/property/addToCompare/${userId}-${id}`, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                }
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            setTimeout(() => {
                window.location.reload()
            }, 2500)
        } catch (error) {
            toast.error(error.message);
        }
    };


    useEffect(() => {
        async function getWishlist() {
            const url = `${apiUrl}/property/getWishlist/${user.id}`;


            let response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setWishlist(response.wishlistProperty)
                if (Array.isArray(response.wishlistProperty)) {
                    console.log("wishlistProperty is an array");
                } else {
                    console.log("wishlistProperty is not an array");
                }
            }
        }
        if (user?.id) {
            getWishlist()
        }

    }, [user, apiUrl, Token]);


    const settings = {
        focusOnSelect: true,
        dots: true,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            {/*=============== basic  ===============*/}
            <meta charSet="UTF-8" />
            <title>Hauzzi Beta Website UI/UX design by Webkype </title>
            <meta name="robots" content="index, follow" />
            <meta name="keywords" content />
            <meta name="description" content />
            {/*=============== css  ===============*/}
            <link type="text/css" rel="stylesheet" href="css/plugins.css" />
            <link type="text/css" rel="stylesheet" href="css/style.css" />
            <link type="text/css" rel="stylesheet" href="css/color.css" />
            {/*=============== favicons ===============*/}
            <link rel="shortcut icon" href="images/favicon.ico" />
            {/*loader*/}
            <div className="loader-wrap">
                <div className="loader-inner">
                    <svg>
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
                                <feColorMatrix
                                    in="blur"
                                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                                    result="gooey"
                                />
                                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div>
            {/*loader end*/}
            {/* main */}
            <div id="main">
                {/* header */}
                {/* header end  */}
                {/* wrapper  */}
                <div id="wrapper p-0">
                    {/* content */}
                    <div className="content">

                        <div className="gray-bg small-padding fl-wrap">
                            <div className="container">
                                <div className="row">
                                    {/*  listing-single content */}
                                    <div className="col-md-12">
                                        <div className="list-single-main-wrapper fl-wrap pl-0">
                                            {/*  list-single-header-item*/}
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    {!photo.length &&
                                                        <div
                                                            style={{ height: '500px' }}
                                                            className="list-single-main-media fl-wrap color-bg"
                                                        >
                                                            <div className="single-slider-wrapper carousel-wrap fl-wrap">
                                                                <div className="slider-for fl-wrap carousel lightgallery">
                                                                    {/* <div className="slick-slide-item mt-5">
                                                                        <div className="box-item">
                                                                            <a
                                                                                href="images/all/1.jpg"
                                                                                className="gal-link popup-image"
                                                                            >
                                                                                <i className="fal fa-search" />
                                                                            </a>
                                                                            <img
                                                                                src="images/all/1.jpg"
                                                                                alt="property"
                                                                            />
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="slick-slide-item mt-5">
                                                                        <div className="box-item">
                                                                            <a
                                                                                href="images/all/2.jpg"
                                                                                className="gal-link popup-image"
                                                                            >
                                                                                <i className="fal fa-search" />
                                                                            </a>
                                                                            <img src="images/all/2.jpg" alt />
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="slick-slide-item">
                                                                        <div className="box-item">
                                                                            <a
                                                                                href="images/all/3.jpg"
                                                                                className="gal-link popup-image"
                                                                            >
                                                                                <i className="fal fa-search" />
                                                                            </a>
                                                                            <img src="images/all/3.jpg" alt />
                                                                        </div>
                                                                    </div>
                                                                    <div className="slick-slide-item">
                                                                        <div className="box-item">
                                                                            <a
                                                                                href="images/all/4.jpg"
                                                                                className="gal-link popup-image"
                                                                            >
                                                                                <i className="fal fa-search" />
                                                                            </a>
                                                                            <img src="images/all/4.jpg" alt />
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                                {/* <div className="swiper-button-prev ssw-btn">
                                                                    <i className="fa-solid fa-caret-left" />
                                                                </div>
                                                                <div className="swiper-button-next ssw-btn">
                                                                    <i className="fa-solid fa-caret-right" />
                                                                </div> */}
                                                            </div>
                                                            {/* <div className="single-slider-wrapper fl-wrap">
                                                            <div className="slider-nav fl-wrap">
                                                                <div className="slick-slide-item">
                                                                    <img src="images/all/1.jpg" alt="property" />
                                                                </div>
                                                                <div className="slick-slide-item">
                                                                    <img src="images/all/2.jpg" alt="property" />
                                                                </div>
                                                                <div className="slick-slide-item">
                                                                    <img src="images/all/3.jpg" alt="property" />
                                                                </div>
                                                                <div className="slick-slide-item">
                                                                    <img src="images/all/4.jpg" alt="property" />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        </div>}
                                                    {photo?.length ? <div className='carousel slide' id="propertyCarousel" data-bs-ride="carousel">
                                                        <div className='carousel-inner'>
                                                            {photo.map((data, index) => (
                                                                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                                    <img
                                                                        src={`${apiUrl}/uploads/${data}`}
                                                                        alt="property"
                                                                        height={'500vh'}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <button class="carousel-control-prev" type="button" data-bs-target="#propertyCarousel" data-bs-slide="prev">
                                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Previous</span>
                                                        </button>
                                                        <button class="carousel-control-next" type="button" data-bs-target="#propertyCarousel" data-bs-slide="next">
                                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Next</span>
                                                        </button>
                                                    </div> : <></>}
                                                </div>
                                                <div className="col-sm-6">
                                                    <div
                                                        className="list-single-header-item  fl-wrap"
                                                        id="sec1"
                                                    >
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h1>
                                                                    Explore {masterData?.name}{" "}
                                                                    <span
                                                                        className="verified-badge tolt"
                                                                        data-microtip-position="bottom"
                                                                        data-tooltip="Verified"
                                                                    >
                                                                        <i className="fas fa-check" />
                                                                    </span>
                                                                </h1>
                                                                <p className="pb-0">
                                                                    {masterData?.City?.[0]?.tagline ? masterData?.City?.[0]?.tagline : "Wonderful City "}.{" "}
                                                                </p>
                                                                <div className="geodir-category-location fl-wrap">
                                                                    <a href="#">
                                                                        <i className="fas fa-map-marker-alt" />{masterData?.states?.name}, {masterData?.states?.country?.name}
                                                                    </a>
                                                                    {/* <div
                                                                        className="listing-rating card-popup-rainingvis"
                                                                        data-starrating2={4}
                                                                    >
                                                                        <span className="re_stars-title">Good</span>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="list-single-header-footer fl-wrap">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div
                                                                        className="list-single-header-price w-100"
                                                                        data-propertyprise={50500}
                                                                    >
                                                                        <strong className="text15">Max Price: ${masterData?.maxPrice}</strong>
                                                                    </div>
                                                                    <div
                                                                        className="list-single-header-price w-100"
                                                                        data-propertyprise={50500}
                                                                    >
                                                                        <strong className="text15">Min Price: ${masterData?.minPrice}</strong>
                                                                    </div>
                                                                    <div
                                                                        className="list-single-header-price w-100"
                                                                        data-propertyprise={50500}
                                                                    >
                                                                        <strong className="text15">Max Size: ${masterData?.maxSize}</strong>
                                                                    </div>
                                                                    <div
                                                                        className="list-single-header-price w-100"
                                                                        data-propertyprise={50500}
                                                                    >
                                                                        <strong className="text15">Min Size: ${masterData?.minSize}</strong>
                                                                    </div>
                                                                    <div
                                                                        className="list-single-header-price"
                                                                        data-propertyprise={50500}
                                                                    >
                                                                        <strong className="text15">
                                                                            Total: {masterData?.property?.length} Property
                                                                            {/* : Rated 1/10 - 8/10 */}
                                                                        </strong>
                                                                    </div>
                                                                    {/* <div
                                                                        className="list-single-header-price w-100"
                                                                        data-propertyprise={50500}
                                                                    >
                                                                        <strong className="text15">
                                                                            No commute data
                                                                        </strong>
                                                                    </div> */}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div
                                                                        className="list-single-header-price w-100"
                                                                        data-propertyprise={50500}
                                                                    >
                                                                        {/* <strong className="text15">
                                                                            For Rent on Trulia: 50 homes
                                                                        </strong> */}
                                                                    </div>
                                                                    <div
                                                                        className="list-single-header-price w-100"
                                                                        data-propertyprise={50500}
                                                                    >
                                                                        {/* <strong className="text15">
                                                                            For Sale on Trulia: 28 homes
                                                                        </strong> */}
                                                                    </div>
                                                                    {/* <div
                                                                        className="list-single-header-price w-100"
                                                                        data-propertyprise={50500}
                                                                    >
                                                                        <strong className="text15">
                                                                            {masterData?.property?.length} Properties in Total
                                                                        </strong>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="list-single-facts fl-wrap mt-5 mb-4">
                                                                {/* inline-facts */}
                                                                {masterData?.categoryCount?.map((data) => (
                                                                    <div className="inline-facts-wrap">
                                                                        <div className="inline-facts">
                                                                            <i className="fa-solid fa-home-lg" />
                                                                            <h6>{data?.count}</h6>
                                                                            <span>{data?.category}</span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                {/* inline-facts end */}
                                                                {/* inline-facts  */}
                                                                {/* <div className="inline-facts-wrap">
                                                                    <div className="inline-facts">
                                                                        <i className="fal fa-users" />
                                                                        <h6>Accomodation</h6>
                                                                        <span>6 Guest</span>
                                                                    </div>
                                                                </div> */}
                                                                {/* inline-facts end */}
                                                                {/* inline-facts */}
                                                                {/* <div className="inline-facts-wrap">
                                                                    <div className="inline-facts">
                                                                        <i className="fal fa-bed" />
                                                                        <h6>Bedrooms</h6>
                                                                        <span>3 Bedrooms / 4 Beds</span>
                                                                    </div>
                                                                </div> */}
                                                                {/* inline-facts end */}
                                                                {/* inline-facts */}
                                                                {/* <div className="inline-facts-wrap">
                                                                    <div className="inline-facts">
                                                                        <i className="fal fa-bath" />
                                                                        <h6>Bathrooms</h6>
                                                                        <span>3 Full</span>
                                                                    </div>
                                                                </div> */}
                                                                {/* inline-facts end */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div
                                                    className='col-sm-6'
                                                >
                                                    <img src={`${apiUrl}/uploads/${masterData?.City[0]?.photo}`} alt="property" />
                                                </div> */}
                                            </div>
                                            <div className="list-single-main-container fl-wrap mt-5">
                                                {/* list-single-main-item */}
                                                <div className="list-single-main-item fl-wrap " id="sec4">
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="list-single-main-item-title">
                                                                <h3>About {masterData?.name}</h3>
                                                                <p>
                                                                    {masterData?.City?.[0]?.about ? masterData?.City?.[0]?.about : `${masterData?.name}, the capital of England and the United Kingdom, is a 21st-century city with history stretching back to Roman times. At its centre stand the imposing Houses of Parliament, the iconic ‘Big Ben’ clock tower and Westminster Abbey, site of British monarch coronations. Across the Thames River, the London Eye observation wheel provides panoramic views of the South Bank cultural complex, and the entire city.`}
                                                                </p>
                                                            </div>

                                                            <div className="list-single-main-item_content fl-wrap">

                                                                <MapMarker propertyList={[]} />
                                                                {/* <iframe
                                                                    src={masterData?.City?.[0]?.mapUrl}
                                                                    width="100%"
                                                                    height={350}
                                                                    style={{ border: 0 }}
                                                                    allowFullScreen
                                                                    loading="lazy"
                                                                    referrerPolicy="no-referrer-when-downgrade"
                                                                /> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            {/* list-single-main-item */}
                                                            <div className="list-single-main-item fl-wrap pt-0 border0">
                                                                <div className="list-single-main-item-title">
                                                                    <h3>Local's View</h3>
                                                                </div>
                                                                <div className="list-single-main-item_content fl-wrap ">
                                                                    <div className="listing-features tag_view oneRow-listing-features">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 98% are
                                                                                    side ways
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 95% Its
                                                                                    dog friendly
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 95%
                                                                                    Walkable Market
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 73%
                                                                                    Nearby Roads
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 44%
                                                                                    Nearby Metro/Stations
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 95%
                                                                                    Walkable Hospital/Malls
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 98% are
                                                                                    side ways
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 95% Its
                                                                                    dog friendly
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 95%
                                                                                    Walkable Market
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 73%
                                                                                    Nearby Roads
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 44%
                                                                                    Nearby Metro/Stations
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fal fa-thumbs-up" /> 95%
                                                                                    Walkable Hospital/Malls
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* list-single-main-item end */}
                                            </div>
                                            {/* section */}
                                            <section className="gray-bg ">
                                                <div className="container">
                                                    <div className="section-title st-center fl-wrap">
                                                        <h4>Testimonilas</h4>
                                                        <h2>What Our Clients Say</h2>
                                                    </div>
                                                </div>
                                                <div className="clearfix" />

                                                <div className="custom-slider-testimonial">
                                                    {testimonial?.length ?
                                                        <div className={classes.testimonialsSlider}>
                                                            <Slider {...settings}>
                                                                {testimonial.map((testimonial, index) => (
                                                                    <div key={index} className={classes.testimonial}>

                                                                        <div className={classes.testimonialContain} >
                                                                            <img src={`${apiUrl}/uploads/${testimonial.photo}`}
                                                                                alt={testimonial.name}
                                                                                className={classes.testimonialImage} />
                                                                            <div>
                                                                                <h3 className={classes.testimonialName}>{testimonial.name}</h3>
                                                                            </div>

                                                                        </div>

                                                                        <p className={classes.testimonialText}>{testimonial.content}</p>

                                                                    </div>
                                                                ))}
                                                            </Slider>
                                                        </div>
                                                        : <>
                                                            <Button variant="primary" disabled>
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                Loading...
                                                            </Button>
                                                        </>}
                                                </div>
                                            </section>
                                            {/* section end*/}
                                        </div>
                                    </div>
                                    {/* listing-single content end*/}
                                </div>
                                <div className="fl-wrap limit-box" />

                                <div className='carousel slide' id="propertyCarousel" data-bs-ride="carousel">
                                    <div className="list-single-main-item-title">
                                        <h3>Similar Properties</h3>
                                    </div>
                                    <div className='carousel-inner'>
                                        {masterData?.property?.map((prop, index) => (
                                            index <= 2 ? <div
                                                className="listing-item"
                                                style={{ width: '25vw' }}
                                            >
                                                <article className="geodir-category-listing fl-wrap">
                                                    <div className="geodir-category-img fl-wrap">
                                                        <p
                                                            className="geodir-category-img_item"
                                                        >
                                                            <img src={`${apiUrl}/uploads/${prop.photo1 ? prop.photo1 : prop.photo2}`} alt="property" style={{ height: '250px' }} />
                                                            <div className="overlay" />
                                                        </p>
                                                        <div className="geodir-category-location">
                                                            <a
                                                                href="#1"
                                                                className="map-item tolt"
                                                                data-microtip-position="top-left"
                                                                data-tooltip="On the map"
                                                            >
                                                                <FontAwesomeIcon icon="fa-solid  fa-map-marker-alt" /> {prop.address1}
                                                            </a>
                                                        </div>
                                                        <ul className="list-single-opt_header_cat">
                                                            <li>
                                                                <a href="#2" className="cat-opt blue-bg">
                                                                    {prop.purpose !== 'wantToRent' ? 'Sale' : 'Rent'}
                                                                </a>
                                                            </li>
                                                            {/* <li>
                                                                      <a href="#3" className="cat-opt color-bg">
                                                                          {prop.type}
                                                                      </a>
                                                                  </li> */}
                                                            <li>
                                                                <a href="#" className="cat-opt color-bg">
                                                                    {prop.category}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <p
                                                            className={`geodir_save-btn tolt text-center ${wishlist?.find((item) => item === prop.id) ? "text-danger" : ""}`}
                                                            data-microtip-position="left"
                                                            data-tooltip="Save"
                                                            onClick={(e) => {
                                                                handleWishlist(e, prop.id, user.id)
                                                            }}
                                                        >
                                                            <span>
                                                                <FontAwesomeIcon icon="fa-solid fa-heart" />
                                                            </span>
                                                        </p>
                                                        {/* <p
                                                            className="compare-btn tolt text-center"
                                                            data-microtip-position="left"
                                                            data-tooltip="Compare"
                                                            onClick={(e) => {
                                                                handleCompare(e, prop.id, user.id)
                                                            }}
                                                        >
                                                            <span>
                                                                <FontAwesomeIcon icon="fa-solid  fa-random" />
                                                            </span>
                                                        </p> */}
                                                        <div className="geodir-category-listing_media-list">
                                                            <span>
                                                                <FontAwesomeIcon icon="fa-solid  fa-camera" />
                                                                {/* {photoCount[i]} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="geodir-category-content fl-wrap"
                                                        onClick={() => {
                                                            window.location.href = prop.slug
                                                        }}
                                                        role='button'
                                                    >
                                                        <h3>
                                                            {
                                                                prop.name ?
                                                                    <>{prop.name}</> :
                                                                    <>Gorgeous house for sale</>
                                                            }
                                                        </h3>
                                                        <div className="geodir-category-content_price">$ {prop.maxPrice}</div>

                                                        {
                                                            prop.description ?
                                                                <p>{prop.description.slice(0, 100)}...</p> :
                                                                <p>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                    Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                                                                    Donec a consectetur nulla.
                                                                </p>
                                                        }
                                                        <div className="geodir-category-content-details">
                                                            <ul>
                                                                <li>
                                                                    <FontAwesomeIcon icon="fa-solid  fa-bed" />
                                                                    <span>{prop?.listingDetails?.Bedrooms}</span>
                                                                </li>
                                                                <li>
                                                                    <FontAwesomeIcon icon="fa-solid  fa-bath" />
                                                                    <span>{prop?.listingDetails?.Bathrooms}</span>
                                                                </li>
                                                                <li>
                                                                    <FontAwesomeIcon icon="fa-solid  fa-cube" />
                                                                    <span>{prop.maxSize}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="geodir-category-footer fl-wrap">
                                                            <a href="agent-single.html" className="gcf-company">
                                                                <img src={prop?.Customer?.userDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.userDetails[0]?.photo}` : (prop?.Customer?.agentDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agentDetails[0]?.photo}` : (prop?.Customer?.agencyDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agencyDetails[0]?.photo}` : "images/avatar/2.jpg"))} style={{objectFit:'cover'}} />
                                                                {/* <img src={prop?.Customer?.userDetails ? `${apiUrl}/uploads/${prop?.Customer?.userDetails[0]?.photo}` : "images/avatar/2.jpg"} alt="poster" /> */}
                                                                <span>By {prop.postedBy}</span>
                                                            </a>
                                                            {/* <div
                                                                className="listing-rating card-popup-rainingvis"
                                                                data-microtip-position="top"
                                                                data-tooltip="Good"
                                                                data-starrating2={4}
                                                            /> */}
                                                        </div>
                                                    </div>
                                                </article>
                                            </div> : <></>
                                        ))}
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#propertyCarousel" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#propertyCarousel" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>

                                {/* <div className="listing-carousel-wrapper carousel-wrap fl-wrap">
                                    <div className="list-single-main-item-title">
                                        <h3>Nearby Properties</h3>
                                    </div>
                                    <div className="listing-carousel carousel ">
                                        <div className="slick-slide-item">
                                            <div className="listing-item">
                                                <article className="geodir-category-listing fl-wrap">
                                                    <div className="geodir-category-img fl-wrap">
                                                        <a
                                                            href="listing-single.html"
                                                            className="geodir-category-img_item"
                                                        >
                                                            <img src="images/all/3.jpg" alt />
                                                            <div className="overlay" />
                                                        </a>
                                                        <div className="geodir-category-location">
                                                            <a href="#4" className="map-item">
                                                                <i className="fas fa-map-marker-alt" /> 70 Bright St
                                                                New York, USA
                                                            </a>
                                                        </div>
                                                        <ul className="list-single-opt_header_cat">
                                                            <li>
                                                                <a href="#" className="cat-opt blue-bg">
                                                                    Sale
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="cat-opt color-bg">
                                                                    Apartment
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <a
                                                            href="#"
                                                            className="geodir_save-btn tolt"
                                                            data-microtip-position="left"
                                                            data-tooltip="Save"
                                                        >
                                                            <span>
                                                                <i className="fal fa-heart" />
                                                            </span>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="compare-btn tolt"
                                                            data-microtip-position="left"
                                                            data-tooltip="Compare"
                                                        >
                                                            <span>
                                                                <i className="fal fa-random" />
                                                            </span>
                                                        </a>
                                                        <div className="geodir-category-listing_media-list">
                                                            <span>
                                                                <i className="fas fa-camera" /> 8
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="geodir-category-content fl-wrap">
                                                        <h3>
                                                            <a href="listing-single.html">
                                                                Gorgeous house for sale
                                                            </a>
                                                        </h3>
                                                        <div className="geodir-category-content_price">
                                                            $ 600,000
                                                        </div>
                                                        <p>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit. Maecenas in pulvinar neque. Nulla finibus
                                                            lobortis pulvinar. Donec a consectetur nulla.
                                                        </p>
                                                        <div className="geodir-category-content-details">
                                                            <ul>
                                                                <li>
                                                                    <i className="fal fa-bed" />
                                                                    <span>3</span>
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-bath" />
                                                                    <span>2</span>
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-cube" />
                                                                    <span>450 ft2</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="geodir-category-footer fl-wrap">
                                                            <a href="agent-single.html" className="gcf-company">
                                                                <img src="images/avatar/2.jpg" alt />
                                                                <span>By Liza Rose</span>
                                                            </a>
                                                            <div
                                                                className="listing-rating card-popup-rainingvis tolt"
                                                                data-microtip-position="top"
                                                                data-tooltip="Good"
                                                                data-starrating2={4}
                                                            />
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="slick-slide-item">
                                            <div className="listing-item">
                                                <article className="geodir-category-listing fl-wrap">
                                                    <div className="geodir-category-img fl-wrap">
                                                        <a
                                                            href="listing-single.html"
                                                            className="geodir-category-img_item"
                                                        >
                                                            <img src="images/all/1.jpg" alt />
                                                            <div className="overlay" />
                                                        </a>
                                                        <div className="geodir-category-location">
                                                            <a href="#4" className="map-item">
                                                                <i className="fas fa-map-marker-alt" /> 40 Journal
                                                                Square , NJ, USA
                                                            </a>
                                                        </div>
                                                        <ul className="list-single-opt_header_cat">
                                                            <li>
                                                                <a href="#" className="cat-opt blue-bg">
                                                                    Sale
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="cat-opt color-bg">
                                                                    Apartment
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <a
                                                            href="#"
                                                            className="geodir_save-btn tolt"
                                                            data-microtip-position="left"
                                                            data-tooltip="Save"
                                                        >
                                                            <span>
                                                                <i className="fal fa-heart" />
                                                            </span>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="compare-btn tolt"
                                                            data-microtip-position="left"
                                                            data-tooltip="Compare"
                                                        >
                                                            <span>
                                                                <i className="fal fa-random" />
                                                            </span>
                                                        </a>
                                                        <div className="geodir-category-listing_media-list">
                                                            <span>
                                                                <i className="fas fa-camera" /> 47
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="geodir-category-content fl-wrap">
                                                        <h3>
                                                            <a href="listing-single.html">Luxury Family Home</a>
                                                        </h3>
                                                        <div className="geodir-category-content_price">
                                                            $ 300,000
                                                        </div>
                                                        <p>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit. Maecenas in pulvinar neque. Nulla finibus
                                                            lobortis pulvinar. Donec a consectetur nulla.
                                                        </p>
                                                        <div className="geodir-category-content-details">
                                                            <ul>
                                                                <li>
                                                                    <i className="fal fa-bed" />
                                                                    <span>4</span>
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-bath" />
                                                                    <span>2</span>
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-cube" />
                                                                    <span>460 ft2</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="geodir-category-footer fl-wrap">
                                                            <a href="agent-single.html" className="gcf-company">
                                                                <img src="images/avatar/1.jpg" alt />
                                                                <span>By Anna Lips</span>
                                                            </a>
                                                            <div
                                                                className="listing-rating card-popup-rainingvis tolt"
                                                                data-microtip-position="top"
                                                                data-tooltip="Excellent"
                                                                data-starrating2={5}
                                                            />
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="slick-slide-item">
                                            <div className="listing-item">
                                                <article className="geodir-category-listing fl-wrap">
                                                    <div className="geodir-category-img fl-wrap">
                                                        <a
                                                            href="listing-single.html"
                                                            className="geodir-category-img_item"
                                                        >
                                                            <img src="images/all/9.jpg" alt />
                                                            <div className="overlay" />
                                                        </a>
                                                        <div className="geodir-category-location">
                                                            <a href="#4" className="map-item">
                                                                <i className="fas fa-map-marker-alt" /> 34-42
                                                                Montgomery St , NY, USA
                                                            </a>
                                                        </div>
                                                        <ul className="list-single-opt_header_cat">
                                                            <li>
                                                                <a href="#" className="cat-opt blue-bg">
                                                                    Sale
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="cat-opt color-bg">
                                                                    Apartment
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <a
                                                            href="#"
                                                            className="geodir_save-btn tolt"
                                                            data-microtip-position="left"
                                                            data-tooltip="Save"
                                                        >
                                                            <span>
                                                                <i className="fal fa-heart" />
                                                            </span>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="compare-btn tolt"
                                                            data-microtip-position="left"
                                                            data-tooltip="Compare"
                                                        >
                                                            <span>
                                                                <i className="fal fa-random" />
                                                            </span>
                                                        </a>
                                                        <div className="geodir-category-listing_media-list">
                                                            <span>
                                                                <i className="fas fa-camera" /> 4
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="geodir-category-content fl-wrap">
                                                        <h3>
                                                            <a href="listing-single.html">
                                                                Gorgeous house for sale
                                                            </a>
                                                        </h3>
                                                        <div className="geodir-category-content_price">
                                                            $ 120,000
                                                        </div>
                                                        <p>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit. Maecenas in pulvinar neque. Nulla finibus
                                                            lobortis pulvinar. Donec a consectetur nulla.
                                                        </p>
                                                        <div className="geodir-category-content-details">
                                                            <ul>
                                                                <li>
                                                                    <i className="fal fa-bed" />
                                                                    <span>2</span>
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-bath" />
                                                                    <span>1</span>
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-cube" />
                                                                    <span>220 ft2</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="geodir-category-footer fl-wrap">
                                                            <a href="agent-single.html" className="gcf-company">
                                                                <img src="images/avatar/3.jpg" alt />
                                                                <span>By Mark Frosty</span>
                                                            </a>
                                                            <div
                                                                className="listing-rating card-popup-rainingvis tolt"
                                                                data-microtip-position="top"
                                                                data-tooltip="Good"
                                                                data-starrating2={4}
                                                            />
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="slick-slide-item">
                                            <div className="listing-item">
                                                <article className="geodir-category-listing fl-wrap">
                                                    <div className="geodir-category-img fl-wrap">
                                                        <a
                                                            href="listing-single.html"
                                                            className="geodir-category-img_item"
                                                        >
                                                            <img src="images/all/6.jpg" alt />
                                                            <div className="overlay" />
                                                        </a>
                                                        <div className="geodir-category-location">
                                                            <a href="#4" className="map-item">
                                                                <i className="fas fa-map-marker-alt" /> W 85th St,
                                                                New York, USA{" "}
                                                            </a>
                                                        </div>
                                                        <ul className="list-single-opt_header_cat">
                                                            <li>
                                                                <a href="#" className="cat-opt blue-bg">
                                                                    Sale
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="cat-opt color-bg">
                                                                    Apartment
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <a
                                                            href="#"
                                                            className="geodir_save-btn tolt"
                                                            data-microtip-position="left"
                                                            data-tooltip="Save"
                                                        >
                                                            <span>
                                                                <i className="fal fa-heart" />
                                                            </span>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="compare-btn tolt"
                                                            data-microtip-position="left"
                                                            data-tooltip="Compare"
                                                        >
                                                            <span>
                                                                <i className="fal fa-random" />
                                                            </span>
                                                        </a>
                                                        <div className="geodir-category-listing_media-list">
                                                            <span>
                                                                <i className="fas fa-camera" /> 13
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="geodir-category-content fl-wrap">
                                                        <h3>
                                                            <a href="listing-single.html">
                                                                Contemporary Apartment
                                                            </a>
                                                        </h3>
                                                        <div className="geodir-category-content_price">
                                                            $ 1,600,000
                                                        </div>
                                                        <p>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit. Maecenas in pulvinar neque. Nulla finibus
                                                            lobortis pulvinar. Donec a consectetur nulla.
                                                        </p>
                                                        <div className="geodir-category-content-details">
                                                            <ul>
                                                                <li>
                                                                    <i className="fal fa-bed" />
                                                                    <span>4</span>
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-bath" />
                                                                    <span>1</span>
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-cube" />
                                                                    <span>550 ft2</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="geodir-category-footer fl-wrap">
                                                            <a href="agent-single.html" className="gcf-company">
                                                                <img src="images/avatar/4.jpg" alt />
                                                                <span>By Bill Trust</span>
                                                            </a>
                                                            <div
                                                                className="listing-rating card-popup-rainingvis tolt"
                                                                data-microtip-position="top"
                                                                data-tooltip="Excellent
                                                        "
                                                                data-starrating2={5}
                                                            />
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-button-prev lc-wbtn lc-wbtn_prev">
                                        <i className="far fa-angle-left" />
                                    </div>
                                    <div className="swiper-button-next lc-wbtn lc-wbtn_next">
                                        <i className="far fa-angle-right" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* content end */}
                    {/* subscribe-wrap */}
                    {/* <div className="subscribe-wrap fl-wrap">
                        <div className="container">
                            <div className="subscribe-container fl-wrap color-bg">
                                <div className="pwh_bg" />
                                <div className="mrb_dec mrb_dec3" />
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="subscribe-header">
                                            <h4>newsletter</h4>
                                            <h3>Sign up for newsletter and get latest news and update</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-1" />
                                    <div className="col-md-5">
                                        <div className="footer-widget fl-wrap">
                                            <div className="subscribe-widget fl-wrap">
                                                <div className="subcribe-form">
                                                    <form id="subscribe">
                                                        <input
                                                            className="enteremail fl-wrap"
                                                            name="email"
                                                            id="subscribe-email"
                                                            placeholder="Enter Your Email"
                                                            spellCheck="false"
                                                            type="text"
                                                        />
                                                        <button
                                                            type="submit"
                                                            id="subscribe-button"
                                                            className="subscribe-button color-bg"
                                                        >
                                                            {" "}
                                                            Subscribe
                                                        </button>
                                                        <label
                                                            htmlFor="subscribe-email"
                                                            className="subscribe-message"
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* subscribe-wrap end */}
                    {/* footer */}
                    <Footer />
                    {/* footer end */}
                </div>
                {/* wrapper end */}
                {/*register form */}
                {/*register form end */}
            </div>
            {/* Main end */}
            {/*=============== scripts  ===============*/}
        </div>
    )
}

export default CityListing
