import React from 'react'
import { useParams } from 'react-router-dom'
import SingleListing from '../Listing/SingleListing'
import BlogSingle from '../Home/BlogSingle'
import UserProfile from '../Profile/UserProfile';
import CityListing from '../Listing/cityListing';
import Edit from '../Listing/edit';

function SlugRoutes() {
    const { slug } = useParams();

    if (slug.startsWith('property-')) {
        return (
            <div>
                <SingleListing />
            </div>
        );
    } else if (slug.startsWith('Blog-')) {
        return (
            <div>
                <BlogSingle />
            </div>
        );
    } else if (slug.startsWith('profile-info-')) {
        return (
            <div>
                <UserProfile />
            </div>
        );
    } else if (slug.startsWith('city-')) {
        return (
            <div>
                <CityListing />
            </div>
        );
    } else if (slug.startsWith('edit-')) {
        return (
            <div>
                <Edit />
            </div>
        );
    }
    else {
        return (
            <div>
                <h1 className='text-center'>Page Not Found: {slug}</h1>
            </div>
        );
    }
}




export default SlugRoutes
