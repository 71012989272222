import React, { useState, useEffect, useRef } from "react";
import HeroSection from "./HeroSection";
import Footer from "../Header/Footer";
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Print from "./Print";
import ReactToPrint from 'react-to-print';

function Compare() {

  const componentRef = useRef();
  const [user, setUser] = useState({})
  const [compare, setCompare] = useState([])

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");

  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
      } else if (!localStorage.getItem("Token")) {
        setUser({})
      }
    }
    getUser();
  }, []);

  useEffect(() => {
    async function getCompare() {
      const url = `${apiUrl}/property/getCompare/${user.id}`;


      let response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setCompare(response.data)
      }
    }
    if (user?.id) {
      getCompare()
    }

  }, [user, apiUrl, apiKey]);

  // A function to handle the submit of the property form
  const handleCompare = async (e, id, userId) => {
    e.preventDefault();
    e.stopPropagation()
    e.stopPropagation();
    if (!userId) {
      toast.error("Login First")
      return
    }

    try {

      const response = await fetch(`${apiUrl}/property/addToCompare/${userId}-${id}`, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        }
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        {/* <meta name="description" content /> */}
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* put header here */}
          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper p-0">
            {/* content */}
            <div className="content">
              {/*  section  */}
              <HeroSection />
              {/* <section className="hero-section gray-bg">
                <div className="bg-wrap">
                  <div className="half-hero-bg-media full-height">
                    <div className="slider-progress-bar">
                      <span>
                        <svg className="circ" width={30} height={30}>
                          <circle
                            className="circ2"
                            cx={15}
                            cy={15}
                            r={13}
                            stroke="rgba(255,255,255,0.4)"
                            strokeWidth={1}
                            fill="none"
                          />
                          <circle
                            className="circ1"
                            cx={15}
                            cy={15}
                            r={13}
                            stroke="#fff"
                            strokeWidth={2}
                            fill="none"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="slideshow-container">
                      <div className="slideshow-item">
                        <div
                          className="bg"
                          data-bg="https://hauzzi.webkype.net/images/bg/19.jpg"
                        />
                      </div>
                      <div className="slideshow-item">
                        <div
                          className="bg"
                          data-bg="https://hauzzi.webkype.net/images/bg/17.jpg"
                        />
                      </div>
                      <div className="slideshow-item">
                        <div
                          className="bg"
                          data-bg="https://hauzzi.webkype.net/images/bg/12.jpg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="hero-title hero-title_small">
                    <h4>Real Estate Searching Platform</h4>
                    <h2>
                      Find The House of Your Dream <br />
                      Using Our Platform
                    </h2>
                  </div>
                  <div className="main-search-input-wrap shadow_msiw">
                    <div className="main-search-input fl-wrap">
                      <div className="main-search-input-item">
                        <input
                          type="text"
                          placeholder="What are you looking for?"
                          defaultValue
                        />
                      </div>
                      <div className="main-search-input-item">
                        <select
                          data-placeholder="All Categories"
                          className="chosen-select no-search-select"
                        >
                          <option>All Statuses</option>
                          <option>For Rent</option>
                          <option>For Sale</option>
                        </select>
                      </div>
                      <div className="main-search-input-item">
                        <select
                          data-placeholder="All Categories"
                          className="chosen-select"
                        >
                          <option>All Cities</option>
                          <option>New York</option>
                          <option>London</option>
                          <option>Paris</option>
                          <option>Kiev</option>
                          <option>Moscow</option>
                          <option>Dubai</option>
                          <option>Rome</option>
                          <option>Beijing</option>
                        </select>
                      </div>
                      <button
                        className="main-search-button color-bg"
                        onclick="window.location.href='listing.html'"
                      >
                        {" "}
                        Search <i className="fa-solid fa-search" />{" "}
                      </button>
                    </div>
                  </div>
                  <div className="hero-notifer fl-wrap">
                    Need more search options?{" "}
                    <a href="listing.html">Advanced Search</a>{" "}
                  </div>
                  <div className="scroll-down-wrap">
                    <div className="mousey">
                      <div className="scroller" />
                    </div>
                    <span>Scroll Down To Discover</span>
                  </div>
                </div>
              </section> */}
              {/*  section  end*/}
              {/* breadcrumbs*/}
              <div className="breadcrumbs fw-breadcrumbs sp-brd fl-wrap">
                <div className="container" style={{ maxWidth: '1324px' }}>
                  <div className="breadcrumbs-list">
                    <a href="#">Home</a>
                    <a href="#">Pages</a> <span>Compare</span>
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    gap: "10px",
                    float: "right",
                    position: "relative"
                  }}>
                    <div className="share-holder hid-share">
                      <a href="#" className="share-btn showshare sfcs">
                        {" "}
                        <i className="fa-solid fa-share-alt" /> Share{" "}
                      </a>
                      <div className="share-container  isShare" />
                    </div>

                    <ReactToPrint

                      trigger={() =>
                        <a
                          className="print-btn tolt"
                          // href="#1"
                          data-microtip-position="bottom"
                          data-tooltip="Print"
                        // onClick={() => {                  
                        //   window.print();
                        // }}
                        >
                          <i className="fa-solid fa-print" />
                        </a>
                      }
                      content={() => componentRef.current}
                    />
                  </div>
                </div>
              </div>
              {/* breadcrumbs end */}
              {/* section */}
              <section ref={componentRef} className="gray-bg small-padding ">
                <div className="container" style={{ maxWidth: '1324px' }}>
                  <div className="compare-container fl-wrap">
                    <div className="compare-counter">
                      <span>4</span> Option
                    </div>
                    <div className="compare-header">
                      <ul>
                        <li>Price</li>
                        <li>Size</li>
                        <li>Price Per SqFt</li>
                        <li>Category</li>
                        <li>Rental Price</li>
                        {/* <li>Kitchens</li>
                        <li>Livingrooms</li>
                        <li>Building Age</li>
                        <li>Floors</li> */}
                      </ul>
                    </div>
                    {compare.length === 0 &&
                      <Button variant="primary" disabled
                        style={{ font: '300px', fontSize: '20px' }}
                      >
                        No property selected
                      </Button>

                    }

                    <div className="compare-slider fl-wrap" >
                      {/* slick-slide-item */}

                      {compare?.map((data) => (
                        <div >
                          <div className="compare-column" style={{ width: "min-content", marginBottom: "20px" }}>
                            <div className="compare-link fl-wrap">
                              <div className="compare-link-meia fl-wrap">
                                <img
                                  height={'200px'}
                                  src={`${apiUrl}/uploads/${data.photo1 ? data.photo1 : data.photo2}`}
                                  alt="property"
                                />
                                <div className="overlay" />
                                <div
                                  className="remove-compare color-bg"
                                  onClick={(e) => {
                                    handleCompare(e, data.id, user.id)
                                  }}

                                >
                                  <i className="fa-solid fa-times" />
                                </div>
                              </div>
                              
                            </div>
                            <h4 style={{ width: 'auto' }}>
                                <a href={`${data.slug}`}>
                                  {data.name.slice(0, 35)}
                                </a>
                              </h4>
                            <div className="compare-content">
                              <ul>
                                <li>${data.maxPrice}</li>
                                <li>{data.maxSize}</li>
                                <li>{data.maxPSF}</li>
                                <li>{data.category}</li>
                                <li>{data?.rentalPrice}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* slick-slide-item end*/}
                      {/* slick-slide-item */}
                      {/* <div className="slick-slide-item">
                        <div className="compare-column">
                          <div className="compare-link fl-wrap">
                            <div className="compare-link-meia fl-wrap">
                              <div
                                className="bg par-elem "
                                data-bg="https://hauzzi.webkype.net/images/all/2.jpg"
                              />
                              <div className="remove-compare color-bg">
                                <i className="fa-solid fa-times" />
                              </div>
                            </div>
                            <h4>
                              <a href="listing-single.html">
                                Kayak Point House
                              </a>
                            </h4>
                          </div>
                          <div className="compare-content">
                            <ul>
                              <li>$97.000</li>
                              <li>2</li>
                              <li>2</li>
                              <li>-</li>
                              <li>856</li>
                              <li>1</li>
                              <li>3</li>
                              <li>1-2</li>
                              <li>1</li>
                            </ul>
                          </div>
                        </div>
                      </div> */}
                      {/* slick-slide-item end*/}
                      {/* slick-slide-item */}
                      {/* <div className="slick-slide-item">
                        <div className="compare-column">
                          <div className="compare-link fl-wrap">
                            <div className="compare-link-meia fl-wrap">
                              <div
                                className="bg par-elem "
                                data-bg="https://hauzzi.webkype.net/images/all/8.jpg"
                              />
                              <div className="remove-compare color-bg">
                                <i className="fa-solid fa-times" />
                              </div>
                            </div>
                            <h4>
                              <a href="listing-single.html">
                                Contemporary Apartment
                              </a>
                            </h4>
                          </div>
                          <div className="compare-content">
                            <ul>
                              <li>$60.000</li>
                              <li>3</li>
                              <li>1</li>
                              <li>-</li>
                              <li>1565</li>
                              <li>1</li>
                              <li>5</li>
                              <li>1-2</li>
                              <li>1/3</li>
                            </ul>
                          </div>
                        </div>
                      </div> */}
                      {/* slick-slide-item end*/}
                      {/* slick-slide-item */}
                      {/* <div className="slick-slide-item">
                        <div className="compare-column">
                          <div className="compare-link fl-wrap">
                            <div className="compare-link-meia fl-wrap">
                              <div
                                className="bg par-elem "
                                data-bg="https://hauzzi.webkype.net/images/all/9.jpg"
                              />
                              <div className="remove-compare color-bg">
                                <i className="fa-solid fa-times" />
                              </div>
                            </div>
                            <h4>
                              <a href="listing-single.html">
                                Luxury Family Home
                              </a>
                            </h4>
                          </div>
                          <div className="compare-content">
                            <ul>
                              <li>$82.000</li>
                              <li>4</li>
                              <li>2</li>
                              <li>1</li>
                              <li>648</li>
                              <li>1</li>
                              <li>2</li>
                              <li>1-2</li>
                              <li>2/4</li>
                            </ul>
                          </div>
                        </div>
                      </div> */}
                      {/* slick-slide-item end*/}
                    </div>
                  </div>
                </div>
              </section>
              {/* section end*/}
            </div>
            {/* content end */}
            {/* subscribe-wrap */}
            {/* <div className="subscribe-wrap fl-wrap">
              <div className="container">
                <div className="subscribe-container fl-wrap color-bg">
                  <div className="pwh_bg" />
                  <div className="mrb_dec mrb_dec3" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="subscribe-header">
                        <h4>newsletter</h4>
                        <h3>
                          Sign up for newsletter and get latest news and update
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-1" />
                    <div className="col-md-5">
                      <div className="footer-widget fl-wrap">
                        <div className="subscribe-widget fl-wrap">
                          <div className="subcribe-form">
                            <form id="subscribe">
                              <input
                                className="enteremail fl-wrap"
                                name="email"
                                id="subscribe-email"
                                placeholder="Enter Your Email"
                                spellCheck="false"
                                type="text"
                              />
                              <button
                                type="submit"
                                id="subscribe-button"
                                className="subscribe-button color-bg"
                              >
                                {" "}
                                Subscribe
                              </button>
                              <label
                                htmlFor="subscribe-email"
                                className="subscribe-message"
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* subscribe-wrap end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
          </div>
          {/* wrapper end */}
          {/*register form */}
          {/*register form end */}
          {/*secondary-nav */}
          <div className="secondary-nav">
            <ul>
              <li>
                <a
                  href="dashboard-add-listing.html"
                  className="tolt"
                  data-microtip-position="left"
                  data-tooltip="Sell Property"
                >
                  <i className="fa-solid fa-truck-couch" />{" "}
                </a>
              </li>
              <li>
                <a
                  href="listing.html"
                  className="tolt"
                  data-microtip-position="left"
                  data-tooltip="Buy Property"
                >
                  {" "}
                  <i className="fa-solid fa-shopping-bag" />
                </a>
              </li>
              <li>
                <a
                  href="compare.html"
                  className="tolt"
                  data-microtip-position="left"
                  data-tooltip="Your Compare"
                >
                  <i className="fa-solid fa-exchange" />
                </a>
              </li>
            </ul>
            <div className="progress-indicator">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                <circle
                  cx={16}
                  cy={16}
                  r="15.9155"
                  className="progress-bar__background"
                />
                <circle
                  cx={16}
                  cy={16}
                  r="15.9155"
                  className="progress-bar__progress 
                      js-progress-bar"
                />
              </svg>
            </div>
          </div>
          {/*secondary-nav end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
    </>
  );
}

export default Compare;
