import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import Header2 from "../Header/Header2";
import HeroSection from "./HeroSection";
import { toast } from 'react-toastify';

function Agency() {

  const [masterData, setMasterData] = useState([])

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem('Token');

  function handleChange(event) {

    const value = event.target.value;

    if (value === 'increase') {
      setMasterData((prev) => {

        let newPrev = [...prev];
        const sortedItems = newPrev.sort((a, b) => a.name.localeCompare(b.name));
        return sortedItems

      })
    }

    if (value === 'decrease') {
      setMasterData((prev) => {

        let newPrev = [...prev];
        const sortedItems = newPrev.sort((a, b) => b.name.localeCompare(a.name));
        return sortedItems

      })
    }

  }


  useEffect(() => {
    fetch(`${apiUrl}/profile/getAll?userType=2`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setMasterData(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])


  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link rel="shortcut icon" href="images/favicon.ico" />
        {/*loader*/}
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          <Header2 />
          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper p-0">
            {/* content */}
            <div className="content">
              {/*  section  */}
              <HeroSection />
              {/*  section  end*/}
              {/* breadcrumbs*/}
              <div className="breadcrumbs fw-breadcrumbs sp-brd fl-wrap">
                <div className="container">
                  <div className="breadcrumbs-list">
                    <a href="#">Home</a>
                    <a href="#">Pages</a> <span>Agency</span>
                  </div>
                  <div className="share-holder hid-share">
                    <a href="#" className="share-btn showshare sfcs">
                      {" "}
                      <i className="fa-solid fa-share-alt" /> Share{" "}
                    </a>
                    <div className="share-container  isShare" />
                  </div>
                </div>
              </div>
              {/* breadcrumbs end */}
              {/* col-list-wrap */}
              <section className="gray-bg small-padding ">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      {/* list-main-wrap-header*/}
                      <div className="list-main-wrap-header box-list-header fl-wrap">
                        {/* list-main-wrap-title*/}
                        <div className="list-main-wrap-title">
                          <h2>
                            In Total : {masterData.length}
                            {/* Results For : <span>New York Agencys </span>
                            <strong>14</strong> */}
                          </h2>
                        </div>
                        {/* list-main-wrap-title end*/}
                        {/* list-main-wrap-opt*/}
                        <div className="list-main-wrap-opt">
                          {/* price-opt*/}
                          <div className="price-opt">
                            <span className="price-opt-title">Sort by:</span>
                            <div className="listsearch-input-item">
                              <select
                                data-placeholder="Popularity"
                                className="selectBox"
                                onChange={(event) => {
                                  handleChange(event)
                                }}
                              >
                                <option>Select</option>
                                <option value="increase">Name: A-Z</option>
                                <option value="decrease">Name: Z-A</option>
                              </select>
                            </div>
                          </div>
                          {/* price-opt end*/}
                        </div>
                        {/* list-main-wrap-opt end*/}
                      </div>
                      {/* list-main-wrap-header end*/}
                      {/* listing-item-wrap*/}
                      <div className="listing-item-container one-column-grid-wrap  box-list_ic agency-list fl-wrap"
                        style={{ display: 'grid' }}
                      >
                        {/*  agecy card item */}
                        {masterData?.map((data) => (
                          <div
                            className="listing-item"    
                            style={{width:'90%'}}                      
                            onClick={(e) => {
                              if (Token) {
                                window.location.href = `profile-info-${data.id}`
                              }
                            }}
                            key={data.id}
                          >
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img fl-wrap">
                                <div

                                  className="geodir-category-img_item"
                                >
                                  <img src={data.agencyDetails?.[0]?.photo ? `${apiUrl}/uploads/${data.agencyDetails?.[0]?.photo}` : "images/agency/1.png"} alt="agency" style={{ height: '100px', width: '100px', objectFit: 'cover' }} />
                                </div>
                              </div>
                              <div className="geodir-category-content fl-wrap" style={{ height: 'auto' }}>
                                <div
                                  className="card-verified tolt"
                                  data-microtip-position="left"
                                  data-tooltip="Verified"
                                >
                                  <i className="fa-solid fa-user-check" />
                                </div>
                                <div className="agent_card-title fl-wrap">
                                  <h4>

                                    {data.name}

                                  </h4>
                                  <div className="geodir-category-location fl-wrap">
                                    <i className="fa-solid fa-map-marker-alt" /> {data.agencyDetails?.[0]?.address}
                                    {/* <div
                                      className="listing-rating card-popup-rainingvis"
                                      data-starrating2={4}
                                    >
                                      <span className="re_stars-title">Good</span>
                                    </div> */}
                                  </div>
                                </div>
                                <p>
                                  {data.agencyDetails?.[0]?.notes.slice(0, 60)}...
                                </p>
                                <div className="small-facts fl-wrap">
                                  <ul>
                                    <li>
                                      <i className="fa-solid fa-home" />{" "}
                                      <span>
                                        <strong>{data.property?.length}</strong>Listing
                                      </span>
                                    </li>
                                    {/* <li>
                                      <i className="fal fa-users-class" />{" "}
                                      <span>
                                        <strong>10</strong>Agents
                                      </span>
                                    </li> */}
                                    {/* <li>
                                      <i className="fa-solid fa-comment-alt" />{" "}
                                      <span>
                                        <strong>56</strong>Reviews
                                      </span>
                                    </li> */}
                                  </ul>
                                </div>

                                {!Token &&
                                  <div
                                    href="#1"
                                    className="btn float-btn color-bg small-btn "
                                    style={{float: "right"}}
                                    onClick={(e) => {
                                      toast.error("Login First")
                                    }}
                                  >
                                    View Details
                                  </div>
                                }

                                {Token &&
                                  <div className="geodir-category-footer fl-wrap">
                                    <a
                                      href="#agency"
                                      className="btn float-btn color-bg small-btn"
                                      style={{float: "right"}}
                                    >
                                      View Details
                                    </a>
                                    {/* <a
                                      href="#"
                                      className="tolt ftr-btn"
                                      data-microtip-position="top-left"
                                      data-tooltip="Write Message"

                                    >
                                      <i className="fa-solid fa-envelope" />
                                    </a>
                                    <a
                                      href="tel:123-456-7890"
                                      className="tolt ftr-btn"
                                      data-microtip-position="top-left"
                                      data-tooltip="Call Now"
                                    >
                                      <i className="fa-solid fa-phone" />
                                    </a> */}
                                  </div>
                                }
                              </div>
                            </article>
                          </div>
                        ))}
                        {/*  agecy card item end*/}
                        {/*  agecy card item */}
                        {/* <div className="listing-item">
                          <article className="geodir-category-listing fl-wrap">
                            <div className="geodir-category-img fl-wrap">
                              <a
                                href="agency-single.html"
                                className="geodir-category-img_item"
                              >
                                <img src="images/agency/2.png" alt />
                              </a>
                            </div>
                            <div className="geodir-category-content fl-wrap">
                              <div
                                className="card-verified tolt"
                                data-microtip-position="left"
                                data-tooltip="Verified"
                              >
                                <i className="fal fa-user-check" />
                              </div>
                              <div className="agent_card-title fl-wrap">
                                <h4>
                                  <a href="agency-single.html">
                                    Modern House Real Estate
                                  </a>
                                </h4>
                                <div className="geodir-category-location fl-wrap">
                                  <a href="#">
                                    <i className="fas fa-map-marker-alt" /> 40 Journal
                                    Square , NJ, USA
                                  </a>
                                  <div
                                    className="listing-rating card-popup-rainingvis"
                                    data-starrating2={3}
                                  >
                                    <span className="re_stars-title">Average</span>
                                  </div>
                                </div>
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit. Maecenas in pulvinar neque. Nulla finibus
                                lobortis pulvinar. Donec a consectetur nulla.
                              </p>
                              <div className="small-facts fl-wrap">
                                <ul>
                                  <li>
                                    <i className="fal fa-home" />{" "}
                                    <span>
                                      <strong>22</strong>Listing
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fal fa-users-class" />{" "}
                                    <span>
                                      <strong>5</strong>Agents
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fal fa-comment-alt" />{" "}
                                    <span>
                                      <strong>126</strong>Reviews
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="geodir-category-footer fl-wrap">
                                <a
                                  href="agency-single.html"
                                  className="btn float-btn color-bg small-btn"
                                >
                                  View Details
                                </a>
                                <a
                                  href="/cdn-cgi/l/email-protection#98e1f7edeaf5f9f1f4d8fdf5f9f1f4b6fbf7f5"
                                  className="tolt ftr-btn"
                                  data-microtip-position="top-left"
                                  data-tooltip="Write Message"
                                >
                                  <i className="fal fa-envelope" />
                                </a>
                                <a
                                  href="tel:123-456-7890"
                                  className="tolt ftr-btn"
                                  data-microtip-position="top-left"
                                  data-tooltip="Call Now"
                                >
                                  <i className="fal fa-phone" />
                                </a>
                              </div>
                            </div>
                          </article>
                        </div> */}
                        {/*  agecy card item end*/}
                        {/*  agecy card item */}
                        {/* <div className="listing-item">
                          <article className="geodir-category-listing fl-wrap">
                            <div className="geodir-category-img fl-wrap">
                              <a
                                href="agency-single.html"
                                className="geodir-category-img_item"
                              >
                                <img src="images/agency/3.png" alt />
                              </a>
                            </div>
                            <div className="geodir-category-content fl-wrap">
                              <div
                                className="card-verified tolt"
                                data-microtip-position="left"
                                data-tooltip="Verified"
                              >
                                <i className="fal fa-user-check" />
                              </div>
                              <div className="agent_card-title fl-wrap">
                                <h4>
                                  <a href="agency-single.html">
                                    Mavers RealEstate Agency
                                  </a>
                                </h4>
                                <div className="geodir-category-location fl-wrap">
                                  <a href="#">
                                    <i className="fas fa-map-marker-alt" /> 34-42
                                    Montgomery St , NY, USA
                                  </a>
                                  <div
                                    className="listing-rating card-popup-rainingvis"
                                    data-starrating2={5}
                                  >
                                    <span className="re_stars-title">Excellent</span>
                                  </div>
                                </div>
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit. Maecenas in pulvinar neque. Nulla finibus
                                lobortis pulvinar. Donec a consectetur nulla.
                              </p>
                              <div className="small-facts fl-wrap">
                                <ul>
                                  <li>
                                    <i className="fal fa-home" />{" "}
                                    <span>
                                      <strong>44</strong>Listing
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fal fa-users-class" />{" "}
                                    <span>
                                      <strong>22</strong>Agents
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fal fa-comment-alt" />{" "}
                                    <span>
                                      <strong>13</strong>Reviews
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="geodir-category-footer fl-wrap">
                                <a
                                  href="agency-single.html"
                                  className="btn float-btn color-bg small-btn"
                                >
                                  View Details
                                </a>
                                <a
                                  href="/cdn-cgi/l/email-protection#423b2d37302f232b2e02272f232b2e6c212d2f"
                                  className="tolt ftr-btn"
                                  data-microtip-position="top-left"
                                  data-tooltip="Write Message"
                                >
                                  <i className="fal fa-envelope" />
                                </a>
                                <a
                                  href="tel:123-456-7890"
                                  className="tolt ftr-btn"
                                  data-microtip-position="top-left"
                                  data-tooltip="Call Now"
                                >
                                  <i className="fal fa-phone" />
                                </a>
                              </div>
                            </div>
                          </article>
                        </div> */}
                        {/*  agecy card item end*/}
                        {/*  agecy card item */}
                        {/* <div className="listing-item">
                          <article className="geodir-category-listing fl-wrap">
                            <div className="geodir-category-img fl-wrap">
                              <a
                                href="agency-single.html"
                                className="geodir-category-img_item"
                              >
                                <img src="images/agency/4.png" alt />
                              </a>
                            </div>
                            <div className="geodir-category-content fl-wrap">
                              <div
                                className="card-verified cv_not tolt"
                                data-microtip-position="left"
                                data-tooltip="Not Verified"
                              >
                                <i className="fal fa-minus-octagon" />
                              </div>
                              <div className="agent_card-title fl-wrap">
                                <h4>
                                  <a href="agency-single.html">
                                    Your Sweet Home Agency
                                  </a>
                                </h4>
                                <div className="geodir-category-location fl-wrap">
                                  <a href="#">
                                    <i className="fas fa-map-marker-alt" /> W 85th St,
                                    New York, USA
                                  </a>
                                  <div
                                    className="listing-rating card-popup-rainingvis"
                                    data-starrating2={4}
                                  >
                                    <span className="re_stars-title">Good</span>
                                  </div>
                                </div>
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit. Maecenas in pulvinar neque. Nulla finibus
                                lobortis pulvinar. Donec a consectetur nulla.
                              </p>
                              <div className="small-facts fl-wrap">
                                <ul>
                                  <li>
                                    <i className="fal fa-home" />{" "}
                                    <span>
                                      <strong>22</strong>Listing
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fal fa-users-class" />{" "}
                                    <span>
                                      <strong>9</strong>Agents
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fal fa-comment-alt" />{" "}
                                    <span>
                                      <strong>27</strong>Reviews
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="geodir-category-footer fl-wrap">
                                <a
                                  href="agency-single.html"
                                  className="btn float-btn color-bg small-btn"
                                >
                                  View Details
                                </a>
                                <a
                                  href="/cdn-cgi/l/email-protection#1f66706a6d727e76735f7a727e7673317c7072"
                                  className="tolt ftr-btn"
                                  data-microtip-position="top-left"
                                  data-tooltip="Write Message"
                                >
                                  <i className="fal fa-envelope" />
                                </a>
                                <a
                                  href="tel:123-456-7890"
                                  className="tolt ftr-btn"
                                  data-microtip-position="top-left"
                                  data-tooltip="Call Now"
                                >
                                  <i className="fal fa-phone" />
                                </a>
                              </div>
                            </div>
                          </article>
                        </div> */}
                        {/*  agecy card item end*/}
                        {/*  agecy card item */}
                        {/* <div className="listing-item">
                          <article className="geodir-category-listing fl-wrap">
                            <div className="geodir-category-img fl-wrap">
                              <a
                                href="agency-single.html"
                                className="geodir-category-img_item"
                              >
                                <img src="images/agency/5.png" alt />
                              </a>
                            </div>
                            <div className="geodir-category-content fl-wrap">
                              <div
                                className="card-verified tolt"
                                data-microtip-position="left"
                                data-tooltip="Verified"
                              >
                                <i className="fal fa-user-check" />
                              </div>
                              <div className="agent_card-title fl-wrap">
                                <h4>
                                  <a href="agency-single.html">
                                    Luxary Estate Agency
                                  </a>
                                </h4>
                                <div className="geodir-category-location fl-wrap">
                                  <a href="#">
                                    <i className="fas fa-map-marker-alt" /> 75 Prince
                                    St, NY, USA
                                  </a>
                                  <div
                                    className="listing-rating card-popup-rainingvis"
                                    data-starrating2={5}
                                  >
                                    <span className="re_stars-title">Excellent</span>
                                  </div>
                                </div>
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit. Maecenas in pulvinar neque. Nulla finibus
                                lobortis pulvinar. Donec a consectetur nulla.
                              </p>
                              <div className="small-facts fl-wrap">
                                <ul>
                                  <li>
                                    <i className="fal fa-home" />{" "}
                                    <span>
                                      <strong>78</strong>Listing
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fal fa-users-class" />{" "}
                                    <span>
                                      <strong>25</strong>Agents
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fal fa-comment-alt" />{" "}
                                    <span>
                                      <strong>86</strong>Reviews
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="geodir-category-footer fl-wrap">
                                <a
                                  href="agency-single.html"
                                  className="btn float-btn color-bg small-btn"
                                >
                                  View Details
                                </a>
                                <a
                                  href="/cdn-cgi/l/email-protection#a4ddcbd1d6c9c5cdc8e4c1c9c5cdc88ac7cbc9"
                                  className="tolt ftr-btn"
                                  data-microtip-position="top-left"
                                  data-tooltip="Write Message"
                                >
                                  <i className="fal fa-envelope" />
                                </a>
                                <a
                                  href="tel:123-456-7890"
                                  className="tolt ftr-btn"
                                  data-microtip-position="top-left"
                                  data-tooltip="Call Now"
                                >
                                  <i className="fal fa-phone" />
                                </a>
                              </div>
                            </div>
                          </article>
                        </div> */}
                        {/*  agecy card item end*/}
                      </div>
                      {/* listing-item-wrap end*/}
                      {/* pagination*/}
                      {/* <div className="pagination">
                        <a href="#" className="prevposts-link">
                          <i className="fa fa-caret-left" />
                        </a>
                        <a href="#">1</a>
                        <a href="#" className="current-page">
                          2
                        </a>
                        <a href="#">3</a>
                        <a href="#">4</a>
                        <a href="#" className="nextposts-link">
                          <i className="fa fa-caret-right" />
                        </a>
                      </div> */}
                      {/* pagination end*/}
                    </div>
                    {/* col-md 8 end */}
                    {/* search sidebar*/}
                    <div className="col-md-4">
                      {/* <div className="list-searh-input-wrap-title fl-wrap">
                        <i className="far fa-sliders-h" />
                        <span>Search Agent</span>
                      </div>
                      <div
                        className="block-box fl-wrap search-sb"
                        id="filters-column"
                      >
                        <div className="listsearch-input-item">
                          <label>Keywords</label>
                          <input
                            type="text"
                            onclick="this.select()"
                            placeholder="Name , agency..."
                            defaultValue
                          />
                        </div>
                        <div className="listsearch-input-item">
                          <label>Cities</label>
                          <select
                            data-placeholder="All Cities"
                            className="chosen-select on-radius no-search-select"
                          >
                            <option>All Cities</option>
                            <option>New York</option>
                            <option>London</option>
                            <option>Paris</option>
                            <option>Kiev</option>
                            <option>Moscow</option>
                            <option>Dubai</option>
                            <option>Rome</option>
                            <option>Beijing</option>
                          </select>
                        </div>
                        <div className="listsearch-input-item">
                          <div className="price-rage-item fl-wrap">
                            <span className="pr_title">Rating:</span>
                            <input
                              type="text"
                              className="price-range-double"
                              data-min={1}
                              data-max={5}
                              name="price-range2"
                              data-step={1}
                              defaultValue={1}
                              data-prefix="*"
                            />
                          </div>
                        </div>
                        <div className="msotw_footer">
                          <a href="#" className="btn small-btn float-btn color-bg">
                            Search Agents
                          </a>
                          <div className="reset-form reset-btn">
                            {" "}
                            <i className="far fa-sync-alt" /> Reset Filters
                          </div>
                        </div>
                      </div> */}
                      {/*box-widget*/}
                      <div className="box-widget fl-wrap">
                        <div className="box-widget-title fl-wrap">
                          Featured Agencies
                        </div>
                        <div className="box-widget-content fl-wrap">
                          {/*widget-posts*/}
                          <div className="widget-posts  fl-wrap">
                            <ul className="no-list-style">
                              {masterData?.map((data) => (
                                <li key={data.id}>
                                  <div className="widget-posts-img">
                                    <img src={data.agencyDetails?.[0]?.photo ? `${apiUrl}/uploads/${data.agencyDetails?.[0]?.photo}` : "images/agency/1.png"} alt="agency" style={{ height: '95px', objectFit: 'cover' }} />

                                  </div>
                                  <div className="widget-posts-descr agent-post_descr">
                                    <h4>
                                      {data.name}
                                    </h4>
                                    <div className="agent-post_descr_counter fl-wrap">
                                      <span>{data.property.length}</span> Property Listings
                                    </div>
                                    {/* <div
                                      className="listing-rating card-popup-rainingvis"
                                      data-starrating2={4}
                                    >
                                      {" "}
                                    </div> */}
                                    <div
                                      // href="/cdn-cgi/l/email-protection#daa3b5afa8b7bbb3b69abfb7bbb3b6f4b9b5b7"
                                      className="tolt ftr-btn"
                                      data-microtip-position="top-left"
                                      data-tooltip="Write Message"
                                    >
                                      <i className="fa-solid fa-envelope" />
                                    </div>
                                    <div
                                      // href="tel:123-456-7890"
                                      className="tolt ftr-btn"
                                      data-microtip-position="top-left"
                                      data-tooltip="Call Now"
                                    >
                                      <i className="fa-solid fa-phone" />
                                    </div>
                                  </div>
                                </li>
                              ))}
                              {/* <li>
                                <div className="widget-posts-img">
                                  <a href="agent-single.html">
                                    <img src="images/agency/agent/6.jpg" alt />
                                  </a>
                                </div>
                                <div className="widget-posts-descr agent-post_descr">
                                  <h4>
                                    <a href="agent-single.html">Martin Smith</a>
                                  </h4>
                                  <div className="agent-post_descr_counter fl-wrap">
                                    <span>5</span> Property Listings
                                  </div>
                                  <div
                                    className="listing-rating card-popup-rainingvis"
                                    data-starrating2={5}
                                  >
                                    {" "}
                                  </div>
                                  <a
                                    href="/cdn-cgi/l/email-protection#9ae3f5efe8f7fbf3f6dafff7fbf3f6b4f9f5f7"
                                    className="tolt ftr-btn"
                                    data-microtip-position="top-left"
                                    data-tooltip="Write Message"
                                  >
                                    <i className="fal fa-envelope" />
                                  </a>
                                  <a
                                    href="tel:123-456-7890"
                                    className="tolt ftr-btn"
                                    data-microtip-position="top-left"
                                    data-tooltip="Call Now"
                                  >
                                    <i className="fal fa-phone" />
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="widget-posts-img">
                                  <a href="agent-single.html">
                                    <img src="images/agency/agent/4.jpg" alt />
                                  </a>
                                </div>
                                <div className="widget-posts-descr agent-post_descr">
                                  <h4>
                                    <a href="agent-single.html">Andy Sposty</a>
                                  </h4>
                                  <div className="agent-post_descr_counter fl-wrap">
                                    <span>10</span> Property Listings
                                  </div>
                                  <div
                                    className="listing-rating card-popup-rainingvis"
                                    data-starrating2={4}
                                  >
                                    {" "}
                                  </div>
                                  <a
                                    href="/cdn-cgi/l/email-protection#2d5442585f404c44416d48404c4441034e4240"
                                    className="tolt ftr-btn"
                                    data-microtip-position="top-left"
                                    data-tooltip="Write Message"
                                  >
                                    <i className="fal fa-envelope" />
                                  </a>
                                  <a
                                    href="tel:123-456-7890"
                                    className="tolt ftr-btn"
                                    data-microtip-position="top-left"
                                    data-tooltip="Call Now"
                                  >
                                    <i className="fal fa-phone" />
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="widget-posts-img">
                                  <a href="agent-single.html">
                                    <img src="images/agency/agent/1.jpg" alt />
                                  </a>
                                </div>
                                <div className="widget-posts-descr agent-post_descr">
                                  <h4>
                                    <a href="agent-single.html">Anna Lips</a>
                                  </h4>
                                  <div className="agent-post_descr_counter fl-wrap">
                                    <span>12</span> Property Listings
                                  </div>
                                  <div
                                    className="listing-rating card-popup-rainingvis"
                                    data-starrating2={5}
                                  >
                                    {" "}
                                  </div>
                                  <a
                                    href="/cdn-cgi/l/email-protection#e29b8d97908f838b8ea2878f838b8ecc818d8f"
                                    className="tolt ftr-btn"
                                    data-microtip-position="top-left"
                                    data-tooltip="Write Message"
                                  >
                                    <i className="fal fa-envelope" />
                                  </a>
                                  <a
                                    href="tel:123-456-7890"
                                    className="tolt ftr-btn"
                                    data-microtip-position="top-left"
                                    data-tooltip="Call Now"
                                  >
                                    <i className="fal fa-phone" />
                                  </a>
                                </div>
                              </li> */}
                            </ul>
                          </div>
                          {/* widget-posts end*/}
                          {/* <a
                            href="listing.html"
                            className="btn float-btn color-bg small-btn"
                          >
                            View All Agents
                          </a> */}
                        </div>
                      </div>
                      {/*box-widget end */}
                      {/*box-widget*/}
                      <div className="box-widget fl-wrap">
                        <div className="banner-widget fl-wrap">
                          <div className="bg-wrap bg-parallax-wrap-gradien">
                            <div className="bg  " data-bg="images/all/blog/1.jpg" />
                          </div>
                          <div className="banner-widget_content">
                            <h5>Do you want to join our real estate network?</h5>
                            <div className="btn float-btn color-bg small-btn modal-open">
                              Become an Agent
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*box-widget end */}
                    </div>
                    {/* search sidebar end*/}
                  </div>
                </div>
              </section>
              <div className="limit-box fl-wrap" />
            </div>
            {/* content end */}
            {/* subscribe-wrap */}
            {/* <div className="subscribe-wrap fl-wrap">
              <div className="container">
                <div className="subscribe-container fl-wrap color-bg">
                  <div className="pwh_bg" />
                  <div className="mrb_dec mrb_dec3" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="subscribe-header">
                        <h4>newsletter</h4>
                        <h3>Sign up for newsletter and get latest news and update</h3>
                      </div>
                    </div>
                    <div className="col-md-1" />
                    <div className="col-md-5">
                      <div className="footer-widget fl-wrap">
                        <div className="subscribe-widget fl-wrap">
                          <div className="subcribe-form">
                            <form id="subscribe">
                              <input
                                className="enteremail fl-wrap"
                                name="email"
                                id="subscribe-email"
                                placeholder="Enter Your Email"
                                spellCheck="false"
                                type="text"
                              />
                              <button
                                type="submit"
                                id="subscribe-button"
                                className="subscribe-button color-bg"
                              >
                                {" "}
                                Subscribe
                              </button>
                              <label
                                htmlFor="subscribe-email"
                                className="subscribe-message"
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* subscribe-wrap end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
          </div>
          {/* wrapper end */}
          {/*register form */}
          {/*register form end */}
          {/*secondary-nav */}
          <div className="secondary-nav">
            <ul>
              <li>
                <a
                  href="dashboard-add-listing.html"
                  className="tolt"
                  data-microtip-position="left"
                  data-tooltip="Sell Property"
                >
                  <i className="fal fa-truck-couch" />{" "}
                </a>
              </li>
              <li>
                <a
                  href="listing.html"
                  className="tolt"
                  data-microtip-position="left"
                  data-tooltip="Buy Property"
                >
                  {" "}
                  <i className="fal fa-shopping-bag" />
                </a>
              </li>
              <li>
                <a
                  href="compare.html"
                  className="tolt"
                  data-microtip-position="left"
                  data-tooltip="Your Compare"
                >
                  <i className="fal fa-exchange" />
                </a>
              </li>
            </ul>
            <div className="progress-indicator">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                <circle
                  cx={16}
                  cy={16}
                  r="15.9155"
                  className="progress-bar__background"
                />
                <circle
                  cx={16}
                  cy={16}
                  r="15.9155"
                  className="progress-bar__progress 
                      js-progress-bar"
                />
              </svg>
            </div>
          </div>
          {/*secondary-nav end */}
          <a className="to-top color-bg">
            <i className="fas fa-caret-up" />
          </a>
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </>
  );
}

export default Agency;
