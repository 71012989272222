import React, { useEffect, useState } from "react";
import SidePanel from "./SIdePanel";
import ProfileHeader from "./ProfileHeader";
import ProfileFooter from "./ProfileFooter";
import DashboardMenu from "./DashboardMenu";

function MortgageQuery() {

  const [masterData, setMasterData] = useState([])
  const [user, setUser] = useState({})



  const apiUrl = process.env.REACT_APP_URL;
  // const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");

  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
        console.log(response)
      } else if (!localStorage.getItem("Token")) {
        setUser({})
      }
    }
    getUser();
  }, [apiUrl, Token]);

  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setMasterData(response.data.mortgageQuery)
      }
    }
    getUser();
  }, []);

  return (
    <>
      <div>
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />

        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />

        <link rel="shortcut icon" href="images/favicon.ico" />
        {/*loader*/}
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}

          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            <SidePanel></SidePanel>
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div className="dashboard-content">
              <DashboardMenu user={user} />
              {/* <div className="dashboard-menu-btn color-bg">
                <span>
                  <i className="fa-solid fa-bars" />
                </span>
                Mortgage Queries
              </div> */}
              <div className="container dasboard-container">
                {/* dashboard-title */}
                <div className="dashboard-title fl-wrap">
                  <div className="dashboard-title-item">
                    <span>Mortgage Queries</span>
                  </div>

                  <ProfileHeader />
                  {/*Tariff Plan menu*/}
                  {/* <div className="tfp-det-container">
                    <div className="tfp-btn">
                      <span>Your Tariff Plan : </span> <strong>Extended</strong>
                    </div>
                    <div className="tfp-det">
                      <p>
                        You Are on <a href="#">Extended</a> . Use link bellow to
                        view details or upgrade.{" "}
                      </p>
                      <a href="#" className="tfp-det-btn color-bg">
                        Details
                      </a>
                    </div>
                  </div> */}
                  {/*Tariff Plan menu end*/}
                </div>
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap">
                  <div className="dasboard-listing-box fl-wrap">
                    {/* dashboard-listings-wrap*/}
                    <div className="dashboard-listings-wrap fl-wrap">
                      <div className="listing-item-container one-column-grid-wrap  box-list_ic agency-list fl-wrap  dashboard-agency-list">
                        <div className="row">
                          <div className="col-sm-12">
                            {masterData?.map((data) => (
                              <div className="listing-item">
                                <article className="geodir-category-listing fl-wrap">
                                  <div className="geodir-category-img fl-wrap">
                                    <a
                                      href="#1"
                                      className="geodir-category-img_item"
                                    >
                                      <img src="images/Mortgage-icon.jpg" alt="mortgage" />
                                    </a>
                                  </div>
                                  <div className="geodir-category-content fl-wrap">
                                    <div
                                      className="card-verified tolt"
                                      data-microtip-position="left"
                                      data-tooltip="Verified"
                                    >
                                      <i className="fa-solid fa-user-check" />
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <div className="agent_card-title fl-wrap">
                                          <h4>
                                            <a href="#">{data.name}</a>
                                          </h4>
                                          <div className="geodir-category-location fl-wrap mb-0">
                                            <a href="#">
                                              Type of employment: {data.typeOfEmployment}
                                            </a>
                                          </div>
                                          <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                            <a href="#">
                                              Family Income: {data.familyIncome} Euros/Mon
                                            </a>
                                          </div>
                                          <div className="geodir-category-location fl-wrap mt-0">
                                            <a href="#">
                                              Fees for other loans :  {data.otherFees}
                                              Euros/Mon
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6">
                                        <div className="agent_card-title fl-wrap">
                                          <div className="geodir-category-location fl-wrap mb-0">
                                            <a href="#">
                                              Type of purchase :  {data.typeOfPurchase}
                                            </a>
                                          </div>
                                          <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                            <a href="#">
                                              When are you going to buy :  {data.buyingWhen}
                                            </a>
                                          </div>
                                          <div className="geodir-category-location fl-wrap mt-0">
                                            <a href="#">
                                              Property Price :  {data.propertyPrice}  Euros
                                            </a>
                                          </div>
                                          <div className="geodir-category-location fl-wrap mt-0">
                                            <a href="#">
                                              Savings contributed :  {data.savingContribution}  Euros
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <p>
                                      Question :{data.yourQuery} ?
                                    </p>
                                    <div className="small-facts fl-wrap">
                                      <ul>
                                        <li>
                                          <i className="fa-solid fa-phone" />{" "}
                                          <span>
                                            M: <strong> {data.phone} </strong>
                                          </span>
                                        </li>
                                        <li>
                                          <i className="fa-solid fa-envelope" />{" "}
                                          <span>
                                            E:{" "}
                                            <strong>
                                              <a
                                                href="/cdn-cgi/l/email-protection"
                                                className="__cf_email__"
                                                data-cfemail="0c6b6d666962687e6d226f636262696f784c6b616d6560226f6361"
                                              >
                                                {data.email}
                                              </a>
                                            </strong>
                                          </span>
                                        </li>
                                        <li>
                                          <i className="fa-solid fa-comment-alt" />{" "}
                                          <span>
                                            Age: <strong> {data.age}  Years</strong>
                                          </span>
                                        </li>
                                        <li>
                                          <i className="fa-solid fa-comment-alt" />{" "}
                                          <span>
                                            Date: <strong>{new Date(data.createdAt).toDateString()}</strong>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            ))}
                            {/* <div className="listing-item">
                              <article className="geodir-category-listing fl-wrap">
                                <div className="geodir-category-img fl-wrap">
                                  <a
                                    href="#"
                                    className="geodir-category-img_item"
                                  >
                                    <img src="images/Mortgage-icon.jpg" alt />
                                  </a>
                                </div>
                                <div className="geodir-category-content fl-wrap">
                                  <div
                                    className="card-verified tolt"
                                    data-microtip-position="left"
                                    data-tooltip="Verified"
                                  >
                                    <i className="fa-solid fa-user-check" />
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="agent_card-title fl-wrap">
                                        <h4>
                                          <a href="#">Gajendra Singh</a>
                                        </h4>
                                        <div className="geodir-category-location fl-wrap mb-0">
                                          <a href="#">
                                            Type of employment: Permanent
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                          <a href="#">
                                            Family Income: 13000 Euros/Mon
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Fees for other loans : 1200
                                            Euros/Mon
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="agent_card-title fl-wrap">
                                        <div className="geodir-category-location fl-wrap mb-0">
                                          <a href="#">
                                            Type of purchase : Primary
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                          <a href="#">
                                            When are you going to buy : I'm
                                            looking for housing/Mon
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Property Price : 685,000 Euros
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Savings contributed : 500.000 Euros
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Maecenas in pulvinar neque.
                                    Nulla finibus lobortis pulvinar. Donec a
                                    consectetur nulla.
                                  </p>
                                  <div className="small-facts fl-wrap">
                                    <ul>
                                      <li>
                                        <i className="fa-solid fa-phone" />{" "}
                                        <span>
                                          M: <strong>8130352808</strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-envelope" />{" "}
                                        <span>
                                          E:{" "}
                                          <strong>
                                            <a
                                              href="/cdn-cgi/l/email-protection"
                                              className="__cf_email__"
                                              data-cfemail="5037313a353e3422317e333f3e3e35332410373d31393c7e333f3d"
                                            >
                                              [email&nbsp;protected]
                                            </a>
                                          </strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-comment-alt" />{" "}
                                        <span>
                                          Age: <strong>35 Years</strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-comment-alt" />{" "}
                                        <span>
                                          Date: <strong>3/Feb/2024</strong>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <div className="listing-item">
                              <article className="geodir-category-listing fl-wrap">
                                <div className="geodir-category-img fl-wrap">
                                  <a
                                    href="#"
                                    className="geodir-category-img_item"
                                  >
                                    <img src="images/Mortgage-icon.jpg" alt />
                                  </a>
                                </div>
                                <div className="geodir-category-content fl-wrap">
                                  <div
                                    className="card-verified tolt"
                                    data-microtip-position="left"
                                    data-tooltip="Verified"
                                  >
                                    <i className="fa-solid fa-user-check" />
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="agent_card-title fl-wrap">
                                        <h4>
                                          <a href="#">Gajendra Singh</a>
                                        </h4>
                                        <div className="geodir-category-location fl-wrap mb-0">
                                          <a href="#">
                                            Type of employment: Permanent
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                          <a href="#">
                                            Family Income: 13000 Euros/Mon
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Fees for other loans : 1200
                                            Euros/Mon
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="agent_card-title fl-wrap">
                                        <div className="geodir-category-location fl-wrap mb-0">
                                          <a href="#">
                                            Type of purchase : Primary
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                          <a href="#">
                                            When are you going to buy : I'm
                                            looking for housing/Mon
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Property Price : 685,000 Euros
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Savings contributed : 500.000 Euros
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Maecenas in pulvinar neque.
                                    Nulla finibus lobortis pulvinar. Donec a
                                    consectetur nulla.
                                  </p>
                                  <div className="small-facts fl-wrap">
                                    <ul>
                                      <li>
                                        <i className="fa-solid fa-phone" />{" "}
                                        <span>
                                          M: <strong>8130352808</strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-envelope" />{" "}
                                        <span>
                                          E:{" "}
                                          <strong>
                                            <a
                                              href="/cdn-cgi/l/email-protection"
                                              className="__cf_email__"
                                              data-cfemail="6403050e010a0016054a070b0a0a010710240309050d084a070b09"
                                            >
                                              [email&nbsp;protected]
                                            </a>
                                          </strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-comment-alt" />{" "}
                                        <span>
                                          Age: <strong>35 Years</strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-comment-alt" />{" "}
                                        <span>
                                          Date: <strong>3/Feb/2024</strong>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <div className="listing-item">
                              <article className="geodir-category-listing fl-wrap">
                                <div className="geodir-category-img fl-wrap">
                                  <a
                                    href="#"
                                    className="geodir-category-img_item"
                                  >
                                    <img src="images/Mortgage-icon.jpg" alt />
                                  </a>
                                </div>
                                <div className="geodir-category-content fl-wrap">
                                  <div
                                    className="card-verified tolt"
                                    data-microtip-position="left"
                                    data-tooltip="Verified"
                                  >
                                    <i className="fa-solid fa-user-check" />
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="agent_card-title fl-wrap">
                                        <h4>
                                          <a href="#">Gajendra Singh</a>
                                        </h4>
                                        <div className="geodir-category-location fl-wrap mb-0">
                                          <a href="#">
                                            Type of employment: Permanent
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                          <a href="#">
                                            Family Income: 13000 Euros/Mon
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Fees for other loans : 1200
                                            Euros/Mon
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="agent_card-title fl-wrap">
                                        <div className="geodir-category-location fl-wrap mb-0">
                                          <a href="#">
                                            Type of purchase : Primary
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                          <a href="#">
                                            When are you going to buy : I'm
                                            looking for housing/Mon
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Property Price : 685,000 Euros
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Savings contributed : 500.000 Euros
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Maecenas in pulvinar neque.
                                    Nulla finibus lobortis pulvinar. Donec a
                                    consectetur nulla.
                                  </p>
                                  <div className="small-facts fl-wrap">
                                    <ul>
                                      <li>
                                        <i className="fa-solid fa-phone" />{" "}
                                        <span>
                                          M: <strong>8130352808</strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-envelope" />{" "}
                                        <span>
                                          E:{" "}
                                          <strong>
                                            <a
                                              href="/cdn-cgi/l/email-protection"
                                              className="__cf_email__"
                                              data-cfemail="395e58535c575d4b58175a5657575c5a4d795e54585055175a5654"
                                            >
                                              [email&nbsp;protected]
                                            </a>
                                          </strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-comment-alt" />{" "}
                                        <span>
                                          Age: <strong>35 Years</strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-comment-alt" />{" "}
                                        <span>
                                          Date: <strong>3/Feb/2024</strong>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <div className="listing-item">
                              <article className="geodir-category-listing fl-wrap">
                                <div className="geodir-category-img fl-wrap">
                                  <a
                                    href="#"
                                    className="geodir-category-img_item"
                                  >
                                    <img src="images/Mortgage-icon.jpg" alt />
                                  </a>
                                </div>
                                <div className="geodir-category-content fl-wrap">
                                  <div
                                    className="card-verified tolt"
                                    data-microtip-position="left"
                                    data-tooltip="Verified"
                                  >
                                    <i className="fa-solid fa-user-check" />
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="agent_card-title fl-wrap">
                                        <h4>
                                          <a href="#">Gajendra Singh</a>
                                        </h4>
                                        <div className="geodir-category-location fl-wrap mb-0">
                                          <a href="#">
                                            Type of employment: Permanent
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                          <a href="#">
                                            Family Income: 13000 Euros/Mon
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Fees for other loans : 1200
                                            Euros/Mon
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="agent_card-title fl-wrap">
                                        <div className="geodir-category-location fl-wrap mb-0">
                                          <a href="#">
                                            Type of purchase : Primary
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                          <a href="#">
                                            When are you going to buy : I'm
                                            looking for housing/Mon
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Property Price : 685,000 Euros
                                          </a>
                                        </div>
                                        <div className="geodir-category-location fl-wrap mt-0">
                                          <a href="#">
                                            Savings contributed : 500.000 Euros
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Maecenas in pulvinar neque.
                                    Nulla finibus lobortis pulvinar. Donec a
                                    consectetur nulla.
                                  </p>
                                  <div className="small-facts fl-wrap">
                                    <ul>
                                      <li>
                                        <i className="fa-solid fa-phone" />{" "}
                                        <span>
                                          M: <strong>8130352808</strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-envelope" />{" "}
                                        <span>
                                          E:{" "}
                                          <strong>
                                            <a
                                              href="/cdn-cgi/l/email-protection"
                                              className="__cf_email__"
                                              data-cfemail="7512141f101b1107145b161a1b1b101601351218141c195b161a18"
                                            >
                                              [email&nbsp;protected]
                                            </a>
                                          </strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-comment-alt" />{" "}
                                        <span>
                                          Age: <strong>35 Years</strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fa-solid fa-comment-alt" />{" "}
                                        <span>
                                          Date: <strong>3/Feb/2024</strong>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </article>
                            </div> */}
                          </div>
                          {/*  agecy card item */}
                        </div>
                      </div>
                    </div>
                    {/* dashboard-listings-wrap end*/}
                  </div>
                  {/* pagination*/}
                  {/* <div className="pagination float-pagination">
                    <a href="#" className="prevposts-link">
                      <i className="fa fa-caret-left" />
                    </a>
                    <a href="#">1</a>
                    <a href="#" className="current-page">
                      2
                    </a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#" className="nextposts-link">
                      <i className="fa fa-caret-right" />
                    </a>
                  </div> */}
                  {/* pagination end*/}
                </div>
              </div>
              {/* dashboard-footer */}
              <ProfileFooter />
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
      ;
    </>
  );
}

export default MortgageQuery;
