import React, { useState, useEffect } from "react";
import SidePanel from "./SIdePanel";
import ProfileHeader from "./ProfileHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileFooter from "./ProfileFooter";
import DashboardMenu from "./DashboardMenu";

function MyListing() {


  const [profile, setProfile] = useState({})
  const [photoCount, setPhotoCount] = useState([])
  const [user, setUser] = useState({})


  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");


  useEffect(() => {
    if (user?.id) {
      fetch(`${apiUrl}/profile/getById/${user.id}`, {
        headers: {
          'Authorization': `Bearer ${Token}`,
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data) {
            setProfile(data.data)
            console.log(data.data)
            let count = []
            for (let j = 0; j < data.data.property.length; j++) {
              let temp = 0;
              for (let i = 0; i < 5; i++) {
                if (data.data.property[j][`photo${i + 1}`]) {
                  temp++;
                }
              }
              count = [...count, temp]
            }
            console.log(count, 'count=======')
            setPhotoCount(count)
          } else {
            console.error('API response does not contain an array:', data);
          }
        })
        .catch(error => {
          console.error('Error fetching masters data:', error);
        });
    }
  }, [Token, apiUrl, user])


  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
      }
    }
    getUser();
  }, []);



  const handleDelete = (e, id) => {
    if (window.confirm("Do you want to delete")) {
      fetch(`${apiUrl}/property/delete/` + id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      }).then((res) => {

        alert('Record Deleted');
        window.location.reload()
      }).catch((err) => {
        console.log(err);
      })
    }
  }


  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link rel="shortcut icon" href="images/favicon.ico" />
        {/*loader*/}
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}

          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            <SidePanel></SidePanel>
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div className="dashboard-content">
              <DashboardMenu user={user} />
              <div className="container dasboard-container">
                {/* dashboard-title */}
                <div className="dashboard-title fl-wrap">
                  <div className="dashboard-title-item">
                    <span>My Property Listing</span>
                  </div>

                  <ProfileHeader />
                  {/*Tariff Plan menu*/}
                  {/* <div className="tfp-det-container">
                    <div className="tfp-btn">
                      <span>Your Tariff Plan : </span> <strong>Extended</strong>
                    </div>
                    <div className="tfp-det">
                      <p>
                        You Are on <a href="#">Extended</a> . Use link bellow to
                        view details or upgrade.{" "}
                      </p>
                      <a href="#" className="tfp-det-btn color-bg">
                        Details
                      </a>
                    </div>
                  </div> */}
                  {/*Tariff Plan menu end*/}
                </div>
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap">
                  <div className="dasboard-listing-box fl-wrap">
                    {/* dashboard-listings-wrap*/}
                    <div className="dashboard-listings-wrap fl-wrap">
                      {/* listing-item-wrap*/}
                      <div className="listing-item-container fl-wrap">

                        {profile?.property?.map((prop, i) => (
                          <div className="listing-item has_one_column">
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img fl-wrap">
                                <a href="#p" className="geodir-category-img_item">
                                  <img
                                    src={`${apiUrl}/uploads/${prop.photo1 ? prop.photo1 : prop.photo2}`}
                                    alt="property"
                                    style={{ height: '290px' }}
                                  />
                                  <div className="overlay" />
                                </a>
                                <div className="geodir-category-location">
                                  <a
                                    href="#1"
                                    className="map-item tolt"
                                    data-microtip-position="top-left"
                                    data-tooltip="On the map"
                                  >
                                    <i className="fa-solid fa-map-marker-alt" />{" "}
                                    {prop.address1}
                                  </a>
                                </div>
                                <ul className="list-single-opt_header_cat">
                                  <li>
                                    <a href="#" className="cat-opt blue-bg">
                                      On Sale
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" className="cat-opt color-bg">
                                      Apartment
                                    </a>
                                  </li>
                                </ul>
                                <div className="geodir-category-listing_media-list">
                                  <span>
                                    <i className="fa-solid fa-camera" />  {photoCount[i]}
                                  </span>
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  window.location.href = prop.slug
                                }}
                                role="button"
                                className="geodir-category-content fl-wrap"
                              >
                                <h3>
                                  <a>
                                    {
                                      prop.name ?
                                        <>{prop.name}</> :
                                        <>Gorgeous house for sale</>
                                    }
                                  </a>
                                </h3>
                                <div className="geodir-category-content_price">
                                  $ {prop.maxPrice}
                                </div>
                                <p>
                                  {
                                    prop.description ?
                                      <p>{prop.description.slice(0, 60)}...</p> :
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                                        Donec a consectetur nulla.
                                      </p>
                                  }
                                </p>
                                <div className="geodir-category-content-details">
                                  <ul>
                                    <li>
                                      <i className="fa-solid fa-bed" />
                                      <span>{prop?.listingDetails?.Bedrooms}</span>
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-bath" />
                                      <span>{prop?.listingDetails?.Bathroom}</span>
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-cube" />
                                      <span>{prop.maxSize} ft2</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="geodir-category-footer fl-wrap">
                                  <a href="#p" className="gcf-company">
                                    {console.log('prop=>', prop)}
                                    {/* <img src={prop?.Customer?.userDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.userDetails[0]?.photo}` : (prop?.Customer?.agentDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agentDetails[0]?.photo}` : (prop?.Customer?.agencyDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agencyDetails[0]?.photo}` : "images/avatar/2.jpg"))} style={{ objectFit: 'cover' }} /> */}
                                    <img src={profile?.userDetails ? `${apiUrl}/uploads/${profile?.userDetails?.[0]?.photo}` : "images/avatar/2.jpg"} alt="poster" style={{ objectFit: 'cover' }} />
                                    <span>By {prop.postedBy}</span>
                                  </a>
                                  {/* <div
                                    className="listing-rating card-popup-rainingvis tolt"
                                    data-microtip-position="top"
                                    data-tooltip="Good"
                                    data-starrating2={4}
                                  /> */}
                                </div>
                                <div className="dashboard-listings-item_opt btm_footer-item">
                                  <span className="viewed-counter">
                                    <i className="fa-solid fa-eye" /> Viewed - {prop.views}{" "}
                                  </span>
                                  <ul>
                                    <li>
                                      <a
                                        href={`edit-${prop.slug}`}
                                        className="tolt"
                                        data-microtip-position="top-left"
                                        data-tooltip="Edit"
                                      >
                                        <i className="fa-solid fa-edit" />
                                      </a>
                                    </li>
                                    {/* <li>
                                      <a
                                        href="#"
                                        className="tolt text-red"
                                        data-microtip-position="top-left"
                                        data-tooltip="Disable"
                                      >
                                        <i className="fa-solid fa-ban" />
                                      </a>
                                    </li> */}
                                    <li>
                                      <a
                                        href="#1"
                                        className="tolt"
                                        data-microtip-position="top-left"
                                        data-tooltip="Delete"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          handleDelete(e, prop.id)
                                        }}
                                      >
                                        <i className="fa-solid fa-trash-alt" />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </article>
                          </div>
                        ))}
                        {/* listing-item */}
                        {/* <div className="listing-item has_one_column">
                          <article className="geodir-category-listing fl-wrap">
                            <div className="geodir-category-img fl-wrap">
                              <a href="#" className="geodir-category-img_item">
                                <img src="images/all/3.jpg" alt />
                                <div className="overlay" />
                              </a>
                              <div className="geodir-category-location">
                                <a
                                  href="#1"
                                  className="map-item tolt"
                                  data-microtip-position="top-left"
                                  data-tooltip="On the map"
                                >
                                  <i className="fa-solid fa-map-marker-alt" />{" "}
                                  70 Bright St New York, USA
                                </a>
                              </div>
                              <ul className="list-single-opt_header_cat">
                                <li>
                                  <a href="#" className="cat-opt blue-bg">
                                    On Sale
                                  </a>
                                </li>
                                <li>
                                  <a href="#" className="cat-opt color-bg">
                                    Apartment
                                  </a>
                                </li>
                              </ul>
                              <div className="geodir-category-listing_media-list">
                                <span>
                                  <i className="fa-solid fa-camera" /> 8
                                </span>
                              </div>
                            </div>
                            <div className="geodir-category-content fl-wrap">
                              <h3>
                                <a href="listing-single.html">
                                  Gorgeous house for sale
                                </a>
                              </h3>
                              <div className="geodir-category-content_price">
                                $ 600,000
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Maecenas in pulvinar neque.
                                Nulla finibus lobortis pulvinar. Donec a
                                consectetur nulla.
                              </p>
                              <div className="geodir-category-content-details">
                                <ul>
                                  <li>
                                    <i className="fa-solid fa-bed" />
                                    <span>3</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-bath" />
                                    <span>2</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-cube" />
                                    <span>450 ft2</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="geodir-category-footer fl-wrap">
                                <a href="#" className="gcf-company">
                                  <img src="images/avatar/2.jpg" alt />
                                  <span>By Liza Rose</span>
                                </a>
                                <div
                                  className="listing-rating card-popup-rainingvis tolt"
                                  data-microtip-position="top"
                                  data-tooltip="Good"
                                  data-starrating2={4}
                                />
                              </div>
                              <div className="dashboard-listings-item_opt btm_footer-item">
                                <span className="viewed-counter">
                                  <i className="fa-solid fa-eye" /> Viewed - 645{" "}
                                </span>
                                <ul>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Edit"
                                    >
                                      <i className="fa-solid fa-edit" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt text-red"
                                      data-microtip-position="top-left"
                                      data-tooltip="Disable"
                                    >
                                      <i className="fa-solid fa-signal-alt-slash" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Delete"
                                    >
                                      <i className="fa-solid fa-trash-alt" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </article>
                        </div> */}
                        {/* listing-item end*/}
                        {/* listing-item */}
                        {/* <div className="listing-item has_one_column">
                          <article className="geodir-category-listing fl-wrap">
                            <div className="geodir-category-img fl-wrap">
                              <a href="#" className="geodir-category-img_item">
                                <img src="images/all/3.jpg" alt />
                                <div className="overlay" />
                              </a>
                              <div className="geodir-category-location">
                                <a
                                  href="#1"
                                  className="map-item tolt"
                                  data-microtip-position="top-left"
                                  data-tooltip="On the map"
                                >
                                  <i className="fa-solid fa-map-marker-alt" />{" "}
                                  70 Bright St New York, USA
                                </a>
                              </div>
                              <ul className="list-single-opt_header_cat">
                                <li>
                                  <a href="#" className="cat-opt green-bg">
                                    Sold Out
                                  </a>
                                </li>
                                <li>
                                  <a href="#" className="cat-opt color-bg">
                                    Apartment
                                  </a>
                                </li>
                              </ul>
                              <a
                                href="#"
                                className="geodir_save-btn tolt"
                                data-microtip-position="left"
                                data-tooltip="Save"
                              >
                                <span>
                                  <i className="fa-solid fa-heart" />
                                </span>
                              </a>
                              <a
                                href="#"
                                className="compare-btn tolt"
                                data-microtip-position="left"
                                data-tooltip="Compare"
                              >
                                <span>
                                  <i className="fa-solid fa-random" />
                                </span>
                              </a>
                              <div className="geodir-category-listing_media-list">
                                <span>
                                  <i className="fa-solid fa-camera" /> 8
                                </span>
                              </div>
                            </div>
                            <div className="geodir-category-content fl-wrap">
                              <h3>
                                <a href="listing-single.html">
                                  Gorgeous house for sale
                                </a>
                              </h3>
                              <div className="geodir-category-content_price">
                                $ 600,000
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Maecenas in pulvinar neque.
                                Nulla finibus lobortis pulvinar. Donec a
                                consectetur nulla.
                              </p>
                              <div className="geodir-category-content-details">
                                <ul>
                                  <li>
                                    <i className="fa-solid fa-bed" />
                                    <span>3</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-bath" />
                                    <span>2</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-cube" />
                                    <span>450 ft2</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="geodir-category-footer fl-wrap">
                                <a href="#" className="gcf-company">
                                  <img src="images/avatar/2.jpg" alt />
                                  <span>By Liza Rose</span>
                                </a>
                                <div
                                  className="listing-rating card-popup-rainingvis tolt"
                                  data-microtip-position="top"
                                  data-tooltip="Good"
                                  data-starrating2={4}
                                />
                              </div>
                              <div className="dashboard-listings-item_opt btm_footer-item">
                                <span className="viewed-counter">
                                  <i className="fa-solid fa-eye" /> Viewed - 645{" "}
                                </span>
                                <ul>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Edit"
                                    >
                                      <i className="fa-solid fa-edit" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt text-green"
                                      data-microtip-position="top-left"
                                      data-tooltip="Enable"
                                    >
                                      <i className="fa-solid fa-signal-alt" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Delete"
                                    >
                                      <i className="fa-solid fa-trash-alt" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </article>
                        </div> */}
                        {/* listing-item end*/}
                        {/* listing-item */}
                        {/* <div className="listing-item has_one_column">
                          <article className="geodir-category-listing fl-wrap">
                            <div className="geodir-category-img fl-wrap">
                              <a href="#" className="geodir-category-img_item">
                                <img src="images/all/3.jpg" alt />
                                <div className="overlay" />
                              </a>
                              <div className="geodir-category-location">
                                <a
                                  href="#1"
                                  className="map-item tolt"
                                  data-microtip-position="top-left"
                                  data-tooltip="On the map"
                                >
                                  <i className="fa-solid fa-map-marker-alt" />{" "}
                                  70 Bright St New York, USA
                                </a>
                              </div>
                              <ul className="list-single-opt_header_cat">
                                <li>
                                  <a href="#" className="cat-opt blue-bg">
                                    On Sale
                                  </a>
                                </li>
                                <li>
                                  <a href="#" className="cat-opt color-bg">
                                    Apartment
                                  </a>
                                </li>
                              </ul>
                              <div className="geodir-category-listing_media-list">
                                <span>
                                  <i className="fa-solid fa-camera" /> 8
                                </span>
                              </div>
                            </div>
                            <div className="geodir-category-content fl-wrap">
                              <h3>
                                <a href="listing-single.html">
                                  Gorgeous house for sale
                                </a>
                              </h3>
                              <div className="geodir-category-content_price">
                                $ 600,000
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Maecenas in pulvinar neque.
                                Nulla finibus lobortis pulvinar. Donec a
                                consectetur nulla.
                              </p>
                              <div className="geodir-category-content-details">
                                <ul>
                                  <li>
                                    <i className="fa-solid fa-bed" />
                                    <span>3</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-bath" />
                                    <span>2</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-cube" />
                                    <span>450 ft2</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="geodir-category-footer fl-wrap">
                                <a href="#" className="gcf-company">
                                  <img src="images/avatar/2.jpg" alt />
                                  <span>By Liza Rose</span>
                                </a>
                                <div
                                  className="listing-rating card-popup-rainingvis tolt"
                                  data-microtip-position="top"
                                  data-tooltip="Good"
                                  data-starrating2={4}
                                />
                              </div>
                              <div className="dashboard-listings-item_opt btm_footer-item">
                                <span className="viewed-counter">
                                  <i className="fa-solid fa-eye" /> Viewed - 645{" "}
                                </span>
                                <ul>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Edit"
                                    >
                                      <i className="fa-solid fa-edit" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt text-red"
                                      data-microtip-position="top-left"
                                      data-tooltip="Disable"
                                    >
                                      <i className="fa-solid fa-signal-alt-slash" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Delete"
                                    >
                                      <i className="fa-solid fa-trash-alt" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </article>
                        </div> */}
                        {/* listing-item end*/}
                        {/* listing-item */}
                        {/* <div className="listing-item has_one_column">
                          <article className="geodir-category-listing fl-wrap">
                            <div className="geodir-category-img fl-wrap">
                              <a href="#" className="geodir-category-img_item">
                                <img src="images/all/3.jpg" alt />
                                <div className="overlay" />
                              </a>
                              <div className="geodir-category-location">
                                <a
                                  href="#1"
                                  className="map-item tolt"
                                  data-microtip-position="top-left"
                                  data-tooltip="On the map"
                                >
                                  <i className="fa-solid fa-map-marker-alt" />{" "}
                                  70 Bright St New York, USA
                                </a>
                              </div>
                              <ul className="list-single-opt_header_cat">
                                <li>
                                  <a href="#" className="cat-opt green-bg">
                                    Sold Out
                                  </a>
                                </li>
                                <li>
                                  <a href="#" className="cat-opt color-bg">
                                    Apartment
                                  </a>
                                </li>
                              </ul>
                              <a
                                href="#"
                                className="geodir_save-btn tolt"
                                data-microtip-position="left"
                                data-tooltip="Save"
                              >
                                <span>
                                  <i className="fa-solid fa-heart" />
                                </span>
                              </a>
                              <a
                                href="#"
                                className="compare-btn tolt"
                                data-microtip-position="left"
                                data-tooltip="Compare"
                              >
                                <span>
                                  <i className="fa-solid fa-random" />
                                </span>
                              </a>
                              <div className="geodir-category-listing_media-list">
                                <span>
                                  <i className="fa-solid fa-camera" /> 8
                                </span>
                              </div>
                            </div>
                            <div className="geodir-category-content fl-wrap">
                              <h3>
                                <a href="listing-single.html">
                                  Gorgeous house for sale
                                </a>
                              </h3>
                              <div className="geodir-category-content_price">
                                $ 600,000
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Maecenas in pulvinar neque.
                                Nulla finibus lobortis pulvinar. Donec a
                                consectetur nulla.
                              </p>
                              <div className="geodir-category-content-details">
                                <ul>
                                  <li>
                                    <i className="fa-solid fa-bed" />
                                    <span>3</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-bath" />
                                    <span>2</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-cube" />
                                    <span>450 ft2</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="geodir-category-footer fl-wrap">
                                <a href="#" className="gcf-company">
                                  <img src="images/avatar/2.jpg" alt />
                                  <span>By Liza Rose</span>
                                </a>
                                <div
                                  className="listing-rating card-popup-rainingvis tolt"
                                  data-microtip-position="top"
                                  data-tooltip="Good"
                                  data-starrating2={4}
                                />
                              </div>
                              <div className="dashboard-listings-item_opt btm_footer-item">
                                <span className="viewed-counter">
                                  <i className="fa-solid fa-eye" /> Viewed - 645{" "}
                                </span>
                                <ul>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Edit"
                                    >
                                      <i className="fa-solid fa-edit" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt text-green"
                                      data-microtip-position="top-left"
                                      data-tooltip="Enable"
                                    >
                                      <i className="fa-solid fa-signal-alt" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Delete"
                                    >
                                      <i className="fa-solid fa-trash-alt" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </article>
                        </div> */}
                        {/* listing-item end*/}
                      </div>
                    </div>
                    {/* dashboard-listings-wrap end*/}
                  </div>
                  {/* pagination*/}
                  {/* <div className="pagination float-pagination">
                    <a href="#" className="prevposts-link">
                      <i className="fa fa-caret-left" />
                    </a>
                    <a href="#">1</a>
                    <a href="#" className="current-page">
                      2
                    </a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#" className="nextposts-link">
                      <i className="fa fa-caret-right" />
                    </a>
                  </div> */}
                  {/* pagination end*/}
                </div>
              </div>
              {/* dashboard-footer */}
              <ProfileFooter />
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
      ;
    </>
  );
}

export default MyListing;
