import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'



function Footer() {
    return (<footer className="main-footer fl-wrap gray-bg" style={{ backgroundColor: 'rgb(255 238 213)' }}>
        <div className="footer-inner fl-wrap" >
            <div className="container">
                <div className="row">
                    {
                        /* footer widget*/
                    }
                    <div className="col-md-3">
                        <div className="footer-widget fl-wrap">
                            <div className="footer-widget-logo fl-wrap">
                                <img src="images/logo.png" alt="logo" />
                            </div>
                            <p>
                                We provide a platform for you to publish or search for property sale or rental listings. We also offer other real estate-related services.
                            </p>
                            <ul className="footer-contacts fl-wrap">
                                <li>
                                    <span>
                                        <FontAwesomeIcon icon="fa-solid  fa-envelope" /> Mail :
                                    </span>
                                    <a href="#" target="_blank">
                                        <span className="__cf_email__" data-cfemail="5f26302a2d323e36331f3b30323e3631713c3032">
                                            Info@hauzzi.com
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    {" "}
                                    <span>
                                        <FontAwesomeIcon icon="fa-solid  fa-map-marker" /> Adress :
                                    </span>
                                    <a href="#" target="_blank">

                                    </a>
                                </li>
                                <li>
                                    <span>
                                        <FontAwesomeIcon icon="fa-solid  fa-phone" /> Phone :
                                    </span>
                                    <a href="#"></a>
                                </li>
                            </ul>
                            <div className="footer-social fl-wrap">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/hauzzi" target="_blank">
                                            <i className="fab  fa-facebook" />
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="" target="_blank">
                                            <i className="fab  fa-twitter" />
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="https://www.youtube.com/@hauzzi" target="_blank">
                                            <i className="fab  fa-youtube" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/hauzzi_/?hl=es" target="_blank">
                                            <i className="fab  fa-instagram" />
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="#" target="_blank">
                                            <i className="fab  fa-vk" />
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {
                        /* footer widget end*/
                    }
                    {
                        /* footer widget*/
                    }
                    <div className="col-md-3">
                        <div className="footer-widget fl-wrap">
                            <div className="footer-widget-title fl-wrap">
                                <h4>Helpful links</h4>
                            </div>
                            <ul className="footer-list fl-wrap">
                                <li>
                                    <a href="about"><FontAwesomeIcon icon="fa-solid fa-caret-right" /> About</a>
                                </li>
                                <li>
                                    <a href="listing"><FontAwesomeIcon icon="fa-solid fa-caret-right" /> Property Listing </a>
                                </li>
                                {/* <li>
                                    <a href="listing"><FontAwesomeIcon icon="fa-solid fa-caret-right" /> Property Listing </a>
                                </li> */}
                                <li>
                                    <a href="agents"><FontAwesomeIcon icon="fa-solid fa-caret-right" /> Agents</a>
                                </li>
                                <li>
                                    {" "}
                                    <a href="agencies"><FontAwesomeIcon icon="fa-solid fa-caret-right" /> Agency</a>
                                </li>
                                <li>
                                    {" "}
                                    <a href="/citylists"><FontAwesomeIcon icon="fa-solid fa-caret-right" /> City</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {
                        /* footer widget end*/
                    }
                    {
                        /* footer widget*/
                    }
                    <div className="col-md-3">
                        <div className="footer-widget fl-wrap">
                            <div className="footer-widget-title fl-wrap">
                                <h4>Contacts Info</h4>
                            </div>
                            <ul className="footer-list fl-wrap">
                                <li>
                                    <a href="contact"><FontAwesomeIcon icon="fa-solid fa-caret-right" /> Contacts</a>
                                </li>
                                <li>
                                    <a href="help"><FontAwesomeIcon icon="fa-solid fa-caret-right" /> Help FAQ</a>
                                </li>
                                <li>
                                    <a href="pricing"><FontAwesomeIcon icon="fa-solid fa-caret-right" /> Pricing Plan</a>
                                </li>
                                <li>
                                    <a href="compare"><FontAwesomeIcon icon="fa-solid fa-caret-right" /> Compare Property</a>
                                </li>
                                <li>
                                    <a href="blog"><FontAwesomeIcon icon="fa-solid fa-caret-right" /> Blog</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {
                        /* footer widget end*/
                    }
                    {
                        /* footer widget*/
                    }
                    <div className="col-md-3">
                        <div className="footer-widget fl-wrap">
                            <div className="footer-widget-title fl-wrap">
                                <h4>Download our API</h4>
                            </div>
                            <p>
                                Start working with Webkype that can provide everything you
                                need{" "}
                            </p>
                            <div className="api-links fl-wrap">
                                <a href="#" className="api-btn color-bg">
                                    {/* <FontAwesomeIcon icon="fa-brands  fa-apple" /> */}
                                    App Store
                                </a>
                                <a href="#" className="api-btn color-bg">
                                    {/* <FontAwesomeIcon icon="fa-brands  fa-android" /> */}
                                    Play Store
                                </a>
                            </div>
                        </div>
                    </div>
                    {
                        /* footer widget end*/
                    }
                </div>
            </div>
        </div>
        {
            /*sub-footer*/
        }
        <div className="sub-footer gray-bg fl-wrap">
            <div className="container">
                <div className="copyright">
                    {" "}
                    © Webkype 2024 . All rights reserved.
                </div>
                <div className="subfooter-nav">
                    <ul className="no-list-style">
                        <li>
                            <a href="terms-of-use">Terms of use</a>
                        </li>
                        <li>
                            <a href="privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="blog">Blog</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        {
            /*sub-footer end */
        }
    </footer>
    )
}

export default Footer
