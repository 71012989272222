import React  from "react";
import Footer from "../Header/Footer";
import HeroSection from "./HeroSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OfficeLocation from "../Listing/OfficeLocation";
import { toast } from 'react-toastify'


function Contact() {

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;

  const handleSubmit = async (e) => {
    try {

      e.preventDefault();

      const fetchData = new FormData(e.target)
      const data = Object.fromEntries(fetchData.entries());

      for (const key in data) {
        if (data[key] !== null) {
          fetchData.append(key, data[key]);
        }
      }


      console.log('fetchData=>', fetchData)
      const response = await fetch(`${apiUrl}/admin-enquiry/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify(data),
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      window.location.reload()
    } catch (error) {
      toast.error(error.message);
    }


  }


  return (
    <div>
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />

      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />

      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />
      {/*loader*/}
      <div className="loader-wrap">
        <div className="loader-inner">
          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation={2}
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                  result="gooey"
                />
                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* Header put here */}

        {/* wrapper  */}
        <div id="wrapper p-0">
          {/* content */}
          <div className="content">
            {/*  section  */}
            <HeroSection />
            {/*  section  end*/}
            {/* breadcrumbs*/}
            <div className="breadcrumbs fw-breadcrumbs sp-brd fl-wrap">
              <div className="container">
                <div className="breadcrumbs-list">
                  <a href="#">Home</a> <a href="#">Pages</a>
                  <span>Contacts</span>
                </div>
                <div className="share-holder hid-share">
                  <a href="#" className="share-btn showshare sfcs">
                    {" "}
                    <i className="fa-solid fa-share-alt" /> Share{" "}
                  </a>
                  <div className="share-container  isShare" />
                </div>
              </div>
            </div>
            {/* breadcrumbs end */}
            {/* section */}



            <section className="gray-bg small-padding">
              <div className="container">
                <div className="row">
                  <div className="about-title ab-hero fl-wrap">
                    <h2>Contacts</h2>
                    <h4>
                      Do you want to collaborate with content for the blog, want to hire advertising or contact our spokespersons?
                    </h4>
                  </div>

                  <div className="row">

                    <div className="col-md-3">
                      {/* <div className="pricing-column fl-wrap"> */}
                      <div className="services-item fl-wrap" style={{ padding: "25px 20px" }}>
                        <div style={{
                          display: 'flex',
                          gap: '15px',
                          alignItems: "center",
                          marginBottom: "13px"
                        }}>
                          <FontAwesomeIcon icon="fa-solid fa-blog" size="3x" />
                          <h4>
                            PROFESSIONAL BLOG CONTENTS
                          </h4>
                        </div>
                        <p>
                          If you want to collaborate as an expert in Hauzzi's professional blog or you want to send us your press releases to be published, contact at {" "}
                        </p>
                        <a href="#" className="serv-link sl-b">
                          comunicacion@hauzzi.com
                        </a>
                      </div>
                      {/* </div> */}
                    </div>

                    <div className="col-md-3">
                      {/* <div className="pricing-column fl-wrap"> */}
                      <div className="services-item fl-wrap" style={{ padding: "25px 20px" }}>
                        <div style={{
                          display: 'flex',
                          gap: '15px',
                          alignItems: "center",
                          marginBottom: "13px"
                        }}>
                          {/* <FontAwesomeIcon icon="fa-solid fa-rectangle-ad"  /> */}
                          <FontAwesomeIcon icon="fa-solid fa-bullhorn" size="3x" />
                          <h4>
                            CONTRACTING ADVERTISING CAMPAIGNS
                          </h4>
                        </div>
                        <p>
                          If you want to hire advertising campaigns in Hauzzi contact our commercial advisors at  {" "}
                        </p>
                        <a href="#" className="serv-link sl-b">
                          publicidad@hauzzi.com
                        </a>
                      </div>
                      {/* </div> */}
                    </div>

                    <div className="col-md-3">
                      {/* <div className="pricing-column fl-wrap"> */}
                      <div className="services-item fl-wrap" style={{ padding: "25px 20px" }}>
                        <div style={{
                          display: 'flex',
                          gap: '15px',
                          alignItems: "center",
                          marginBottom: "13px"
                        }}>
                          <FontAwesomeIcon icon="fa-solid fa-circle-play" size="3x" />
                          <h4>
                            MEDIA
                          </h4>
                        </div>
                        <p style={{ marginTop: '20px', marginBottom: '20px' }}>
                          If you are a journalist and would like to talk to our communications department, please contact us at  {" "}
                        </p>
                        <a href="#" className="serv-link sl-b">
                          comunicacion@hauzzi.com
                        </a>
                      </div>
                      {/* </div> */}
                    </div>

                    <div className="col-md-3">
                      {/* <div className="pricing-column fl-wrap"> */}
                      <div className="services-item fl-wrap" style={{ padding: "25px 20px" }}>
                        <div style={{
                          display: 'flex',
                          gap: '15px',
                          alignItems: "center",
                          marginBottom: "13px"
                        }}>
                          <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" size="3x" />
                          <h4>
                            OTHER ISSUES
                          </h4>
                        </div>
                        <p style={{ marginTop: '30px', marginBottom: '30px' }}>
                          If none of the above fulfills your request, do not hesitate to contact us through  {" "}
                        </p>
                        <a href="#" className="serv-link sl-b">
                          info@hauzzi.com
                        </a>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>


                  {/* <div className="col-md-4">
                    <div className="services-item fl-wrap" style={{ padding: "25px 20px" }}>
                      <div style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: "center",
                        marginBottom: "13px"
                      }}>
                        <FontAwesomeIcon icon="fa-solid fa-blog" size="3x" />
                        <h4>
                          PROFESSIONAL BLOG CONTENTS
                        </h4>
                      </div>
                      <p>
                        If you want to collaborate as an expert in Hauzzi's professional blog or you want to send us your press releases to be published, please contact the editorial team at {" "}
                      </p>
                      <a href="#" className="serv-link sl-b">
                        comunicacion@hauzzi.com
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="services-item fl-wrap">
                      <i className="fa-solid fa-phone" />
                      <h4>
                        CONTRACTING ADVERTISING CAMPAIGNS
                      </h4>
                      <p>
                        If you want to hire advertising campaigns in Hauzzi contact our commercial advisors at .{" "}
                      </p>
                      <a href="#" className="serv-link sl-b">
                        publicidad@hauzzi.com
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="services-item fl-wrap">
                      <i className="fa-solid fa-map-marked" />
                      <h4>
                        MEDIA
                      </h4>
                      <p>
                        If you are a journalist and would like to talk to our communications department, please contact us at .{" "}
                      </p>
                      <a href="#" className="serv-link sl-b">
                        comunicacion@hauzzi.com
                      </a>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="services-item fl-wrap">
                      <i className="fa-solid fa-map-marked" />
                      <h4>
                        OTHER ISSUES
                      </h4>
                      <p>
                        If none of the above fulfills your request, do not hesitate to contact us through.{" "}
                      </p>
                      <a href="#" className="serv-link sl-b">
                        info@hauzzi.com
                      </a>
                    </div>
                  </div> */}
                </div>
                <div className="clearfix" />
                <div className="contacts-opt fl-wrap">
                  <div className="contact-social">
                    <span className="cs-title">Find us on: </span>
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/hauzzi" target="_blank">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/hauzzi_/?hl=es" target="_blank">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/@hauzzi" target="_blank">
                          <i className="fab fa-youtube" />
                        </a>
                      </li>
                      {/* <li>
                        <a href="#" target="_blank">
                          <i className="fab fa-vk" />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <a
                    href="#"
                    className="btn small-btn float-btn color-bg cf_btn"
                  >
                    Write Mesagge
                  </a>
                  <div className="contact-notifer">
                    Or visit our <a href="Help"> help page</a>
                  </div>
                </div>
                {/*box-widget  */}
                <div className="box-widget">
                  <div className="box-widget-title single_bwt fl-wrap   ">
                    Office Location
                  </div>
                  <p>
                    We provide a platform for you to publish or search for property sale or rental listings. We also offer other real estate-related services.
                  </p>
                  {/*box-widget end*/}
                </div>
                {/*box-widget*/}
                <OfficeLocation propertyList={[]} />

                {/* <div className="box-widget fl-wrap">
                  <div className="map-widget contacts-map fl-wrap">
                    <div className="map-container mapC_vis">
                      <div
                        id="singleMap"
                        data-latitude="40.7427837"
                        data-longitude="-73.11445617675781"
                        data-infotitle="Our Loacation In NewYork"
                        data-infotext="70 Bright St New York, USA"
                      />
                      <div className="scrollContorl" />
                    </div>
                  </div>
                </div> */}
                {/*box-widget end */}
              </div>
            </section>
            {/* section end*/}
          </div>
          {/* content end */}
          {/* subscribe-wrap */}
          {/* <div className="subscribe-wrap fl-wrap">
            <div className="container">
              <div className="subscribe-container fl-wrap color-bg">
                <div className="pwh_bg" />
                <div className="mrb_dec mrb_dec3" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="subscribe-header">
                      <h4>newsletter</h4>
                      <h3>
                        Sign up for newsletter and get latest news and update
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-5">
                    <div className="footer-widget fl-wrap">
                      <div className="subscribe-widget fl-wrap">
                        <div className="subcribe-form">
                          <form id="subscribe">
                            <input
                              className="enteremail fl-wrap"
                              name="email"
                              id="subscribe-email"
                              placeholder="Enter Your Email"
                              spellCheck="false"
                              type="text"
                            />
                            <button
                              type="submit"
                              id="subscribe-button"
                              className="subscribe-button color-bg"
                            >
                              {" "}
                              Subscribe
                            </button>
                            <label
                              htmlFor="subscribe-email"
                              className="subscribe-message"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* subscribe-wrap end */}
          {/* footer */}
          <Footer />
          {/* footer end */}
        </div>
        {/* wrapper end */}
        {/*register form */}

        {/*register form end */}
        {/*secondary-nav */}

        {/*secondary-nav end */}
      </div>
      {/* Main end */}
      {/*contact-form-wrap */}
      <div className="contact-form-wrap">
        <div className="contact-form-container">
          <div className="contact-form-main fl-wrap">
            <div className="contact-form-header">
              <h4>Get In Touch</h4>
              <span className="close-contact-form">
                <i className="fa-solid fa-times" />
              </span>
            </div>
            <div id="contact-form" className="contact-form fl-wrap">
              <div id="message" />
              <form
                className="custom-form"
                action
                name="contactform"
                id="contactform"
                onSubmit={(e) => {
                  handleSubmit(e)
                }}
              >
                <fieldset>
                  <label>
                    Your name*{" "}
                    <span className="dec-icon">
                      <i className="fa-solid fa-user" />
                    </span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your Name *"
                    defaultValue
                    required
                  />
                  <label>
                    Your mail*{" "}
                    <span className="dec-icon">
                      <i className="fa-solid fa-envelope" />
                    </span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address*"
                    required
                  />
                  <label>
                    Your Mobile Numer*{" "}
                    <span className="dec-icon">
                      <i className="fa-solid fa-phone" />
                    </span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    required
                    placeholder="Mobile Number*"
                   
                  />
                  <textarea
                    name="message"
                    id="message"
                    cols={40}
                    rows={3}
                    placeholder="Your Message:"
                    defaultValue={""}
                    
                  />
                </fieldset>
                <button
                  className="btn float-btn color-bg"
                  style={{ marginTop: 15 }}
                  id="submit"
                >
                  Send Message
                </button>
              </form>
            </div>
            {/* contact form  end*/}
          </div>
        </div>
        <div className="contact-form-overlay" />
      </div>
      {/*contact-form-wrap end*/}
    </div>
  );
}

export default Contact;
