import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Footer from "../Header/Footer";
import HeroSection from "../Home/HeroSection";

function UserProfile() {

  const initialFormData = {
    message: "",
  }

  const initialFormReview = {
    content: "",
    // rating: ""
  }

  const [formData, setFormData] = useState(initialFormData)
  const [formReviewData, setFormReviewData] = useState(initialFormReview)
  const { slug } = useParams()
  const [profile, setProfile] = useState({})
  const [photoCount, setPhotoCount] = useState([])
  const [user, setUser] = useState({})
  const [rating, setRating] = useState(4)


  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");


  // const openWhatsApp = ({ phoneNumber }) => {
  //   const whatsappUrl = `https://wa.me/${phoneNumber}`;
  //   window.open(whatsappUrl, "_blank");
  // };

  const openWhatsApp = (phoneNumber) => {
    if (phoneNumber) {
      // Remove any non-digit characters
      let sanitizedNumber = phoneNumber.replace(/\D/g, "");

      // Add Venezuela country code (58) if it's not already included
      if (!sanitizedNumber.startsWith("58")) {
        sanitizedNumber = `58${sanitizedNumber}`;
      }

      // Construct the WhatsApp URL
      const whatsappUrl = `https://wa.me/${sanitizedNumber}`;

      // Open the WhatsApp link in a new tab
      window.open(whatsappUrl, "_blank");
    } else {
      console.error("Phone number is not available.");
    }
  };


  useEffect(() => {
    fetch(`${apiUrl}/profile/getById/${slug.split('-').pop()}`, {
      headers: {
        'Authorization': `Bearer ${Token}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          setProfile(data.data)
          console.log(data.data, 'profile')
          let count = []
          for (let j = 0; j < data.data.property.length; j++) {
            let temp = 0;
            for (let i = 0; i < 5; i++) {
              if (data.data.property[j][`photo${i + 1}`]) {
                temp++;
              }
            }
            count = [...count, temp]
          }
          console.log(count, 'count=======')
          setPhotoCount(count)
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [Token, apiUrl, slug])


  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
      }
    }
    getUser();
  }, []);

  // A function to handle the change of the input fields
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target
    setFormReviewData((prev) => ({ ...prev, [name]: value }));
  };


  // A function to handle the submit of the property form
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formData.customerId = slug.split('-').pop()
      formData.userId = user.id

      if (!formData.userId) {
        toast.error("Login First")
        return
      }
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }
      formDataToSend.rating = rating

      const response = await fetch(`${apiUrl}/profile-enquiry/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      setFormData(initialFormData)
      // navigate("/Master");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formReviewData.customerId = slug.split('-').pop()
      formReviewData.reviewerId = user?.userDetails?.[0]?.customerId
      formReviewData.rating = String(rating)
      if (!formReviewData.reviewerId) {
        toast.error("Login First")
        return
      }
      for (const key in formReviewData) {
        if (formReviewData[key] !== null) {
          formDataToSend.append(key, formReviewData[key]);
        }
      }

      const response = await fetch(`${apiUrl}/profile-review/addReview`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    } catch (error) {
      toast.error(error.message);
    }
  };


  // A function to handle the submit of the property form
  const handleSaveAgent = async (e, id, userId) => {
    e.preventDefault();

    try {

      const response = await fetch(`${apiUrl}/profile/addAgent/${id}-${userId}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        }
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      setTimeout(() => {
        window.location.reload()
      }, 2500)
    } catch (error) {
      toast.error(error.message);
    }
  };



  // A function to handle the submit of the property form
  const handleWishlist = async (e, id, userId) => {
    e.preventDefault();
    e.stopPropagation()
    if (!userId) {
      toast.error("Login First")
      return
    }

    try {

      const response = await fetch(`${apiUrl}/property/addToWishlist/${userId}-${id}`, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        }
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      setTimeout(() => {
        window.location.reload()
      }, 2500)
    } catch (error) {
      toast.error(error.message);
    }
  };

  // A function to handle the submit of the property form
  const handleCompare = async (e, id, userId) => {
    e.preventDefault();
    e.stopPropagation()
    if (!userId) {
      toast.error("Login First")
      return
    }

    try {

      const response = await fetch(`${apiUrl}/property/addToCompare/${userId}-${id}`, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        }
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      setTimeout(() => {
        window.location.reload()
      }, 2500)
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div>
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* put header here */}
          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper">
            {/* content */}
            <div className="content">
              <HeroSection />
              {/* breadcrumbs*/}
              <div className="breadcrumbs fw-breadcrumbs sp-brd fl-wrap   top-smpar  ">
                <div className="container">
                  <div className="breadcrumbs-list">
                    <a href="#">Home</a>
                    <a href="#">Agency</a> <span>Agent Single</span>
                  </div>
                  <div className="share-holder hid-share">
                    <a href="#" className="share-btn showshare sfcs">
                      {" "}
                      <i className="fa-solid fa-share-alt" /> Share{" "}
                    </a>
                    <div className="share-container  isShare" />
                  </div>
                </div>
              </div>
              {/* breadcrumbs end */}
              {/* col-list-wrap */}
              <section className="gray-bg small-padding ">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      {/* <div className="card-info smpar fl-wrap">
                        <div className="box-widget-menu-btn smact">
                          <i className="fa-solid fa-ellipsis-h" />
                        </div>
                        <div className="show-more-snopt-tooltip bxwt">
                          <a href="#">
                            {" "}
                            <i className="fa-solid fa-comment-alt" /> Write a
                            review
                          </a>
                          <a href="#">
                            {" "}
                            <i className="fa-solid fa-exclamation-triangle" />{" "}
                            Report{" "}
                          </a>
                        </div>
                        <div className="bg-wrap bg-parallax-wrap-gradien">
                          <div
                            className="bg"
                            data-bg={profile?.userDetails ? `${apiUrl}/uploads/${profile?.userDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/bg/8.jpg"}
                          />
                        </div>
                        <div className="card-info-media">
                          <div
                            className="bg"
                            data-bg={profile?.userDetails ? `${apiUrl}/uploads/${profile?.userDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/agency/agent/4.jpg"}
                          />
                        </div>
                        <div className="card-info-content">
                          <div className="agent_card-title fl-wrap">
                            <h4> {profile?.name}</h4>
                            <div className="geodir-category-location fl-wrap">
                              <h5>
                                <a href="agency-single.html">
                                  {profile?.userDetails?.length ? profile?.userDetails[0].address : "Mavers RealEstate Agency"}
                                </a>
                              </h5>
                              <div
                                className="listing-rating card-popup-rainingvis"
                                data-starrating2={4}
                              >
                                <span className="re_stars-title">Good</span>
                              </div>
                            </div>
                          </div>
                          <div className="list-single-stats">
                            <ul className="no-list-style">
                              <li>
                                <span className="viewed-counter">
                                  <i className="fa-solid fa-eye" /> Viewed - 156{" "}
                                </span>
                              </li>
                              <li>
                                <span className="bookmark-counter">
                                  <i className="fa-solid fa-comment-alt" />{" "}
                                  Reviews - {profile?.profileReview?.length}{" "}
                                </span>
                              </li>
                              <li>
                                <span className="bookmark-counter">
                                  <i className="fa-solid fa-sitemap" /> Listings
                                  - {profile?.property?.length}{" "}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div
                            className="card-verified tolt"
                            data-microtip-position="left"
                            data-tooltip="Verified"
                          >
                            <i className="fa-solid fa-user-check" />
                          </div>
                        </div>
                      </div> */}

                      {/*box-widget*/}
                      <div className="box-widget fl-wrap">
                        <div className="profile-widget">
                          <div className="profile-widget-header color-bg smpar fl-wrap">
                            <div className="pwh_bg" style={{ transform: "rotate(180deg)" }} />
                            <div className="call-btn">
                              <a
                                href="tel:123-456-7890"
                                className="tolt color-bg"
                                data-microtip-position="right"
                                data-tooltip="Call Now"
                              >
                                <i className="fa-solid fa-phone-alt" />
                              </a>
                            </div>
                            <div className="box-widget-menu-btn smact">
                              <i className="fa-solid fa-ellipsis-h" />
                            </div>
                            <div className="show-more-snopt-tooltip bxwt">
                              {/* <a href="#">
                                {" "}
                                <i className="fa-solid fa-comment-alt" /> Write a review
                              </a> */}
                              <a href="#">
                                {" "}
                                <i className="fa-solid fa-exclamation-triangle" />{" "}
                                Report{" "}
                              </a>
                            </div>
                            <div className="profile-widget-card" style={{ display: 'flex', background: "transparent" }}>
                              <div
                              // className="w-100"
                              >
                                <img
                                  style={{ objectFit: 'cover', width: "120px", height: "120px", border: "4px solid black ", borderRadius: "10px", boxShadow: '0px 0px 0px 7px rgba(255, 255, 255, 0.4)' }}
                                  // className="w-100"
                                  src={profile?.userDetails ? `${apiUrl}/uploads/${profile?.userDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/agent/4.jpg"}
                                  alt="Posted By"
                                />
                              </div>
                              <div className="profile-widget-header-title">
                                <div style={{ paddingBottom: "10px", borderBottom: "1px solid rgba(255, 255, 255, 0.8)" }}>
                                  <h4 style={{ color: '#fff', fontSize: 'xx-large', marginBottom: '10px' }}>
                                    {profile?.name}
                                  </h4>
                                  <div className="clearfix" />
                                  <div className="pwh_counter" style={{ color: 'white', fontSize: 'small' }}>
                                    Property Listings <span style={{ color: 'white' }}>{profile?.property?.length}</span>
                                  </div>
                                  <br />
                                </div>
                                <div className="pwh_counter" style={{ color: 'white', fontSize: 'small' }}>
                                  Profile Reviews <span style={{ color: 'white' }}>{profile?.profileReview?.length}</span>
                                  <button
                                    className="btn-sm color-bg text-white m-4"
                                    onClick={(e) => {
                                      handleSaveAgent(e, user.id, slug.split('-').pop())
                                    }}
                                  >
                                    {" "}
                                    Save
                                  </button>
                                </div>
                                <div className="clearfix" />
                                {/* <div
                                  className="listing-rating card-popup-rainingvis"
                                  data-starrating2={4}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*box-widget end */}
                      <div className="list-single-main-container fl-wrap">
                        {/* list-single-main-item */}
                        <div className="list-single-main-item fl-wrap">
                          <div className="list-single-main-item-title">
                            <h3>About This Agent</h3>
                          </div>

                          <div className="list-single-main-item_content fl-wrap">
                            <p>
                              {profile?.userDetails?.[0]?.notes}
                            </p>
                            <div
                              className="list-single-tags fl-wrap tags-stylwrap"
                              style={{ marginTop: 20 }}
                            >
                              <span>Service Areas:</span>
                              <a href="#">Residentails</a>
                              <a href="#">Commercial</a>
                              <a href="#">Land</a>
                              <a href="#">Plot</a>
                            </div>
                          </div>
                        </div>
                        {/* list-single-main-item end */}
                      </div>
                      {/* content-tabs-wrap */}
                      <div className="content-tabs-wrap tabs-act fl-wrap">
                        <div className="content-tabs fl-wrap">
                          <ul className="tabs-menu fl-wrap no-list-style">
                            <li className="current">
                              <a href="#tab-listing"> Listing</a>
                            </li>
                            <li>
                              <a href="#tab-reviews">Reviews</a>
                            </li>
                          </ul>
                        </div>
                        {/*tabs */}
                        <div className="tabs-container">
                          {/*tab */}
                          <div className="tab">
                            <div
                              id="tab-listing"
                              className="tab-content first-tab"
                            >
                              {/* listing-item-wrap*/}
                              <div className="listing-item-container one-column-grid-wrap  box-list_ic fl-wrap">

                                {profile?.property?.map((prop, i) => (
                                  <div
                                    className="listing-item"
                                    style={{ height: '78vh' }}
                                  >
                                    <article className="geodir-category-listing fl-wrap">
                                      <div className="geodir-category-img fl-wrap">
                                        <p
                                          className="geodir-category-img_item"
                                        >
                                          <img src={`${apiUrl}/uploads/${prop.photo1 ? prop.photo1 : prop.photo2}`} alt="property" style={{ height: "250px" }} />
                                          <div className="overlay" />
                                        </p>
                                        <div className="geodir-category-location">
                                          <a
                                            href="#1"
                                            className="map-item tolt"
                                            data-microtip-position="top-left"
                                            data-tooltip="On the map"
                                          >
                                            <FontAwesomeIcon icon="fa-solid  fa-map-marker-alt" /> {prop.cities.name},  {prop.states.name}
                                          </a>
                                        </div>
                                        <ul className="list-single-opt_header_cat">
                                          <li>
                                            <a href="#2" className="cat-opt blue-bg">
                                              {prop.purpose !== 'wantToRent' ? 'Sale' : 'Rent'}
                                            </a>
                                          </li>
                                          {/* <li>
                                            <a href="#3" className="cat-opt color-bg">
                                              {prop.type}
                                            </a>
                                          </li> */}
                                          <li>
                                            <a href="#" className="cat-opt color-bg">
                                              {prop.category}
                                            </a>
                                          </li>
                                        </ul>
                                        <p
                                          className="geodir_save-btn tolt text-center"
                                          data-microtip-position="left"
                                          role="button"
                                          data-tooltip="Save"
                                          onClick={(e) => {
                                            handleWishlist(e, prop.id, user.id)
                                          }}
                                        >
                                          <span>
                                            <FontAwesomeIcon icon="fa-solid fa-heart" />
                                          </span>
                                        </p>
                                        <p
                                          className="compare-btn tolt text-center"
                                          data-microtip-position="left"
                                          data-tooltip="Compare"
                                          role="button"
                                          onClick={(e) => {
                                            handleCompare(e, prop.id, user.id)
                                          }}
                                        >
                                          <span>
                                            <FontAwesomeIcon icon="fa-solid  fa-random" />
                                          </span>
                                        </p>
                                        <div className="geodir-category-listing_media-list">
                                          <span>
                                            <FontAwesomeIcon icon="fa-solid  fa-camera" /> {photoCount[i]}
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="geodir-category-content fl-wrap"
                                        onClick={() => {
                                          window.location.href = prop.slug
                                        }}
                                        role='button'
                                      >
                                        <h3>
                                          {
                                            prop.name ?
                                              <>{prop.name}</> :
                                              <>Gorgeous house for sale</>
                                          }
                                        </h3>
                                        <div className="geodir-category-content_price">$ {prop.maxPrice}</div>

                                        {
                                          prop.description ?
                                            <p>{prop.description.slice(0, 40)}...</p> :
                                            <p>
                                              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                              Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                                              Donec a consectetur nulla.
                                            </p>
                                        }
                                        <div className="geodir-category-content-details">
                                          <ul>
                                            <li>
                                              <FontAwesomeIcon icon="fa-solid  fa-bed" />
                                              <span>{" "}{prop?.listingDetails?.Bedrooms}</span>
                                            </li>
                                            <li>
                                              <FontAwesomeIcon icon="fa-solid  fa-bath" />
                                              <span>{" "}{prop?.listingDetails?.Bathroom}</span>
                                            </li>
                                            <li>
                                              <FontAwesomeIcon icon="fa-solid  fa-cube" />
                                              <span>{" "}{prop.maxSize} Sq Ft</span>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="geodir-category-footer fl-wrap">
                                          <a href="agent-single.html" className="gcf-company">
                                            {/* <img src={prop?.Customer?.userDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.userDetails[0]?.photo}` : (prop?.Customer?.agentDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agentDetails[0]?.photo}` : (prop?.Customer?.agencyDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agencyDetails[0]?.photo}` : "images/avatar/2.jpg"))} /> */}
                                            <img src={profile?.userDetails ? `${apiUrl}/uploads/${profile?.userDetails?.[0]?.photo}` : "images/avatar/2.jpg"} alt="poster" style={{ objectFit: 'cover' }} />
                                            <span>By {prop.postedBy}</span>
                                          </a>
                                          {/* <div
                                            className="listing-rating card-popup-rainingvis"
                                            data-microtip-position="top"
                                            data-tooltip="Good"
                                            data-starrating2={4}
                                          /> */}
                                        </div>
                                      </div>
                                    </article>
                                  </div>
                                ))}


                                {/* grid-item-holder*/}
                                <div
                                  className="grid-item-holder gallery-items gisp fl-wrap"
                                >
                                  {/* gallery-item*/}
                                  {/* {propertyList.length === 0 &&
                                    <>
                                      <Button variant="primary" disabled>
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        Loading...
                                      </Button>
                                    </>
                                  } */}
                                  {/* {profile?.property?.map((prop, i) => (
                                    <div
                                      key={prop.slug}
                                      className={`gallery-item for_${prop.purpose !== 'wantToRent' ? 'sale' : 'rent'}`}
                                      onClick={() => {
                                        window.location.href = prop.slug
                                      }}
                                      role="button"
                                    >
                                      <article className="geodir-category-listing fl-wrap" >
                                        <div className="geodir-category-img fl-wrap" >
                                          <p
                                            className="geodir-category-img_item"
                                          >
                                            <img src={`${apiUrl}/uploads/${prop.photo1 ? prop.photo1 : prop.photo2}`} alt="property" style={{ height: '250px' }} />
                                            <span className="overlay" />
                                          </p>
                                          <div className="geodir-category-location">
                                            <a
                                              href="#1"
                                              className="map-item tolt"
                                              data-microtip-position="top-left"
                                              data-tooltip="On the map"
                                            >
                                              <FontAwesomeIcon icon="fa-solid  fa-map-marker-alt" />

                                              {prop.cities.name} ,{prop.states.name} , {prop.countries.name}
                                            </a>
                                          </div>
                                          <ul className="list-single-opt_header_cat">
                                            <li>
                                              <a href="#2" className="cat-opt blue-bg">
                                                {prop.purpose !== 'wantToRent' ? 'Sale' : 'Rent'}
                                              </a>
                                            </li>
                                            {prop.purpose === 'bothSellRent' && <li>
                                              <a href="#3" className="cat-opt color-bg">
                                                Rent
                                              </a>
                                            </li>}
                                            <li>
                                              <a href="#" className="cat-opt color-bg">
                                                {prop.category}
                                              </a>
                                            </li>
                                          </ul>
                                          <p
                                            className={`geodir_save-btn tolt text-center ${wishlist?.find((item) => item === prop.id) ? "text-danger" : ""}`}
                                            data-microtip-position="left"
                                            data-tooltip="Save"
                                            onClick={(e) => {
                                              handleWishlist(e, prop.id, user.id);
                                            }}
                                          >
                                            <span>
                                              <FontAwesomeIcon icon="fa-solid fa-heart" />
                                            </span>
                                          </p>
                                          <p
                                            className="compare-btn tolt text-center"
                                            data-microtip-position="left"
                                            data-tooltip="Compare"
                                            onClick={(e) => {
                                              handleCompare(e, prop.id, user.id)
                                            }}
                                          >
                                            <span>
                                              <FontAwesomeIcon icon="fa-solid  fa-random" />
                                            </span>
                                          </p>
                                          <div className="geodir-category-listing_media-list">
                                            <span>
                                              <FontAwesomeIcon icon="fa-solid  fa-camera" /> {photoCount[i]}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          className="geodir-category-content fl-wrap"
                                          role='button'
                                        >
                                          <h3>
                                            <a>{
                                              prop.name ? <>{prop.name.slice(0, 70)}</> :
                                                <>Gorgeous house for sale</>
                                            }
                                            </a>
                                          </h3>
                                          <div className="geodir-category-content_price">$ {prop.maxPrice}</div>

                                          {
                                            prop.description ?
                                              <p>{prop.description.slice(0, 50)}...</p> :
                                              <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                                                Donec a consectetur nulla.
                                              </p>
                                          }
                                          <div className="geodir-category-content-details">
                                            <ul>
                                              <li>
                                                <FontAwesomeIcon icon="fa-solid  fa-bed" />
                                                <span>{" "}{prop?.listingDetails?.Bedrooms}</span>
                                              </li>
                                              <li>
                                                <FontAwesomeIcon icon="fa-solid  fa-bath" />
                                                <span>{" "}{prop?.listingDetails?.Bathroom}</span>
                                              </li>
                                              <li>
                                                <FontAwesomeIcon icon="fa-solid  fa-cube" />
                                                <span>{" "}{prop.maxSize} Sq Ft</span>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="geodir-category-footer fl-wrap">

                                            <a href={`${prop.slug}`} className="gcf-company">
                                              <img src={prop?.Customer?.userDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.userDetails[0]?.photo}` : (prop?.Customer?.agentDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agentDetails[0]?.photo}` : (prop?.Customer?.agencyDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agencyDetails[0]?.photo}` : "images/avatar/2.jpg"))} alt="poster" style={{ objectFit: 'cover' }} />
                                              <span>By {prop.postedBy}</span>
                                            </a>

                                          </div>
                                        </div>
                                      </article>
                                    </div>
                                  ))} */}
                                  <div className="gallery-item for_sale">

                                  </div>
                                  {/* gallery-item end*/}
                                  {/* gallery-item*/}
                                  <div className="gallery-item for_sale">

                                  </div>
                                  {/* gallery-item end*/}
                                  {/* gallery-item*/}
                                  <div className="gallery-item for_rent">

                                  </div>
                                  {/* gallery-item end*/}
                                  {/* gallery-item*/}
                                  <div className="gallery-item for_sale">

                                  </div>
                                  {/* gallery-item end*/}
                                  {/* gallery-item*/}
                                  <div className="gallery-item for_sale for_rent">

                                  </div>
                                  {/* gallery-item end*/}
                                  {/* gallery-item*/}
                                  <div className="gallery-item for_rent">

                                  </div>
                                  {/* gallery-item end*/}
                                </div>
                                {/* grid-item-holder*/}





                                {/* listing-item */}
                                {/* <div className="listing-item">
                                  <article className="geodir-category-listing fl-wrap">
                                    <div className="geodir-category-img fl-wrap">
                                      <a
                                        href="listing-single.html"
                                        className="geodir-category-img_item"
                                      >
                                        <img
                                          src={profile?.userDetails ? `${apiUrl}/uploads/${profile?.userDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/all/3.jpg"}
                                          alt="property"
                                        />
                                        <div className="overlay" />
                                      </a>
                                      <div className="geodir-category-location">
                                        <a
                                          href="#"
                                          className="single-map-item tolt"
                                          data-newlatitude="40.72956781"
                                          data-newlongitude="-73.99726866"
                                          data-microtip-position="top-left"
                                          data-tooltip="On the map"
                                        >
                                          <i className="fa-solid fa-map-marker-alt" />{" "}
                                          <span>
                                            {" "}
                                            70 Bright St New York, USA
                                          </span>
                                        </a>
                                      </div>
                                      <ul className="list-single-opt_header_cat">
                                        <li>
                                          <a
                                            href="#"
                                            className="cat-opt blue-bg"
                                          >
                                            Sale
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            className="cat-opt color-bg"
                                          >
                                            Apartment
                                          </a>
                                        </li>
                                      </ul>
                                      <a
                                        href="#"
                                        className="geodir_save-btn tolt"
                                        data-microtip-position="left"
                                        data-tooltip="Save"
                                      >
                                        <span>
                                          <i className="fa-solid fa-heart" />
                                        </span>
                                      </a>
                                      <a
                                        href="#"
                                        className="compare-btn tolt"
                                        data-microtip-position="left"
                                        data-tooltip="Compare"
                                      >
                                        <span>
                                          <i className="fa-solid fa-random" />
                                        </span>
                                      </a>
                                      <div className="geodir-category-listing_media-list">
                                        <span>
                                          <i className="fa-solid fa-camera" /> 8
                                        </span>
                                      </div>
                                    </div>
                                    <div className="geodir-category-content fl-wrap">
                                      <h3 className="title-sin_item">
                                        <a href="listing-single.html">
                                          Gorgeous House For Sale
                                        </a>
                                      </h3>
                                      <div className="geodir-category-content_price">
                                        $ 600,000
                                      </div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Maecenas in pulvinar
                                        neque. Nulla finibus lobortis pulvinar.
                                        Donec a consectetur nulla.
                                      </p>
                                      <div className="geodir-category-content-details">
                                        <ul>
                                          <li>
                                            <i className="fa-solid fa-bed" />
                                            <span>3</span>
                                          </li>
                                          <li>
                                            <i className="fa-solid fa-bath" />
                                            <span>2</span>
                                          </li>
                                          <li>
                                            <i className="fa-solid fa-cube" />
                                            <span>450 ft2</span>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="geodir-category-footer fl-wrap">
                                        <a
                                          href="agent-single.html"
                                          className="gcf-company"
                                        >
                                          <img
                                            src="https://hauzzi.webkype.net/images/avatar/2.jpg"
                                            alt
                                          />
                                          <span>By Liza Rose</span>
                                        </a>
                                        <div
                                          className="listing-rating card-popup-rainingvis tolt"
                                          data-microtip-position="top"
                                          data-tooltip="Good"
                                          data-starrating2={4}
                                        />
                                      </div>
                                    </div>
                                  </article>
                                </div> */}
                                {/* listing-item end*/}
                                {/* listing-item */}
                                {/* <div className="listing-item">
                                  <article className="geodir-category-listing fl-wrap">
                                    <div className="geodir-category-img fl-wrap">
                                      <a
                                        href="listing-single.html"
                                        className="geodir-category-img_item"
                                      >
                                        <img
                                          src="https://hauzzi.webkype.net/images/all/1.jpg"
                                          alt
                                        />
                                        <div className="overlay" />
                                      </a>
                                      <div className="geodir-category-location">
                                        <a
                                          href="#"
                                          className="single-map-item tolt"
                                          data-newlatitude="40.88496706"
                                          data-newlongitude="-73.88191222"
                                          data-microtip-position="top-left"
                                          data-tooltip="On the map"
                                        >
                                          <i className="fa-solid fa-map-marker-alt" />{" "}
                                          <span>
                                            {" "}
                                            40 Journal Square , NJ, USA
                                          </span>
                                        </a>
                                      </div>
                                      <ul className="list-single-opt_header_cat">
                                        <li>
                                          <a
                                            href="#"
                                            className="cat-opt blue-bg"
                                          >
                                            Sale
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            className="cat-opt color-bg"
                                          >
                                            Apartment
                                          </a>
                                        </li>
                                      </ul>
                                      <a
                                        href="#"
                                        className="geodir_save-btn tolt"
                                        data-microtip-position="left"
                                        data-tooltip="Save"
                                      >
                                        <span>
                                          <i className="fa-solid fa-heart" />
                                        </span>
                                      </a>
                                      <a
                                        href="#"
                                        className="compare-btn tolt"
                                        data-microtip-position="left"
                                        data-tooltip="Compare"
                                      >
                                        <span>
                                          <i className="fa-solid fa-random" />
                                        </span>
                                      </a>
                                      <div className="geodir-category-listing_media-list">
                                        <span>
                                          <i className="fa-solid fa-camera" />{" "}
                                          47
                                        </span>
                                      </div>
                                    </div>
                                    <div className="geodir-category-content fl-wrap">
                                      <h3 className="title-sin_item">
                                        <a href="listing-single.html">
                                          Luxury Family Home
                                        </a>
                                      </h3>
                                      <div className="geodir-category-content_price">
                                        $ 320,000
                                      </div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Maecenas in pulvinar
                                        neque. Nulla finibus lobortis pulvinar.
                                        Donec a consectetur nulla.
                                      </p>
                                      <div className="geodir-category-content-details">
                                        <ul>
                                          <li>
                                            <i className="fa-solid fa-bed" />
                                            <span>4</span>
                                          </li>
                                          <li>
                                            <i className="fa-solid fa-bath" />
                                            <span>2</span>
                                          </li>
                                          <li>
                                            <i className="fa-solid fa-cube" />
                                            <span>460 ft2</span>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="geodir-category-footer fl-wrap">
                                        <a
                                          href="agent-single.html"
                                          className="gcf-company"
                                        >
                                          <img
                                            src="https://hauzzi.webkype.net/images/avatar/1.jpg"
                                            alt
                                          />
                                          <span>By Anna Lips</span>
                                        </a>
                                        <div
                                          className="listing-rating card-popup-rainingvis tolt"
                                          data-microtip-position="top"
                                          data-tooltip="Excellent"
                                          data-starrating2={5}
                                        />
                                      </div>
                                    </div>
                                  </article>
                                </div> */}
                                {/* listing-item end*/}
                                {/* listing-item */}
                                {/* <div className="listing-item">
                                  <article className="geodir-category-listing fl-wrap">
                                    <div className="geodir-category-img fl-wrap">
                                      <a
                                        href="listing-single.html"
                                        className="geodir-category-img_item"
                                      >
                                        <img
                                          src="https://hauzzi.webkype.net/images/all/9.jpg"
                                          alt
                                        />
                                        <div className="overlay" />
                                      </a>
                                      <div className="geodir-category-location">
                                        <a
                                          href="#"
                                          className="single-map-item tolt"
                                          data-newlatitude="40.94982541"
                                          data-newlongitude="-73.84357452"
                                          data-microtip-position="top-left"
                                          data-tooltip="On the map"
                                        >
                                          <i className="fa-solid fa-map-marker-alt" />{" "}
                                          <span>
                                            {" "}
                                            34-42 Montgomery St , NY, USA
                                          </span>
                                        </a>
                                      </div>
                                      <ul className="list-single-opt_header_cat">
                                        <li>
                                          <a
                                            href="#"
                                            className="cat-opt blue-bg"
                                          >
                                            Rent
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            className="cat-opt color-bg"
                                          >
                                            House
                                          </a>
                                        </li>
                                      </ul>
                                      <a
                                        href="#"
                                        className="geodir_save-btn tolt"
                                        data-microtip-position="left"
                                        data-tooltip="Save"
                                      >
                                        <span>
                                          <i className="fa-solid fa-heart" />
                                        </span>
                                      </a>
                                      <a
                                        href="#"
                                        className="compare-btn tolt"
                                        data-microtip-position="left"
                                        data-tooltip="Compare"
                                      >
                                        <span>
                                          <i className="fa-solid fa-random" />
                                        </span>
                                      </a>
                                      <div className="geodir-category-listing_media-list">
                                        <span>
                                          <i className="fa-solid fa-camera" /> 4
                                        </span>
                                      </div>
                                    </div>
                                    <div className="geodir-category-content fl-wrap">
                                      <h3 className="title-sin_item">
                                        <a href="listing-single.html">
                                          Family House for Rent
                                        </a>
                                      </h3>
                                      <div className="geodir-category-content_price">
                                        $ 700 / per month
                                      </div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Maecenas in pulvinar
                                        neque. Nulla finibus lobortis pulvinar.
                                        Donec a consectetur nulla.
                                      </p>
                                      <div className="geodir-category-content-details">
                                        <ul>
                                          <li>
                                            <i className="fa-solid fa-bed" />
                                            <span>2</span>
                                          </li>
                                          <li>
                                            <i className="fa-solid fa-bath" />
                                            <span>1</span>
                                          </li>
                                          <li>
                                            <i className="fa-solid fa-cube" />
                                            <span>220 ft2</span>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="geodir-category-footer fl-wrap">
                                        <a
                                          href="agent-single.html"
                                          className="gcf-company"
                                        >
                                          <img
                                            src="https://hauzzi.webkype.net/images/avatar/3.jpg"
                                            alt
                                          />
                                          <span>By Mark Frosty</span>
                                        </a>
                                        <div
                                          className="listing-rating card-popup-rainingvis tolt"
                                          data-microtip-position="top"
                                          data-tooltip="Good"
                                          data-starrating2={4}
                                        />
                                      </div>
                                    </div>
                                  </article>
                                </div> */}
                                {/* listing-item end*/}
                                {/* listing-item */}
                                {/* <div className="listing-item">
                                  <article className="geodir-category-listing fl-wrap">
                                    <div className="geodir-category-img fl-wrap">
                                      <a
                                        href="listing-single.html"
                                        className="geodir-category-img_item"
                                      >
                                        <img
                                          src="https://hauzzi.webkype.net/images/all/6.jpg"
                                          alt
                                        />
                                        <div className="overlay" />
                                      </a>
                                      <div className="geodir-category-location">
                                        <a
                                          href="#"
                                          className="single-map-item tolt"
                                          data-newlatitude="40.72228267"
                                          data-newlongitude="-73.99246214"
                                          data-microtip-position="top-left"
                                          data-tooltip="On the map"
                                        >
                                          <i className="fa-solid fa-map-marker-alt" />{" "}
                                          <span> W 85th St, New York, USA</span>
                                        </a>
                                      </div>
                                      <ul className="list-single-opt_header_cat">
                                        <li>
                                          <a
                                            href="#"
                                            className="cat-opt blue-bg"
                                          >
                                            Sale
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            className="cat-opt color-bg"
                                          >
                                            Apartment
                                          </a>
                                        </li>
                                      </ul>
                                      <a
                                        href="#"
                                        className="geodir_save-btn tolt"
                                        data-microtip-position="left"
                                        data-tooltip="Save"
                                      >
                                        <span>
                                          <i className="fa-solid fa-heart" />
                                        </span>
                                      </a>
                                      <a
                                        href="#"
                                        className="compare-btn tolt"
                                        data-microtip-position="left"
                                        data-tooltip="Compare"
                                      >
                                        <span>
                                          <i className="fa-solid fa-random" />
                                        </span>
                                      </a>
                                      <div className="geodir-category-listing_media-list">
                                        <span>
                                          <i className="fa-solid fa-camera" />{" "}
                                          13
                                        </span>
                                      </div>
                                    </div>
                                    <div className="geodir-category-content fl-wrap">
                                      <h3 className="title-sin_item">
                                        <a href="listing-single.html">
                                          Contemporary Apartment
                                        </a>
                                      </h3>
                                      <div className="geodir-category-content_price">
                                        $ 1,600,000
                                      </div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Maecenas in pulvinar
                                        neque. Nulla finibus lobortis pulvinar.
                                        Donec a consectetur nulla.
                                      </p>
                                      <div className="geodir-category-content-details">
                                        <ul>
                                          <li>
                                            <i className="fa-solid fa-bed" />
                                            <span>4</span>
                                          </li>
                                          <li>
                                            <i className="fa-solid fa-bath" />
                                            <span>1</span>
                                          </li>
                                          <li>
                                            <i className="fa-solid fa-cube" />
                                            <span>550 ft2</span>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="geodir-category-footer fl-wrap">
                                        <a
                                          href="agent-single.html"
                                          className="gcf-company"
                                        >
                                          <img
                                            src="https://hauzzi.webkype.net/images/avatar/4.jpg"
                                            alt
                                          />
                                          <span>By Bill Trust</span>
                                        </a>
                                        <div
                                          className="listing-rating card-popup-rainingvis tolt"
                                          data-microtip-position="top"
                                          data-tooltip="Excellent
                                                                      "
                                          data-starrating2={5}
                                        />
                                      </div>
                                    </div>
                                  </article>
                                </div> */}
                                {/* listing-item end*/}
                              </div>
                              {/* listing-item-wrap end*/}
                              {/* pagination*/}
                              {/* <div className="pagination">
                                <a href="#" className="prevposts-link">
                                  <i className="fa fa-caret-left" />
                                </a>
                                <a href="#">1</a>
                                <a href="#" className="current-page">
                                  2
                                </a>
                                <a href="#">3</a>
                                <a href="#">4</a>
                                <a href="#" className="nextposts-link">
                                  <i className="fa fa-caret-right" />
                                </a>
                              </div> */}
                              {/* pagination end*/}
                            </div>
                          </div>
                          {/*tab  end*/}
                          {/*tab */}
                          <div className="tab">
                            <div id="tab-reviews" className="tab-content">
                              <div
                                className="list-single-main-container fl-wrap"
                                style={{ marginTop: 30 }}
                              >
                                {/* list-single-main-item */}
                                <div
                                  className="list-single-main-item fl-wrap"
                                  id="sec6"
                                >
                                  <div className="list-single-main-item-title">
                                    <h3>
                                      Reviews <span>{profile?.profileReview?.length}</span>
                                    </h3>
                                  </div>
                                  <div className="list-single-main-item_content fl-wrap">
                                    <div className="reviews-comments-wrap fl-wrap">
                                      {/* <div className="review-total">
                                        <span className="review-number blue-bg">
                                          5.0
                                        </span>
                                        <div
                                          className="listing-rating card-popup-rainingvis"
                                          data-starrating2={5}
                                        >
                                          <span className="re_stars-title">
                                            Excellent
                                          </span>
                                        </div>
                                      </div> */}
                                      {/* reviews-comments-item */}
                                      {profile?.profileReview?.map((data) => (
                                        <div className="reviews-comments-item">
                                          <div className="review-comments-avatar">
                                            <img
                                              src={(data?.reviewer?.photo ? `${apiUrl}/uploads/${data?.reviewer?.photo}` : "https://hauzzi.webkype.net/images/avatar/2.jpg")}
                                              alt="reviewer"
                                            />
                                          </div>
                                          <div className="reviews-comments-item-text smpar">
                                            <div className="box-widget-menu-btn smact">
                                              <i className="fa-solid fa-ellipsis-h" />
                                            </div>
                                            <div className="show-more-snopt-tooltip bxwt">
                                              <a href="#">
                                                {" "}
                                                <i className="fa-solid fa-reply" />{" "}
                                                Reply
                                              </a>
                                              <a href="#">
                                                {" "}
                                                <i className="fa-solid fa-exclamation-triangle" />{" "}
                                                Report{" "}
                                              </a>
                                            </div>
                                            <div style={{
                                              display: 'flex',
                                              justifyContent: 'start',
                                              alignItems: 'center',
                                              gap: '10px',
                                              marginBottom: '25px'
                                            }}>
                                              <h4 style={{ marginBottom: '0px' }}>
                                                {data?.reviewer?.customer?.name}
                                              </h4>

                                              <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                {/* <h2 style={{ width: '15%', alignItems: 'center', fontSize: 'small', fontWeight: '600', color: '#566985' }}>
                                                RATING -
                                              </h2> */}
                                                {[1, 2, 3, 4, 5].map((star) => {
                                                  return (
                                                    <span
                                                      className='start'
                                                      style={{
                                                        cursor: 'pointer',
                                                        color: data?.rating >= star ? 'gold' : 'gray',
                                                        fontSize: `20px`,
                                                      }}
                                                    >
                                                      {' '}
                                                      ★{' '}
                                                    </span>
                                                  )
                                                })}
                                              </div>
                                            </div>



                                            {/* <div
                                              className="listing-rating card-popup-rainingvis"
                                              data-starrating2={5}
                                            >
                                              <span className="re_stars-title">
                                                Excellent
                                              </span>
                                            </div> */}
                                            <div className="clearfix" />
                                            <p>
                                              " {
                                                data.content
                                              }
                                              "
                                            </p>
                                            <div className="reviews-comments-item-date">
                                              <span className="reviews-comments-item-date-item">
                                                <i className="fa-solid fa-calendar-check" />
                                                {new Date(data?.createdAt).toDateString()}
                                              </span>
                                              {/* <a href="#" className="rate-review">
                                                <i className="fa-solid fa-thumbs-up" />{" "}
                                                Helpful Review <span>6</span>{" "}
                                              </a> */}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                      {/*reviews-comments-item end*/}
                                      {/* reviews-comments-item */}
                                      {/* <div className="reviews-comments-item">
                                        <div className="review-comments-avatar">
                                          <img
                                            src="https://hauzzi.webkype.net/images/avatar/4.jpg"
                                            alt
                                          />
                                        </div>
                                        <div className="reviews-comments-item-text smpar">
                                          <div className="box-widget-menu-btn smact">
                                            <i className="fa-solid fa-ellipsis-h" />
                                          </div>
                                          <div className="show-more-snopt-tooltip bxwt">
                                            <a href="#">
                                              {" "}
                                              <i className="fa-solid fa-reply" />{" "}
                                              Reply
                                            </a>
                                            <a href="#">
                                              {" "}
                                              <i className="fa-solid fa-exclamation-triangle" />{" "}
                                              Report{" "}
                                            </a>
                                          </div>
                                          <h4>
                                            <a href="#">Adam Koncy</a>
                                          </h4>
                                          <div
                                            className="listing-rating card-popup-rainingvis"
                                            data-starrating2={5}
                                          >
                                            <span className="re_stars-title">
                                              Excellent
                                            </span>
                                          </div>
                                          <div className="clearfix" />
                                          <p>
                                            " Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Nunc
                                            posuere convallis purus non cursus.
                                            Cras metus neque, gravida sodales
                                            massa ut. "
                                          </p>
                                          <div className="reviews-comments-item-date">
                                            <span className="reviews-comments-item-date-item">
                                              <i className="fa-solid fa-calendar-check" />
                                              03 December 2017
                                            </span>
                                            <a href="#" className="rate-review">
                                              <i className="fa-solid fa-thumbs-up" />{" "}
                                              Helpful Review <span>2</span>{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div> */}
                                      {/*reviews-comments-item end*/}
                                    </div>
                                  </div>
                                </div>
                                {/* list-single-main-item end */}
                                {/* list-single-main-item */}
                                <div
                                  className="list-single-main-item fl-wrap"
                                  id="sec5"
                                >
                                  <div className="list-single-main-item-title fl-wrap">
                                    <h3>Add Your Review</h3>
                                  </div>
                                  {/* Add Review Box */}
                                  <div
                                    id="add-review"
                                    className="add-review-box"
                                  >


                                    {/* <div className="leave-rating-wrap">
                                      <span className="leave-rating-title">
                                        Your rating for this listing :{" "}
                                      </span>
                                      <div className="leave-rating">
                                        <input
                                          type="radio"
                                          data-ratingtext="Excellent"
                                          name="rating"
                                          id="rating-1"
                                          defaultValue={1}
                                        />
                                        <label
                                          htmlFor="rating-1"
                                          className="fa-solid fa-star"
                                        />
                                        <input
                                          type="radio"
                                          data-ratingtext="Good"
                                          name="rating"
                                          id="rating-2"
                                          defaultValue={2}
                                        />
                                        <label
                                          htmlFor="rating-2"
                                          className="fa-solid fa-star"
                                        />
                                        <input
                                          type="radio"
                                          name="rating"
                                          data-ratingtext="Average"
                                          id="rating-3"
                                          defaultValue={3}
                                        />
                                        <label
                                          htmlFor="rating-3"
                                          className="fa-solid fa-star"
                                        />
                                        <input
                                          type="radio"
                                          data-ratingtext="Fair"
                                          name="rating"
                                          id="rating-4"
                                          defaultValue={4}
                                        />
                                        <label
                                          htmlFor="rating-4"
                                          className="fa-solid fa-star"
                                        />
                                        <input
                                          type="radio"
                                          data-ratingtext="Very Bad "
                                          name="rating"
                                          id="rating-5"
                                          defaultValue={5}
                                        />
                                        <label
                                          htmlFor="rating-5"
                                          className="fa-solid fa-star"
                                        />
                                      </div>
                                      <div className="count-radio-wrapper">
                                        <span id="count-checked-radio">
                                          Your Rating
                                        </span>
                                      </div>
                                    </div> */}
                                    {/* Review Comment */}
                                    <form
                                      className="add-comment custom-form"
                                      onSubmit={handleReviewSubmit}
                                    >

                                      <fieldset>
                                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: '10px' }}>
                                          <h2 style={{ marginRight: '10px', paddingTop: '4px', alignItems: 'center', fontSize: 'small', fontWeight: '600', color: '#566985' }}>
                                            RATING -
                                          </h2>
                                          {[1, 2, 3, 4, 5].map((star) => {
                                            return (
                                              <span
                                                className='start'
                                                style={{
                                                  cursor: 'pointer',
                                                  color: rating >= star ? 'gold' : 'gray',
                                                  fontSize: `25px`,
                                                }}
                                                onClick={() => {
                                                  setRating(star)
                                                }}
                                              >
                                                {' '}
                                                ★{' '}
                                              </span>
                                            )
                                          })}
                                        </div>


                                        {/* <div className="row">
                                          <div className="col-md-6">
                                            <label>
                                              Your name*{" "}
                                              <span className="dec-icon">
                                                <i className="fa-solid fa-user" />
                                              </span>
                                            </label>
                                            <input
                                              name="phone"
                                              type="text"
                                              onclick="this.select()"
                                              defaultValue
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label>
                                              Yourmail*{" "}
                                              <span className="dec-icon">
                                                <i className="fas fa-envelope" />
                                              </span>
                                            </label>
                                            <input
                                              name="reviewwname"
                                              type="text"
                                              onclick="this.select()"
                                              defaultValue
                                            />
                                          </div>
                                        </div> */}
                                        <textarea
                                          cols={40}
                                          rows={3}
                                          placeholder="Your Review:"
                                          name='content'
                                          defaultValue={""}
                                          required={true}
                                          onChange={(e) => {
                                            handleReviewChange(e)
                                          }}
                                        />
                                      </fieldset>
                                      {user.id !== profile.id ? <button
                                        className="btn big-btn color-bg float-btn text-white"
                                        type='submit'
                                      >
                                        Submit Review{" "}
                                        <i
                                          className="fa fa-paper-plane-o"
                                          aria-hidden="true"
                                        />
                                      </button> : <></>}
                                    </form>
                                  </div>
                                  {/* Add Review Box / End */}
                                </div>
                                {/* list-single-main-item end */}
                              </div>
                            </div>
                          </div>
                          {/*tab end*/}
                        </div>
                        {/*tabs end*/}
                      </div>
                      {/* content-tabs-wrap end */}
                    </div>
                    {/* col-md 8 end */}
                    {/*  sidebar*/}
                    <div className="col-md-4">
                      {/*box-widget*/}
                      <div className="box-widget bwt-first fl-wrap">
                        <div className="box-widget-title fl-wrap box-widget-title-color color-bg no-top-margin">
                          Agent Contacts
                        </div>
                        <div className="box-widget-content fl-wrap">
                          <div className="contats-list clm fl-wrap">
                            <ul className="no-list-style">
                              <li>

                                <span onClick={() => { openWhatsApp(profile?.userDetails?.[0]?.phone) }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <i className="fa-brands fa-whatsapp" /> Whatsapp :
                                </span>{" "}
                                <a
                                  onClick={() => { openWhatsApp(profile?.userDetails?.[0]?.phone) }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {profile?.userDetails?.[0]?.phone}
                                </a>

                              </li>
                              <li>
                                <span>
                                  <i className="fa-solid fa-envelope" /> Mail :
                                </span>{" "}
                                <a href="#">
                                  {profile?.email}
                                </a>
                              </li>
                              <li>
                                <span>
                                  <i className="fa-solid fa-map-marker" />{" "}
                                  Adress :
                                </span>{" "}
                                <a href="#">{profile?.userDetails?.[0]?.address}</a>
                              </li>
                              <li>
                                <span>
                                  <i className="fa-solid  fa-globe" /> Website
                                  :
                                </span>{" "}
                                <a href="#">{profile?.userDetails?.[0]?.website}</a>
                              </li>
                            </ul>
                          </div>
                          <div className="profile-widget-footer fl-wrap">
                            <div className="card-info-content_social ">
                              <ul>
                                <li>
                                  <a href={profile?.userDetails?.[0]?.facebook} target="_blank">
                                    <i className="fab fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href={profile?.userDetails?.[0]?.twitter} target="_blank">
                                    <i className="fab fa-twitter" />
                                  </a>
                                </li>
                                <li>
                                  <a href={profile?.userDetails?.[0]?.instagram} target="_blank">
                                    <i className="fab fa-instagram" />
                                  </a>
                                </li>
                                {/* <li>
                                  <a href="#" target="_blank">
                                    <i className="fab fa-vk" />
                                  </a>
                                </li> */}
                              </ul>
                            </div>
                            <a
                              href="#sec-contact"
                              className="custom-scroll-link tolt csls"
                              data-microtip-position="left"
                              data-tooltip="Write Message"
                            >
                              <i className="fa-solid fa-paper-plane" />
                            </a>
                          </div>
                        </div>
                      </div>
                      {/*box-widget end */}
                      {/*box-widget*/}
                      {profile?.id !== user.id || !user.id ? <div className="box-widget fl-wrap">
                        <div
                          className="box-widget-fixed-init fl-wrap"
                          id="sec-contact"
                        >
                          <div className="box-widget-title fl-wrap box-widget-title-color color-bg no-top-margin">
                            Get In Touch
                          </div>
                          <div className="box-widget-content fl-wrap">
                            <div className="custom-form">
                              {/* <form
                                method="post"
                                name="contact-property-form"
                                onSubmit={handleSubmit}
                              >
                                <label>
                                  Your name*{" "}
                                  <span className="dec-icon">
                                    <FontAwesomeIcon icon="fa-solid  fa-user" />
                                  </span>
                                </label>
                                <input
                                  name="name"
                                  type="text"
                                  value={formData.name}
                                  onChange={(e) => {
                                    handleChange(e)
                                  }}
                                  required={true}
                                />
                                <label>
                                  Your Email *{" "}
                                  <span className="dec-icon">
                                    <FontAwesomeIcon icon="fa-solid  fa-envelope" />
                                  </span>
                                </label>
                                <input
                                  name="email"
                                  type="text"
                                  value={formData.email}
                                  onChange={(e) => {
                                    handleChange(e)
                                  }}
                                  required={true}
                                />
                                <label>
                                  Your Message *{" "}
                                  <span className="dec-icon">
                                    <FontAwesomeIcon icon="fa-solid  fa-phone" />
                                  </span>
                                </label>
                                <input
                                  name="message"
                                  type="text"
                                  value={formData.message}
                                  onChange={(e) => {
                                    handleChange(e)
                                  }}
                                  required={true}
                                />
                                <textarea
                                  cols={40}
                                  rows={3}
                                  placeholder="Your Message:"
                                  defaultValue={""}
                                  style={{ width: "100%", height: 150 }}
                                />
                                <button
                                  type="submit"
                                  className="btn float-btn color-bg fw-btn"
                                // onClick={(e) => {
                                //     handleSubmit(e)
                                // }}
                                >
                                  {" "}
                                  Send
                                </button>
                              </form> */}
                              <form
                                method="post"
                                name="contact-property-form"
                                onSubmit={handleSubmit}
                              >
                                {/* <label>
                                  Your name*{" "}
                                  <span className="dec-icon">
                                    <FontAwesomeIcon icon="fa-solid fa-user" />
                                  </span>
                                </label>
                                <input
                                  name="name"
                                  type="text"
                                  value={formData.name}
                                  onChange={(e) => {
                                    handleChange(e)
                                  }}
                                  required={true}
                                />
                                <label>
                                  Your Email *{" "}
                                  <span className="dec-icon">
                                    <FontAwesomeIcon icon="fa-solid  fa-envelope" />
                                  </span>
                                </label>
                                <input
                                  name="email"
                                  type="text"
                                  value={formData.email}
                                  onChange={(e) => {
                                    handleChange(e)
                                  }}
                                  required={true}
                                /> */}
                                <textarea
                                  cols={40}
                                  rows={3}
                                  placeholder="Your Message:"
                                  name="message"
                                  value={formData.message}
                                  onChange={(e) => {
                                    handleChange(e)
                                  }}
                                  required={true}
                                  style={{ width: "100%", height: 150 }}
                                />
                                <button
                                  type="submit"
                                  className="btn float-btn color-bg fw-btn text-white"
                                >
                                  {" "}
                                  Send
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div> : <></>}
                      {/*box-widget end */}
                    </div>
                    {/*   sidebar end*/}
                  </div>
                </div>
                <div className="limit-box fl-wrap" />
              </section>
            </div>
            {/* content end */}
            {/* subscribe-wrap */}
            {/* <div className="subscribe-wrap fl-wrap">
              <div className="container">
                <div className="subscribe-container fl-wrap color-bg">
                  <div className="pwh_bg" />
                  <div className="mrb_dec mrb_dec3" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="subscribe-header">
                        <h4>newsletter</h4>
                        <h3>
                          Sign up for newsletter and get latest news and update
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-1" />
                    <div className="col-md-5">
                      <div className="footer-widget fl-wrap">
                        <div className="subscribe-widget fl-wrap">
                          <div className="subcribe-form">
                            <form id="subscribe">
                              <input
                                className="enteremail fl-wrap"
                                name="email"
                                id="subscribe-email"
                                placeholder="Enter Your Email"
                                spellCheck="false"
                                type="text"
                              />
                              <button
                                type="submit"
                                id="subscribe-button"
                                className="subscribe-button color-bg"
                              >
                                {" "}
                                Subscribe
                              </button>
                              <label
                                htmlFor="subscribe-email"
                                className="subscribe-message"
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* subscribe-wrap end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
          </div>
          {/* wrapper end */}
          {/*secondary-nav */}
          <div className="secondary-nav">
            <ul>
              <li>
                <a
                  href="dashboard-add-listing.html"
                  className="tolt"
                  data-microtip-position="left"
                  data-tooltip="Sell Property"
                >
                  <i className="fa-solid fa-truck-couch" />{" "}
                </a>
              </li>
              <li>
                <a
                  href="listing.html"
                  className="tolt"
                  data-microtip-position="left"
                  data-tooltip="Buy Property"
                >
                  {" "}
                  <i className="fa-solid fa-shopping-bag" />
                </a>
              </li>
              <li>
                <a
                  href="compare.html"
                  className="tolt"
                  data-microtip-position="left"
                  data-tooltip="Your Compare"
                >
                  <i className="fa-solid fa-exchange" />
                </a>
              </li>
            </ul>
            <div className="progress-indicator">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                <circle
                  cx={16}
                  cy={16}
                  r="15.9155"
                  className="progress-bar__background"
                />
                <circle
                  cx={16}
                  cy={16}
                  r="15.9155"
                  className="progress-bar__progress 
                      js-progress-bar"
                />
              </svg>
            </div>
          </div>
          {/*secondary-nav end */}
        </div >
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div >
    </>
  )
}

export default UserProfile;
