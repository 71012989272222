import React, { useEffect, useState, Component } from 'react'
import Slider from "react-slick";


function DiscoverCities() {
    const [city, setCity] = useState([]);

    console.log('city=>', city)

    const apiUrl = process.env.REACT_APP_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    // const Token = localStorage.getItem("Token");


    const settings = {
        className: "center",
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            );
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    useEffect(() => {
        async function getData() {
            try {
                const response = await fetch(`${apiUrl}/city/getAll`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                    },
                });
                const resData = await response.json();
                if (resData.status === "success") {
                    console.log(resData);
                    setCity(resData.data);
                }
            } catch (error) {
                throw new Error("Failed to fetch Data ", error.message);
            }
        }
        getData();
    }, [apiKey, apiUrl]);

    return (
        <section className="hidden-section no-padding-section">
            <div className="half-carousel-wrap">
                <div className="half-carousel-title color-bg">
                    <div className="half-carousel-title-item fl-wrap">
                        <h2>Discover cities</h2>
                        <h5>
                            Explore original city photos, drone imagery, resident reviews
                            and local information to see if properties are right for you
                        </h5>
                    </div>
                    <div className="pwh_bg" />
                </div>
                <div className="half-carousel-conatiner">
                    {/* <div className="half-carousel fl-wrap full-height"> */}
                    <div className="slider-container">
                        <Slider {...settings}>
                            {/* <div> */}
                            {city.map((item) =>
                                <div className="slick-item" key={item.id}>
                                    <div className="half-carousel-item fl-wrap">
                                        <div className="bg-wrap bg-parallax-wrap-gradien">
                                            <img className="bg" src={item.images[0]} alt={item.name} style={{ objectFit: 'cover' }} />
                                            {/* <div className="bg" data-bg="images/bg/long/1.jpg" /> */}
                                        </div>
                                        <div className="half-carousel-content">
                                            <div style={{
                                                position: 'absolute',
                                                left: "40px",
                                                padding: "8px 30px",
                                                borderRadius: "4px",
                                                zIndex: "10",
                                                fontSize: "11px",
                                                backgroundColor: 'black',
                                                color: 'white',
                                                top: "-110px"
                                            }}>{item.totalProperty} Properties</div>
                                            <h3>
                                                <a href={`/listing?search=${item?.states?.name}`}>Explore {item.states.name}</a>
                                            </h3>
                                            <p>
                                                {item.tagline}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* </div> */}
                            {/* <div>
                                    <h3>2</h3>
                                </div>
                                <div>
                                    <h3>3</h3>
                                </div>
                                <div>
                                    <h3>4</h3>
                                </div>
                                <div>
                                    <h3>5</h3>
                                </div>
                                <div>
                                    <h3>6</h3>
                                </div>
                                <div>
                                    <h3>7</h3>
                                </div>
                                <div>
                                    <h3>8</h3>
                                </div>
                                <div>
                                    <h3>9</h3>
                                </div> */}
                        </Slider>
                    </div>
                    {/* </div> */}
                </div>
            </div >
        </section >
    )
}

export default DiscoverCities
