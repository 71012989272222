import React, { useEffect, useState } from "react";
import SidePanel from "./SIdePanel";
import { toast } from "react-toastify";
import ProfileHeader from "./ProfileHeader";
import ProfileFooter from "./ProfileFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardMenu from "./DashboardMenu";

// {user?.userDetails?.[0]?.notes ? user?.userDetails?.[0]?.notes : (user?.agentDetails?.[0]?.notes ? user?.agentDetails?.[0]?.notes : (user?.agencyDetails?.[0]?.notes ? user?.agencyDetails?.[0]?.notes : 'About me'))}
function Profile() {

  const [user, setUser] = useState({})
  const [userDetails, setUserDetails] = useState({})
  // const [userNestedDetails, setUserNestedDetails] = useState({})
  // const [profilePhoto, setProfilePhoto] = useState(null);
  // const [cover, setCover] = useState(null);

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;

  const Token = localStorage.getItem("Token");

  const initialFormData = {
    name: "",
    userType: 0,
    address: "",
    // country: "207",
    // state: "",
    // city: "",
    // zip: "",
    email: "",
    phone: "",
    notes: "",
    website: "",
    agency: "",
    customerId: "",
    facebook: "",
    twitter: "",
    instagram: "",
    photo: null,
    coverImg: ""
  }

  const initialFormResetData = {
    email: "",
    customerId: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  }

  const [formData, setFormData] = useState(initialFormData)
  const [formResetData, setFormResetData] = useState(initialFormResetData)
  const [coverPhoto, setCoverPhoto] = useState(null)
  const [profilePhoto, setProfilePhoto] = useState(null)

  const handleRemoveClick = (e, setPic, picName) => {
    e.preventDefault();
    setPic(null);
    setFormData((prev) => ({ ...prev, [picName]: null }));
  }


  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
        formData.name = response.data.name
        formData.email = response.data.email
        formData.userType = response.data.userType
        console.log(response)
      } else if (!localStorage.getItem("Token")) {
        setUser({})
      }
    }
    getUser();
  }, [apiUrl, Token]);


  useEffect(() => {
    async function getUser() {
      if (!user?.id) {
        return;
      }
      const url = `${apiUrl}/profile/getById/${user.id}-${user.userType}`;


      let response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        console.log('notes =>', response.data)

        // setUserNestedDetails(response.data.userDetails[0])

        setUserDetails(response.data)

        console.log(response.data, 'photo')
        formData.phone = response.data?.userDetails?.[0]?.phone
        formData.website = response.data?.userDetails?.[0]?.website
        formData.address = response.data?.userDetails?.[0]?.address
        if (formData.photo !== "") {
          formData.photo = response.data?.userDetails?.[0]?.photo
          console.log(response.data?.userDetails?.[0]?.photo, 'photo')
        }
        formData.instagram = response.data?.userDetails?.[0]?.instagram
        formData.notes = response.data?.userDetails?.[0]?.notes
        formData.facebook = response.data?.userDetails?.[0]?.facebook
        formData.twitter = response.data?.userDetails?.[0]?.twitter
        formData.coverImg = response.data?.userDetails?.[0]?.coverImg
        console.log(response)
      } else if (!localStorage.getItem("Token")) {
        setUser({})
      }
    }
    getUser();
  }, [apiUrl, Token, user]);


  // A function to handle the change of the input fields
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    }
    else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // A function to handle the change of the input fields
  const handleResetChange = (e) => {
    const { name, value } = e.target;
    setFormResetData((prev) => ({ ...prev, [name]: value }));
  };


  // A function to handle the submit of the property form
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formData.customerId = user.id
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }
      console.log('formData =>', formData)

      const response = await fetch(`${apiUrl}/profile/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      // setFormData(initialFormData)
      // navigate("/Master");
      setTimeout(() => {
        window.location.href = "/profile";
      }, 2000)
    } catch (error) {
      toast.error(error.message);
    }
  };


  // A function to handle the submit of the property form
  const handleResetSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formResetData.customerId = user.id
      for (const key in formResetData) {
        if (formResetData[key] !== null) {
          formDataToSend.append(key, formResetData[key]);
        }
      }

      const response = await fetch(`${apiUrl}/employee/reset-password`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      // setFormData(initialFormData)
      // navigate("/Master");
      setTimeout(() => {
        window.location.href = "/profile";
      }, 2000)
    } catch (error) {
      toast.error(error.message);
    }
  };

  console.log('formData =>', formData)

  return (
    <>
      <div>
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />

        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />

        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* put header here */}
          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            <SidePanel />
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div
              className="dashboard-content"
            // style={{ padding: "40px 50px 70px 351px" }}
            >
              <DashboardMenu user={user} />
              <div className="container dasboard-container">
                {/* dashboard-title */}
                <div className="dashboard-title fl-wrap">
                  <div className="dashboard-title-item">
                    <span>Edit Profile</span>
                  </div>
                  <ProfileHeader />
                  {/*Tariff Plan menu*/}
                  {/* <div className="tfp-det-container">
                    <div className="tfp-btn">
                      <span>Your Tariff Plan : </span> <strong>Extended</strong>
                    </div>
                    <div className="tfp-det">
                      <p>
                        You Are on <a href="#">Extended</a> . Use link bellow to
                        view details or upgrade.{" "}
                      </p>
                      <a href="#" className="tfp-det-btn color-bg">
                        Details
                      </a>
                    </div>
                  </div> */}
                  {/*Tariff Plan menu end*/}
                </div>
                {/* dashboard-title end */}
                {/* dasboard-wrapper*/}
                <div className="dasboard-wrapper fl-wrap no-pag">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="dasboard-widget-title fl-wrap">
                        <h5>
                          <i className="fa-solid fa-user-circle" />
                          Change Avatar
                        </h5>
                      </div>
                      <div className="dasboard-widget-box nopad-dash-widget-box fl-wrap">
                        <div className="edit-profile-photo">

                          {(formData.photo === "") && <img
                            src="https://hauzzi.webkype.net/images/avatar/5.jpg"
                            className="respimg"
                            alt="profile"
                          />}
                          {(formData.photo !== "" && !profilePhoto) && <img
                            src={`${apiUrl}/uploads/${formData.photo}`}
                            className="respimg"
                            alt="profile"
                          />}

                          {profilePhoto &&
                            <img
                              src={URL.createObjectURL(profilePhoto)}
                              alt="Selected File"
                              style={{
                                maxWidth: "100%",
                              }}
                            />
                          }

                          <div className="change-photo-btn">
                            <div className="photoUpload">
                              <span> Upload New Photo</span>
                              <input
                                type="file"
                                className="upload"
                                name="photo"
                                onChange={(e) => {
                                  const files = e.target.files;
                                  const photoFile = files[0];
                                  setProfilePhoto(photoFile)
                                  handleChange(e)
                                }}
                              />

                            </div>
                          </div>

                        </div>
                        <div className="bg-wrap bg-parallax-wrap-gradien">

                          {(formData.coverImg === "") && <div
                            className="bg"
                            data-bg="https://hauzzi.webkype.net/images/bg/3.jpg"
                          />}

                          {(formData.coverImg !== "") && <img
                            src={`${apiUrl}/uploads/${formData.coverImg}`}
                            className="respimg"
                            alt="profile"
                          />}

                          {coverPhoto &&
                            <img
                              src={URL.createObjectURL(coverPhoto)}
                              alt="Selected File"
                              style={{
                                maxWidth: "100%",
                              }}
                            />
                          }

                        </div>
                        <div className="change-photo-btn cpb-2  ">
                          <div className="photoUpload color-bg">
                            <span>
                              {" "}
                              <i className="fa-solid  fa-pen-to-square" /> Change Cover{" "}
                            </span>
                            {/* <input type="file" className="upload" /> */}
                            <input
                              type="file"
                              className="upload"
                              name="coverImg"
                              onChange={(e) => {
                                const files = e.target.files;
                                const coverFile = files[0];
                                setCoverPhoto(coverFile)
                                handleChange(e)
                              }}
                            />

                          </div>
                        </div>
                      </div>


                      <div className="dasboard-widget-title fl-wrap">
                        <h5>
                          <i className="fa-solid fa-key" />
                          Personal Info
                        </h5>
                      </div>
                      <div className="dasboard-widget-box fl-wrap">
                        <div className="custom-form">
                          <label>
                            User Type{" "}
                            <span className="dec-icon">
                              <i className="fa-solid fa-user" />
                            </span>
                          </label>
                          <select
                            className="selectBox"
                            name="userType"
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            disabled={true}
                          >
                            <option>Select</option>
                            <option value={0} selected={user?.userType === 0}>USER</option>
                            <option value={2} selected={user?.userType === 2}>AGENCY</option>
                            <option value={1} selected={user?.userType === 1}>AGENT</option>
                          </select>
                          <label>
                            Full Name{" "}
                            <span className="dec-icon">
                              <i className="fa-solid fa-user" />
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Full Name"
                            name="name"
                            defaultValue={user.name}
                            value={formData.name}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                          <label>
                            Email Address{" "}
                            <span className="dec-icon">
                              <i className="fa-solid fa-envelope" />
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Email"
                            name="email"
                            defaultValue={user.email}
                            value={formData.email}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                          <label>
                            Phone
                            <span className="dec-icon">
                              <i className="fa-solid fa-phone" />{" "}
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Phone"
                            name="phone"
                            defaultValue={userDetails?.phone}
                            value={formData.phone}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                          <label>
                            Adress{" "}
                            <span className="dec-icon">
                              <i className="fa-solid fa-map-marker" />{" "}
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Address"
                            name="address"
                            defaultValue={userDetails?.address}
                            value={formData.address}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                          <label>
                            Website{" "}
                            <span className="dec-icon">
                              <i className="fa-solid fa-globe" />{" "}
                            </span>
                          </label>
                          <input
                            type="text"
                            name="website"
                            placeholder="Enter Website"
                            value={formData.website}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                          {user.userType === 1 ? <label>
                            Agency
                            <span className="dec-icon">
                              <i className="fa-solid fa-home-lg-alt" />{" "}
                            </span>
                          </label> : <></>}
                          {user.userType === 1 ? <input
                            type="text"
                            name="agency"
                            placeholder="Enter Agency"
                            value={formData.agency}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          /> : <></>}
                          <label>Notes </label>
                          <textarea
                            cols={40}
                            rows={3}
                            name="notes"
                            placeholder='About Me'
                            defaultValue=""
                            style={{ marginBottom: 20 }}
                            value={formData.notes}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                          <button
                            className="btn color-bg float-btn"
                            onClick={(e) => {
                              handleSubmit(e)
                            }}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="dasboard-widget-title dbt-mm fl-wrap">
                        <h5>
                          <i className="fa-solid fa-key" />
                          Change Password
                        </h5>
                      </div>
                      <div className="dasboard-widget-box fl-wrap">
                        <div className="custom-form">
                          <div className="pass-input-wrap fl-wrap">
                            <label>
                              Current Password
                              <span className="dec-icon">
                                <i className="fa-solid fa-unlock" />
                              </span>
                            </label>
                            <input
                              type="password"
                              placeholder="enter password"
                              name="oldPassword"
                              value={formResetData.oldPassword}
                              onChange={(e) => {
                                handleResetChange(e)
                              }}
                            />
                            {/* <span className="eye">
                              <i
                                className="fa-solid fa-eye"
                                aria-hidden="true"
                              />{" "}
                            </span> */}
                          </div>
                          <div className="pass-input-wrap fl-wrap">
                            <label>
                              New Password
                              <span className="dec-icon">
                                <i className="fa-solid fa-lock" />
                              </span>
                            </label>
                            <input
                              type="password"
                              placeholder="enter new password"
                              name="newPassword"
                              value={formResetData.newPassword}
                              onChange={(e) => {
                                handleResetChange(e)
                              }}
                            />
                            {/* <span className="eye">
                              <i
                                className="fa-solid fa-eye"
                                aria-hidden="true"
                              />{" "}
                            </span> */}
                          </div>
                          <div className="pass-input-wrap fl-wrap">
                            <label>
                              Confirm New Password
                              <span className="dec-icon">
                                <i className="fa-solid fa-user-shield" />{" "}
                              </span>
                            </label>
                            <input
                              type="password"
                              placeholder="confirm password"
                              name="confirmPassword"
                              value={formResetData.confirmPassword}
                              onChange={(e) => {
                                handleResetChange(e)
                              }}
                            />
                            {/* <span className="eye">
                              <i
                                className="fa-solid fa-eye"
                                aria-hidden="true"
                              />{" "}
                            </span> */}
                          </div>
                          <button
                            className="btn color-bg float-btn text-white"
                            onClick={handleResetSubmit}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                      <div
                        className="dasboard-widget-title fl-wrap"
                        style={{ marginTop: 30 }}
                      >
                        <h5>
                          <i className="fa-solid fa-share-alt" />
                          Your Socials
                        </h5>
                      </div>
                      <div className="dasboard-widget-box fl-wrap">
                        <div className="custom-form">
                          <label>
                            Facebook{" "}
                            <span className="dec-icon">
                              <i className="fab fa-facebook" />
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder='https://www.facebook.com/'
                            name="facebook"
                            value={formData.facebook}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                          <label>
                            Twitter{" "}
                            <span className="dec-icon">
                              <i className="fab fa-twitter" />
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder='https://www.twitter.com/'
                            name="twitter"
                            value={formData.twitter}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                          <label>
                            Instagram
                            <span className="dec-icon">
                              <i className="fab fa-instagram" />
                            </span>
                          </label>
                          <input
                            type="text"
                            name="instagram"
                            placeholder='https://www.instagram.com/'
                            value={formData.instagram}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                          {/* <label>
                            Vkontakte
                            <span className="dec-icon">
                              <i className="fab fa-vk" />
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="https://vk.com/"
                            defaultValue
                          /> */}
                          <button
                            className="btn color-bg float-btn"
                            onClick={(e) => {
                              handleSubmit(e)
                            }}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* dasboard-wrapper end */}
              </div>
              {/* dashboard-footer */}
              <ProfileFooter />
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
      ;
    </>
  );
}

export default Profile;
