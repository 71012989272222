//-----------------------------------------------------------------------------------------------------------
//By default spanish lang.

import React, { useEffect } from 'react';

const GoogleTranslate = ({ setChangeLanguage }) => {

    useEffect(() => {
        const addGoogleTranslateScript = () => {
            if (!document.getElementById('google-translate-script')) {
                const script = document.createElement('script');
                script.id = 'google-translate-script';
                script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
                document.body.appendChild(script);
            }
        };

        const initGoogleTranslate = () => {
            if (window.google && window.google.translate) {
                new window.google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: 'es,en', autoDisplay: false }, 'google_translate_element');

                const selectLang = document.getElementsByClassName('goog-te-combo');
                if (selectLang.length > 0) {
                    const selectLangElem = selectLang[0];
                    selectLangElem.value = 'es';
                    selectLangElem.dispatchEvent(new Event('change'));

                    const changeLanguage = (language) => {
                        selectLangElem.value = language;
                        selectLangElem.dispatchEvent(new Event('change'));
                    };

                    setChangeLanguage(() => changeLanguage);
                }
            }
        };

        window.googleTranslateElementInit = initGoogleTranslate;
        addGoogleTranslateScript();
    }, [setChangeLanguage]);

    return <div style={{ maxHeight: '20px' }} id="google_translate_element"></div>;
};

export default GoogleTranslate;



//-----------------------------------------------------------------------------------------------------------


// import React, { useEffect, useRef } from 'react';


// const GoogleTranslate = ({ setChangeLanguage }) => {
//     const changeLanguageRef = useRef();

//     useEffect(() => {
//         const addGoogleTranslateScript = () => {
//             if (!document.getElementById('google-translate-script')) {
//                 const script = document.createElement('script');
//                 script.id = 'google-translate-script';
//                 script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//                 document.body.appendChild(script);
//             }
//         };

//         const initGoogleTranslate = () => {
//             if (window.google && window.google.translate) {
//                 new window.google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: 'es,en', autoDisplay: false }, 'google_translate_element');

//                 const selectLang = document.getElementsByClassName('goog-te-combo');
//                 if (selectLang.length > 0) {
//                     const selectLangElem = selectLang[0];
//                     selectLangElem.value = 'es';
//                     selectLangElem.dispatchEvent(new Event('change'));

//                     changeLanguageRef.current = (language) => {
//                         selectLangElem.value = language;
//                         selectLangElem.dispatchEvent(new Event('change'));
//                     };

//                     setChangeLanguage(changeLanguageRef.current);
//                 }
//             }
//         };

//         window.googleTranslateElementInit = initGoogleTranslate;
//         addGoogleTranslateScript();
//     }, [setChangeLanguage]);

//     return <div style={{ maxHeight: '20px' }} id="google_translate_element"></div>;
// };


// export default GoogleTranslate;


//-------------------------------------------------------------------------------------------------------------


// import React, { useEffect } from 'react';

// const GoogleTranslate = () => {

//     useEffect(() => {
//         const addGoogleTranslateScript = () => {
//             if (!document.getElementById('google-translate-script')) {
//                 const script = document.createElement('script');
//                 script.id = 'google-translate-script';
//                 script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//                 document.body.appendChild(script);
//             }
//         };

//         const initGoogleTranslate = () => {
//             if (window.google && window.google.translate) {
//                 new window.google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: 'en,es', autoDisplay: false }, 'google_translate_element');

//                 const selectLang = document.getElementsByClassName('goog-te-combo');
//                 if (selectLang.length > 0) {
//                     const selectLangElem = selectLang[0];
//                     selectLangElem.value = 'en';
//                     selectLangElem.dispatchEvent(new Event('change'));
//                 }
//             }
//         };

//         window.googleTranslateElementInit = initGoogleTranslate;
//         addGoogleTranslateScript();
//     }, []);

//     return <div style={{ maxHeight: '20px' }} id="google_translate_element"></div>;
// };

// export default GoogleTranslate;


//-------------------------------------------------------------------------------------------------------------

// import React, { useEffect } from 'react';
// import './Translate.css'

// const GoogleTranslate = () => {

//     useEffect(() => {
//         const addGoogleTranslateScript = () => {
//             if (!document.getElementById('google-translate-script')) {
//                 const script = document.createElement('script');
//                 script.id = 'google-translate-script';
//                 script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//                 document.body.appendChild(script);
//             }
//         };

//         const initGoogleTranslate = () => {
//             if (window.google && window.google.translate) {
//                 new window.google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: 'es,en', autoDisplay: false }, 'google_translate_element');

//                 const selectLang = document.getElementsByClassName('goog-te-combo');
//                 if (selectLang.length > 0) {
//                     const selectLangElem = selectLang[0];
//                     selectLangElem.addEventListener('change', () => {
//                         console.log(selectLangElem.value, 'selectLangElem.value');
//                     });
//                 }
//             }
//         };

//         window.googleTranslateElementInit = initGoogleTranslate;
//         addGoogleTranslateScript();
//     }, []);

//     return <div style={{ maxHeight: '20px' }} id="google_translate_element"></div>
//         ;
// };

// export default GoogleTranslate;

//-------------------------------------------------------------------------------------------------------------


document.addEventListener('DOMContentLoaded', () => {
    console.log('Hello!')
    const interval = setInterval(() => {
        const translateFrame = document.querySelector('.goog-te-banner-frame');
        const translateContainer = document.querySelector('.skiptranslate');

        if (translateFrame || translateContainer) {
            if (translateFrame) {
                translateFrame.style.setProperty('display', 'none', 'important');
            }
            if (translateContainer) {
                translateContainer.style.setProperty('display', 'none', 'important');
            }
            clearInterval(interval);
        }
    }, 500);
});





