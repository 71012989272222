import React, { useEffect, useState } from 'react'
import Footer from '../Header/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function SidePanel() {


  const [user, setUser] = useState({})

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");

  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
      } else {
        window.location.href = '/'
      }
    }
    getUser();
  }, [apiUrl, Token]);

  // useEffect(() => {
  //   async function getUser() {
  //     const url = `${apiUrl}/staff/getAll?userType=1`;


  //     let response = await fetch(url, {
  //       headers: {
  //         Authorization: `Bearer ${apiKey}`,
  //       },
  //     });
  //     response = await response.json();

  //     // if (response.status !== "error") {
  //     //   setUser(response.data)
  //     // } else {
  //     //   window.location.href = '/'
  //     // }
  //   }
  //   getUser();
  // }, [apiUrl, Token]);



  return (

    <>
      <div className="dashbard-menu-wrap" style={{ top: '20px' }}>
        <div className="dashbard-menu-close">
          <i className="fa-solid fa-times" />
        </div>
        <div className="dashbard-menu-container" style={{ height: '75vh', overflow: 'scroll', width: "22rem", scrollbarWidth: "none" }}>
          {
            /* user-profile-menu*/
          }
          <div className="user-profile-menu">
            <h3>BUYER OPERATIONS</h3>
            <ul className="no-list-style">
              <li>
                <a href="/dashboard" className="user-profile-act">
                  <FontAwesomeIcon icon="fa-solid fa-chart-line" />
                  {" "}{" "}Dashboard
                </a>
              </li>
              <li>
                <a href="profile">
                  <FontAwesomeIcon icon="fa-solid fa-user-edit" /> {" "}{" "}Profile
                </a>
              </li>
              {/* {user?.userType == 0 &&
                <li>
                  <a href="message">
                    <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}{" "} Messages
                  </a>
                </li>
              } */}
              {user?.userType === 0 && <li>
                <a href="review">
                  <FontAwesomeIcon icon="fa-solid fa-comment-alt" />{" "}{" "} My Reviews{" "}
                </a>
              </li>}
              {user?.userType === 0 && <li>
                <a href="agent">
                  <FontAwesomeIcon icon="fa-solid fa-users" />{" "}{" "} My Agents
                </a>
              </li>}
              {user?.userType === 0 && <li>
                <a href="agency">
                  <FontAwesomeIcon icon="fa-solid fa-users" />{" "}{" "} My Agencies
                </a>
              </li>}
              {user?.userType === 0 && <li>
                <a href="saved-property">
                  <FontAwesomeIcon icon="fa-solid fa-heart" />{" "}{" "} Saved Properties
                </a>
              </li>}
              {user?.userType === 0 && <li>
                <a href="enquiry">
                  {" "}
                  <FontAwesomeIcon icon="fa-solid fa-calendar-check" />{" "}{" "} My Enquiry{" "}
                </a>
              </li>}
              {user?.userType === 0 && <li>
                <a href="mortgage">
                  {" "}
                  <FontAwesomeIcon icon="fa-solid fa-calendar-check" />{" "}{" "} Apply For
                  Mortgage{" "}
                </a>
              </li>}
              {user?.userType === 0 && <li>
                <a href="mortgage-query">
                  {" "}
                  <FontAwesomeIcon icon="fa-solid fa-calendar-check" />{" "}{" "} Mortgage
                  Queries{" "}
                </a>
              </li>}
            </ul>
          </div>
          {
            /* user-profile-menu end*/
          }
          {
            /* user-profile-menu*/
          }
          <div className="user-profile-menu">
            <h3>SELLER OPERATIONS</h3>
            <ul className="no-list-style">
              <li>
                <a href="add">
                  <FontAwesomeIcon icon="fa-solid fa-th-list" />{" "}{" "} List Property{" "}
                </a>
              </li>
              <li>
                <a href="my-listing">
                  <FontAwesomeIcon icon="fa-solid fa-th-list" />{" "}{" "} My Listing{" "}
                </a>
              </li>
              <li>
                <a href="profile-enquiry">
                  <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}{" "} Profile Enquiry
                </a>
              </li>
              <li>
                <a href="booking">
                  <FontAwesomeIcon icon="fa-solid fa-file" />{" "}{" "} My Leads
                </a>
              </li>
              {/* {user?.userType !== 0 && <li> */}
              <li>
                <a href="customers">
                  <FontAwesomeIcon icon="fa-solid fa-file" /> {" "}{" "}Customers
                </a>
              </li>
              {/* </li>} */}
              {user?.userType === 1 && <li>
                <a href="agency">
                  <FontAwesomeIcon icon="fa-solid fa-file-plus" />{" "}{" "} My Agency
                </a>
              </li>}
              {user?.userType === 2 && <li>
                <a href="agent">
                  <FontAwesomeIcon icon="fa-solid fa-file-plus" />{" "}{" "} Agent Team
                </a>
              </li>}
            </ul>
          </div>
          {
            /* user-profile-menu end*/
          }
        </div>
        <div className="dashbard-menu-footer" >
          {" "}
          © Webkype 2024 . All rights reserved.
        </div>
      </div>
    </>

  )
}


export default SidePanel
