import React from "react";
import StarRatings from "react-star-ratings";
import HeroSection from "./HeroSection";
import Footer from "../Header/Footer";




function PrivacyPolicy() {
    return (
        <div>
            <meta charSet="UTF-8" />
            <title>Hauzzi Beta Website UI/UX design by Webkype </title>
            <meta name="robots" content="index, follow" />

            <link type="text/css" rel="stylesheet" href="css/plugins.css" />
            <link type="text/css" rel="stylesheet" href="css/style.css" />
            <link type="text/css" rel="stylesheet" href="css/color.css" />

            <link
                rel="shortcut icon"
                href="https://hauzzi.webkype.net/images/favicon.ico"
            />

            {/*loader*/}
            <div className="loader-wrap">
                <div className="loader-inner">
                    <svg>
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur
                                    in="SourceGraphic"
                                    stdDeviation={2}
                                    result="blur"
                                />
                                <feColorMatrix
                                    in="blur"
                                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                                    result="gooey"
                                />
                                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div>
            {/*loader end*/}
            {/* main */}
            <div id="main">
                {/* header */}
                {/* put Header here */}

                {/* wrapper  */}
                <div id="wrapper p-0">
                    {/* content */}
                    <div className="content">
                        {/*  section  */}
                        {/*  section  */}
                        <HeroSection />
                        {/*  section  end*/}
                        {/*  section  end*/}
                        {/* breadcrumbs*/}
                        <div className="breadcrumbs fw-breadcrumbs sp-brd fl-wrap">
                            <div className="container">
                                <div className="breadcrumbs-list">
                                    <a href="#">Home</a> <span>Privacy Policy</span>
                                </div>
                                <div className="share-holder hid-share">
                                    <a href="#" className="share-btn showshare sfcs">
                                        {" "}
                                        <i className="fa-solid fa-share-alt" /> Share{" "}
                                    </a>
                                    <div className="share-container  isShare" />
                                </div>
                            </div>
                        </div>
                        {/* breadcrumbs end */}
                        {/* section */}
                        <section>
                            <div className="container">

                                <div className="about-wrap">
                                    <div className="row">
                                        <div className="about-title fl-wrap">
                                            <h2>
                                                Privacy <span>Policy</span>
                                            </h2>
                                        </div>

                                        {/* <div className="col-md-9">
                                            <div className="about-title fl-wrap">
                                                <h2>
                                                    Privacy <span>Policy</span>
                                                </h2>
                                            </div>
                                            <h4 style={{ marginBottom: '20px' }}>
                                                Additional Information on Data Protection
                                            </h4>
                                            <p>

                                                This Privacy Policy describes how personal data provided by you, the User, is collected, used, stored, shared, and protected through the public and private areas, as applicable, of the Hauzzi website (hereinafter referred to as the "Website") and mobile applications (hereinafter referred to as the "Mobile Application").
                                            </p>
                                            <p>
                                                Your personal data will be processed efficiently, proportionately, and responsibly, in strict compliance with the provisions of Regulation (EU) 679/2016 General Data Protection Regulation ("GDPR"), Organic Law 3/2018 on Data Protection and Digital Rights Guarantee ("LOPDGDD"), and any other applicable regulations. Below, we provide you with all the information you need to know, clearly and transparently, about how we process your personal data and protect your privacy.
                                            </p>

                                            <p>
                                                As a User, you must ensure: (a) the authenticity and accuracy of all data (especially, but not limited to, personal data) communicated or provided through the Website or the Mobile Application; (b) that the data is personal to you; and (c) that you will keep the provided information updated to reflect your current situation, being solely responsible for any false or inaccurate statements made and the resulting damages.
                                            </p>

                                            <h4 style={{ marginBottom: '20px' }}>
                                                DATA CONTROLLER
                                            </h4>
                                            <p>Who is responsible for processing your data?</p>
                                            <ul style={{ textAlign: 'start' }}>
                                                <li>Identity: Hauzzi OU.</li>
                                                <li>  Address:</li>
                                                <li>Phone:</li>
                                                <li>Email: privacidad@hauzzi.com</li>
                                                <li>Data Protection Officer Contact: privacidad@hauzzi.com</li>
                                            </ul>

                                            <h4 style={{ marginBottom: '20px' }}>
                                                DATA CONTROLLER
                                            </h4>
                                            
                                    


                                            <a href="#" className="btn small-btn float-btn color-bg">
                                                Read More
                                            </a>
                                        </div> */}
                                        {/* <div className="col-md-1" />
                                        <div className="col-md-6">
                                            <div className="about-img fl-wrap">
                                                <img
                                                    src="https://hauzzi.webkype.net/images/all/18.jpg"
                                                    className="respimg"
                                                    alt
                                                />
                                                <div className="about-img-hotifer color-bg">
                                                    <p>
                                                        Your website is fully responsive so visitors can
                                                        view your content from their choice of device.
                                                    </p>
                                                    <h4>Mark Antony</h4>
                                                    <h5>Webkype CEO</h5>
                                                </div>
                                            </div>
                                        </div> */}
                                        <h4 style={{ marginBottom: '20px' }}>Additional Information on Data Protection</h4>
                                        <p>
                                            This Privacy Policy describes how personal data provided by you, the User, is collected, used, stored, shared, and protected through the public and private areas, as applicable, of the Hauzzi website (hereinafter referred to as the "Website") and mobile applications (hereinafter referred to as the "Mobile Application").
                                        </p>
                                        <p>
                                            Your personal data will be processed efficiently, proportionately, and responsibly, in strict compliance with the provisions of Regulation (EU) 679/2016 General Data Protection Regulation ("GDPR"), Organic Law 3/2018 on Data Protection and Digital Rights Guarantee ("LOPDGDD"), and any other applicable regulations. Below, we provide you with all the information you need to know, clearly and transparently, about how we process your personal data and protect your privacy.
                                        </p>
                                        <p>
                                            As a User, you must ensure: (a) the authenticity and accuracy of all data (especially, but not limited to, personal data) communicated or provided through the Website or the Mobile Application; (b) that the data is personal to you; and (c) that you will keep the provided information updated to reflect your current situation, being solely responsible for any false or inaccurate statements made and the resulting damages.
                                        </p>

                                        <h4 style={{ marginBottom: '20px' }}>DATA CONTROLLER</h4>
                                        <p>Who is responsible for processing your data?</p>
                                        <ul style={{ textAlign: 'start' }}>
                                            <li>Identity: Hauzzi OU.</li>
                                            <li>Address:</li>
                                            <li>Phone:</li>
                                            <li>Email: privacidad@hauzzi.com</li>
                                            <li>Data Protection Officer Contact: privacidad@hauzzi.com</li>
                                        </ul>

                                        <h4 style={{ marginBottom: '20px' }}>TYPES OF PROCESSED DATA</h4>
                                        <p>What personal information will we process?</p>
                                        <p>
                                            We manage your personal data to provide you with the services you have requested through your interactions on the Website and the Mobile Application, when requesting specific personal information. The personal data we process may include, but is not limited to: your name, surname, email address, profile photo, user ID, password, phone number, complete information of the properties you want to advertise (including their physical address), browsing data, banking or billing information, as appropriate, among others.
                                        </p>
                                        <p>
                                            All fields marked with an asterisk (*) on the forms are mandatory. Therefore, failure to provide or incorrect provision of such data could result in the impossibility of providing the requested services or information, without prejudice to your ability to continue viewing the content of the Website and/or the Mobile Application.
                                        </p>

                                        <h4 style={{ marginBottom: '20px' }}>PURPOSES OF PROCESSING AND LEGAL BASIS</h4>
                                        <p>How will we process your personal data and what enables us to do so?</p>
                                        <p>Regarding the list of services you may request and we may provide:</p>

                                        <h4 style={{ marginBottom: '20px' }}>3.1. REGISTRATION ON OUR PLATFORMS AND PROVISION OF OUR SERVICES</h4>
                                        <p>Management of the registration process as a Hauzzi user, allowing you to access our platform and benefit from Hauzzi's services by managing your user account. The legal basis will be the execution of a contract. If you choose to register on Hauzzi through your Google or Apple account, you will be directed from our platform to the Google or Apple website, where you will need to enter your account login details. The data we obtain from Google or Apple will be used to set up your account on Hauzzi. This means that we will use your Google or Apple username as your username on Hauzzi, visible to other users of the website and the mobile application, as appropriate. Any other data obtained from Google or Apple will not be visible to other platform users. You can unlink your account from your Google or Apple profile at any time. The collection and use of this data are based on your consent.</p>
                                        <p>Account and profile setup. The legal basis will be your consent.</p>
                                        <p>Save and manage your property searches. The legal basis will be your consent.</p>
                                        <p>Through our platform, you can directly contact advertisers, providing them with the personal data and information included in the contact form. The legal basis will be the execution of a contract.</p>
                                        <p>Management of sending alerts to inform you about the publication of ads that match saved search preferences. The legal basis will be your consent.</p>
                                        <p>Publication of your ads on the Hauzzi Website and/or Mobile Application. The legal basis will be the execution of a contract.</p>
                                        <p>Instant messaging service between users to facilitate transaction and communication between them. By using the contact form or chat in the user area, you will be using our messaging service. The legal basis will be the execution of a contract. Messages and ads are reviewed through manual and/or automatic processes. We do this to prevent fraud or abusive or inappropriate uses of our services, including the sale of illegal goods. If we detect suspicious or illegal activity or behavior, our team may review sent and received messages. In cases where we use automated processes, you have the right to have a person from Hauzzi intervene, expressing your point of view and filing a complaint if you disagree. The legal basis will be the legitimate interest.</p>
                                        <p>Request for support service from a real estate expert who will help you buy, sell, or rent a property. The legal basis for communicating your data to these experts will be your consent.</p>
                                        <p>Sending important and necessary communications related to the provision of our services, including reminders, technical notices, updates, security alerts, and administrative messages. The legal basis will be the execution of a contract.</p>
                                        <p>Analysis of user activity and behavior on the Website and the Mobile Application to improve and personalize your experience, as well as to develop new products, services, and functionalities. The legal basis will be your consent.</p>
                                        <p>Geolocation to show personalized content and ads and improve the quality of our services. The legal basis will be your consent. You can withdraw your consent at any time by disabling location permissions in your web browser or mobile application. Disabling this permission may affect Hauzzi's functionality, and you may not be able to access all the features offered by Hauzzi, such as accessing offers or requests for products or services near your location or offering them.</p>
                                        <p>To improve our services and adapt them to your preferences, interests, and needs, we analyze your behavior and interaction with Hauzzi. This includes analyzing your activity within our platform and the characteristics of the device you use, as well as the information we obtain through cookies and similar technologies when you access Hauzzi, such as the pages you visit or the searches you perform. The legal basis for this processing will be your consent.</p>
                                        <p>Provide information about the services offered by Hauzzi, send newsletters, promotional communications, and advertising related to the real estate sector, as well as news, recommendations, and advice on buying, selling, renting, and investing in properties. This may include suggestions for third-party products and services that may be of interest to you. The legal basis for sending this type of communication will be your consent. In any case, you can unsubscribe from these communications at any time.</p>
                                        <p>Customization and improvement of our services, such as adapting the content you see on the platform or improving our product and service recommendations, based on the analysis of your behavior, preferences, and interests. The legal basis will be your consent.</p>

                                        <h4 style={{ marginBottom: '20px' }}>3.2. PROCESSING OF DATA BY LEGITIMATE INTEREST</h4>
                                        <p>It is necessary to process certain data to fulfill our legitimate interests.</p>
                                        <p>Specifically, we will process your data:</p>
                                        <ul style={{ textAlign: 'start' }}>
                                            <li>To manage the relationship with you as a Hauzzi user and for administrative and accounting management, such as billing and collection management. The legal basis will be the legitimate interest pursued by Hauzzi.</li>
                                            <li>To conduct satisfaction surveys, analysis, and statistics to assess the quality of the services provided, as well as to detect improvements that can be made to our services. The legal basis will be the legitimate interest pursued by Hauzzi.</li>
                                            <li>For the development, maintenance, and improvement of our systems, including technical maintenance of the Website and the Mobile Application. The legal basis will be the legitimate interest pursued by Hauzzi.</li>
                                            <li>For the prevention and detection of fraud and illegal activities on the Website and the Mobile Application. The legal basis will be the legitimate interest pursued by Hauzzi.</li>
                                        </ul>

                                        <h4 style={{ marginBottom: '20px', marginTop: '10px' }}>3.3. DATA PROCESSING FOR COMPLIANCE WITH LEGAL OBLIGATIONS</h4>
                                        <p>We are required to process certain data to comply with our legal obligations.</p>
                                        <p>Specifically, we will process your data:</p>
                                        <ul style={{ textAlign: 'start' }}>
                                            <li>To comply with legal obligations related to tax, commercial, and consumer protection matters. The legal basis will be compliance with legal obligations.</li>
                                        </ul>

                                        <h4 style={{ marginBottom: '20px', marginTop: '10px'  }}>DATA RECIPIENTS</h4>
                                        <p>To which recipients will your data be communicated?</p>
                                        <p>In general, we will not transfer your data to third parties unless we are legally obliged to do so or you have given your prior consent.</p>
                                        <p>Your data may be communicated to other users interested in your ads or with whom you decide to communicate through the chat service.</p>
                                        <p>Additionally, we may transfer your data to service providers who assist us in providing our services (such as hosting, maintenance, support, analysis, communications, infrastructure, or similar services). These service providers may only process your data in accordance with our instructions and for the purposes described in this Privacy Policy.</p>
                                        <p>We may also transfer your data to other companies within the Hauzzi Group, for internal administrative purposes.</p>

                                        <h4 style={{ marginBottom: '20px', marginTop: '10px'  }}>INTERNATIONAL DATA TRANSFERS</h4>
                                        <p>We do not transfer your data internationally.</p>

                                        <h4 style={{ marginBottom: '20px', marginTop: '10px'  }}>STORAGE PERIOD</h4>
                                        <p>How long will we retain your data?</p>
                                        <p>We will keep your personal data for as long as necessary to fulfill the purposes for which it was collected. In general, the personal data you provide during the registration process will be retained for the duration of your relationship with Hauzzi and, once terminated, for the time necessary to comply with our legal obligations.</p>
                                        <p>Once this period has elapsed, your data will be blocked and will only be retained for the time required by law and until any liabilities arising from the processing have expired.</p>

                                        <h4 style={{ marginBottom: '20px', marginTop: '10px'  }}>RIGHTS OF DATA SUBJECTS</h4>
                                        <p>What rights do you have regarding the processing of your data?</p>
                                        <p>As a user, you have the right to:</p>
                                        <ul style={{ textAlign: 'start' }}>
                                            <li>Access your personal data.</li>
                                            <li>Rectify inaccurate data or, where appropriate, request its deletion when the data is no longer necessary for the purposes for which it was collected.</li>
                                            <li>Request the limitation of its processing, in which case we will only keep it for the exercise or defense of claims.</li>
                                            <li>Object to the processing of your data, in certain circumstances and for reasons related to your particular situation.</li>
                                            <li>Request the portability of your data, which will be provided in a structured, common, and machine-readable format.</li>
                                            <li>Revoke the consents given.</li>
                                        </ul>
                                        <p>To exercise your rights, you can send a written request, attaching a copy of your ID card or equivalent identification document, to the postal or email address indicated in section 1 of this Privacy Policy. Additionally, we inform you that you have the right to lodge a complaint with the competent data protection authority if you consider that the processing of your personal data breaches applicable regulations.</p>

                                        <h4 style={{ marginBottom: '20px', marginTop: '10px'  }}>CONFIDENTIALITY AND SECURITY OF DATA</h4>
                                        <p>We are committed to treating your personal data with the utmost confidentiality and to implementing the necessary technical and organizational measures to ensure the security of your data and prevent its alteration, loss, treatment, or unauthorized access, taking into account the state of technology, the nature of the data stored, and the risks to which they are exposed.</p>
                                        <p>In this regard, we have implemented appropriate security measures to protect your personal data, including security protocols, firewalls, data encryption, and physical access controls to our facilities.</p>

                                        <h4 style={{ marginBottom: '20px', marginTop: '10px'  }}>MODIFICATIONS TO THE PRIVACY POLICY</h4>
                                        <p>We reserve the right to modify this Privacy Policy at any time, based on changes in applicable legislation, our internal policies, or the services we offer. Any substantial changes to this Privacy Policy will be notified to Users through the Website and/or the Mobile Application or by electronic communications. It is recommended to periodically review this Privacy Policy to be informed about how we protect your personal data.</p>

                                        <h4 style={{ marginBottom: '20px', marginTop: '10px'  }}>ACCEPTANCE OF THE PRIVACY POLICY</h4>
                                        <p>By using the Website and/or the Mobile Application, you expressly accept this Privacy Policy and agree to the processing of your personal data in accordance with its provisions.</p>
                                        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the processing of your personal data, please do not hesitate to contact us through the contact details provided in section 1 of this Privacy Policy.</p>

                                        <ul style={{ textAlign: 'start' }}>
                                            <li>Date of entry into force:

                                            </li>
                                            <li>Date of last update:</li>
                                        </ul>

                                    </div>
                                </div>
                                {/* about-wrap end  */}
                            </div>
                        </section>
                        {/* section end*/}
                    </div>
                    {/* content end */}
                    {/* subscribe-wrap */}
                    {/* <div className="subscribe-wrap fl-wrap">
                        <div className="container">
                            <div className="subscribe-container fl-wrap color-bg">
                                <div className="pwh_bg" />
                                <div className="mrb_dec mrb_dec3" />
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="subscribe-header">
                                            <h4>newsletter</h4>
                                            <h3>
                                                Sign up for newsletter and get latest news and update
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-1" />
                                    <div className="col-md-5">
                                        <div className="footer-widget fl-wrap">
                                            <div className="subscribe-widget fl-wrap">
                                                <div className="subcribe-form">
                                                    <form id="subscribe">
                                                        <input
                                                            className="enteremail fl-wrap"
                                                            name="email"
                                                            id="subscribe-email"
                                                            placeholder="Enter Your Email"
                                                            spellCheck="false"
                                                            type="text"
                                                        />
                                                        <button
                                                            type="submit"
                                                            id="subscribe-button"
                                                            className="subscribe-button color-bg"
                                                        >
                                                            {" "}
                                                            Subscribe
                                                        </button>
                                                        <label
                                                            htmlFor="subscribe-email"
                                                            className="subscribe-message"
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* subscribe-wrap end */}
                    {/* footer */}
                    <Footer />
                    {/* footer end */}
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
