import React from "react";
import HeroSection from "./HeroSection";
import Footer from "../Header/Footer";
import "./TermsOfUse.css"


function TermsOfUse() {
    return (
        <div>

            <link type="text/css" rel="stylesheet" href="css/plugins.css" />
            <link type="text/css" rel="stylesheet" href="css/style.css" />
            <link type="text/css" rel="stylesheet" href="css/color.css" />

            <link
                rel="shortcut icon"
                href="https://hauzzi.webkype.net/images/favicon.ico"
            />
            {/*loader*/}
            <div className="loader-wrap">
                <div className="loader-inner">
                    <svg>
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur
                                    in="SourceGraphic"
                                    stdDeviation={2}
                                    result="blur"
                                />
                                <feColorMatrix
                                    in="blur"
                                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                                    result="gooey"
                                />
                                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div>
            {/*loader end*/}
            {/* main */}
            <div id="main">
                {/* header */}
                {/* put Header here */}

                {/* wrapper  */}
                <div id="wrapper p-0">
                    {/* content */}
                    <div className="content">
                        {/*  section  */}
                        {/*  section  */}
                        <HeroSection />
                        {/*  section  end*/}
                        {/*  section  end*/}
                        {/* breadcrumbs*/}
                        <div className="breadcrumbs fw-breadcrumbs sp-brd fl-wrap">
                            <div className="container">
                                <div className="breadcrumbs-list">
                                    <a href="#">Home</a> <span>Terms Of Use</span>
                                </div>
                                <div className="share-holder hid-share">
                                    <a href="#" className="share-btn showshare sfcs">
                                        {" "}
                                        <i className="fa-solid fa-share-alt" /> Share{" "}
                                    </a>
                                    <div className="share-container  isShare" />
                                </div>
                            </div>
                        </div>
                        {/* breadcrumbs end */}
                        {/* section */}
                        {/* <div className="about-title fl-wrap">
                            <h2>
                                Our Company <span>Terms</span>
                            </h2>
                        </div> */}
                        <div className="container">
                            <div className="about-wrap">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="about-title fl-wrap" style={{ marginTop: '20px' }}>
                                            <h2>
                                                Our Company <span>Terms</span>
                                            </h2>
                                        </div>
                                        <section style={{ padding: '20px 0px' }} >
                                            <div className="section summary" style={{ marginBottom: '20px', textAlign: 'start', padding: '0px' }}>
                                                <h2 style={{ fontWeight: '600', fontSize: 'medium', margin: '2px' }}>Summary Version for When You're Short on Time</h2>
                                                <p>We aim to provide clarity and transparency about Hauzzi. We understand that legal texts are important but can be cumbersome. To make it easier for you, here’s a brief overview of who we are, what services we offer, and what you can and cannot do:</p>
                                                <h3>Who Are We?</h3>
                                                <p>Hauzzi (Hauzzi, OU, our legal name) is the company that manages this website and apps. ID: TO BE DEFINED</p>
                                                <h3>What Services Do We Offer?</h3>
                                                <p>We provide a platform for you to publish or search for property sale or rental listings, whether it's an apartment, warehouse, or land, to name a few. We also offer other real estate-related services.</p>
                                                <h3>What Can and Can’t You Do on Hauzzi?</h3>
                                                <p>You can browse the website and apps, register to save searches and favorites, contact advertisers, post properties for sale or rent, and purchase additional services.</p>
                                                <p>You cannot harm third parties or Hauzzi, engage in illegal activities, use automated mechanisms to copy or extract our content, engage in fraudulent contacts, or use other users’ access keys without their permission.</p>
                                                <h3>Who Owns the Content on the Website and Apps?</h3>
                                                <p>Hauzzi owns all the content, images, trademarks, logos, videos, texts, etc., that appear on the website and apps (or they belong to third parties who have given us permission). Additionally, by sharing material on Hauzzi, you authorize us to continue using it even after your listing has been deactivated, for our price reports, historical market references, or for the Hauzzi/maps service, for example.</p>
                                                <h3>What’s Next?</h3>
                                                <p>Now comes the part that will test your command of legal jargon, which binds us. Perhaps it’s a good time to grab a coffee. Here we go:</p>
                                            </div>

                                            <div className="section content" style={{ marginBottom: '20px', textAlign: 'start', padding: '0px' }}>
                                                <h2 style={{ fontWeight: '600', fontSize: 'medium', margin: '2px' }}>Object and General Information</h2>
                                                <p>From Hauzzi, OU (hereinafter, “Hauzzi”, “we”, or “us”), we provide these terms and conditions (hereinafter, the “General Conditions”) which regulate:</p>
                                                <ul>
                                                    <li>The access, use, navigation, or registration to the website located under the domain www.Hauzzi.com and its respective subdomains, or mobile applications, owned by Hauzzi (hereinafter, the “Website and Apps”).</li>
                                                    <li>The download and use of any information, text, images, or other content you can access from the Website and Apps.</li>
                                                </ul>
                                                <p>Our goal is for you to better understand what we do, how our Website and Apps work, and the services we offer. At Hauzzi, we value our users and the trust you place in us when using our services or accessing the Website and Apps is fundamental. Therefore, we want to reciprocate that trust by providing you with information about our services in a simple and easy-to-understand manner.</p>
                                            </div>

                                            <div className="section content" style={{ marginBottom: '20px', textAlign: 'start', padding: '0px' }}>
                                                <h2>What is www.Hauzzi.com?</h2>
                                                <p>Our website, hosted under the domain www.Hauzzi.com and its subdomains, contains a technological platform that we provide to our users to publish real estate sale or rental listings or search for a property (hereinafter, the “Main Services”).</p>
                                                <p>Hauzzi merely facilitates contact between those users offering a property and those looking for one. We do not intervene in the transactions that may arise from these contacts. We are not a real estate agency nor do we engage in the sale and/or rental of properties. In this context, we offer some related additional services (hereinafter, the “Additional Services”, and together with the “Main Services”, the “Services”). You can obtain more information about our Additional Services in clause 7 of these General Conditions.</p>
                                            </div>

                                            <div className="section content" style={{ marginBottom: '20px', textAlign: 'start', padding: '0px' }}>
                                                <h2>Who is Hauzzi?</h2>
                                                <p>Hauzzi is the owner of this Website and Apps, with the following company details:</p>
                                                <ul>
                                                    <li>Company name: Hauzzi, OU</li>
                                                    <li>Tax Identification Number:</li>
                                                    <li>Registered in:</li>
                                                    <li>Registered office:</li>
                                                </ul>
                                                <p>You can contact us at any time by sending an email to privacidad@hauzzi.com or contacting us through our website.</p>
                                            </div>

                                            <div className="section content" style={{ marginBottom: '20px', textAlign: 'start', padding: '0px' }}>
                                                <h2 style={{ fontWeight: '600', fontSize: 'medium', margin: '2px' }}>Access and Acceptance of the General Conditions</h2>
                                                <p>By accessing or using the Website and Apps, you do so at your own and exclusive responsibility, granting you the status of user (hereinafter, “User” or “Users”), and it implies that you have read, understand, and expressly agree to comply without reservation with these General Conditions as published at each moment. In the case that you use services requiring registration, either personally or professionally, you will become a registered user (hereinafter, “Registered User” or “Registered Users”), and it will equally imply that you accept the particular conditions that may replace, complete, and/or modify these General Conditions (hereinafter, the “Particular Conditions”), as applicable in each case.</p>
                                                <p>We reserve the right to expand, modify, replace, and/or cancel our Services and/or these General Conditions at any time, without prior notice and without any liability or compensation on Hauzzi’s part. We will do our best to notify you of any changes to the General Conditions. In particular, if you are a Registered User, we will notify you of any changes to the General Conditions via email to the email address you provided during your registration. We will also update the “last updated date” located at the top of these General Conditions. However, we advise you to frequently review these General Conditions to stay informed of any changes we may have made.</p>
                                                <p>If you access the Website and Apps or use the Services after any changes to the General Conditions, you will be expressly accepting the new General Conditions.</p>
                                                <p>If you do not agree with these General Conditions or any modifications that may be made to them, you must stop using the Website and Apps or the Services. If you continue to use them, you will be accepting the General Conditions.</p>
                                                <p>For clarification purposes, these General Conditions and, where applicable, the Particular Conditions, will apply to both individual and professional Users.</p>
                                            </div>

                                            <div className="section content" style={{ marginBottom: '20px', textAlign: 'start', padding: '0px' }}>
                                                <h2 style={{ fontWeight: '600', fontSize: 'medium', margin: '2px' }}>Minors</h2>
                                                <p>The Services offered by Hauzzi are not intended for minors under 18 years of age. By accessing or using our Website and Apps or Services, you declare and guarantee that you are over 18 years old.</p>
                                            </div>

                                            <div className="section content" style={{ marginBottom: '20px', textAlign: 'start', padding: '0px' }}>
                                                <h2 style={{ fontWeight: '600', fontSize: 'medium', margin: '2px' }}>Conditions of Use and Responsibility for the Use of the Website and Apps</h2>
                                                <p>Your use of the Website and Apps must comply with these General Conditions, and where applicable, the Particular Conditions, the law in force at each moment, morality, good customs, and public order.</p>
                                                <p>You cannot make illegal or improper use that violates any provision of these General Conditions or, where applicable, the Particular Conditions. To facilitate your navigation and use of our Website and Apps, here is a list of what you can and cannot do:</p>
                                                <h3>You can:</h3>
                                                <ul>
                                                    <li>Access and browse the Website and Apps without needing to register and for free.</li>
                                                    <li>If you decide to register, use our two-step access system with verification to enhance the security of your account and ensure that no one else can access it without your consent.</li>
                                                    <li>Receive notifications of new properties or save properties that may interest you as favorites.</li>
                                                    <li>Contact the advertisers of the properties you are interested in.</li>
                                                    <li>Qualify your profile if you are specifically looking to rent a home or room.</li>
                                                    <li>Advertise properties for sale or rent that are your property or under your management.</li>
                                                    <li>Contract Additional Services to better position your properties.</li>
                                                    <li>Share content or material, such as images, videos, texts, opinions, etc., through the tools we provide.</li>
                                                    <li>Share the content of our Website and Apps on social networks through the tools we make available to you.</li>
                                                </ul>
                                                <h3>You cannot:</h3>
                                                <ul>
                                                    <li>Use the Website and Apps or their content in a way that infringes our rights or those of third parties.</li>
                                                    <li>Use the Website and Apps to send or distribute unsolicited communications or unauthorized commercial advertisements (spam).</li>
                                                    <li>Use the Website and Apps for fraudulent purposes, or connected with a criminal offense or other unlawful activity.</li>
                                                    <li>Interfere with the proper functioning of the Website and Apps or introduce viruses or other malware.</li>
                                                    <li>Attempt to gain unauthorized access to any part of the Website and Apps, servers, or databases connected to Hauzzi.</li>
                                                    <li>Impersonate any person or entity or otherwise misrepresent your affiliation with a person or entity.</li>
                                                </ul>
                                            </div>

                                            <div className="section content" style={{ marginBottom: '20px', textAlign: 'start', padding: '0px' }}>
                                                <h2 style={{ fontWeight: '600', fontSize: 'medium', margin: '2px' }}>Intellectual Property and Use of Content Provided by Users</h2>
                                                <p>All content available on the Website and Apps, including but not limited to, text, images, graphics, logos, videos, and software, are the property of Hauzzi or its content suppliers and are protected by intellectual property laws.</p>
                                                <p>By sharing content on the Website and Apps, you grant Hauzzi a non-exclusive, worldwide, royalty-free, perpetual license to use, reproduce, modify, adapt, publish, and display such content in connection with our services.</p>
                                                <p>Hauzzi reserves the right to remove or modify any content that violates these General Conditions or infringes upon the rights of any third party.</p>
                                            </div>

                                            <div className="section content" style={{ marginBottom: '20px', textAlign: 'start', padding: '0px' }}>
                                                <h2 style={{ fontWeight: '600', fontSize: 'medium', margin: '2px' }}>Privacy and Data Protection</h2>
                                                <p>We value your privacy and are committed to protecting your personal data. Please review our Privacy Policy to understand how we collect, use, and safeguard your information.</p>
                                            </div>

                                            <div className="section content" style = {{marginBottom:'20px',textAlign: 'start',padding:'0px'}}>
                                                <h2 style={{ fontWeight: '600', fontSize: 'medium', margin: '2px' }}>Limitation of Liability</h2>
                                                <p>Hauzzi is not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of the Website and Apps, including any damages for loss of profits, goodwill, data, or other intangible losses.</p>
                                                <p>We do not guarantee the accuracy, completeness, or usefulness of any content provided on the Website and Apps. Your use of the Website and Apps is at your sole risk.</p>
                                            </div>

                                            <div className="section content" style={{ marginBottom: '20px', textAlign: 'start', padding: '0px' }}>
                                                <h2 style={{ fontWeight: '600', fontSize: 'medium', margin: '2px' }}>Governing Law and Jurisdiction</h2>
                                                <p>These General Conditions and any disputes arising out of or relating to them will be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.</p>
                                                <p>Any legal actions or proceedings related to these General Conditions will be brought exclusively in the courts of [Jurisdiction], and you consent to the jurisdiction of such courts.</p>
                                            </div>

                                            <div className="section content" style={{ marginBottom: '20px', textAlign: 'start', padding: '0px' }}>
                                                <h2 style={{ fontWeight: '600', fontSize: 'medium', margin: '2px' }}>Contact Information</h2>
                                                <p>If you have any questions or concerns about these General Conditions, please contact us at privacidad@hauzzi.com.</p>
                                            </div>
                                        </section>

                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* section end*/}
                    </div>

                    <Footer />
                    {/* footer end */}
                </div>
            </div>
        </div>
    );
}

export default TermsOfUse;


{/* <div className="container">
    <div className="about-wrap">
        <div className="row">
            <div className="col-md-9">
                <div className="about-title fl-wrap">
                    <h2>
                        Our Company <span>Terms</span>
                    </h2>
                    <h4>
                    </h4>
                </div>
                <p>
                    Ut euismod ultricies sollicitudin. Curabitur sed dapibus
                    nulla. Nulla eget iaculis lectus. Mauris ac maximus
                    neque. Nam in mauris quis libero sodales eleifend. Morbi
                    varius, nulla sit amet rutrum elementum, est elit
                    finibus tellus, ut tristique elit risus at metus.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Maecenas in pulvinar neque. Nulla finibus lobortis
                    pulvinar. Donec a consectetur nulla. Nulla posuere
                    sapien vitae lectus suscipit, et pulvinar nisi
                    tincidunt. Aliquam erat volutpat. Curabitur convallis
                    fringilla diam sed aliquam. Sed tempor iaculis massa
                    faucibus feugiat. In fermentum facilisis massa, a
                    consequat purus viverra.
                </p>
                <a href="#" className="btn small-btn float-btn color-bg">
                    Read More
                </a>
            </div>
        </div>
    </div>
</div> */}