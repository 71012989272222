
import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

const API_KEY = process.env.REACT_APP_MAP_API_KEY;

function OfficeLocation({ propertyList }) {
  const [mapLoaded, setMapLoaded] = useState(false);

  const [defaultCenter, setDefaultCenter] = useState({
    lat: 40.41345088305735,
    lng: -3.708791763896553
  })

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
    script.async = true;
    script.onload = () => setMapLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const mapStyles = {
    height: "75vh",
    width: "100%"
  };

  return (
    <div>
      {mapLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={10}
          center={defaultCenter}
        >
          {/* {mapLoaded && <MarkerF position={defaultCenter} />}
          {mapLoaded && <MarkerF position={markerPosition} />} */}
          {propertyList.map((prop) => (
            <MarkerF
              key={prop.slug}
              position={{
                lat: +prop.latitude,
                lng: +prop.longitude
              }}
              data-toggle="tooltip"
              data-placement="top"
              title={`${prop.name}`}
              onClick={() => {
                window.location.href = prop.slug
              }}
            />
          ))}
        </GoogleMap>
      )}
      {!mapLoaded && <div>Loading Maps...</div>}
    </div>
  );
}

export default OfficeLocation;
