import React from "react";
import { useState, useEffect } from "react";
import Footer from "../Header/Footer";
import Testimonial from "../Home/Testimonial/Testimonial";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';



function CityLists() {
    const [data, setdata] = useState([]);
    const [testimonial, setTestimonial] = useState([])

    const apiUrl = process.env.REACT_APP_URL;
    const apiKey = process.env.REACT_APP_API_KEY;


    const TruncatedParagraph = (text, maxLength) => {
        const truncatedText =
            text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

        return truncatedText;
    };


    useEffect(() => {
        fetch(`${apiUrl}/testimonial/getAll`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    setTestimonial(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.log('Error fetching masters data:', error);
            });
    }, [apiKey])


    useEffect(() => {
        async function getData() {
            try {
                const response = await fetch(`${apiUrl}/city/getAll`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                    },
                });
                const resData = await response.json();
                if (resData.status === "success") {
                    console.log(resData);
                    setdata(resData.data);
                }
            } catch (error) {
                throw new Error("Failed to fetch Data ", error.message);
            }
        }
        getData();
    }, []);
    console.log(data);


    return (
        <>
            <div>
                {/*=============== basic  ===============*/}
                <meta charSet="UTF-8" />
                <title>Hauzzi Beta Website UI/UX design by Webkype </title>
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content />
                <meta name="description" content />
                {/*=============== css  ===============*/}
                <link type="text/css" rel="stylesheet" href="css/plugins.css" />
                <link type="text/css" rel="stylesheet" href="css/style.css" />
                <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
                <link type="text/css" rel="stylesheet" href="css/color.css" />
                {/*=============== favicons ===============*/}
                <link
                    rel="shortcut icon"
                    href="https://hauzzi.webkype.net/images/favicon.ico"
                />

                <div className="row" style={{ marginLeft: '10px' }}>

                    <div style={{
                        color: "#144273",
                        fontSize: "26px",
                        textAlign: "center",
                        fontWeight: "600",
                        paddingTop: "5px",
                        marginTop: "10px"
                    }}>
                        Latest Cities
                    </div>

                    <div className="col-md-8" style={{ margin: 'auto' }}>
                        {/* list-main-wrap-header*/}
                        <div className="list-main-wrap-header box-list-header fl-wrap" style={{ marginTop: '40px' }}>
                            {/* list-main-wrap-title*/}
                            <div className="list-main-wrap-title">
                                <h2>
                                    <span>City Listing </span>
                                    <strong>{data.length}</strong>
                                </h2>
                            </div>
                            {/* list-main-wrap-title end*/}
                            {/* list-main-wrap-opt*/}
                            <div className="list-main-wrap-opt">
                                {/* price-opt*/}
                                <div className="price-opt">
                                    <span className="price-opt-title">
                                        Sort by:
                                    </span>
                                    <div className="listsearch-input-item">
                                        <select
                                            data-placeholder="Popularity"
                                            className="chosen-select no-search-select"
                                        >
                                            <option>Popularity</option>
                                            <option>State</option>
                                            <option>City</option>
                                            <option>Tagline</option>
                                        </select>
                                    </div>
                                </div>
                                {/* price-opt end*/}
                            </div>
                            {/* list-main-wrap-opt end*/}
                        </div>
                        {/* list-main-wrap-header end*/}
                        {/* dashboard-listings-wrap*/}
                        <div className="listing-item-container one-column-grid-wrap  box-list_ic agency-list fl-wrap  dashboard-agency-list">
                            <div className="row" >
                                <div
                                    className="col-sm-12"
                                    style={{ display: "inline-grid" }}

                                >
                                    {data.length &&
                                        data.map((item, index) => (
                                            <div
                                                className="listing-item"
                                                key={index}
                                                style={{ width: "100%" }}
                                            >
                                                <article className="geodir-category-listing fl-wrap">
                                                    <div className="geodir-category-img fl-wrap">
                                                        <a
                                                            href={`/city-${item?.cities?.name}-${item?.cities?.id}`}
                                                            className="geodir-category-img_item"
                                                        >
                                                            <img
                                                                style={{
                                                                    height: "150px",
                                                                    width: "150px",
                                                                }}
                                                                key={index}
                                                                src={
                                                                    item.photo == ""
                                                                        ? "https://graphicsfamily.com/wp-content/uploads/edd/2020/04/Realstate-and-construction-logo-999x999.jpg"
                                                                        : `${apiUrl}/uploads/${item.photo}`
                                                                }
                                                                alt="image"

                                                            />
                                                        </a>
                                                    </div>
                                                    <div
                                                        className="geodir-category-content fl-wrap"
                                                        style={{ height: "170px" }}
                                                    >
                                                        {/* <div
                                              className="card-verified tolt"
                                              data-microtip-position="left"
                                              data-tooltip="Verified"
                                            >
                                              <i className="fa-solid fa-user-check" />
                                            </div> */}
                                                        <div className="agent_card-title fl-wrap">
                                                            <h4
                                                                onClick={(e) => {
                                                                    window.location.href = `/city-${item?.cities?.name}-${item?.cities?.id}`
                                                                }}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {item.cities.name}{" "}
                                                                <span
                                                                    style={{ color: "#808080" }}
                                                                >
                                                                    {item.states.name}
                                                                </span>{" "}
                                                                :{" "}
                                                                <span
                                                                    style={{
                                                                        color: "pink",
                                                                        fontSize: "11px",
                                                                    }}
                                                                >
                                                                    <i className="fa-solid fa-tag" />{" "}
                                                                    {item.tagline}
                                                                </span>
                                                            </h4>

                                                            <div className="geodir-category-location fl-wrap">
                                                                <div
                                                                    className="listing-rating card-popup-rainingvis"
                                                                    data-starrating2={4}
                                                                >
                                                                    <span
                                                                        className="re_stars-title"
                                                                        style={{
                                                                            left: "15px",
                                                                            width: "500px",
                                                                        }}
                                                                    >
                                                                        "
                                                                        {TruncatedParagraph(
                                                                            item.about,
                                                                            200
                                                                        )}
                                                                        "
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="small-facts fl-wrap"
                                                            style={{
                                                                marginTop: "50px",
                                                                paddingLeft: "320px",
                                                            }}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    <div 
                                                                    onClick={(e)=>{
                                                                        window.open(item.mapUrl)
                                                                    }}
                                                                    style={{cursor:'pointer'}}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontSize: "11px",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            <strong>
                                                                                <i className="fa-solid fa-location" />{" "}
                                                                            </strong>
                                                                            Map
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <span
                                                                        style={{ fontSize: "11px" }}
                                                                    >
                                                                        <strong>
                                                                            <i className="fa-solid fa-eye" />{" "}
                                                                        </strong>
                                                                        View
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span
                                                                        style={{ fontSize: "11px" }}
                                                                    >
                                                                        <strong>
                                                                            <i class="fa-solid fa-building"></i>
                                                                        </strong>
                                                                        Property
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        ))}
                                </div>
                                {/*  agecy card item */}
                            </div>
                            {/* dashboard-listings-wrap end*/}
                            {/* pagination*/}

                            {/* pagination end*/}
                        </div>
                        {/* col-md 8 end */}
                    </div>
                </div>

                {/* section end*/}
                {/* section */}
                <section className="gray-bg ">
                    <div className="container">
                        <div className="section-title st-center fl-wrap">
                            <h4>Testimonilas</h4>
                            <h2>What Our Clients Say</h2>
                        </div>
                    </div>
                    <div className="clearfix" />

                    <div className="custom-slider-testimonial">
                        {testimonial?.length ?
                            <Testimonial testimonial={testimonial} />
                            : <>
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </Button>
                            </>}
                    </div>
                </section>
                {/* section end*/}



                <Footer />

            </div >
        </>
    );
}

export default CityLists;
