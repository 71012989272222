import React, { useState, useEffect } from "react";

import SidePanel from "./SIdePanel";
import ProfileHeader from "./ProfileHeader";
import ProfileFooter from "./ProfileFooter";
import { toast } from "react-toastify";
import DashboardMenu from "./DashboardMenu";

function SavedProperity() {


  const [wishlist, setWishlist] = useState([])
  const [user, setUser] = useState({})
  const [photoCount, setPhotoCount] = useState(0)

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");


  // const handleWhatsAppInvite = () => {
  //   const url = `https://wa.me/?text=${encodeURIComponent(message)}`;
  //   window.open(url, '_blank');
  // };

  // const handleEmailInvite = () => {
  //   const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  //   window.location.href = mailtoLink;
  // };


  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
      }
    }
    getUser();
  }, []);

  useEffect(() => {
    async function getWishlist() {
      const url = `${apiUrl}/property/getWishlist/${user.id}`;


      let response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setWishlist(response.data)
        let count = []
        for (let j = 0; j < response.data.length; j++) {
          let temp = 0;
          for (let i = 0; i < 5; i++) {
            if (response.data[j][`photo${i + 1}`]) {
              temp++;
            }
          }
          count = [...count, temp]
        }
        console.log(count, 'count=======')
        setPhotoCount(count)
      }
    }
    if (user?.id) {
      getWishlist()
    }

  }, [user, apiUrl, Token]);



  // A function to handle the submit of the property form
  const handleWishlist = async (e, id, userId) => {
    e.preventDefault();
    e.stopPropagation()
    e.stopPropagation();
    if (!userId) {
      toast.error("Login First")
      return
    }

    try {

      const response = await fetch(`${apiUrl}/property/addToWishlist/${userId}-${id}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        }
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      setTimeout(() => {
        window.location.reload()
      }, 2500)
    } catch (error) {
      toast.error(error.message);
    }
  };

  // A function to handle the submit of the property form
  const handleCompare = async (e, id, userId) => {
    e.preventDefault();
    e.stopPropagation()
    e.stopPropagation();
    if (!userId) {
      toast.error("Login First")
      return
    }

    try {

      const response = await fetch(`${apiUrl}/property/addToCompare/${userId}-${id}`, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        }
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      setTimeout(() => {
        window.location.reload()
      }, 2500)
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* header put here */}
          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            <SidePanel></SidePanel>
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div
              className="dashboard-content"
            // style={{ padding: "40px 50px 70px 351px" }}
            >
              <DashboardMenu user={user} />
              <div className="container dasboard-container">
                {/* dashboard-title */}
                <div class="dashboard-title fl-wrap">
                  <div class="dashboard-title-item">
                    <span>Your Properties</span>
                  </div>
                  <ProfileHeader />
                  {/* <div class="tfp-det-container">
                    <div class="tfp-btn">
                      <span>Your Tariff Plan : </span> <strong>Extended</strong>
                    </div>
                    <div class="tfp-det">
                      <p>
                        You Are on <a href="#">Extended</a> . Use link bellow to
                        view details or upgrade.{" "}
                      </p>
                      <a href="#" class="tfp-det-btn color-bg">
                        Details
                      </a>
                    </div>
                  </div> */}
                </div>
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap">
                  <div className="dasboard-listing-box fl-wrap">
                    {/* dashboard-listings-wrap*/}
                    <div className="dashboard-listings-wrap fl-wrap">
                      {/* listing-item-wrap*/}
                      <div className="listing-item-container fl-wrap">
                        {/* listing-item */}
                        {wishlist?.map((data) => (
                          <div className="listing-item has_one_column">
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img fl-wrap">
                                <a href={data.slug} className="geodir-category-img_item">
                                  <img src={`${apiUrl}/uploads/${data.photo1 ? data.photo1 : data.photo2}`} alt="property" style={{ height: '290px' }} />
                                  <div className="overlay" />
                                </a>
                                <div className="geodir-category-location">
                                  <a
                                    href="#1"
                                    className="map-item tolt"
                                    data-microtip-position="top-left"
                                    data-tooltip="On the map"
                                  >
                                    <i className="fa-solid fa-map-marker-alt" />{" "}
                                    {data.address1}
                                  </a>
                                </div>
                                <ul className="list-single-opt_header_cat">
                                  <li>
                                    <a href="#" className="cat-opt blue-bg">

                                      {data.purpose !== 'wantToRent' ? 'Sale' : 'Rent'}
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" className="cat-opt color-bg">
                                      {data.category}
                                    </a>
                                  </li>
                                </ul>
                                <a
                                  href="#1"
                                  className="geodir_save-btn tolt text-danger"
                                  data-microtip-position="left"
                                  data-tooltip="Save"
                                  onClick={(e) => {
                                    handleWishlist(e, data.id, user.id)
                                  }}
                                >
                                  <span>
                                    <i className="fa-solid fa-heart" />
                                  </span>
                                </a>
                                <a
                                  href="#1"
                                  className="compare-btn tolt"
                                  data-microtip-position="left"
                                  data-tooltip="Compare"
                                  onClick={(e) => {
                                    handleCompare(e, data.id, user.id)
                                  }}
                                >
                                  <span>
                                    <i className="fa-solid fa-random" />
                                  </span>
                                </a>
                                <div className="geodir-category-listing_media-list">
                                  <span>
                                    <i className="fa-solid fa-camera" /> {photoCount}
                                  </span>
                                </div>
                              </div>
                              <div className="geodir-category-content fl-wrap">
                                <h3>
                                  <a href={data.slug}>
                                    {data.name}
                                  </a>
                                </h3>
                                <div className="geodir-category-content_price">
                                  $ {data?.maxPrice ? data?.maxPrice : data?.rentalPrice + " /Month"}
                                </div>
                                <p>
                                  {data.description.slice(0, 150)}...
                                </p>
                                <div className="geodir-category-content-details">
                                  <ul>
                                    <li>
                                      <i className="fa-solid fa-bed" />
                                      <span>{" "}{data?.listingDetails?.Bedrooms}</span>
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-bath" />
                                      <span>{" "}{data?.listingDetails?.Bathroom}</span>
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-cube" />
                                      <span>{" "}{data?.maxSize} Sq Ft</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="geodir-category-footer fl-wrap">
                                  <a href={`profile-info-${data.customerId}`} className="gcf-company">
                                    {/* 
                                    <img src={data?.Customer?.userDetails ? `${apiUrl}/uploads/${data?.Customer?.userDetails[0]?.photo}` : "images/avatar/2.jpg"} alt="poster" /> */}
                                    <span>By {data.postedBy}</span>
                                  </a>
                                  {/* <div
                                    className="listing-rating card-popup-rainingvis tolt"
                                    data-microtip-position="top"
                                    data-tooltip="Good"
                                    data-starrating2={4}
                                  /> */}
                                </div>
                                <div className="dashboard-listings-item_opt btm_footer-item">
                                  <span className="viewed-counter">
                                    <i className="fa-solid fa-eye" /> Viewed - {data.views}{" "}
                                  </span>
                                  <ul>
                                    <li
                                    // onClick={(e) => {
                                    //   handleWishlist(e, data.id, user.id)
                                    // }}
                                    >
                                      <a
                                        href="#1"
                                        className="tolt"
                                        data-microtip-position="top-left"
                                        data-tooltip="Send Email"
                                      >
                                        <i class="fa-solid fa-envelope"></i>

                                      </a>
                                    </li>
                                    <li
                                    // onClick={(e) => {
                                    //   handleWishlist(e, data.id, user.id)
                                    // }}
                                    >
                                      <a
                                        href="#1"
                                        className="tolt"
                                        data-microtip-position="top-left"
                                        data-tooltip="Send Whatsapp"
                                      >
                                        <i class="fa-brands fa-whatsapp"></i>
                                      </a>
                                    </li>
                                    <li
                                      onClick={(e) => {
                                        handleWishlist(e, data.id, user.id)
                                      }}
                                    >
                                      <a
                                        href="#1"
                                        className="tolt"
                                        data-microtip-position="top-left"
                                        data-tooltip="Delete"
                                      >
                                        <i className="fa-solid fa-trash-alt" />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </article>
                          </div>
                        ))}
                        {/* listing-item end*/}
                        {/* listing-item */}
                        {/* <div className="listing-item has_one_column">
                          <article className="geodir-category-listing fl-wrap">
                            <div className="geodir-category-img fl-wrap">
                              <a href="#" className="geodir-category-img_item">
                                <img
                                  src="https://hauzzi.webkype.net/images/all/3.jpg"
                                  alt
                                />
                                <div className="overlay" />
                              </a>
                              <div className="geodir-category-location">
                                <a
                                  href="#1"
                                  className="map-item tolt"
                                  data-microtip-position="top-left"
                                  data-tooltip="On the map"
                                >
                                  <i className="fa-solid fa-map-marker-alt" />{" "}
                                  70 Bright St New York, USA
                                </a>
                              </div>
                              <ul className="list-single-opt_header_cat">
                                <li>
                                  <a href="#" className="cat-opt blue-bg">
                                    Sale
                                  </a>
                                </li>
                                <li>
                                  <a href="#" className="cat-opt color-bg">
                                    Apartment
                                  </a>
                                </li>
                              </ul>
                              <a
                                href="#"
                                className="geodir_save-btn tolt"
                                data-microtip-position="left"
                                data-tooltip="Save"
                              >
                                <span>
                                  <i className="fa-solid fa-heart" />
                                </span>
                              </a>
                              <a
                                href="#"
                                className="compare-btn tolt"
                                data-microtip-position="left"
                                data-tooltip="Compare"
                              >
                                <span>
                                  <i className="fa-solid fa-random" />
                                </span>
                              </a>
                              <div className="geodir-category-listing_media-list">
                                <span>
                                  <i className="fa-solid fa-camera" /> 8
                                </span>
                              </div>
                            </div>
                            <div className="geodir-category-content fl-wrap">
                              <h3>
                                <a href="listing-single.html">
                                  Gorgeous house for sale
                                </a>
                              </h3>
                              <div className="geodir-category-content_price">
                                $ 600,000
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Maecenas in pulvinar neque.
                                Nulla finibus lobortis pulvinar. Donec a
                                consectetur nulla.
                              </p>
                              <div className="geodir-category-content-details">
                                <ul>
                                  <li>
                                    <i className="fa-solid fa-bed" />
                                    <span>3</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-bath" />
                                    <span>2</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-cube" />
                                    <span>450 ft2</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="geodir-category-footer fl-wrap">
                                <a href="#" className="gcf-company">
                                  <img
                                    src="https://hauzzi.webkype.net/images/avatar/2.jpg"
                                    alt
                                  />
                                  <span>By Liza Rose</span>
                                </a>
                                <div
                                  className="listing-rating card-popup-rainingvis tolt"
                                  data-microtip-position="top"
                                  data-tooltip="Good"
                                  data-starrating2={4}
                                />
                              </div>
                              <div className="dashboard-listings-item_opt btm_footer-item">
                                <span className="viewed-counter">
                                  <i className="fa-solid fa-eye" /> Viewed - 645{" "}
                                </span>
                                <ul>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Edit"
                                    >
                                      <i className="fa-solid fa-edit" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Disable"
                                    >
                                      <i className="fa-solid fa-signal-alt-slash" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Delete"
                                    >
                                      <i className="fa-solid fa-trash-alt" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </article>
                        </div> */}
                        {/* listing-item end*/}
                        {/* listing-item */}
                        {/* <div className="listing-item has_one_column">
                          <article className="geodir-category-listing fl-wrap">
                            <div className="geodir-category-img fl-wrap">
                              <a href="#" className="geodir-category-img_item">
                                <img
                                  src="https://hauzzi.webkype.net/images/all/3.jpg"
                                  alt
                                />
                                <div className="overlay" />
                              </a>
                              <div className="geodir-category-location">
                                <a
                                  href="#1"
                                  className="map-item tolt"
                                  data-microtip-position="top-left"
                                  data-tooltip="On the map"
                                >
                                  <i className="fa-solid fa-map-marker-alt" />{" "}
                                  70 Bright St New York, USA
                                </a>
                              </div>
                              <ul className="list-single-opt_header_cat">
                                <li>
                                  <a href="#" className="cat-opt blue-bg">
                                    Sale
                                  </a>
                                </li>
                                <li>
                                  <a href="#" className="cat-opt color-bg">
                                    Apartment
                                  </a>
                                </li>
                              </ul>
                              <a
                                href="#"
                                className="geodir_save-btn tolt"
                                data-microtip-position="left"
                                data-tooltip="Save"
                              >
                                <span>
                                  <i className="fa-solid fa-heart" />
                                </span>
                              </a>
                              <a
                                href="#"
                                className="compare-btn tolt"
                                data-microtip-position="left"
                                data-tooltip="Compare"
                              >
                                <span>
                                  <i className="fa-solid fa-random" />
                                </span>
                              </a>
                              <div className="geodir-category-listing_media-list">
                                <span>
                                  <i className="fa-solid fa-camera" /> 8
                                </span>
                              </div>
                            </div>
                            <div className="geodir-category-content fl-wrap">
                              <h3>
                                <a href="listing-single.html">
                                  Gorgeous house for sale
                                </a>
                              </h3>
                              <div className="geodir-category-content_price">
                                $ 600,000
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Maecenas in pulvinar neque.
                                Nulla finibus lobortis pulvinar. Donec a
                                consectetur nulla.
                              </p>
                              <div className="geodir-category-content-details">
                                <ul>
                                  <li>
                                    <i className="fa-solid fa-bed" />
                                    <span>3</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-bath" />
                                    <span>2</span>
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-cube" />
                                    <span>450 ft2</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="geodir-category-footer fl-wrap">
                                <a href="#" className="gcf-company">
                                  <img
                                    src="https://hauzzi.webkype.net/images/avatar/2.jpg"
                                    alt
                                  />
                                  <span>By Liza Rose</span>
                                </a>
                                <div
                                  className="listing-rating card-popup-rainingvis tolt"
                                  data-microtip-position="top"
                                  data-tooltip="Good"
                                  data-starrating2={4}
                                />
                              </div>
                              <div className="dashboard-listings-item_opt btm_footer-item">
                                <span className="viewed-counter">
                                  <i className="fa-solid fa-eye" /> Viewed - 645{" "}
                                </span>
                                <ul>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Edit"
                                    >
                                      <i className="fa-solid fa-edit" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Disable"
                                    >
                                      <i className="fa-solid fa-signal-alt-slash" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="tolt"
                                      data-microtip-position="top-left"
                                      data-tooltip="Delete"
                                    >
                                      <i className="fa-solid fa-trash-alt" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </article>
                        </div> */}
                        {/* listing-item end*/}
                      </div>
                    </div>
                    {/* dashboard-listings-wrap end*/}
                  </div>
                  {/* pagination*/}
                  {/* <div className="pagination float-pagination">
                    <a href="#" className="prevposts-link">
                      <i className="fa fa-caret-left" />
                    </a>
                    <a href="#">1</a>
                    <a href="#" className="current-page">
                      2
                    </a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#" className="nextposts-link">
                      <i className="fa fa-caret-right" />
                    </a>
                  </div> */}
                  {/* pagination end*/}
                </div>
              </div>
              {/* dashboard-footer */}
              <ProfileFooter />
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
    </>
  );
}

export default SavedProperity;
