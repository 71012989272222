import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import HeroSection from "./HeroSection";

function FAQHelp() {

  const [buyerData, setBuyerData] = useState([])
  const [sellerData, setSellerData] = useState([])
  const [agencyData, setAgencyData] = useState([])
  const [agentData, setAgentData] = useState([])
  const [listingData, setListingData] = useState([])
  const [aboutData, setAboutData] = useState([])
  const [faqData, setFaqData] = useState([])

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;


  useEffect(() => {
    fetch(`${apiUrl}/faq/getByCategory/Buyer`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setBuyerData(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])


  useEffect(() => {
    fetch(`${apiUrl}/faq/getByCategory/Seller`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setSellerData(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])

  useEffect(() => {
    fetch(`${apiUrl}/faq/getByCategory/Agency`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setAgencyData(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])

  useEffect(() => {
    fetch(`${apiUrl}/faq/getByCategory/Agent`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setAgentData(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])

  useEffect(() => {
    fetch(`${apiUrl}/faq/getByCategory/Listing`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setListingData(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])

  useEffect(() => {
    fetch(`${apiUrl}/faq/getByCategory/AboutUs`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setAboutData(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])

  useEffect(() => {
    fetch(`${apiUrl}/faq/getByCategory/FAQ`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setFaqData(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])


  return (
    <div>
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />

      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />

      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />

      <div className="loader-wrap">
        <div className="loader-inner">
          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation={2}
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                  result="gooey"
                />
                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>

      {/* main */}
      <div id="main">
        {/* Put header here */}

        {/* wrapper  */}
        <div id="wrapper p-0">
          {/* content */}
          <div className="content">
            {/*  section  */}
            <HeroSection />
            {/*  section  end*/}
            {/* breadcrumbs*/}
            <div className="breadcrumbs fw-breadcrumbs sp-brd fl-wrap">
              <div className="container">
                <div className="breadcrumbs-list">
                  <a href="#">Home</a> <a href="#">Pages</a>
                  <span>Help FAQ</span>
                </div>
                <div className="share-holder hid-share">
                  <a href="#" className="share-btn showshare sfcs">
                    {" "}
                    <i className="fa-solid fa-share-alt" /> Share{" "}
                  </a>
                  <div className="share-container  isShare" />
                </div>
              </div>
            </div>
            {/* breadcrumbs end */}
            {/* col-list-wrap */}
            <section className="gray-bg small-padding ">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="box-widget fl-wrap fixed-column_menu-init">
                      <div className="box-widget-content fl-wrap  ">
                        <div className="box-widget-title fl-wrap">
                          FAQ Navigation
                        </div>
                        <div className="faq-nav scroll-init fl-wrap">
                          <ul>
                            <li>
                              <a className="act-scrlink" href="#faq1">
                                Buyer
                              </a>
                            </li>
                            <li>
                              <a href="#faq2">Seller</a>
                            </li>
                            <li>
                              <a href="#faq3">Agency</a>
                            </li>
                            <li>
                              <a href="#faq4">Agent</a>
                            </li>
                            <li>
                              <a href="#faq5">Listing</a>
                            </li>
                            <li>
                              <a href="#faq6">About Us</a>
                            </li>
                            <li>
                              <a href="#faq7">FAQ</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="list-single-main-container">
                      {/*   list-single-main-item */}
                      <div className="list-single-main-item fl-wrap" id="faq1">
                        <div className="list-single-main-item-title big-lsmt fl-wrap">
                          <h3>Buyer</h3>
                        </div>
                        {/*   accordion-lite */}
                        <div class="accordion" id="accordionPanelsStayOpenExample">
                          {buyerData.map((data, index) => (
                            <div className="accordion" id={`panelsStayOpen-${index}`}>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id={`panelsStayOpen-o${index}`}>
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#panelsStayOpen-c${index}`}
                                    aria-expanded="false"
                                    aria-controls={`panelsStayOpen-c${index}`}
                                  >
                                    {data.query}
                                  </button>
                                </h2>
                                <div
                                  id={`panelsStayOpen-c${index}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`panelsStayOpen-o${index}`}
                                >
                                  <div className="accordion-body">
                                    <strong>{data.answer}</strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        </div>
                        {/*   accordion-lite end */}
                        {/*   accordion-lite */}
                        {/* <div className="accordion-lite-container fl-wrap">
                          <div className="accordion-lite-header fl-wrap">
                            How long does the sending process take?{" "}
                            <i className="fa-solid fa-plus" />
                          </div>
                          <div className="accordion-lite_content fl-wrap">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Maecenas in pulvinar neque. Nulla finibus
                              lobortis pulvinar. Donec a consectetur nulla.
                              Nulla posuere sapien vitae lectus suscipit, et
                              pulvinar nisi tincidunt. Aliquam erat volutpat.
                              Curabitur convallis fringilla diam sed aliquam.
                              Sed tempor iaculis massa faucibus feugiat. In
                              fermentum facilisis massa, a consequat .
                            </p>
                          </div>
                        </div> */}
                        {/*   accordion-lite end */}
                        {/*   accordion-lite */}
                        {/* <div className="accordion-lite-container fl-wrap">
                          <div className="accordion-lite-header fl-wrap">
                            Can I use more than one payment method to pay for a
                            reservation? <i className="fa-solid fa-plus" />
                          </div>
                          <div className="accordion-lite_content fl-wrap">
                            <p>
                              {" "}
                              Nulla finibus lobortis pulvinar. Donec a
                              consectetur nulla. Nulla posuere sapien vitae
                              lectus suscipit, et pulvinar nisi tincidunt.
                              Aliquam erat volutpat. Curabitur convallis
                              fringilla diam sed aliquam. Sed tempor iaculis
                              massa faucibus feugiat. In fermentum facilisis
                              massa, a consequat .
                            </p>
                          </div>
                        </div> */}
                        {/*   accordion-lite end */}
                        {/*   accordion-lite */}
                        {/* <div className="accordion-lite-container fl-wrap">
                          <div className="accordion-lite-header fl-wrap">
                            How do I edit or remove a payment method?{" "}
                            <i className="fa-solid fa-plus" />
                          </div>
                          <div className="accordion-lite_content fl-wrap">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Maecenas in pulvinar neque. Nulla finibus
                              lobortis pulvinar. Donec a consectetur nulla.
                              Nulla posuere sapien vitae lectus suscipit, et
                              pulvinar nisi tincidunt.
                            </p>
                          </div>
                        </div> */}
                        {/*   accordion-lite end */}
                      </div>
                      {/*   list-single-main-item end */}
                      {/*   list-single-main-item */}
                      <div className="list-single-main-item fl-wrap" id="faq2">
                        <div className="list-single-main-item-title big-lsmt fl-wrap">
                          <h3>Seller</h3>
                        </div>
                        <div class="accordion" id="accordionPanelsStayOpenExample">
                          {sellerData.map((data, index) => (
                            <div className="accordion" id={`panelsStayOpen-${index}`}>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id={`panelsStayOpen-o${index}`}>
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#panelsStayOpen-c${index}`}
                                    aria-expanded="false"
                                    aria-controls={`panelsStayOpen-c${index}`}
                                  >
                                    {data.query}
                                  </button>
                                </h2>
                                <div
                                  id={`panelsStayOpen-c${index}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`panelsStayOpen-o${index}`}
                                >
                                  <div className="accordion-body">
                                    <strong>{data.answer}</strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        </div>
                      </div>
                      {/*   list-single-main-item end */}
                      {/*   list-single-main-item */}
                      <div className="list-single-main-item fl-wrap" id="faq3">
                        <div className="list-single-main-item-title big-lsmt fl-wrap">
                          <h3>Agency</h3>
                        </div>
                        <div class="accordion" id="accordionPanelsStayOpenExample">
                          {agencyData.map((data, index) => (
                            <div className="accordion" id={`panelsStayOpen-${index}`}>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id={`panelsStayOpen-o${index}`}>
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#panelsStayOpen-c${index}`}
                                    aria-expanded="false"
                                    aria-controls={`panelsStayOpen-c${index}`}
                                  >
                                    {data.query}
                                  </button>
                                </h2>
                                <div
                                  id={`panelsStayOpen-c${index}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`panelsStayOpen-o${index}`}
                                >
                                  <div className="accordion-body">
                                    <strong>
                                      <div dangerouslySetInnerHTML={{ __html: data.answer }} />
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        </div>
                      </div>
                      {/*   list-single-main-item end */}
                      {/*   list-single-main-item */}
                      <div className="list-single-main-item fl-wrap" id="faq4">
                        <div className="list-single-main-item-title big-lsmt fl-wrap">
                          <h3>Agent</h3>
                        </div>
                        <div class="accordion" id="accordionPanelsStayOpenExample">
                          {agentData.map((data, index) => (
                            <div className="accordion" id={`panelsStayOpen-${index}`}>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id={`panelsStayOpen-o${index}`}>
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#panelsStayOpen-c${index}`}
                                    aria-expanded="false"
                                    aria-controls={`panelsStayOpen-c${index}`}
                                  >
                                    {data.query}
                                  </button>
                                </h2>
                                <div
                                  id={`panelsStayOpen-c${index}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`panelsStayOpen-o${index}`}
                                >
                                  <div className="accordion-body">
                                    <strong>
                                      <div dangerouslySetInnerHTML={{ __html: data.answer }} />
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        </div>
                      </div>
                      {/*   list-single-main-item end */}
                      {/*   list-single-main-item */}
                      <div className="list-single-main-item fl-wrap" id="faq5">
                        <div className="list-single-main-item-title big-lsmt fl-wrap">
                          <h3>Listing</h3>
                        </div>
                        <div class="accordion" id="accordionPanelsStayOpenExample">
                          {listingData.map((data, index) => (
                            <div className="accordion" id={`panelsStayOpen-${index}`}>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id={`panelsStayOpen-o${index}`}>
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#panelsStayOpen-c${index}`}
                                    aria-expanded="false"
                                    aria-controls={`panelsStayOpen-c${index}`}
                                  >
                                    {data.query}
                                  </button>
                                </h2>
                                <div
                                  id={`panelsStayOpen-c${index}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`panelsStayOpen-o${index}`}
                                >
                                  <div className="accordion-body">
                                    <strong>
                                      <div dangerouslySetInnerHTML={{ __html: data.answer }} />
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        </div>
                      </div>
                      {/*   list-single-main-item end */}
                      {/*   list-single-main-item */}
                      <div className="list-single-main-item fl-wrap" id="faq6">
                        <div className="list-single-main-item-title big-lsmt fl-wrap">
                          <h3>About Us</h3>
                        </div>
                        {/*   accordion-lite */}
                        <div class="accordion" id="accordionPanelsStayOpenExample">
                          {aboutData.map((data, index) => (
                            <div className="accordion" id={`panelsStayOpen-${index}`}>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id={`panelsStayOpen-o${index}`}>
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#panelsStayOpen-c${index}`}
                                    aria-expanded="false"
                                    aria-controls={`panelsStayOpen-c${index}`}
                                  >
                                    {data.query}
                                  </button>
                                </h2>
                                <div
                                  id={`panelsStayOpen-c${index}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`panelsStayOpen-o${index}`}
                                >
                                  <div className="accordion-body">
                                    <strong>
                                      <div dangerouslySetInnerHTML={{ __html: data.answer }} />
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        </div>
                        {/*   accordion-lite end */}
                        {/*   accordion-lite */}
                        {/* <div className="accordion-lite-container fl-wrap">
                          <div className="accordion-lite-header fl-wrap">
                            How long does the sending process take?{" "}
                            <i className="fa-solid fa-plus" />
                          </div>
                          <div className="accordion-lite_content fl-wrap">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Maecenas in pulvinar neque. Nulla finibus
                              lobortis pulvinar. Donec a consectetur nulla.
                              Nulla posuere sapien vitae lectus suscipit, et
                              pulvinar nisi tincidunt. Aliquam erat volutpat.
                              Curabitur convallis fringilla diam sed aliquam.
                              Sed tempor iaculis massa faucibus feugiat. In
                              fermentum facilisis massa, a consequat .
                            </p>
                          </div>
                        </div> */}
                        {/*   accordion-lite end */}
                        {/*   accordion-lite */}
                        {/* <div className="accordion-lite-container fl-wrap">
                          <div className="accordion-lite-header fl-wrap">
                            Can I use more than one payment method to pay for a
                            reservation? <i className="fa-solid fa-plus" />
                          </div>
                          <div className="accordion-lite_content fl-wrap">
                            <p>
                              {" "}
                              Nulla finibus lobortis pulvinar. Donec a
                              consectetur nulla. Nulla posuere sapien vitae
                              lectus suscipit, et pulvinar nisi tincidunt.
                              Aliquam erat volutpat. Curabitur convallis
                              fringilla diam sed aliquam. Sed tempor iaculis
                              massa faucibus feugiat. In fermentum facilisis
                              massa, a consequat .
                            </p>
                          </div>
                        </div> */}
                        {/*   accordion-lite end */}
                        {/*   accordion-lite */}
                        {/* <div className="accordion-lite-container fl-wrap">
                          <div className="accordion-lite-header fl-wrap">
                            How do I edit or remove a payment method?{" "}
                            <i className="fa-solid fa-plus" />
                          </div>
                          <div className="accordion-lite_content fl-wrap">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Maecenas in pulvinar neque. Nulla finibus
                              lobortis pulvinar. Donec a consectetur nulla.
                              Nulla posuere sapien vitae lectus suscipit, et
                              pulvinar nisi tincidunt.
                            </p>
                          </div>
                        </div> */}
                        {/*   accordion-lite end */}
                      </div>
                      {/*   list-single-main-item end */}
                      {/*   list-single-main-item */}
                      <div className="list-single-main-item fl-wrap" id="faq7">
                        <div className="list-single-main-item-title big-lsmt fl-wrap">
                          <h3>FAQ</h3>
                        </div>
                        {/*   accordion-lite */}
                        <div class="accordion" id="accordionPanelsStayOpenExample">
                          {faqData.map((data, index) => (
                            <div className="accordion" id={`panelsStayOpen-${index}`}>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id={`panelsStayOpen-o${index}`}>
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#panelsStayOpen-c${index}`}
                                    aria-expanded="false"
                                    aria-controls={`panelsStayOpen-c${index}`}
                                  >
                                    {data.query}
                                  </button>
                                </h2>
                                <div
                                  id={`panelsStayOpen-c${index}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`panelsStayOpen-o${index}`}
                                >
                                  <div className="accordion-body">
                                    <strong>
                                      <div dangerouslySetInnerHTML={{ __html: data.answer }} />
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        </div>
                        {/*   accordion-lite end */}
                        {/*   accordion-lite */}
                        {/* <div className="accordion-lite-container fl-wrap">
                          <div className="accordion-lite-header fl-wrap">
                            How long does the sending process take?{" "}
                            <i className="fa-solid fa-plus" />
                          </div>
                          <div className="accordion-lite_content fl-wrap">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Maecenas in pulvinar neque. Nulla finibus
                              lobortis pulvinar. Donec a consectetur nulla.
                              Nulla posuere sapien vitae lectus suscipit, et
                              pulvinar nisi tincidunt. Aliquam erat volutpat.
                              Curabitur convallis fringilla diam sed aliquam.
                              Sed tempor iaculis massa faucibus feugiat. In
                              fermentum facilisis massa, a consequat .
                            </p>
                          </div>
                        </div> */}
                        {/*   accordion-lite end */}
                        {/*   accordion-lite */}
                        {/* <div className="accordion-lite-container fl-wrap">
                          <div className="accordion-lite-header fl-wrap">
                            Can I use more than one payment method to pay for a
                            reservation? <i className="fa-solid fa-plus" />
                          </div>
                          <div className="accordion-lite_content fl-wrap">
                            <p>
                              {" "}
                              Nulla finibus lobortis pulvinar. Donec a
                              consectetur nulla. Nulla posuere sapien vitae
                              lectus suscipit, et pulvinar nisi tincidunt.
                              Aliquam erat volutpat. Curabitur convallis
                              fringilla diam sed aliquam. Sed tempor iaculis
                              massa faucibus feugiat. In fermentum facilisis
                              massa, a consequat .
                            </p>
                          </div>
                        </div> */}
                        {/*   accordion-lite end */}
                        {/*   accordion-lite */}
                        {/* <div className="accordion-lite-container fl-wrap">
                          <div className="accordion-lite-header fl-wrap">
                            How do I edit or remove a payment method?{" "}
                            <i className="fa-solid fa-plus" />
                          </div>
                          <div className="accordion-lite_content fl-wrap">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Maecenas in pulvinar neque. Nulla finibus
                              lobortis pulvinar. Donec a consectetur nulla.
                              Nulla posuere sapien vitae lectus suscipit, et
                              pulvinar nisi tincidunt.
                            </p>
                          </div>
                        </div> */}
                        {/*   accordion-lite end */}
                      </div>
                      {/*   list-single-main-item end */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="limit-box fl-wrap" />
            </section>
          </div>
          {/* content end */}
          {/* subscribe-wrap */}
          {/* <div className="subscribe-wrap fl-wrap">
            <div className="container">
              <div className="subscribe-container fl-wrap color-bg">
                <div className="pwh_bg" />
                <div className="mrb_dec mrb_dec3" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="subscribe-header">
                      <h4>newsletter</h4>
                      <h3>
                        Sign up for newsletter and get latest news and update
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-5">
                    <div className="footer-widget fl-wrap">
                      <div className="subscribe-widget fl-wrap">
                        <div className="subcribe-form">
                          <form id="subscribe">
                            <input
                              className="enteremail fl-wrap"
                              name="email"
                              id="subscribe-email"
                              placeholder="Enter Your Email"
                              spellCheck="false"
                              type="text"
                            />
                            <button
                              type="submit"
                              id="subscribe-button"
                              className="subscribe-button color-bg"
                            >
                              {" "}
                              Subscribe
                            </button>
                            <label
                              htmlFor="subscribe-email"
                              className="subscribe-message"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* subscribe-wrap end */}
          {/* footer */}
          <Footer />
          {/* footer end */}
        </div>
      </div>
    </div>
  );
}

export default FAQHelp;
