import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const API_KEY = process.env.REACT_APP_MAP_API_KEY;

function MapContainer({ onMarkerClick }) {
  const [map, setMap] = useState(null);
  const [clickedPosition, setClickedPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 28.608, lng: 77.75 }); // Default center
  const mapRef = useRef(null);
  const searchBoxRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: ['places'],
  });

  useEffect(() => {
    if (isLoaded && searchBoxRef.current) {
      const searchBox = new window.google.maps.places.SearchBox(searchBoxRef.current);

      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();
        if (places.length === 0) return;

        const place = places[0];
        const newPosition = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        setClickedPosition(newPosition);
        setMapCenter(newPosition); // Update map center to the selected place
        onMarkerClick(newPosition);
      });
    }
  }, [isLoaded]);

  const mapStyles = {
    height: '50vh',
    width: '100%',
  };

  const handleMapClick = (event) => {
    const newPosition = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setClickedPosition(newPosition);
    setMapCenter(newPosition); // Update map center to the clicked position
    onMarkerClick(newPosition);
  };

  return (
    <div>
      {isLoaded ? (
        <>
          <input
            id="search-box"
            type="text"
            placeholder="Search for places..."
            style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
            ref={searchBoxRef}
          />
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={10}
            center={mapCenter} // Use mapCenter as the center
            onClick={handleMapClick}
            onLoad={(map) => {
              mapRef.current = map;
              setMap(map);
            }}
          >
            {clickedPosition && <Marker position={clickedPosition} />}
          </GoogleMap>
        </>
      ) : (
        <div>Loading Maps...</div>
      )}
      {loadError && <div>Map cannot be loaded right now, sorry.</div>}
    </div>
  );
}

export default MapContainer;
