import React from 'react'


function ProfileFooter() {
    return (<div className="dashboard-footer">
        <div className="dashboard-footer-links fl-wrap">
            <span>Helpfull Links:</span>
            <ul>
                <li>
                    <a href="about">About</a>
                </li>
                <li>
                    <a href="blog">Blog</a>
                </li>
                <li>
                    <a href="pricing">Pricing Plans</a>
                </li>
                <li>
                    <a href="contact">Contacts</a>
                </li>
                <li>
                    <a href="help">Help Center</a>
                </li>
            </ul>
        </div>
    </div>);
}

export default ProfileFooter
