import React, { useEffect, useState } from "react";
import SidePanel from "./SIdePanel";
import ProfileHeader from "./ProfileHeader";
import { toast } from "react-toastify"
import ProfileFooter from "./ProfileFooter";
import DashboardMenu from "./DashboardMenu";

function Mortgage() {


  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;

  const Token = localStorage.getItem("Token");

  const initialFormData = {
    name: "",
    yourQuery: "",
    email: "",
    phone: "",
    otherFees: "",
    familyIncome: "",
    age: "",
    typeOfEmployment: "",
    savingContribution: "",
    propertPrice: "",
    buyingWhen: "",
    typeOfPurchase: "",
  }

  const [formData, setFormData] = useState(initialFormData)
  const [user, setUser] = useState({})



  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-customer`;


      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status !== "error") {
        setUser(response.data)
        console.log(response)
      } else if (!localStorage.getItem("Token")) {
        setUser({})
      }
    }
    getUser();
  }, [apiUrl, Token]);

  // A function to handle the change of the input fields
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    }
    else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };



  // A function to handle the submit of the property form
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formData.userId = user.id
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }

      const response = await fetch(`${apiUrl}/mortgage/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      // setFormData(initialFormData)
      // navigate("/Master");
      setTimeout(() => {
        window.location.href = "/mortgage";
      }, 2000)
    } catch (error) {
      toast.error(error.message);
    }
  };


  return (
    <>
      <div>
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />

        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />

        <link rel="shortcut icon" href="images/favicon.ico" />

        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}

          {/* header end  */}
          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            <SidePanel></SidePanel>
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div className="dashboard-content">
              <DashboardMenu user={user} />
              <div className="container dasboard-container">
                {/* dashboard-title */}
                <div className="dashboard-title fl-wrap">
                  <div className="dashboard-title-item">
                    <span>Apply For Mortgage</span>
                  </div>

                  <ProfileHeader />
                  {/*Tariff Plan menu*/}
                  {/* <div className="tfp-det-container">
                    <div className="tfp-btn">
                      <span>Your Tariff Plan : </span> <strong>Extended</strong>
                    </div>
                    <div className="tfp-det">
                      <p>
                        You Are on <a href="#">Extended</a> . Use link bellow to
                        view details or upgrade.{" "}
                      </p>
                      <a href="#" className="tfp-det-btn color-bg">
                        Details
                      </a>
                    </div>
                  </div> */}
                  {/*Tariff Plan menu end*/}
                </div>
                {/* dashboard-title end */}
                <form
                  onSubmit={(e) => {
                    handleSubmit(e)
                  }}
                >
                  <div className="dasboard-wrapper fl-wrap no-pag">
                    <div className="dasboard-scrollnav-wrap scroll-to-fixed-fixed scroll-init2 fl-wrap">
                      <ul>
                        <li>
                          <a href="#sec1" className="act-scrlink">
                            Step 1
                          </a>
                        </li>
                        <li>
                          <a href="#sec2">Step 2</a>
                        </li>
                        <li>
                          <a href="#sec3">Step 3</a>
                        </li>
                      </ul>
                      <div className="progress-indicator">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="-1 -1 34 34"
                        >
                          <circle
                            cx={16}
                            cy={16}
                            r="15.9155"
                            className="progress-bar__background"
                          />
                          <circle
                            cx={16}
                            cy={16}
                            r="15.9155"
                            className="progress-bar__progress 
                                      js-progress-bar"
                          />
                        </svg>
                      </div>
                    </div>
                    {/* dasboard-widget-title */}
                    <div className="dasboard-widget-title fl-wrap" id="sec1">
                      <h5>
                        <i className="fa-solid fa-info" />
                        Property Information
                      </h5>
                    </div>
                    {/* dasboard-widget-title end */}
                    {/* dasboard-widget-box  */}
                    <div className="dasboard-widget-box fl-wrap">
                      <div className="custom-form">
                        <div className="row">
                          <div className="col-sm-6 mb-2">
                            <label>Type of purchase</label>
                            <div
                              className="listsearch-input-item"
                            >
                              <select
                                data-placeholder="All Types"
                                className="selectBox"
                                name="typeOfPurchase"
                                required={true}
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                              >
                                <option>All Types</option>
                                <option>Primary</option>
                                <option>Secondary</option>
                                <option>Non Resident</option>
                                <option>Other</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6 mb-2">
                            <label>When are you going to buy?</label>
                            <div className="listsearch-input-item">
                              <select
                                data-placeholder="All Types"
                                className="selectBox"
                                name="buyingWhen"
                                required={true}
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                              >
                                <option>Select</option>
                                <option>I have already made a reservation</option>
                                <option>I want to make an offer</option>
                                <option>I'm looking for housing</option>
                                <option>I just want advice for now</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label>
                              Property Price{" "}
                              <span className="dec-icon">
                                <i className="far fa-money-bill-wave" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="685 Dollars"
                              name="propertPrice"
                              required={true}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label>
                              Savings contributed{" "}
                              <span className="dec-icon">
                                <i className="far fa-money-bill-wave" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="500,000 Euros"
                              name="savingContribution"
                              required={true}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* dasboard-widget-box  end*/}
                    {/* dasboard-widget-title */}
                    <div
                      className="dasboard-widget-title dwb-mar fl-wrap"
                      id="sec2"
                    >
                      <h5>
                        <i className="fa-solid fa-street-view" />
                        Information About You
                      </h5>
                    </div>
                    {/* dasboard-widget-title end */}
                    {/* dasboard-widget-box  */}
                    <div className="dasboard-widget-box fl-wrap">
                      <div className="custom-form">
                        <div className="row">
                          <div className="col-sm-6">
                            <label>Type of employment</label>
                            <div className="listsearch-input-item">
                              <select
                                data-placeholder="Apartments"
                                className="selectBox"
                                name="typeOfEmployment"
                                required={true}
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                              >
                                <option>Select</option>
                                <option>Permanent</option>
                                <option>Temporary</option>
                                <option>Pensioner</option>
                                <option>Civil Servent</option>
                                <option>Self Employed</option>
                                <option>Other</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>
                              How old are you ?{" "}
                              <span className="dec-icon">
                                <i className="far fa-map-marker" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter"
                              name="age"
                              required={true}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>
                              Family income / per month?{" "}
                              <span className="dec-icon">
                                <i className="far fa-map-marker" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter"
                              name="familyIncome"
                              required={true}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>
                              Fees for other loans / per month?{" "}
                              <span className="dec-icon">
                                <i className="far fa-map-marker" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter"
                              name="otherFees"
                              required={true}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* dasboard-widget-box  end*/}
                    {/* dasboard-widget-title */}
                    <div
                      className="dasboard-widget-title dwb-mar fl-wrap"
                      id="sec3"
                    >
                      <h5>
                        <i className="fa-solid fa-street-view" />
                        Leave us your contact information and we will contact you
                      </h5>
                    </div>
                    {/* dasboard-widget-title end */}
                    {/* dasboard-widget-box  */}
                    <div className="dasboard-widget-box fl-wrap">
                      <div className="custom-form">
                        <div className="row">
                          <div className="col-md-6">
                            <label>
                              Your Full name{" "}
                              <span className="dec-icon">
                                <i className="far fa-map-marker" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter"
                              name="name"
                              required={true}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>
                              Your phone{" "}
                              <span className="dec-icon">
                                <i className="far fa-map-marker" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter"
                              name="phone"
                              required={true}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>
                              Your email{" "}
                              <span className="dec-icon">
                                <i className="far fa-map-marker" />
                              </span>
                            </label>
                            <input
                              type="email"
                              placeholder="Enter"
                              name="email"
                              required={true}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>
                              Do you have any questions? (Optional){" "}
                              <span className="dec-icon">
                                <i className="far fa-map-marker" />
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter"
                              name="yourQuery"
                              required={true}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </div>
                          <div className="col-sm-12">
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li className="w-100">
                                <input
                                  id="check-aaa5"
                                  type="checkbox"
                                  name="check"
                                  required={true}
                                />
                                <label htmlFor="check-aaa5">
                                  {" "}
                                  I accept the privacy policy and general
                                  conditions
                                </label>
                              </li>
                              <li className="w-100">
                                <input
                                  id="check-bb5"
                                  type="checkbox"
                                  name="check"
                                  required={true}
                                />
                                <label htmlFor="check-bb5">
                                  I agree to receive commercial communications
                                  from idealista/mortgages, by any means, and
                                  those sent to me from the financial, real
                                  estate, legal and insurance sectors.
                                </label>
                              </li>
                            </ul>
                            {/* Checkboxes end */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* dasboard-widget-box  end*/}
                    <button
                      className="btn color-bg float-btn text-white"
                      type="submit"
                    >
                      Save Changes{" "}
                    </button>
                  </div>
                </form>
              </div>
              <div className="limit-box fl-wrap" />
              {/* dashboard-footer */}
              <ProfileFooter />
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
      ;
    </>
  );
}

export default Mortgage;
