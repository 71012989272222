import React, { useEffect } from "react";
import Home from "./components/Home/home";
import { Route, Routes } from "react-router-dom"
import AddListing from "./components/Listing/addListing";
import Add from "./components/Listing/add";
import Listing from "./components/Listing/Listing";
import Header2 from "./components/Header/Header2";
import SlugRoutes from "./components/Home/SlugRoutes";
import Blog from "./components/Home/Blog";
import Contact from "./components/Home/Contact"
import FAQHelp from "./components/Home/FAQHelp";
import About from "./components/Home/About"
import DashBoard from "./components/Profile/DashBoard";
import Profile from "./components/Profile/Profile";
import MyReview from "./components/Profile/MyReview";
import Message from "./components/Profile/Message";
import Mortgage from "./components/Profile/Mortgage";
import MortgageQuery from "./components/Profile/MortgageQuery";
import MyAgency from "./components/Profile/MyAgencies";
import MyAgent from "./components/Profile/MyAgent";
import MyBooking from "./components/Profile/MyBooking";
import MyListing from "./components/Profile/MyListing";
import SavedProperity from "./components/Profile/SaveProperty";
import Compare from "./components/Home/Compare"
import OwnerBooking from "./components/Profile/ownerBooking";
import Agency from "./components/Home/AgencyPage";
import Agent from "./components/Home/AgentPage";
import PricePlanning from "./components/Home/PricePlanning";
import PrivacyPolicy from "./components/Home/PrivacyPolicy";
import TermsOfUse from "./components/Home/TermsOfUse";
import Customer from "./components/Profile/Customers";
// import Dashboard from "./components/Listing/Dashboard";
import CityLists from "./components/Listing/cityLists";
import ProfileEnquiry from "./components/Profile/ProfileEnquiry";
// import Demo from "./components/Home/Print"

function App() {

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {

        const translateFrame = document.querySelector(".goog-te-banner-frame");
        const translateContainer = document.querySelector(".skiptranslate");

        if (translateFrame || translateContainer) {
          if (translateFrame) {
            translateFrame.style.setProperty("display", "none", "important");
          }
          if (translateContainer) {
            translateContainer.style.setProperty("display", "none", "important");
          }

          observer.disconnect();
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);


  return (
    <div>
      {/* <Header /> */}
      <Header2 />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/add-listing" element={<AddListing />} />
        <Route path="/add" element={<Add />} />
        <Route path="listing" element={<Listing />} />
        <Route path="blog" element={<Blog />} />
        <Route path="contact" element={<Contact />} />
        <Route path="Help" element={<FAQHelp />} />
        <Route path="about" element={<About />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-of-use" element={<TermsOfUse />} />
        <Route path="dashboard" element={<DashBoard />} />
        <Route path="profile" element={<Profile />} />
        <Route path="review" element={<MyReview />} />
        <Route path="message" element={<Message />} />
        <Route path="profile-enquiry" element={<ProfileEnquiry />} />
        <Route path="mortgage" element={<Mortgage />} />
        <Route path="mortgage-query" element={<MortgageQuery />} />
        <Route path="agency" element={<MyAgency />} />
        <Route path="agent" element={<MyAgent />} />
        <Route path="enquiry" element={<MyBooking />} />
        <Route path="customers" element={<Customer />} />
        <Route path="booking" element={<OwnerBooking />} />
        <Route path="saved-property" element={<SavedProperity />} />
        <Route path="my-listing" element={<MyListing />} />
        <Route path="compare" element={<Compare />} />
        <Route path="agencies" element={<Agency />} />
        <Route path="agents" element={<Agent />} />
        <Route path="pricing" element={<PricePlanning />} />
        {/* <Route path="profile-info" element={<UserProfile />} /> */}
        {/* <Route path="dashboard" element={<Dashboard />} /> */}
        <Route path="/:slug" element={<SlugRoutes />} />
        <Route path="citylists" element={<CityLists />} />
        {/* <Route path="demo" element={<Demo />} /> */}


      </Routes>
    </div>
  );
}

export default App;
