import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom';
import HeroSection from './HeroSection';
import Footer from '../Header/Footer';

function Blog() {

  const [masterData, setMasterData] = useState([])
  const [blogList, setBlogList] = useState([])
  const [categoryCount, setCategoryCount] = useState([])

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;


  useEffect(() => {
    fetch(`${apiUrl}/blog/getAll`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setMasterData(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])



  useEffect(() => {
    fetch(`${apiUrl}/blog/getAll?limit=5`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        setCategoryCount(data.categoryCounts);
        if (data && Array.isArray(data.data)) {
          setBlogList(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [])


  return (
    <div>
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />

      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />

      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />
      {/*loader*/}
      <div className="loader-wrap">
        <div className="loader-inner">
          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation={2}
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                  result="gooey"
                />
                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>

      <div className='main'>
        {/* put header component here */}
        <div id="wrapper p=0">
          {/* content */}
          <div className="content">
            {/*  section  */}
            <HeroSection />
            {/*  section  end*/}
            {/* breadcrumbs*/}
            <div className="breadcrumbs fw-breadcrumbs sp-brd fl-wrap">
              <div className="container">
                <div className="breadcrumbs-list">
                  <a href="#">Home</a> <span>Blog</span>
                </div>
                <div className="share-holder hid-share">
                  <a href="#" className="share-btn showshare sfcs">
                    {" "}
                    <i className="fa-solid fa-share-alt" /> Share{" "}
                  </a>
                  <div className="share-container  isShare" />
                </div>
              </div>
            </div>
            {/* breadcrumbs end */}
            {/* col-list-wrap */}
            <div className="gray-bg small-padding fl-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="post-container fl-wrap">
                      {/* article> */}
                      {masterData.map((data) => (
                        <article
                          key={data.slug}
                          className="post-article fl-wrap"
                          onClick={() => {
                            window.location.href = data.slug
                          }}
                        >
                          <div className="list-single-main-media fl-wrap color-bg">
                            <img
                              src={`${apiUrl}/uploads/${data.photo}`}
                              className="respimg"
                              alt="blog"
                              style={{
                                height: '500px',
                                objectFit: 'cover',
                                backgroundColor: 'white',
                                
                              }}
                            />
                          </div>
                          <div className="list-single-main-item fl-wrap block_box">
                            <h2 className="post-opt-title">
                              <a href={`/${data.slug}`}>
                                {data.title}
                              </a>
                            </h2>
                            <p>
                              <div dangerouslySetInnerHTML={{ __html: data.content.slice(0, 200) }} />
                            </p>
                            {/* <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Maecenas in pulvinar neque. Nulla finibus
                              lobortis pulvinar. Donec a consectetur nulla. Nulla
                              posuere sapien vitae lectus suscipit, et pulvinar
                              nisi tincidunt...
                            </p> */}
                            <span className="fw-separator fl-wrap" />
                            {/* <div className="post-author">
                              <a href="#">
                                <img
                                  src="https://hauzzi.webkype.net/images/avatar/5.jpg"
                                  alt
                                />
                                <span>By , Hauzzi</span>
                              </a>
                            </div> */}
                            <div className="post-opt">
                              <ul className="no-list-style">
                                <li>
                                  <i className="fa-solid fa-calendar" />{" "}
                                  <span> {new Date(data.createdAt).toDateString()}</span>
                                </li>
                                {/* <li>
                                  <i className="fa-solid fa-eye" />{" "}
                                  <span>164</span>
                                </li>
                                <li>
                                  <i className="fa-solid fa-tags" />{" "}
                                  <a href="#">Shop</a> , <a href="#">Hotels</a>{" "}
                                </li> */}
                              </ul>
                            </div>
                            {/* <a
                              href="blog-single.html"
                              className="btn color-bg float-btn small-btn"
                            >
                              Read more
                            </a> */}
                          </div>
                        </article>))}
                      {/* article end */}
                      {/* article> */}
                      {/* <article className="post-article fl-wrap">
                        <div className="list-single-main-media fl-wrap">
                          <div className="single-slider-wrapper carousel-wrap fl-wrap">
                            <div className="single-slider fl-wrap carousel lightgallery">
                              <div className="slick-slide-item">
                                <div className="box-item">
                                  <a
                                    href="https://hauzzi.webkype.net/images/all/blog/1.jpg"
                                    className="gal-link popup-image"
                                  >
                                    <i className="fa-solid fa-search" />
                                  </a>
                                  <img
                                    src="https://hauzzi.webkype.net/images/all/blog/1.jpg"
                                    alt
                                  />
                                </div>
                              </div>
                              <div className="slick-slide-item">
                                <div className="box-item">
                                  <a
                                    href="https://hauzzi.webkype.net/images/all/blog/2.jpg"
                                    className="gal-link popup-image"
                                  >
                                    <i className="fa-solid fa-search" />
                                  </a>
                                  <img
                                    src="https://hauzzi.webkype.net/images/all/blog/2.jpg"
                                    alt='property'
                                  />
                                </div>
                              </div>
                              <div className="slick-slide-item">
                                <div className="box-item">
                                  <a
                                    href="https://hauzzi.webkype.net/images/all/blog/3.jpg"
                                    className="gal-link popup-image"
                                  >
                                    <i className="fa-solid fa-search" />
                                  </a>
                                  <img
                                    src="https://hauzzi.webkype.net/images/all/blog/3.jpg"
                                    alt
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="swiper-button-prev ssw-btn">
                              <i className="fa-solid fa-caret-left" />
                            </div>
                            <div className="swiper-button-next ssw-btn">
                              <i className="fa-solid fa-caret-right" />
                            </div>
                          </div>
                        </div>
                        <div className="list-single-main-item fl-wrap block_box">
                          <h2 className="post-opt-title">
                            <a href="blog-single.html">
                              Best House to Your Family .
                            </a>
                          </h2>
                          <p>
                            Ut euismod ultricies sollicitudin. Curabitur sed
                            dapibus nulla. Nulla eget iaculis lectus. Mauris ac
                            maximus neque. Nam in mauris quis libero sodales
                            eleifend. Morbi varius, nulla sit amet rutrum
                            elementum, est elit finibus tellus, ut tristique
                            elit risus at metus.
                          </p>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in pulvinar neque. Nulla finibus
                            lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar
                            nisi tincidunt...
                          </p>
                          <span className="fw-separator fl-wrap" />
                          <div className="post-author">
                            <a href="#">
                              <img
                                src="https://hauzzi.webkype.net/images/avatar/5.jpg"
                                alt
                              />
                              <span>By , Alisa Noory</span>
                            </a>
                          </div>
                          <div className="post-opt">
                            <ul className="no-list-style">
                              <li>
                                <i className="fa-solid fa-calendar" />{" "}
                                <span>15 April 2019</span>
                              </li>
                              <li>
                                <i className="fa-solid fa-eye" />{" "}
                                <span>164</span>
                              </li>
                              <li>
                                <i className="fa-solid fa-tags" />{" "}
                                <a href="#">Shop</a> , <a href="#">Hotels</a>{" "}
                              </li>
                            </ul>
                          </div>
                          <a
                            href="blog-single.html"
                            className="btn color-bg float-btn small-btn"
                          >
                            Read more
                          </a>
                        </div>
                      </article> */}
                      {/* article end */}
                      {/* article> */}
                      {/* <article className="post-article fl-wrap">
                        <div className="list-single-main-media fl-wrap">
                          <img
                            src="https://hauzzi.webkype.net/images/all/blog/4.jpg"
                            className="respimg"
                            alt
                          />
                        </div>
                        <div className="list-single-main-item fl-wrap block_box">
                          <h2 className="post-opt-title">
                            <a href="blog-single.html">
                              How to choose the right Agent.
                            </a>
                          </h2>
                          <p>
                            Ut euismod ultricies sollicitudin. Curabitur sed
                            dapibus nulla. Nulla eget iaculis lectus. Mauris ac
                            maximus neque. Nam in mauris quis libero sodales
                            eleifend. Morbi varius, nulla sit amet rutrum
                            elementum, est elit finibus tellus, ut tristique
                            elit risus at metus.
                          </p>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in pulvinar neque. Nulla finibus
                            lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar
                            nisi tincidunt...
                          </p>
                          <span className="fw-separator fl-wrap" />
                          <div className="post-author">
                            <a href="#">
                              <img
                                src="https://hauzzi.webkype.net/images/avatar/5.jpg"
                                alt
                              />
                              <span>By , Alisa Noory</span>
                            </a>
                          </div>
                          <div className="post-opt">
                            <ul className="no-list-style">
                              <li>
                                <i className="fa-solid fa-calendar" />{" "}
                                <span>15 April 2019</span>
                              </li>
                              <li>
                                <i className="fa-solid fa-eye" />{" "}
                                <span>164</span>
                              </li>
                              <li>
                                <i className="fa-solid fa-tags" />{" "}
                                <a href="#">Shop</a> , <a href="#">Hotels</a>{" "}
                              </li>
                            </ul>
                          </div>
                          <a
                            href="blog-single.html"
                            className="btn color-bg float-btn small-btn"
                          >
                            Read more
                          </a>
                        </div>
                      </article> */}
                      {/* article end */}
                      {/* article> */}
                      {/* <article className="post-article fl-wrap">
                        <div className="list-single-main-media fl-wrap">
                          <img
                            src="https://hauzzi.webkype.net/images/all/blog/2.jpg"
                            className="respimg"
                            alt
                          />
                        </div>
                        <div className="list-single-main-item fl-wrap block_box">
                          <h2 className="post-opt-title">
                            <a href="blog-single.html">
                              RealEstate Facts And Story
                            </a>
                          </h2>
                          <p>
                            Ut euismod ultricies sollicitudin. Curabitur sed
                            dapibus nulla. Nulla eget iaculis lectus. Mauris ac
                            maximus neque. Nam in mauris quis libero sodales
                            eleifend. Morbi varius, nulla sit amet rutrum
                            elementum, est elit finibus tellus, ut tristique
                            elit risus at metus.
                          </p>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in pulvinar neque. Nulla finibus
                            lobortis pulvinar. Donec a consectetur nulla. Nulla
                            posuere sapien vitae lectus suscipit, et pulvinar
                            nisi tincidunt...
                          </p>
                          <span className="fw-separator fl-wrap" />
                          <div className="post-author">
                            <a href="#">
                              <img
                                src="https://hauzzi.webkype.net/images/avatar/5.jpg"
                                alt
                              />
                              <span>By , Alisa Noory</span>
                            </a>
                          </div>
                          <div className="post-opt">
                            <ul className="no-list-style">
                              <li>
                                <i className="fa-solid fa-calendar" />{" "}
                                <span>15 April 2019</span>
                              </li>
                              <li>
                                <i className="fa-solid fa-eye" />{" "}
                                <span>164</span>
                              </li>
                              <li>
                                <i className="fa-solid fa-tags" />{" "}
                                <a href="#">Shop</a> , <a href="#">Hotels</a>{" "}
                              </li>
                            </ul>
                          </div>
                          <a
                            href="blog-single.html"
                            className="btn color-bg float-btn small-btn"
                          >
                            Read more
                          </a>
                        </div>
                      </article> */}
                      {/* article end */}
                      {/* pagination*/}
                      {/* <div className="pagination">
                        <a href="#" className="prevposts-link">
                          <i className="fa fa-caret-left" />
                        </a>
                        <a href="#">1</a>
                        <a href="#" className="current-page">
                          2
                        </a>
                        <a href="#">3</a>
                        <a href="#">4</a>
                        <a href="#" className="nextposts-link">
                          <i className="fa fa-caret-right" />
                        </a>
                      </div> */}
                      {/* pagination end*/}
                    </div>
                  </div>
                  {/* col-md 8 end */}
                  {/*  sidebar*/}
                  <div className="col-md-4">
                    <div className="box-widget-wrap fl-wrap fixed-bar">
                      {/*box-widget*/}
                      {/* <div className="box-widget fl-wrap">
                        <div className="search-widget fl-wrap">
                          <form action="#" className="fl-wrap custom-form">
                            <input
                              name="se"
                              id="se"
                              type="text"
                              className="search"
                              placeholder="Search.."
                              defaultValue
                            />
                            <button className="search-submit" id="submit_btn">
                              <i className="fa-solid fa-search" />
                            </button>
                          </form>
                        </div>
                      </div> */}
                      {/*box-widget end */}
                      {/*box-widget*/}
                      <div className="box-widget fl-wrap">
                        <div className="box-widget-title fl-wrap">
                          Popular Posts
                        </div>
                        <div className="box-widget-content fl-wrap">
                          {/*widget-posts*/}
                          <div className="widget-posts  fl-wrap">
                            <ul className="no-list-style">
                              {blogList?.map((data) => (
                                <li>
                                  <div className="widget-posts-img">
                                    <a href={data.slug}>
                                      <img
                                        src={`${apiUrl}/uploads/${data.photo}`}
                                        alt="blog"
                                        style={{ height: '60px' }}
                                      />
                                    </a>
                                  </div>
                                  <div className="widget-posts-descr">
                                    <h4>
                                      <a href={data.slug}>
                                        {data.title}
                                      </a>
                                    </h4>
                                    <div className="geodir-category-location fl-wrap">
                                      <a href="#1">
                                        <i className="fa-solid fa-calendar" /> {new Date(data.createdAt).toDateString()}
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              ))}
                              {/* <li>
                                <div className="widget-posts-img">
                                  <a href="blog-single.html">
                                    <img
                                      src="https://hauzzi.webkype.net/images/all/blog/2.jpg"
                                      alt
                                    />
                                  </a>
                                </div>
                                <div className="widget-posts-descr">
                                  <h4>
                                    <a href="listing-single.html">
                                      Scrambled it to mak
                                    </a>
                                  </h4>
                                  <div className="geodir-category-location fl-wrap">
                                    <a href="#">
                                      <i className="fa-solid fa-calendar" /> 12
                                      May 2020
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="widget-posts-img">
                                  <a href="blog-single.html">
                                    <img
                                      src="https://hauzzi.webkype.net/images/all/blog/6.jpg"
                                      alt
                                    />
                                  </a>{" "}
                                </div>
                                <div className="widget-posts-descr">
                                  <h4>
                                    <a href="listing-single.html">
                                      Fermentum nis type
                                    </a>
                                  </h4>
                                  <div className="geodir-category-location fl-wrap">
                                    <a href="#">
                                      <i className="fa-solid fa-calendar" />
                                      22 Feb 2020
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="widget-posts-img">
                                  <a href="blog-single.html">
                                    <img
                                      src="https://hauzzi.webkype.net/images/all/blog/4.jpg"
                                      alt
                                    />
                                  </a>{" "}
                                </div>
                                <div className="widget-posts-descr">
                                  <h4>
                                    <a href="listing-single.html">
                                      Rutrum elementum
                                    </a>
                                  </h4>
                                  <div className="geodir-category-location fl-wrap">
                                    <a href="#">
                                      <i className="fa-solid fa-calendar" /> 7
                                      Mar 2019
                                    </a>
                                  </div>
                                </div>
                              </li> */}
                            </ul>
                          </div>
                          {/* widget-posts end*/}
                        </div>
                      </div>
                      {/*box-widget end */}
                      {/*box-widget*/}
                      <div className="box-widget fl-wrap">
                        <div className="box-widget-title fl-wrap">
                          Categories
                        </div>
                        <div className="box-widget-content fl-wrap">
                          <ul className="cat-item no-list-style">
                            {categoryCount?.map((data) => (
                              <li>
                                <a href="#">{data.category}</a> <span>{data.count}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      {/*box-widget end */}
                      {/*box-widget*/}
                      {/* <div className="box-widget fl-wrap">
                        <div className="banner-widget fl-wrap">
                          <div className="bg-wrap bg-parallax-wrap-gradien">
                            <div
                              className="bg  "
                              data-bg="https://hauzzi.webkype.net/images/all/blog/1.jpg"
                            />
                          </div>
                          <div className="banner-widget_content">
                            <h5>
                              Do you want to join our real estate network?
                            </h5>
                            <a
                              href="#"
                              className="btn float-btn color-bg small-btn"
                            >
                              Become an Agent
                            </a>
                          </div>
                        </div>
                      </div> */}
                      {/*box-widget end */}
                      {/*box-widget*/}
                      {/*tags*/}
                      {/* <div className="box-widget fl-wrap">
                        <div className="box-widget-title fl-wrap">Tags</div>
                        <div className="box-widget-content fl-wrap">
                          <div
                            className="list-single-tags fl-wrap tags-stylwrap"
                            style={{ marginTop: 20 }}
                          >
                            <a href="#">Hotel</a>
                            <a href="#">Hostel</a>
                            <a href="#">Room</a>
                            <a href="#">Spa</a>
                            <a href="#">Restourant</a>
                            <a href="#">Parking</a>
                          </div>
                        </div>
                      </div> */}
                      {/*tags end*/}
                      {/*box-widget end */}
                      {/*box-widget*/}
                      {/* <div className="box-widget fl-wrap">
                        <div className="box-widget-title fl-wrap">Archive</div>
                        <div className="box-widget-content fl-wrap">
                          <ul className="cat-item cat-item_dec no-list-style">
                            <li>
                              <a href="#">March 2020</a>
                            </li>
                            <li>
                              <a href="#">May 2019</a>
                            </li>
                            <li>
                              <a href="#">January 2016</a>
                            </li>
                            <li>
                              <a href="#">Decemder 2018</a>{" "}
                            </li>
                          </ul>
                        </div>
                      </div> */}
                      {/*box-widget end */}
                    </div>
                    {/* sidebar end*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="limit-box fl-wrap" />
          </div>
          {/* content end */}
          {/* subscribe-wrap */}
          {/* <div className="subscribe-wrap fl-wrap">
            <div className="container">
              <div className="subscribe-container fl-wrap color-bg">
                <div className="pwh_bg" />
                <div className="mrb_dec mrb_dec3" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="subscribe-header">
                      <h4>newsletter</h4>
                      <h3>
                        Sign up for newsletter and get latest news and update
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-5">
                    <div className="footer-widget fl-wrap">
                      <div className="subscribe-widget fl-wrap">
                        <div className="subcribe-form">
                          <form id="subscribe">
                            <input
                              className="enteremail fl-wrap"
                              name="email"
                              id="subscribe-email"
                              placeholder="Enter Your Email"
                              spellCheck="false"
                              type="text"
                            />
                            <button
                              type="submit"
                              id="subscribe-button"
                              className="subscribe-button color-bg"
                            >
                              {" "}
                              Subscribe
                            </button>
                            <label
                              htmlFor="subscribe-email"
                              className="subscribe-message"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* subscribe-wrap end */}
          {/* footer */}
          <Footer />
          {/* footer end */}
        </div>

      </div>
    </div>
  );
}

export default Blog;
