import React from 'react'
import AddListSideBar from './addListSideBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MapContainer from './mapContainer'


function AddListing() {
  return (
    <div>

      <AddListSideBar />
      <div className="dashboard-content" style={{ marginTop: '10vh', background: '#f5f7fb' }}>
        <div className="dashboard-menu-btn color-bg"><span><i className="fas fa-bars"></i></span>Dasboard Menu</div>
        <div className="container dasboard-container" style={{zIndex:10}}>
          <div className="dashboard-title fl-wrap">
            <div className="dashboard-title-item"><span>Add Listing</span></div>
            <div className="dashbard-menu-header">
              <div className="dashbard-menu-avatar fl-wrap">
                <img src="images/avatar/5.jpg" alt="" />
                <h4>Welcome, <span>Alica Noory</span></h4>
              </div>
              <a href="index.html" className="log-out-btn   tolt" data-microtip-position="bottom" data-tooltip="Log Out"><i className="far fa-power-off"></i></a>
            </div>
            <div className="tfp-det-container">
              <div className="tfp-btn"><span>Your Tariff Plan : </span> <strong>Extended</strong></div>
              <div className="tfp-det">
                <p>You Are on <a href="#">Extended</a> . Use link bellow to view details or upgrade. </p>
                <a href="#" className="tfp-det-btn color-bg">Details</a>
              </div>
            </div>
          </div>
          <div className="dasboard-wrapper fl-wrap no-pag">
            <div className="dasboard-scrollnav-wrap scroll-init2 fl-wrap" style={{ zIndex: 10, top: '10vh', position: 'sticky', background: 'white' }}>
              <ul>
                <li><a href="#sec1" className="act-scrlink">Info</a></li>
                <li><a href="#sec2" className="">Location</a></li>
                <li><a href="#sec3" className="">Media</a></li>
                <li><a href="#sec4" className="">Details</a></li>
                <li><a href="#sec5" className="">Rooms</a></li>
                <li><a href="#sec6">Plans</a></li>
                <li><a href="#sec7" className="">Widgets</a></li>
              </ul>
              <div className="progress-indicator">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                  <circle cx="16" cy="16" r="15.9155" className="progress-bar__background"></circle>
                  <circle cx="16" cy="16" r="15.9155" className="progress-bar__progress 
                                            js-progress-bar"></circle>
                </svg>
              </div>
            </div>
            <div>
            </div>
            <div className="dasboard-widget-title fl-wrap" id="sec1">
              <h5><FontAwesomeIcon icon="fa-solid  fa-info"></FontAwesomeIcon>Basic Informations</h5>
            </div>
            <div className="dasboard-widget-box fl-wrap">
              <div className="custom-form">
                <div className="row">
                  <div className="col-sm-4">
                    <label>Listing Title  <span className="dec-icon"><i className="far fa-briefcase"></i></span></label>
                    <input type="text" placeholder="Name of your business" />
                  </div>
                  <div className="col-sm-4">
                    <label>Type</label>
                    <select className="nice-select" name='type'>
                      <option>All Types</option>
                      <option>Rent</option>
                      <option>Sale</option>
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label>Listing Price<span className="dec-icon"><FontAwesomeIcon icon="far fa-money-bill-wave"></FontAwesomeIcon></span></label>
                    <input type="text" placeholder="Listing Price" />
                  </div>
                  <div className="col-sm-4">
                    <label>Category</label>
                    <select data-placeholder="Apartments" className="nice-select">
                      <option>All Categories</option>
                      <option>House</option>
                      <option>Apartment</option>
                      <option>Hotel</option>
                      <option>Villa</option>
                      <option>Office</option>
                    </select>
                  </div>
                  <div className="col-sm-8">
                    <label>Keywords  <span className="dec-icon"><i className="far fa-key"></i></span></label>
                    <input type="text" placeholder="Maximum 15 , should be separated by commas" />
                  </div>
                </div>
              </div>
            </div>
            <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec2">
              <h5><i className="fas fa-street-view"></i>Location / Contacts</h5>
            </div>
            <div className="dasboard-widget-box   fl-wrap">
              <div className="custom-form">
                <div className="row">
                  <div className="col-md-4">
                    <label>Address <span className="dec-icon"><i className="far fa-map-marker"></i></span></label>
                    <input type="text" placeholder="Address of your business" />
                  </div>
                  <div className="col-sm-4">
                    <label>Longitude (Drag marker on the map)  <span className="dec-icon"><i className="far fa-long-arrow-alt-right"></i></span></label>
                    <input type="text" id="long" placeholder="Map Longitude" />
                  </div>
                  <div className="col-sm-4">
                    <label>Latitude (Drag marker on the map)<span className="dec-icon"><i className="far fa-long-arrow-alt-down"></i> </span></label>
                    <input type="text" id="lat" placeholder="Map Latitude" />
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="map-container">
                  <MapContainer />
                </div>
                <div className="clearfix"></div>
                <div className="row">
                  <div className="col-sm-6">
                    <label>City</label>
                    <select data-placeholder="Apartments" className='nice-select'>
                      <option>All Cities</option>
                      <option>New York</option>
                      <option>London</option>
                      <option>Paris</option>
                      <option>Kiev</option>
                      <option>Moscow</option>
                      <option>Dubai</option>
                      <option>Rome</option>
                      <option>Beijing</option>
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label>Email Address <span className="dec-icon"><i className="far fa-envelope"></i></span>  </label>
                    <input type="text" placeholder="JessieManrty@domain.com" />
                  </div>
                  <div className="col-sm-6">
                    <label>Phone <span className="dec-icon"><i className="far fa-phone"></i> </span> </label>
                    <input type="text" placeholder="+7(123)987654" />
                  </div>
                  <div className="col-sm-6">
                    <label> Website <span className="dec-icon"><i className="far fa-globe"></i> </span> </label>
                    <input type="text" placeholder="themeforest.net" />
                  </div>
                </div>
              </div>
            </div>
            <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec3">
              <h5><i className="fas fa-image"></i>Header Media</h5>
            </div>
            <div className="dasboard-widget-box   fl-wrap">
              <div className="custom-form">
                <ul className="fl-wrap filter-tags no-list-style ds-tg">
                  <li>
                    <input id="check-aaa5" type="checkbox" name="check" />
                    <label for="check-aaa5"> Carousel</label>
                  </li>
                  <li>
                    <input id="check-bb5" type="checkbox" name="check" />
                    <label for="check-bb5">Slider</label>
                  </li>
                  <li>
                    <input id="check-dd5" type="checkbox" name="check" />
                    <label for="check-dd5"> Background Image</label>
                  </li>
                </ul>
                <div className="clearfix"></div>
                <div className="listsearch-input-item fl-wrap">
                  <form className="fuzone">
                    <div className="fu-text">
                      <span><i className="far fa-cloud-upload-alt"></i> Click here or drop files to upload</span>
                      <div className="photoUpload-files fl-wrap"></div>
                    </div>
                    <input type="file" className="upload" multiple="" />
                  </form>
                </div>
              </div>
            </div>
            <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec4">
              <h5><i className="fas fa-list"></i>Listing Details</h5>
            </div>
            <div className="dasboard-widget-box   fl-wrap">
              <div className="custom-form">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-6">
                        <label>Area: <span className="dec-icon"><i className="far fa-sort-size-down-alt"></i></span></label>
                        <input type="text" placeholder="House Area" />
                        <label>Accomodation: <span className="dec-icon"><i className="far fa-users"></i></span></label>
                        <input type="text" placeholder="Listing Accomodation" />
                        <label>Yard size: <span className="dec-icon"><i className="far fa-trees"></i></span></label>
                        <input type="text" placeholder="Yard size" />
                      </div>
                      <div className="col-sm-6">
                        <label>Bedrooms: <span className="dec-icon"><i className="far fa-bed"></i></span></label>
                        <input type="text" placeholder="House Bedrooms" />
                        <label>Bathrooms: <span className="dec-icon"><i className="far fa-bath"></i></span></label>
                        <input type="text" placeholder="House Bathrooms" />
                        <label>Garage: <span className="dec-icon"><i className="far fa-warehouse"></i></span></label>
                        <input type="text" placeholder="Number of cars" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label>Details Text</label>
                    <div className="listsearch-input-item">
                      <textarea cols="40" rows="3" style={{ height: "235px" }} placeholder="Datails" spellcheck="false"></textarea>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
                <label>Amenities: </label>
                <div className=" add-list-tags fl-wrap">
                  <ul className="fl-wrap filter-tags no-list-style ds-tg">
                    <li>
                      <input id="check-aaa5" type="checkbox" name="check" />
                      <label for="check-aaa5"> Wi Fi</label>
                    </li>
                    <li>
                      <input id="check-bb5" type="checkbox" name="check" />
                      <label for="check-bb5">Pool</label>
                    </li>
                    <li>
                      <input id="check-dd5" type="checkbox" name="check" />
                      <label for="check-dd5"> Security</label>
                    </li>
                    <li>
                      <input id="check-cc5" type="checkbox" name="check" />
                      <label for="check-cc5"> Laundry Room</label>
                    </li>
                    <li>
                      <input id="check-ff5" type="checkbox" name="check" />
                      <label for="check-ff5"> Equipped Kitchen</label>
                    </li>
                    <li>
                      <input id="check-c4" type="checkbox" name="check" />
                      <label for="check-c4">Air Conditioning</label>
                    </li>
                    <li>
                      <input id="check-c18" type="checkbox" name="check" />
                      <label for="check-c18">Parking</label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec5">
              <h5><i className="fas fa-home-lg-alt"></i>Rooms</h5>
              <div className="onoffswitch">
                <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch5" />
                <label className="onoffswitch-label" for="myonoffswitch5">
                  <span className="onoffswitch-inner"></span>
                  <span className="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
            <div className="dasboard-widget-box   fl-wrap">
              <div className="custom-form add_room-item-wrap">
                <div className="add_room-container fl-wrap">
                  <div className="add_room-item fl-wrap">
                    <span className="remove-rp tolt" data-microtip-position="left" data-tooltip="Remove Room"><i className="fa-solid  fa-times-circle"></i></span>
                    <div className="row">
                      <div className="col-sm-6">
                        <label>Room Title: <span className="dec-icon"><i className="fa-solid  fa-layer-group"></i></span></label>
                        <input type="text" placeholder="Standard Family Room" />
                      </div>
                      <div className="col-sm-6">
                        <label>Additional Room: <span className="dec-icon"><i className="fa-solid  fa-layer-plus"></i></span></label>
                        <input type="text" placeholder="Example: Sauna" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <label>Room Details</label>
                        <div className="listsearch-input-item">
                          <textarea cols="40" rows="3" style={{ height: "175px", marginBottom: "10px" }} placeholder="Datails" spellcheck="false"></textarea>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label>Room Images</label>
                        <div className="listsearch-input-item fl-wrap">
                          <form className="fuzone">
                            <div className="fu-text">
                              <span><i className="far fa-cloud-upload-alt"></i> Click here or drop files to upload</span>
                              <div className="photoUpload-files fl-wrap"></div>
                            </div>
                            <input type="file" className="upload" multiple="" />
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <label>Amenities: </label>
                    <div className=" add-list-tags fl-wrap">
                      <ul className="fl-wrap filter-tags no-list-style ds-tg">
                        <li>
                          <input id="check-2aaa5" type="checkbox" name="check" />
                          <label for="check-2aaa5">Air conditioner</label>
                        </li>
                        <li>
                          <input id="check-2bb5" type="checkbox" name="check" />
                          <label for="check-2bb5">Tv Inside</label>
                        </li>
                        <li>
                          <input id="check-2dd5" type="checkbox" name="check" />
                          <label for="check-2dd5"> Ceramic bath</label>
                        </li>
                        <li>
                          <input id="check-2cc5" type="checkbox" name="check" />
                          <label for="check-2cc5">Microwave</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <a href="#" className="add-room-item">Add New <i className="fa-solid  fa-plus"></i> </a>
              </div>
            </div>
            <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec6">
              <h5><i className="fas fa-ruler-combined"></i>House Plans </h5>
              <div className="onoffswitch">
                <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch22" />
                <label className="onoffswitch-label" for="myonoffswitch22">
                  <span className="onoffswitch-inner"></span>
                  <span className="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
            <div className="dasboard-widget-box   fl-wrap">
              <div className="custom-form add_room-item-wrap">
                <div className="add_room-container fl-wrap">
                  <div className="add_room-item fl-wrap">
                    <span className="remove-rp tolt" data-microtip-position="left" data-tooltip="Remove"><i className="fa-solid  fa-times-circle"></i></span>
                    <div className="row">
                      <div className="col-sm-7">
                        <div className="row">
                          <div className="col-sm-6">
                            <label>Plan Title: <span className="dec-icon"><i className="far fa-ruler-horizontal"></i></span></label>
                            <input type="text" placeholder=" First Floor Plan " />
                          </div>
                          <div className="col-sm-6">
                            <label>Plan Optional info: <span className="dec-icon"><i className="far fa-ruler-horizontal"></i></span></label>
                            <input type="text" placeholder="Example: 286 sq ft" />
                          </div>
                        </div>
                        <label>Plan Details</label>
                        <div className="listsearch-input-item">
                          <textarea cols="40" rows="3" style={{ height: "85px" }} placeholder="Datails" spellcheck="false"></textarea>
                        </div>
                      </div>
                      <div className="col-sm-5">
                        <label>Upload Images</label>
                        <div className="listsearch-input-item fl-wrap">
                          <form className="fuzone">
                            <div className="fu-text">
                              <span><i className="far fa-cloud-upload-alt"></i> Click here or drop files to upload</span>
                              <div className="photoUpload-files fl-wrap"></div>
                            </div>
                            <input type="file" className="upload" />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="#" className="add-room-item">Add New <i className="fa-solid  fa-plus"></i> </a>
              </div>
            </div>
            <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec7">
              <h5><i className="fas fa-sliders-h"></i>Content Widgets</h5>
            </div>
            <div className="dasboard-widget-box   fl-wrap">
              <div className="custom-form">
                <div className="row">
                  <div className="col-md-4">
                    <div className="content-widget-switcher fl-wrap">
                      <span className="content-widget-switcher-title">Video Presentation</span>
                      <div className="onoffswitch">
                        <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitchmc" />
                        <label className="onoffswitch-label" for="myonoffswitchmc">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                      <div className="content-widget-switcher-item fl-wrap">
                        <label>Video Youtube: <span className="dec-icon"><i className="fab fa-youtube"></i></span></label>
                        <input type="text" placeholder="Youtube Or Vimeo" />
                        <label>Video VImeo: <span className="dec-icon"><i className="fab fa-vimeo-v"></i></span></label>
                        <input type="text" placeholder="Youtube Or Vimeo" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="content-widget-switcher fl-wrap">
                      <span className="content-widget-switcher-title">Propertie Documents</span>
                      <div className="onoffswitch">
                        <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitchmc523" />
                        <label className="onoffswitch-label" for="myonoffswitchmc523">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                      <div className="content-widget-switcher-item fl-wrap">
                        <form className="fuzone">
                          <div className="fu-text">
                            <span><i className="far fa-cloud-upload-alt"></i> Click here or drop files to upload</span>
                            <div className="photoUpload-files fl-wrap"></div>
                          </div>
                          <input type="file" className="upload" multiple="" />
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="content-widget-switcher fl-wrap">
                      <span className="content-widget-switcher-title">Mortgage Calculator</span>
                      <div className="onoffswitch">
                        <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitchmc423" />
                        <label className="onoffswitch-label" for="myonoffswitchmc423">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                    <div className="content-widget-switcher fl-wrap" style={{ marginTop: "20px" }}>
                      <span className="content-widget-switcher-title">Google Map</span>
                      <div className="onoffswitch">
                        <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitchmc923" />
                        <label className="onoffswitch-label" for="myonoffswitchmc923">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                    <div className="content-widget-switcher fl-wrap" style={{ marginTop: "20px" }}>
                      <span className="content-widget-switcher-title">Contact Form</span>
                      <div className="onoffswitch">
                        <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitchmc`23" />
                        <label className="onoffswitch-label" for="myonoffswitchmc`23">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="#" className="btn text-white p-3" style={{backgroundColor:'black'}}>Save Changes </a>
          </div>
        </div>
        <div className="limit-box fl-wrap"></div>
        <div className="dashboard-footer">
          <div className="dashboard-footer-links fl-wrap">
            <span>Helpfull Links:</span>
            <ul>
              <li><a href="about.html">About  </a></li>
              <li><a href="blog.html">Blog</a></li>
              <li><a href="pricing.html">Pricing Plans</a></li>
              <li><a href="contacts.html">Contacts</a></li>
              <li><a href="help.html">Help Center</a></li>
            </ul>
          </div>
          <a href="#main" className="dashbord-totop  custom-scroll-link"><i className="fas fa-caret-up"></i></a>
        </div>
      </div>
    </div >
  )
}

export default AddListing
